import React from 'react';
import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  Input,
  Typography
} from '@mui/material';
import { Building, Mail, Link, Upload, Key } from 'lucide-react';

function CompanyFormMain({ company, onInputChange, validationErrors, onLogoChange, logoFile }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          autoFocus
          margin="dense"
          name="name"
          label="Nome"
          type="text"
          fullWidth
          value={company.name}
          onChange={onInputChange}
          error={!!validationErrors.name}
          helperText={validationErrors.name}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Building />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          margin="dense"
          name="email"
          label="Email"
          type="email"
          fullWidth
          value={company.email}
          onChange={onInputChange}
          error={!!validationErrors.email}
          helperText={validationErrors.email}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Mail />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          margin="dense"
          name="link_gdpr"
          label="Link GDPR"
          type="url"
          fullWidth
          value={company.link_gdpr}
          onChange={onInputChange}
          error={!!validationErrors.link_gdpr}
          helperText={validationErrors.link_gdpr}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Link />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          margin="dense"
          name="link_website"
          label="Link Website"
          type="url"
          fullWidth
          value={company.link_website}
          onChange={onInputChange}
          error={!!validationErrors.link_website}
          helperText={validationErrors.link_website}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Link />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          margin="dense"
          name="link_marketing_consent"
          label="Link Marketing Consent"
          type="url"
          fullWidth
          value={company.link_marketing_consent}
          onChange={onInputChange}
          error={!!validationErrors.link_marketing_consent}
          helperText={validationErrors.link_marketing_consent}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Link />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          margin="dense"
          name="sitemap_link"
          label="Sitemap Link"
          type="url"
          fullWidth
          value={company.sitemap_link}
          onChange={onInputChange}
          error={!!validationErrors.sitemap_link}
          helperText={validationErrors.sitemap_link}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Link />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          margin="dense"
          name="codice_concessionario"
          label="Codice concessionario"
          type="text"
          fullWidth
          value={company.codice_concessionario}
          onChange={onInputChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Building />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          margin="dense"
          name="openai_api_key"
          label="OpenAI API Key"
          type="password"
          fullWidth
          value={company.openai_api_key || ''}
          onChange={onInputChange}
          error={!!validationErrors.openai_api_key}
          helperText={validationErrors.openai_api_key}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Key />
              </InputAdornment>
            )
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          type="file"
          onChange={onLogoChange}
          sx={{ display: 'none' }}
          id="logo-upload"
        />
        <label htmlFor="logo-upload">
          <Button variant="outlined" component="span" startIcon={<Upload />}>
            {company.logo_url ? 'Cambia Logo' : 'Upload Logo'}
          </Button>
        </label>
        {logoFile && (
          <Typography variant="body2" sx={{ mt: 1 }}>
            Nuovo file selezionato: {logoFile.name}
          </Typography>
        )}
        {company.logo_url && (
          <Grid container justifyContent="center" sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              Logo attuale:
            </Typography>
            <img
              src={company.logo_url}
              alt={`${company.name} logo`}
              style={{
                width: 150,
                height: 150,
                objectFit: 'contain',
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '8px'
              }}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default CompanyFormMain; 
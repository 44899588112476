import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  Box,
  Typography,
  TextField,
  Button,
  CssBaseline,
  Grid,
  createTheme,
  ThemeProvider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Input,
  Snackbar,
  Alert,
  Tooltip,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  Fade,
  Grow,
  Zoom,
  Paper,
  InputAdornment,
  FormControlLabel,
  Switch
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { itIT } from '@mui/material/locale';
import {
    fetchCompanies,
    createCompany,
    updateCompany,
    deleteCompany,
    fetchCurrentUserRole,
    uploadLogo,
    getTaskProgress
  } from '../components/api/company';
  
  
import {
  MapPin,
  Mail,
  Building,
  Edit,
  Trash2,
  Plus,
  X,
  ChevronDown,
  Phone,
  Upload,
  Info,
  Bot,
  Bell,
  Link
} from 'lucide-react';
import AIManagement from '../components/aimanagement/AiManagement';
import CompanyDialog from '../components/aziende/CompanyDialog';
import CompanyCard from '../components/aziende/CompanyCard';
import CompanyGrid from '../components/aziende/CompanyGrid';
import ConfirmDeleteDialog from '../components/aziende/ConfirmDeleteDialog';
import {
    fetchBranches,
    fetchBranchDefinitions,
    createBranchDefinition,
    createBranch,
    updateBranch,
    updateBranchDefinition,
    deleteBranchesForDefinition,
    deleteBranch,
    deleteBranchDefinition
} from '../components/api/branch';
import { createLocation, updateLocation } from '../components/api/location';
import theme from '../components/aziende/theme';
import { urlBase64ToUint8Array } from '../utils/utils';

// 1. Create custom hooks for state management
const useCompanyState = () => {
  const [rows, setRows] = useState([]);
  const [currentCompany, setCurrentCompany] = useState({
    name: '',
    codice_concessionario: '',
    email: '',
    link_gdpr: '',
    link_website: '',
    link_marketing_consent: '',
    sitemap_link: '',
    locations: [],
    branches: []
  });
  const [currentUser, setCurrentUser] = useState(null);
  const [removedBranchIds, setRemovedBranchIds] = useState([]);
  const [logoFile, setLogoFile] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});

  return {
    rows, setRows,
    currentCompany, setCurrentCompany,
    currentUser, setCurrentUser,
    removedBranchIds, setRemovedBranchIds,
    logoFile, setLogoFile,
    validationErrors, setValidationErrors
  };
};

const useDialogState = () => {
  const [open, setOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [companyToDelete, setCompanyToDelete] = useState(null);
  const [openAIDialog, setOpenAIDialog] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);

  return {
    open, setOpen,
    confirmDeleteOpen, setConfirmDeleteOpen,
    companyToDelete, setCompanyToDelete,
    openAIDialog, setOpenAIDialog,
    selectedCompanyId, setSelectedCompanyId
  };
};

// 2. Create a custom hook for snackbar
const useSnackbar = () => {
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  
  const showSnackbar = useCallback((message, severity) => {
    setSnackbar({ open: true, message, severity });
  }, []);

  return { snackbar, setSnackbar, showSnackbar };
};

// Add this after the useSnackbar hook
const useNotificationState = () => {
  const [subscription, setSubscription] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [notificationPermission, setNotificationPermission] = useState(Notification.permission);
  
  return {
    subscription, setSubscription,
    isSubscribed, setIsSubscribed,
    notificationPermission, setNotificationPermission
  };
};

// 3. Main component
const PaginaAziende = () => {
  const [isLoading, setIsLoading] = useState(true);
  const companyState = useCompanyState();
  const dialogState = useDialogState();
  const { snackbar, setSnackbar, showSnackbar } = useSnackbar();
  const notificationState = useNotificationState();

  const fetchDataCompanies = useCallback(async () => {
    setIsLoading(true);
    try {
      const [companiesData, userData] = await Promise.all([
        fetchCompanies(),
        fetchCurrentUserRole()
      ]);
      
      companyState.setCurrentUser(userData.role);
      companyState.setRows(
        userData.role === 'superadmin'
          ? companiesData
          : companiesData && companiesData.length > 0
          ? [companiesData[0]]
          : []
      );
    } catch (error) {
      console.error('Error fetching data:', error);
      showSnackbar('Error fetching data. Please try again.', 'error');
    } finally {
      setIsLoading(false);
    }
  }, [showSnackbar]);

  useEffect(() => {
    fetchDataCompanies();
  }, [fetchDataCompanies]);

  const handleOpenAIDialog = useCallback((companyId) => {
    dialogState.setSelectedCompanyId(companyId);
    dialogState.setOpenAIDialog(true);
  }, []);

  const handleCloseAIDialog = useCallback(() => {
    dialogState.setOpenAIDialog(false);
    dialogState.setSelectedCompanyId(null);
  }, []);

  const handleAdd = useCallback(() => {
    if (companyState.currentUser !== 'superadmin') {
      showSnackbar('Only superadmins can create new companies', 'warning');
      return;
    }
    companyState.setCurrentCompany({
      name: '',
      codice_concessionario: '',
      email: '',
      link_gdpr: '',
      link_website: '',
      link_marketing_consent: '',
      sitemap_link: '',
      locations: [],
      branches: []
    });
    companyState.setLogoFile(null);
    dialogState.setOpen(true);
  }, [companyState.currentUser, showSnackbar]);

  const handleEdit = useCallback(
    async (row) => {
      if (companyState.currentUser !== 'superadmin' && companyState.currentUser !== 'admin') {
        showSnackbar('Only superadmins and admins can edit companies', 'warning');
        return;
      }
      try {
        // First, fetch branch definitions for the company
        const branchDefinitions = await fetchBranchDefinitions(row.id);
        
        // Then fetch branches for each definition
        const branchesPromises = branchDefinitions.map(definition =>
          fetchBranches(definition.id, row.id)
        );
        const branchesResults = await Promise.all(branchesPromises);
        
        // Combine branch definitions with their branch values into a single array
        const branches = branchDefinitions.map((def, index) => {
          const branchesForDef = branchesResults[index] || [];
          return branchesForDef.map(branch => ({
            id: branch.id,
            value: branch.value, // Use the actual branch value
            branch_definition: {
              id: def.id,
              name: def.name,
              description: def.description
            },
            branch_definition_id: def.id, // Add this for consistency
            branch_definition_name: def.name, // Add this for consistency
            branch_definition_description: def.description // Add this for consistency
          }));
        }).flat();

        // Update the current company with the correct branch information
        companyState.setCurrentCompany({
          ...row,
          branches: branches
        });
        companyState.setLogoFile(null);
        dialogState.setOpen(true);
      } catch (error) {
        console.error('Error fetching branches:', error);
        showSnackbar('Error loading company branches', 'error');
      }
    },
    [companyState.currentUser, showSnackbar]
  );

  const handleDelete = useCallback(
    (id) => {
      if (companyState.currentUser !== 'superadmin') {
        showSnackbar('Only superadmins can delete companies', 'warning');
        return;
      }
      dialogState.setCompanyToDelete(id);
      dialogState.setConfirmDeleteOpen(true);
    },
    [companyState.currentUser, showSnackbar]
  );

  const confirmDelete = useCallback(async () => {
    if (dialogState.companyToDelete) {
      setIsLoading(true);
      try {
        await deleteCompany(dialogState.companyToDelete);
        companyState.setRows((prevRows) => prevRows.filter((row) => row.id !== dialogState.companyToDelete));
        showSnackbar('Company deleted successfully', 'success');
      } catch (error) {
        console.error('Error deleting company:', error);
        showSnackbar('Error deleting company', 'error');
      } finally {
        setIsLoading(false);
        dialogState.setConfirmDeleteOpen(false);
        dialogState.setCompanyToDelete(null);
      }
    }
  }, [dialogState.companyToDelete, showSnackbar]);

  const handleClose = useCallback(() => {
    dialogState.setOpen(false);
    companyState.setCurrentCompany({
      name: '',
      codice_concessionario: '',
      email: '',
      link_gdpr: '',
      link_website: '',
      link_marketing_consent: '',
      sitemap_link: '',
      locations: [],
      branches: []
    });
    companyState.setLogoFile(null);
    companyState.setValidationErrors({});
  }, []);

  const handleBranchChange = useCallback((index, field, value) => {
    companyState.setCurrentCompany((prevCompany) => {
      const updatedBranches = [...(prevCompany.branches || [])];
      updatedBranches[index] = { ...updatedBranches[index], [field]: value };
      return { ...prevCompany, branches: updatedBranches };
    });
  }, []);

  const addBranch = useCallback((branchDefinitionId, isValue) => {
    companyState.setCurrentCompany((prevCompany) => {
      const updatedBranches = [...(prevCompany.branches || [])];
      
      if (isValue) {
        // Add a new branch value for a given branch definition.
        const matchingBranch = updatedBranches.find(
          (b) => b.branch_definition_id === branchDefinitionId
        );
        const newBranch = {
          branch_definition_id: branchDefinitionId,
          branch_definition_name: matchingBranch ? matchingBranch.branch_definition_name : '',
          branch_definition_description: matchingBranch ? matchingBranch.branch_definition_description : '',
          value: ''
        };
        updatedBranches.push(newBranch);
      } else {
        // Add a new branch definition (unsaved) with a tempKey
        updatedBranches.push({
          tempKey: `temp-${Date.now()}-${Math.random()}`,
          branch_definition_name: '',
          branch_definition_description: '',
          value: ''
        });
      }
      
      return { ...prevCompany, branches: updatedBranches };
    });
  }, []);

  const removeBranch = useCallback((index) => {
    companyState.setCurrentCompany((prevCompany) => {
      const branchToRemove = prevCompany.branches[index];
      if (branchToRemove?.id) {
        companyState.setRemovedBranchIds(prev => [...prev, branchToRemove.id]);
      }
      return {
        ...prevCompany,
        branches: prevCompany.branches.filter((_, i) => i !== index)
      };
    });
  }, []);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    companyState.setCurrentCompany((prevCompany) => ({ ...prevCompany, [name]: value }));
  }, []);

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      companyState.setLogoFile(file);
    }
  };

  const handleLocationChange = useCallback((index, field, value) => {
    companyState.setCurrentCompany((prevCompany) => {
        const updatedLocations = [...prevCompany.locations];
        
        if (field === 'locations') {
            // Handle bulk update of locations
            return { ...prevCompany, locations: value };
        } else if (field === 'all') {
            // Handle full location replacement
            updatedLocations[index] = value;
            return { ...prevCompany, locations: updatedLocations };
        } else {
            // Handle single field update
            updatedLocations[index] = {
                ...updatedLocations[index],
                [field]: value
            };
            return { ...prevCompany, locations: updatedLocations };
        }
    });
  }, []);

  const addLocation = useCallback(() => {
    companyState.setCurrentCompany((prevCompany) => ({
      ...prevCompany,
      locations: [
        ...prevCompany.locations,
        {
          email: '',
          numero_di_telefono: '',
          citta: '',
          inidirizzo: '',
          cap: '',
          is_branch: false,
          branch: '',
          descrizione_branch: ''
        }
      ]
    }));
  }, []);

  const removeLocation = useCallback((index) => {
    companyState.setCurrentCompany((prevCompany) => ({
      ...prevCompany,
      locations: prevCompany.locations.filter((_, i) => i !== index)
    }));
  }, []);

  const handleMainSave = useCallback(async () => {
    // Validate only main fields
    const errors = {};
    if (!companyState.currentCompany.name) errors.name = 'Name is required';
    if (!companyState.currentCompany.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(companyState.currentCompany.email)) {
      errors.email = 'Email is invalid';
    }
    if (!companyState.currentCompany.link_marketing_consent)
      errors.link_marketing_consent = 'Marketing consent link is required';
    if (!companyState.currentCompany.sitemap_link)
      errors.sitemap_link = 'Sitemap link is required';
    if (!companyState.currentCompany.link_website)
      errors.link_website = 'Website link is required';
    if (!companyState.currentCompany.link_gdpr)
      errors.link_gdpr = 'GDPR link is required';
    if (!companyState.currentCompany.openai_api_key)
      errors.openai_api_key = 'OpenAI API key is required';

    companyState.setValidationErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setIsLoading(true);
    try {
      let updatedCompany;
      const mainCompanyData = {
        name: companyState.currentCompany.name,
        codice_concessionario: companyState.currentCompany.codice_concessionario,
        email: companyState.currentCompany.email,
        link_gdpr: companyState.currentCompany.link_gdpr,
        link_website: companyState.currentCompany.link_website,
        link_marketing_consent: companyState.currentCompany.link_marketing_consent,
        sitemap_link: companyState.currentCompany.sitemap_link,
        openai_api_key: companyState.currentCompany.openai_api_key
      };

      if (companyState.currentCompany.id) {
        updatedCompany = await updateCompany(companyState.currentCompany.id, mainCompanyData);
      } else {
        updatedCompany = await createCompany(mainCompanyData);
      }
      // Handle logo upload if needed
      if (companyState.logoFile) {
        try {
          const logoResponse = await uploadLogo(updatedCompany.id, companyState.logoFile);
          updatedCompany = { ...updatedCompany, logo_url: logoResponse.logo_url };
        } catch (logoError) {
          console.error('Error uploading logo:', logoError);
        }
      }
      companyState.setCurrentCompany(updatedCompany);
      companyState.setRows(prevRows =>
        companyState.currentCompany.id
          ? prevRows.map(row => (row.id === updatedCompany.id ? updatedCompany : row))
          : [...prevRows, updatedCompany]
      );
      showSnackbar(
        companyState.currentCompany.id ? 'Company updated successfully' : 'Company created successfully',
        'success'
      );
    } catch (error) {
      console.error('Error saving company main info:', error);
      const errorMessage =
        error.response?.data?.message ||
        error.response?.data?.error ||
        error.message ||
        'Error saving company main info';
      showSnackbar(errorMessage, 'error');
    } finally {
      setIsLoading(false);
    }
  }, [companyState.currentCompany, companyState.logoFile, showSnackbar]);

  const handleLocationsSave = useCallback(async () => {
    if (!companyState.currentCompany.id) {
      showSnackbar('Save main company info first', 'warning');
      return;
    }
    setIsLoading(true);
    try {
      const updatedLocations = [];
      

      companyState.setCurrentCompany(prev => ({
        ...prev,
        locations: updatedLocations
      }));
      showSnackbar('Locations saved successfully', 'success');
    } catch (error) {
      console.error('Error saving locations:', error);
      showSnackbar(error.message || 'Error saving locations', 'error');
    } finally {
      setIsLoading(false);
    }
  }, [companyState.currentCompany.id, showSnackbar]);

  const handleBranchesSave = useCallback(async () => {
    if (!companyState.currentCompany.id) {
      showSnackbar('Save main company info first', 'warning');
      return;
    }
    setIsLoading(true);
    try {
      const branchDataList = companyState.currentCompany.branches || [];
      const branchDefinitions = {};
      
      // Process all branches to group by definition
      for (const branch of branchDataList) {
        const defId = branch.branch_definition?.id || branch.branch_definition_id;
        if (!defId) continue;

        if (!branchDefinitions[defId]) {
          branchDefinitions[defId] = {
            id: defId,
            name: branch.branch_definition?.name || branch.branch_definition_name,
            description: branch.branch_definition?.description || branch.branch_definition_description,
            values: []
          };
        }

        // Only process valid values
        if (branch.value?.trim()) {
          branchDefinitions[defId].values.push({
            id: branch.id,
            value: branch.value.trim(),
            tempKey: branch.tempKey // Preserve temp key for new entries
          });
        }
      }

      // Process each branch definition
      const updatedBranches = [];
      for (const [defId, definition] of Object.entries(branchDefinitions)) {
        // Create/update branch definition
        let branchDefinitionId = definition.id;
        const branchDefinitionData = {
          name: definition.name,
          description: definition.description,
          company: companyState.currentCompany.id
        };

        if (branchDefinitionId.toString().startsWith('temp-')) {
          // New definition
          const newDef = await createBranchDefinition(branchDefinitionData);
          branchDefinitionId = newDef.id;
        } else {
          // Existing definition
          await updateBranchDefinition(branchDefinitionId, branchDefinitionData);
        }

        // Process branch values
        for (const value of definition.values) {
          const branchData = {
            value: value.value,
            branch_definition: branchDefinitionId,
            company: companyState.currentCompany.id
          };

          let newBranch;
          if (value.tempKey) {
            // New branch value
            newBranch = await createBranch(branchData);
          } else if (value.id) {
            // Existing branch value
            await updateBranch(value.id, branchData);
            newBranch = { id: value.id, ...branchData };
          }

          // Replace temp references in locations
          if (newBranch) {
            companyState.setCurrentCompany(prev => ({
              ...prev,
              locations: prev.locations.map(loc => ({
                ...loc,
                branch: loc.branch?.map(b => 
                  b === value.tempKey ? newBranch.id : b
                )
              }))
            }));
          }

          updatedBranches.push(newBranch || { id: value.id, ...branchData });
        }
      }

      // Update company state with final branch data
      companyState.setCurrentCompany(prev => ({
        ...prev,
        branches: updatedBranches.map(b => ({
          id: b.id,
          value: b.value,
          branch_definition_id: b.branch_definition,
          branch_definition: {
            id: b.branch_definition,
            name: branchDefinitions[b.branch_definition]?.name || '',
            description: branchDefinitions[b.branch_definition]?.description || ''
          }
        }))
      }));

      // Handle deleted branches
      if (companyState.removedBranchIds.length > 0) {
        await Promise.all(
          companyState.removedBranchIds.map(branchId => deleteBranch(branchId))
        );
        companyState.setRemovedBranchIds([]);
      }

      showSnackbar('Branches updated successfully', 'success');
    } catch (error) {
      console.error('Error updating branches:', error);
      showSnackbar(error.message || 'Error updating branches', 'error');
    } finally {
      setIsLoading(false);
    }
  }, [companyState.currentCompany, companyState.removedBranchIds, showSnackbar]);

  const unsubscribeFromNotifications = useCallback(async () => {
    try {
      if (!notificationState.subscription) {
        throw new Error('No active subscription found');
      }

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Authentication token not found');
      }

      await notificationState.subscription.unsubscribe();

      const response = await fetch('https://admin.aitomotivelab.com/api/subscription/', {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to remove subscription');
      }

      notificationState.setSubscription(null);
      notificationState.setIsSubscribed(false);
      showSnackbar('Successfully unsubscribed from notifications', 'success');
    } catch (error) {
      console.error('Error unsubscribing:', error);
      showSnackbar(error.message, 'error');
    }
  }, [notificationState.subscription, showSnackbar]);

  const subscribeToNotifications = useCallback(async () => {
    try {
      if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
        throw new Error('Push notifications are not supported in this browser');
      }

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Authentication token not found');
      }

      const permission = await Notification.requestPermission();
      notificationState.setNotificationPermission(permission);

      if (permission !== 'granted') {
        throw new Error('Notification permission was not granted');
      }

      const registration = await navigator.serviceWorker.register('/service-worker.js', {
        scope: '/'
      });

      const vapidResponse = await fetch('https://admin.aitomotivelab.com/api/vapid-public-key/', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (!vapidResponse.ok) {
        throw new Error('Failed to fetch VAPID key');
      }

      const { public_key } = await vapidResponse.json();
      const convertedVapidKey = await urlBase64ToUint8Array(public_key);

      const pushSubscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertedVapidKey
      });

      const subscriptionData = {
        endpoint: pushSubscription.endpoint,
        keys: {
          p256dh: btoa(String.fromCharCode(...new Uint8Array(pushSubscription.getKey('p256dh')))),
          auth: btoa(String.fromCharCode(...new Uint8Array(pushSubscription.getKey('auth'))))
        }
      };

      const saveResponse = await fetch('https://admin.aitomotivelab.com/api/subscription/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(subscriptionData)
      });

      if (!saveResponse.ok) {
        await pushSubscription.unsubscribe();
        const errorData = await saveResponse.json();
        throw new Error(errorData.error || 'Failed to save subscription');
      }

      notificationState.setSubscription(pushSubscription);
      notificationState.setIsSubscribed(true);
      showSnackbar('Successfully subscribed to notifications', 'success');
    } catch (error) {
      console.error('Notification subscription error:', error);
      showSnackbar(error.message, 'error');
    }
  }, [showSnackbar]);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .getRegistration()
        .then((registration) => {
          console.log('Existing Service Worker registration:', registration);
        })
        .catch((error) => {
          console.error('Error checking Service Worker registration:', error);
        });
    }
  }, []);

  useEffect(() => {
    const checkExistingSubscription = async () => {
      if ('serviceWorker' in navigator) {
        try {
          const registration = await navigator.serviceWorker.ready;
          const existingSubscription = await registration.pushManager.getSubscription();
          if (existingSubscription) {
            notificationState.setSubscription(existingSubscription);
            notificationState.setIsSubscribed(true);
          }
        } catch (error) {
          console.error('Error checking existing subscription:', error);
        }
      }
    };

    checkExistingSubscription();
  }, []);

  const renderNotificationButton = () => (
    <Fade in={true} timeout={1000}>
      <Button
        variant="contained"
        color={notificationState.isSubscribed ? 'error' : 'secondary'}
        onClick={notificationState.isSubscribed ? unsubscribeFromNotifications : subscribeToNotifications}
        disabled={notificationState.notificationPermission === 'denied'}
        startIcon={<Bell />}
        sx={{
          ml: { xs: 0, sm: 2 },
          mt: { xs: 2, sm: 0 },
          width: { xs: '100%', sm: 'auto' },
          background: notificationState.isSubscribed
            ? 'linear-gradient(135deg, #ff4d4d 0%, #ff006e 100%)'
            : 'linear-gradient(135deg, #3a86ff 0%, #ff006e 100%)',
          '&:hover': {
            background: notificationState.isSubscribed
              ? 'linear-gradient(135deg, #ff006e 0%, #ff4d4d 100%)'
              : 'linear-gradient(135deg, #ff006e 0%, #3a86ff 100%)'
          },
          '&:disabled': {
            background: 'linear-gradient(135deg, #ccc 0%, #999 100%)',
            opacity: 0.7
          }
        }}
      >
        {notificationState.notificationPermission === 'denied' ? (
          <Tooltip title="Please enable notifications in your browser settings">
            <span>Notifications Blocked</span>
          </Tooltip>
        ) : (
          notificationState.isSubscribed ? 'Disable Notifications' : 'Enable Notifications'
        )}
      </Button>
    </Fade>
  );

  const handleRemoveBranchDefinition = useCallback(async (identifier) => {
    setIsLoading(true);
    try {
      // If the identifier is numeric, call the API to delete.
      if (typeof identifier === 'number') {
        await deleteBranchDefinition(identifier);
      }
      // Remove all branches matching this branch definition (using branch_definition_id for saved definitions or tempKey for unsaved)
      companyState.setCurrentCompany((prevCompany) => ({
        ...prevCompany,
        branches: prevCompany.branches.filter(branch => {
          if (typeof identifier === 'number') {
            return branch.branch_definition_id !== identifier;
          } else {
            return branch.tempKey !== identifier;
          }
        })
      }));
      showSnackbar('Branch definition deleted successfully', 'success');
    } catch (error) {
      console.error('Error deleting branch definition:', error);
      showSnackbar('Error deleting branch definition', 'error');
    } finally {
      setIsLoading(false);
    }
  }, [showSnackbar]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          minHeight: '100vh',
          bgcolor: 'background.default',
          py: { xs: 4, md: 10 },
          px: { xs: 2, md: 6 },
          background: 'linear-gradient(135deg, rgba(58, 134, 255, 0.05) 0%, rgba(255, 0, 110, 0.05) 100%)'
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretch', sm: 'center' }}
            >
              <Fade in={true} timeout={1000}>
                <Typography variant="h2" gutterBottom={{ xs: true, sm: false }}>
                  {companyState.currentUser === 'superadmin' ? 'Concessionari' : 'Il tuo Concessionario'}
                </Typography>
              </Fade>
              
              {renderNotificationButton()}
            </Box>
            {companyState.currentUser === 'superadmin' && (
              <Fade in={true} timeout={1500}>
                <Typography variant="subtitle1" gutterBottom>
                  <Box component="span" sx={{ color: 'success.main', fontWeight: 'bold' }}>
                    {companyState.rows.length}
                  </Box>{' '}
                  Aziende totali
                </Typography>
              </Fade>
            )}
          </Grid>

          <Grid item xs={12}>
            {companyState.currentUser === 'superadmin' && (
              <Fade in={true} timeout={1000}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAdd}
                  startIcon={<Plus />}
                  sx={{
                    mb: 3,
                    background: 'linear-gradient(135deg, #3a86ff 0%, #ff006e 100%)',
                    '&:hover': { background: 'linear-gradient(135deg, #ff006e 0%, #3a86ff 100%)' }
                  }}
                >
                  Aggiungi Azienda
                </Button>
              </Fade>
            )}

            {isLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                <CircularProgress />
              </Box>
            ) : companyState.currentUser === 'superadmin' ? (
              <Fade in={true} timeout={1500}>
                <div>
                  <CompanyGrid
                    rows={companyState.rows}
                    isLoading={isLoading}
                    currentUser={companyState.currentUser}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    onManageAI={handleOpenAIDialog}
                  />
                </div>
              </Fade>
            ) : (
              companyState.rows.length > 0 && (
                <CompanyCard
                  company={companyState.rows[0]}
                  currentUser={companyState.currentUser}
                  onEdit={handleEdit}
                />
              )
            )}
          </Grid>
        </Grid>
      </Box>

      <CompanyDialog
        open={dialogState.open}
        onClose={handleClose}
        company={companyState.currentCompany}
        onInputChange={handleInputChange}
        onLogoChange={handleLogoChange}
        logoFile={companyState.logoFile}
        validationErrors={companyState.validationErrors}
        onLocationChange={handleLocationChange}
        addLocation={addLocation}
        removeLocation={removeLocation}
        onBranchChange={handleBranchChange}
        addBranch={addBranch}
        removeBranch={removeBranch}
        removeBranchDefinition={handleRemoveBranchDefinition}
        onMainSave={handleMainSave}
        onLocationsSave={handleLocationsSave}
        onBranchesSave={handleBranchesSave}
      />

      <ConfirmDeleteDialog
        open={dialogState.confirmDeleteOpen}
        onClose={() => dialogState.setConfirmDeleteOpen(false)}
        onConfirm={confirmDelete}
      />

      <AIManagement
        open={dialogState.openAIDialog}
        onClose={handleCloseAIDialog}
        companyId={dialogState.selectedCompanyId}
        showSnackbar={showSnackbar}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
          icon={snackbar.severity === 'success' ? <Info /> : undefined}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default PaginaAziende;
import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  TextField,
  Button,
  Typography,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Box,
  Snackbar,
  Alert
} from '@mui/material';
import { ChevronDown, Trash2, Plus } from 'lucide-react';
import { fetchBranchDefinitions, fetchBranches } from '../api/branch';
import {
  fetchLocations,
  createLocation,
  updateLocation,
  deleteLocation
} from '../api/location';

function CompanyLocations({ company }) {
  // Local state for locations, branch definitions, branch options, and loading state.
  const [locations, setLocations] = useState([]);
  const [branchDefinitions, setBranchDefinitions] = useState([]);
  const [branchOptions, setBranchOptions] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [branchesLoaded, setBranchesLoaded] = useState(false);
  const [expandedPanels, setExpandedPanels] = useState({});
  const [unsavedChanges, setUnsavedChanges] = useState({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // Fetch branch definitions when the company changes.
  useEffect(() => {
    async function loadBranchDefinitions() {

      if (company && company.id) {
        try {
          const data = await fetchBranchDefinitions(company.id);
          const definitions = data.results ? data.results : data;
          setBranchDefinitions(definitions);
          console.log("definitions", definitions);
        } catch (error) {
          console.error("Error fetching branch definitions:", error);
        }
      }
    }
    loadBranchDefinitions();
  }, [company]);

  // Fetch locations from API when the company id changes.
  useEffect(() => {
    async function loadLocations() {
      if (company?.id) {
        setIsLoading(true);
        try {
          const locationsData = await fetchLocations(company.id);
          const locs = locationsData.results || locationsData;
          setLocations(locs);
          console.log("locations locs", locs);
        } catch (error) {
          console.error('Error fetching locations:', error);
        } finally {
          setIsLoading(false);
        }
      }
    }
    loadLocations();
  }, [company?.id]);

  // Updated loadBranchOptions useEffect:
  useEffect(() => {
    async function loadBranchOptions() {
      // Only run if there is at least one branch-enabled location missing its branch_definition.
      const branchLocationsMissingDefinition = locations.filter(
        (location) =>
          location.is_branch &&
          Array.isArray(location.branch) &&
          location.branch.length > 0 &&
          !location.branch_definition
      );
      
      if (!company?.id || branchLocationsMissingDefinition.length === 0) return;

      try {
        setIsLoading(true);
        // Fetch all branches for the given company
        const branchData = await fetchBranches(null, company.id);
        const allBranches = branchData.results || branchData;

        // Create a mapping for quick reference and group branches by branch definition.
        const branchesById = {};
        const branchDefMap = {};
        allBranches.forEach((branch) => {
          branchesById[branch.id] = branch;
          const defId = branch.branch_definition.id;
          if (!branchDefMap[defId]) {
            branchDefMap[defId] = {
              definition: branch.branch_definition,
              branches: []
            };
          }
          branchDefMap[defId].branches.push(branch);
        });

        // Create branch options and update locations only for those that are missing branch_definition.
        const newBranchOptions = {};
        const updatedLocations = locations.map((location, idx) => {
          if (
            location.is_branch &&
            Array.isArray(location.branch) &&
            location.branch.length > 0 &&
            !location.branch_definition
          ) {
            // Use the first branch to infer the branch definition.
            const firstBranchId = location.branch[0];
            const matchingBranch = branchesById[firstBranchId];
            if (matchingBranch) {
              const branchDefId = matchingBranch.branch_definition.id;
              newBranchOptions[idx] = branchDefMap[branchDefId].branches;
              return {
                ...location,
                branch_definition: branchDefMap[branchDefId].definition,
                // Ensure branch values are always numbers.
                branch: location.branch.map(id =>
                  typeof id === 'string' ? parseInt(id, 10) : id
                )
              };
            }
          }
          return location;
        });

        // Merge any new branch options with existing ones.
        setBranchOptions((prev) => ({ ...prev, ...newBranchOptions }));
        setLocations(updatedLocations);
      } catch (error) {
        console.error('Error fetching branches:', error);
      } finally {
        setIsLoading(false);
      }
    }

    loadBranchOptions();
  }, [company?.id, locations]);

  // Helper method that updates local state for a single location and then saves changes via the API.
  const updateLocationField = async (index, field, value) => {
    const location = locations[index];
    setLocations(prev => {
      const newLocations = [...prev];
      newLocations[index] = { ...location, [field]: value };
      return newLocations;
    });
    
    // Track that this location has unsaved changes
    setUnsavedChanges(prev => ({
      ...prev,
      [location.id || location.tempId]: true
    }));
  };

  // Modify handleLocationFieldChange to only update local state
  const handleLocationFieldChange = (index, field, value) => {
    updateLocationField(index, field, value);
  };

  // Modify handleBranchDefinitionChange to use existing branch options
  const handleBranchDefinitionChange = async (index, branchDefinitionId) => {
    if (!branchDefinitionId) return;
    
    try {
      const location = locations[index];
      const updatedLocation = {
        ...location,
        branch_definition: { id: branchDefinitionId },
        branch: []
      };

      // Update local state
      const newLocations = [...locations];
      newLocations[index] = updatedLocation;
      setLocations(newLocations);

      // Make API call if location exists
      if (location.id) {
        await updateLocation(company.id, location.id, {
          ...updatedLocation,
          branch_definition: branchDefinitionId
        });
      }
      
      // Use existing branch options instead of fetching again
      const data = await fetchBranches(branchDefinitionId, company.id);
      const values = data.results ? data.results : data;
      
      setBranchOptions(prev => ({
        ...prev,
        [index]: values
      }));
    } catch (error) {
      console.error("Error updating branch definition:", error);
    }
  };

  // Handle branch value changes
  const handleBranchValueChange = (index, selectedBranchIds) => {
    const branchIds = Array.isArray(selectedBranchIds)
      ? selectedBranchIds
          .map(id => (typeof id === 'string' ? parseInt(id, 10) : id))
          .filter(id => !isNaN(id))
      : [];
    
    const location = locations[index];
    const availableBranches = branchOptions[index] || [];
    
    // Ensure all selected branches belong to the current branch definition
    const validBranchIds = branchIds.filter(id => 
      availableBranches.some(b => b.id === id)
    );

    if (location.id) {
      updateLocationField(index, 'branch', validBranchIds);
    } else {
      // If it's a new location, just update local state
      const newLocations = [...locations];
      newLocations[index] = { ...location, branch: validBranchIds };
      console.log("newLocations", newLocations);
      setLocations(newLocations);
    }
  };

  // Create a new location.
  const addNewLocation = () => {
    setLocations(prev => [...prev, {
      tempId: `temp-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
      email: '',
      numero_di_telefono: '',
      citta: '',
      inidirizzo: '',
      cap: '',
      is_branch: false,
      branch: [],
      company: company.id,
      required: true
    }]);
  };

  // Remove a location.
  // For unsaved locations (without id), simply remove it from state.
  const removeLocation = async (index) => {
    const location = locations[index];
    if (location.id) {
      setIsLoading(true);
      try {
        await deleteLocation(location.id);
        setLocations(prev => prev.filter((_, i) => i !== index));
      } catch (error) {
        console.error('Error deleting location:', error);
      } finally {
        setIsLoading(false);
      }
    } else {
      // If no ID, just remove from local state
      setLocations(prev => prev.filter((_, i) => i !== index));
    }
  };

  // Modify handleSaveLocation to show success/error messages
  const handleSaveLocation = async (index) => {
    const location = locations[index];
    
    // Validate required fields
    if (!location.inidirizzo?.trim()) {
      setSnackbar({
        open: true,
        message: 'Indirizzo is required',
        severity: 'error'
      });
      return;
    }

    try {
      if (location.id) {
        const savedLocation = await updateLocation(company.id, location.id, location);
        setLocations(prev => {
          const newLocations = [...prev];
          newLocations[index] = {
            ...savedLocation,
            tempId: location.tempId
          };
          return newLocations;
        });
      } else {
        const createdLocation = await createLocation(company.id, location);
        setLocations(prev => {
          const newLocations = [...prev];
          newLocations[index] = {
            ...createdLocation,
            tempId: location.tempId
          };
          return newLocations;
        });
      }
      
      setUnsavedChanges(prev => ({
        ...prev,
        [location.id || location.tempId]: false
      }));

      setSnackbar({
        open: true,
        message: 'Location saved successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error saving location:', error);
      setSnackbar({
        open: true,
        message: 'Error saving location',
        severity: 'error'
      });
    }
  };

  // Add handleSnackbarClose handler
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const duplicateCounts = {};
  if (locations && Array.isArray(locations)) {
    locations.forEach(loc => {
      const city = loc.citta ? loc.citta.toLowerCase().trim() : '';
      const address = loc.inidirizzo ? loc.inidirizzo.toLowerCase().trim() : '';
      const key = `${company.id}-${city}-${address}`;
      duplicateCounts[key] = (duplicateCounts[key] || 0) + 1;
    });
  }

  // Add handler for Accordion changes
  const handleAccordionChange = (panelKey) => (event, isExpanded) => {
    setExpandedPanels(prev => ({
      ...prev,
      [panelKey]: isExpanded
    }));
  };

  /////////////////////////
  // RENDER
  /////////////////////////
  return (
    <>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Locations
      </Typography>
      
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {Array.isArray(locations) &&
            locations.map((location, index) => {
              const cityNormalized = location.citta ? location.citta.toLowerCase().trim() : '';
              const addrNormalized = location.inidirizzo ? location.inidirizzo.toLowerCase().trim() : '';
              const combinationKey = `${company.id}-${cityNormalized}-${addrNormalized}`;
              const duplicateError = duplicateCounts[combinationKey] > 1;
              
              const panelKey = location.id ? `location-${location.id}` : `location-${location.tempId}`;
              
              return (
                <Accordion 
                  key={panelKey} 
                  sx={{ mb: 2 }}
                  expanded={!!expandedPanels[panelKey]}
                  onChange={handleAccordionChange(panelKey)}
                >
                  <AccordionSummary expandIcon={<ChevronDown />}>
                    <Typography>
                      {location.citta ? `Location: ${location.citta}` : `Location ${index + 1}`}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Città"
                          name="citta"
                          value={location.citta || ''}
                          onChange={(e) => handleLocationFieldChange(index, 'citta', e.target.value)}
                          error={duplicateError}
                          helperText={duplicateError ? "The combination of Company, Città, and Indirizzo must be unique." : ""}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          required
                          label="Indirizzo"
                          name="inidirizzo"
                          value={location.inidirizzo || ''}
                          onChange={(e) => handleLocationFieldChange(index, 'inidirizzo', e.target.value)}
                          error={duplicateError || (!location.inidirizzo?.trim() && unsavedChanges[location.id || location.tempId])}
                          helperText={
                            duplicateError 
                              ? "The combination of Company, Città, and Indirizzo must be unique." 
                              : (!location.inidirizzo?.trim() && unsavedChanges[location.id || location.tempId])
                                ? "This field is required."
                                : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="CAP"
                          name="cap"
                          value={location.cap || ''}
                          onChange={(e) => handleLocationFieldChange(index, 'cap', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Email"
                          name="email"
                          type="email"
                          value={location.email || ''}
                          onChange={(e) => handleLocationFieldChange(index, 'email', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Numero di Telefono"
                          name="numero_di_telefono"
                          value={location.numero_di_telefono || ''}
                          onChange={(e) => handleLocationFieldChange(index, 'numero_di_telefono', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={location.is_branch || false}
                              onChange={(e) => handleLocationFieldChange(index, 'is_branch', e.target.checked)}
                            />
                          }
                          label="Is Branch"
                        />
                      </Grid>
                      {location.is_branch ? (
                        <>
                          <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                              <InputLabel id={`branch-definition-label-${index}`}>
                                Branch Definition
                              </InputLabel>
                              <Select
                                labelId={`branch-definition-label-${index}`}
                                id={`branch-definition-select-${index}`}
                                value={location.branch_definition?.id || ''}
                                label="Branch Definition"
                                onChange={(e) => handleBranchDefinitionChange(index, e.target.value)}
                              >
                                {branchDefinitions.map((def) => (
                                  <MenuItem key={def.id} value={def.id}>
                                    {def.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                              <InputLabel id={`branch-value-label-${index}`}>
                                Branch Value
                              </InputLabel>
                              <Select
                                multiple
                                labelId={`branch-value-label-${index}`}
                                id={`branch-value-select-${index}`}
                                value={location.branch || []}
                                label="Branch Value"
                                onChange={(e) => handleBranchValueChange(index, e.target.value)}
                                disabled={!location.branch_definition}
                              >
                                {(branchOptions[index] || []).map((branchOption) => (
                                  <MenuItem key={branchOption.id} value={branchOption.id}>
                                    {branchOption.value}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </>
                      ) : (
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            label="Smilenet ID"
                            name="smilenet_id"
                            value={location.smilenet_id || ''}
                            onChange={(e) => handleLocationFieldChange(index, 'smilenet_id', e.target.value)}
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                      {unsavedChanges[location.id || location.tempId] && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleSaveLocation(index)}
                        >
                          Save Changes
                        </Button>
                      )}
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => removeLocation(index)}
                        startIcon={<Trash2 />}
                      >
                        Rimuovi Location
                      </Button>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          
          <Button
            variant="outlined"
            onClick={addNewLocation}
            startIcon={<Plus />}
            sx={{ mt: 2 }}
          >
            Aggiungi Location
          </Button>
        </>
      )}
      
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default CompanyLocations;
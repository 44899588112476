import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Box, Avatar, Badge, Menu, MenuItem, Tooltip, useMediaQuery, useTheme, Snackbar, Alert } from '@mui/material';
import { Bell, Bot, User, LogOut, Menu as MenuIcon, X, Download, ChevronDown, Smartphone, Monitor, Apple, Chrome, BellOff, MessageCircle } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import InternalAssistant from '../internal_assistant/InternalAssistant';
import Messaging from '../internal_messages/Messaging';

async function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const Header = ({ 
  toggleSidebar, 
  isSidebarOpen, 
  darkMode,
}) => {
  const { logoutUser, user } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const [assistantOpen, setAssistantOpen] = useState(false);
  const [notificationPermission, setNotificationPermission] = useState(
    typeof Notification !== 'undefined' ? Notification.permission : 'default'
  );
  const [subscription, setSubscription] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info'
  });
  const [messagingOpen, setMessagingOpen] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState({ total_unread: 0, chat_rooms: {} });

  useEffect(() => {
    const checkExistingSubscription = async () => {
      if ('serviceWorker' in navigator) {
        try {
          const registration = await navigator.serviceWorker.ready;
          const existingSubscription = await registration.pushManager.getSubscription();
          if (existingSubscription) {
            setSubscription(existingSubscription);
            setIsSubscribed(true);
          }
        } catch (error) {
          console.error('Error checking existing subscription:', error);
        }
      }
    };

    checkExistingSubscription();
  }, []);

  const unsubscribeFromNotifications = async () => {
    try {
      if (!subscription) {
        throw new Error('No active subscription found');
      }

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Authentication token not found');
      }

      await subscription.unsubscribe();

      const response = await fetch('https://admin.aitomotivelab.com/api/subscription/', {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to remove subscription');
      }

      setSubscription(null);
      setIsSubscribed(false);
      showSnackbar('Successfully unsubscribed from notifications', 'success');

    } catch (error) {
      console.error('Error unsubscribing:', error);
      showSnackbar(error.message, 'error');
    }
  };

  const subscribeToNotifications = async () => {
    try {
      if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
        throw new Error('Push notifications are not supported in this browser');
      }

      if (typeof Notification === 'undefined') {
        throw new Error('Notification API is not supported in this browser');
      }

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Authentication token not found');
      }

      const permission = await Notification.requestPermission();
      setNotificationPermission(permission);
      
      if (permission !== 'granted') {
        throw new Error('Notification permission was not granted');
      }

      const registration = await navigator.serviceWorker.register('/service-worker.js', {
        scope: '/'
      });

      const vapidResponse = await fetch('https://admin.aitomotivelab.com/api/vapid-public-key/', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!vapidResponse.ok) {
        throw new Error('Failed to fetch VAPID key');
      }

      const { public_key } = await vapidResponse.json();
      const convertedVapidKey = await urlBase64ToUint8Array(public_key);

      const pushSubscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertedVapidKey
      });

      const subscriptionData = {
        endpoint: pushSubscription.endpoint,
        keys: {
          p256dh: btoa(String.fromCharCode(...new Uint8Array(pushSubscription.getKey('p256dh')))),
          auth: btoa(String.fromCharCode(...new Uint8Array(pushSubscription.getKey('auth'))))
        }
      };

      const saveResponse = await fetch('https://admin.aitomotivelab.com/api/subscription/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(subscriptionData)
      });

      if (!saveResponse.ok) {
        await pushSubscription.unsubscribe();
        const errorData = await saveResponse.json();
        throw new Error(errorData.error || 'Failed to save subscription');
      }

      setSubscription(pushSubscription);
      setIsSubscribed(true);
      showSnackbar('Successfully subscribed to notifications', 'success');

    } catch (error) {
      console.error('Notification subscription error:', error);
      showSnackbar(error.message, 'error');
    }
  };

  const fetchUnreadCounts = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) return;

      const response = await fetch('https://admin.aitomotivelab.com/company-chat/api/chat-rooms/unread_counts/', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        setUnreadMessages(data);
      } else {
        console.error('Failed to fetch unread counts');
      }
    } catch (error) {
      console.error('Error fetching unread message counts:', error);
    }
  };

  useEffect(() => {
    // Fetch unread counts on component mount
    fetchUnreadCounts();
    
    // Set up interval to periodically check for new messages
    const interval = setInterval(fetchUnreadCounts, 5000); // Check every 5 seconds
    
    return () => clearInterval(interval); // Clean up interval on unmount
  }, []);

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleAssistantToggle = () => {
    setAssistantOpen(!assistantOpen);
  };

  const handleMessageToggle = () => {
    setMessagingOpen(!messagingOpen);
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: darkMode ? '#1E1E1E' : '#FFFFFF',
          color: darkMode ? '#FFFFFF' : '#333333',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          height: { xs: 64, md: 80 },
          transition: 'all 0.3s ease',
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between', height: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              color="inherit"
              aria-label="toggle sidebar"
              onClick={toggleSidebar}
              edge="start"
              sx={{ mr: 2, display: { md: 'none' } }}
            >
              <AnimatePresence mode="wait" initial={false}>
                <motion.div
                  key={isSidebarOpen ? 'open' : 'closed'}
                  initial={{ opacity: 0, rotate: -180 }}
                  animate={{ opacity: 1, rotate: 0 }}
                  exit={{ opacity: 0, rotate: 180 }}
                  transition={{ duration: 0.3 }}
                >
                  {isSidebarOpen ? <X size={24} /> : <MenuIcon size={24} />}
                </motion.div>
              </AnimatePresence>
            </IconButton>
            <motion.img 
              src={darkMode ? "/AitomotiveLab_White.svg" : "/AitomotiveLab.svg"}
              alt="AITomotive Logo" 
              style={{ height: isMobile ? '30px' : '40px', maxWidth: isMobile ? '120px' : '160px' }}
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: 1, md: 2 } }}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <Tooltip title="Messaggi">
                <IconButton color="inherit" onClick={handleMessageToggle}>
                  <Badge badgeContent={unreadMessages.total_unread} color="error">
                    <MessageCircle size={24} />
                  </Badge>
                </IconButton>
              </Tooltip>
            </motion.div>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <Tooltip title="Assistente IA">
                <IconButton color="inherit" onClick={handleAssistantToggle}>
                  <Bot size={24} />
                </IconButton>
              </Tooltip>
            </motion.div>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <Tooltip title="Menu Utente">
                <IconButton onClick={handleMenuOpen} sx={{ p: 0 }}>
                  <Avatar
                    alt={user?.name || 'User'}
                    src={user?.avatar}
                    sx={{
                      width: 40,
                      height: 40,
                      border: '2px solid',
                      borderColor: darkMode ? '#4CC9F0' : '#4361EE',
                    }}
                  />
                </IconButton>
              </Tooltip>
            </motion.div>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  backgroundColor: darkMode ? '#1E1E1E' : '#FFFFFF',
                  color: darkMode ? '#FFFFFF' : '#333333',
                  border: `1px solid ${darkMode ? '#ffffff20' : '#00000020'}`,
                  '& .MuiMenuItem-root': {
                    transition: 'background-color 0.3s',
                    '&:hover': {
                      backgroundColor: darkMode ? '#ffffff20' : '#00000010',
                    },
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {typeof Notification !== 'undefined' && (
                <MenuItem 
                  onClick={isSubscribed ? unsubscribeFromNotifications : subscribeToNotifications}
                  disabled={notificationPermission === 'denied'}
                >
                  {isSubscribed ? (
                    <Bell size={16} style={{ marginRight: 8, color: darkMode ? '#4CC9F0' : '#4361EE' }} />
                  ) : (
                    <BellOff size={16} style={{ marginRight: 8, opacity: 0.7 }} />
                  )}
                  {isSubscribed ? 'Disabilita Notifiche' : 'Abilita Notifiche'}
                </MenuItem>
              )}
              <MenuItem onClick={() => { handleMenuClose(); navigate('/install-guide'); }}>
                <Download size={16} style={{ marginRight: 8 }} />
                Installa App
              </MenuItem>
              <MenuItem onClick={() => { handleMenuClose(); logoutUser(); }}>
                <LogOut size={16} style={{ marginRight: 8 }} />
                Esci
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      <InternalAssistant 
        open={assistantOpen}
        onClose={() => setAssistantOpen(false)}
        darkMode={darkMode}
      />

      <Messaging 
        open={messagingOpen}
        onClose={() => setMessagingOpen(false)}
        darkMode={darkMode}
        unreadCounts={unreadMessages.chat_rooms}
        onMessagesRead={fetchUnreadCounts}
      />

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Header;
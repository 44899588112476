// Property mappings for different tools/views
export const propertyMappings = {
  car_stock_search: {
    id: 'vehicleid',
    title: 'title',
    description: 'description',
    price: 'price',
    image: 'image',
    url: 'url',
    available: 'available',
  },
};

export async function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export const mapItems = (items, mappings) => {
  if (!Array.isArray(items) || !mappings) {
    return [];
  }

  return items.map(item => {
    const mappedItem = {};
    Object.entries(mappings).forEach(([key, value]) => {
      mappedItem[key] = item[value];
    });
    return mappedItem;
  });
};

export const formatDate = (date) => {
  if (!date) return '';
  return new Date(date).toLocaleDateString();
};

export const formatPrice = (price) => {
  if (!price) return '';
  return new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR'
  }).format(price);
};

export const truncateText = (text, maxLength) => {
  if (!text || text.length <= maxLength) return text;
  return `${text.substring(0, maxLength)}...`;
}; 
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Container, Typography, TextField, Button, Grid, Box,
  CircularProgress, Snackbar, Alert, Link
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

const API_BASE_URL = 'https://admin.aitomotivelab.com/car_stock/api';

const CarEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [car, setCar] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [sliderValue, setSliderValue] = useState(50); // Default value
  const sliderRef = useRef(null);
  const isDragging = useRef(false);

  useEffect(() => {
    const fetchCar = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/carstocks/${id}/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        setCar(response.data);
        setSliderValue(response.data.priority || 50); // Initialize slider with car priority
        setLoading(false);
      } catch (error) {
        console.error('Error fetching car details:', error);
        setError('Failed to fetch car details. Please try again.');
        setLoading(false);
      }
    };

    fetchCar();
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCar({ ...car, [name]: value });
  };

  const calculatePriorityFromPosition = (clientX) => {
    if (!sliderRef.current) return 50;
    const rect = sliderRef.current.getBoundingClientRect();
    const percentage = Math.max(0, Math.min(1, (clientX - rect.left) / rect.width));
    return Math.max(1, Math.min(100, Math.round(percentage * 99 + 1)));
  };

  // Initialize dragging
  const startDrag = (e) => {
    // Prevent default behaviors
    e.preventDefault();
    // Set dragging state
    isDragging.current = true;
    // Update the priority based on initial position
    const clientX = e.type.includes('mouse') ? e.clientX : e.touches[0].clientX;
    const newPriority = calculatePriorityFromPosition(clientX);
    setSliderValue(newPriority);
    setCar(prev => ({ ...prev, priority: newPriority }));
    
    // Add event listeners for dragging
    document.addEventListener('mousemove', onDrag);
    document.addEventListener('touchmove', onDrag, { passive: false });
    document.addEventListener('mouseup', endDrag);
    document.addEventListener('touchend', endDrag);
  };

  // Handle dragging
  const onDrag = (e) => {
    if (!isDragging.current) return;
    e.preventDefault();
    
    const clientX = e.type.includes('mouse') ? e.clientX : e.touches[0].clientX;
    const newPriority = calculatePriorityFromPosition(clientX);
    setSliderValue(newPriority);
    setCar(prev => ({ ...prev, priority: newPriority }));
  };

  // End dragging
  const endDrag = () => {
    isDragging.current = false;
    document.removeEventListener('mousemove', onDrag);
    document.removeEventListener('touchmove', onDrag);
    document.removeEventListener('mouseup', endDrag);
    document.removeEventListener('touchend', endDrag);
  };

  // Clean up event listeners if component unmounts during drag
  useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', onDrag);
      document.removeEventListener('touchmove', onDrag);
      document.removeEventListener('mouseup', endDrag);
      document.removeEventListener('touchend', endDrag);
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Only send the priority field instead of the entire car object
      const dataToSend = {
        priority: parseInt(sliderValue)
      };
      
      console.log('Sending data to update priority:', dataToSend); // Debug log
      
      await axios.patch(`${API_BASE_URL}/carstocks/${id}/`, dataToSend, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      // Show success message without navigating away
      setSnackbar({ open: true, message: 'Priorità aggiornata con successo', severity: 'success' });
      
      // Refresh car data to reflect the updated priority
      const updatedCarResponse = await axios.get(`${API_BASE_URL}/carstocks/${id}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setCar(updatedCarResponse.data);
      
    } catch (error) {
      console.error('Error updating car:', error);
      console.error('Error details:', error.response?.data || 'No response data');
      setSnackbar({ open: true, message: 'Impossibile aggiornare la priorità. Riprova.', severity: 'error' });
    }
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCar({ ...car, [name]: checked });
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Dettagli Auto
        </Typography>
        <Button
          variant="outlined"
          startIcon={<ArrowBack />}
          onClick={() => navigate(-1)}
          sx={{ mr: 2, mb: 2 }}
        >
          Torna Indietro
        </Button>

        {/* Priority Section - Editable */}
        <Box 
          sx={{ 
            mb: 4, 
            p: 3, 
            borderRadius: 2,
            background: 'linear-gradient(145deg, #f0f7ff 0%, #e1f5fe 100%)',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            border: '1px solid #bbdefb',
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <Box 
            sx={{ 
              position: 'absolute', 
              top: 0, 
              right: 0, 
              backgroundColor: 'primary.main', 
              color: 'white', 
              px: 2,
              py: 0.5,
              borderBottomLeftRadius: 8
            }}
          >
            <Typography variant="caption" fontWeight="bold">
              MODIFICABILE
            </Typography>
          </Box>
          
          <Typography variant="h6" color="primary.dark" gutterBottom fontWeight="bold">
            Imposta Priorità Visualizzazione
          </Typography>
          
          <Typography variant="body2" color="text.secondary" paragraph>
            Regola il valore della priorità per controllare dove appare questa auto negli elenchi. 
            <strong> Numeri bassi = Priorità alta</strong> (1 è la priorità massima, 100 è la minima).
          </Typography>
          
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ position: 'relative', mb: 3, mt: 2 }}>
                  {/* Priority Scale Visualization */}
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      mb: 1,
                      px: 1
                    }}
                  >
                    <Typography variant="caption" fontWeight="bold" color="success.main">
                      1 (Priorità Massima)
                    </Typography>
                    <Typography variant="caption" fontWeight="bold" color="warning.main">
                      50 (Media)
                    </Typography>
                    <Typography variant="caption" fontWeight="bold" color="error.main">
                      100 (Priorità Minima)
                    </Typography>
                  </Box>
                  
                  {/* Slider container */}
                  <Box sx={{ position: 'relative', height: 60, my: 2 }}>
                    {/* Track */}
                    <Box 
                      ref={sliderRef}
                      sx={{ 
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        height: 8, 
                        width: '100%',
                        borderRadius: 4,
                        background: 'linear-gradient(90deg, #4caf50 0%, #ff9800 50%, #f44336 100%)',
                      }}
                    />
                    
                    {/* Thumb */}
                    <Box 
                      onMouseDown={startDrag}
                      onTouchStart={startDrag}
                      sx={{ 
                        position: 'absolute',
                        top: '50%',
                        left: `${(sliderValue - 1) / 99 * 100}%`, // Simplified position calculation
                        transform: 'translate(-50%, -50%)',
                        width: 60, // Much larger hit area
                        height: 60, // Much larger hit area
                        backgroundColor: 'rgba(25, 118, 210, 0.1)', // Visible hit area
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'grab',
                        '&:active': {
                          cursor: 'grabbing',
                        }
                      }}
                    >
                      <Box 
                        sx={{
                          width: 22,
                          height: 22,
                          borderRadius: '50%',
                          backgroundColor: 'white',
                          border: '3px solid #1976d2',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                        }}
                      />
                    </Box>
                  </Box>
                  
                  {/* Current priority value display */}
                  <Box sx={{ textAlign: 'center', mt: 3 }}>
                    <Typography 
                      variant="h5" 
                      fontWeight="bold" 
                      color="primary.main"
                      sx={{
                        backgroundColor: 'rgba(255,255,255,0.8)',
                        borderRadius: 2,
                        py: 1,
                        display: 'inline-block',
                        minWidth: 80,
                        boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
                      }}
                    >
                      {sliderValue}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              
              <Grid item xs={12}>
                <Button 
                  type="submit" 
                  variant="contained" 
                  color="primary"
                  fullWidth
                  sx={{ 
                    py: 1.5,
                    fontWeight: 'bold',
                    boxShadow: '0 4px 10px rgba(25, 118, 210, 0.3)',
                    '&:hover': {
                      boxShadow: '0 6px 15px rgba(25, 118, 210, 0.4)',
                    }
                  }}
                >
                  Aggiorna Priorità
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>

        {/* Vehicle Details Section - Redesigned */}
        <Box 
          sx={{ 
            mt: 4,
            position: 'relative',
            borderRadius: 3,
            overflow: 'hidden',
            boxShadow: '0 10px 30px rgba(0, 0, 0, 0.08)',
            border: '1px solid rgba(0, 0, 0, 0.05)',
          }}
        >
          {/* Curved header banner */}
          <Box
            sx={{
              background: 'linear-gradient(135deg, #2c3e50 0%, #4a69bd 100%)',
              height: '120px',
              width: '100%',
              position: 'relative',
              display: 'flex',
              alignItems: 'flex-end',
              p: 3,
              pt: 6,
            }}
          >
            <Typography 
              variant="h5" 
              sx={{ 
                color: 'white', 
                fontWeight: 'bold',
                textShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
              }}
            >
              {car?.brand} {car?.model}
              <Typography 
                component="span" 
                variant="subtitle1" 
                sx={{ 
                  display: 'block', 
                  color: 'rgba(255, 255, 255, 0.8)', 
                  mt: 0.5,
                  fontWeight: 'normal',
                }}
              >
                {car?.version}
              </Typography>
            </Typography>
            
            {/* Price tag */}
            <Box
              sx={{
                position: 'absolute',
                top: 20,
                right: 20,
                backgroundColor: 'rgba(255, 255, 255, 0.95)',
                borderRadius: 2,
                px: 2, 
                py: 1,
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                border: '2px solid #fff',
              }}
            >
              <Typography 
                variant="h6" 
                sx={{ 
                  color: '#2c3e50', 
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap', 
                }}
              >
                € {car?.saleprice?.toLocaleString()}
              </Typography>
            </Box>
          </Box>
          
          {/* Main content area with spec cards */}
          <Box sx={{ p: 3, pt: 2, backgroundColor: 'white' }}>
            <Typography 
              variant="subtitle1" 
              color="text.secondary" 
              sx={{ mb: 3, ml: 1, fontStyle: 'italic' }}
            >
              Informazioni Veicolo
            </Typography>
            
            <Grid container spacing={3}>
              {/* Technical Specs Card */}
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    borderRadius: 2,
                    p: 2,
                    border: '1px solid rgba(0, 0, 0, 0.08)',
                    background: 'linear-gradient(145deg, #f9f9f9 0%, #ffffff 100%)',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.03)',
                    height: '100%',
                  }}
                >
                  <Typography 
                    variant="h6" 
                    color="primary" 
                    sx={{ 
                      mb: 2, 
                      fontSize: '1rem',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <Box 
                      component="span" 
                      sx={{ 
                        width: 30, 
                        height: 30, 
                        borderRadius: '50%', 
                        backgroundColor: 'primary.light',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        fontSize: '0.9rem',
                      }}
                    >
                      <span>⚙️</span>
                    </Box>
                    Specifiche Tecniche
                  </Typography>
                  
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        Marca
                      </Typography>
                      <Typography variant="body1" fontWeight="medium">
                        {car?.brand || '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        Modello
                      </Typography>
                      <Typography variant="body1" fontWeight="medium">
                        {car?.model || '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        Versione
                      </Typography>
                      <Typography variant="body1" fontWeight="medium">
                        {car?.version || '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        Carrozzeria
                      </Typography>
                      <Typography variant="body1" fontWeight="medium">
                        {car?.bodystyle || '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        Cambio
                      </Typography>
                      <Typography variant="body1" fontWeight="medium">
                        {car?.transmission || '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        Link Sito
                      </Typography>
                      <Typography variant="body1" fontWeight="medium">
                        {car?.url ? (
                          <Link 
                            href={car.url} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            sx={{ 
                              color: 'primary.main',
                              textDecoration: 'none',
                              '&:hover': { textDecoration: 'underline' }
                            }}
                          >
                            Visualizza sul sito
                          </Link>
                        ) : '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              
              {/* General Info Card */}
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    borderRadius: 2,
                    p: 2,
                    border: '1px solid rgba(0, 0, 0, 0.08)',
                    background: 'linear-gradient(145deg, #f9f9f9 0%, #ffffff 100%)',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.03)',
                    height: '100%',
                  }}
                >
                  <Typography 
                    variant="h6" 
                    color="primary" 
                    sx={{ 
                      mb: 2, 
                      fontSize: '1rem',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <Box 
                      component="span" 
                      sx={{ 
                        width: 30, 
                        height: 30, 
                        borderRadius: '50%', 
                        backgroundColor: 'primary.light',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                        fontSize: '0.9rem',
                      }}
                    >
                      <span>📊</span>
                    </Box>
                    Informazioni Generali
                  </Typography>
                  
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        Prezzo di Vendita
                      </Typography>
                      <Typography variant="body1" fontWeight="medium" color="error.main">
                        € {car?.saleprice?.toLocaleString() || '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        Chilometraggio
                      </Typography>
                      <Typography variant="body1" fontWeight="medium">
                        {car?.km?.toLocaleString() || '-'} km
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        Anno
                      </Typography>
                      <Typography variant="body1" fontWeight="medium">
                        {car?.registrationyear || '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        Colore
                      </Typography>
                      <Typography variant="body1" fontWeight="medium">
                        {car?.colour || '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        Carburante
                      </Typography>
                      <Typography variant="body1" fontWeight="medium">
                        {car?.fuel || '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        Disponibile Test Drive
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          type="checkbox"
                          name="available_for_test_drive"
                          checked={car?.available_for_test_drive || false}
                          onChange={handleCheckboxChange}
                          style={{ 
                            width: '20px', 
                            height: '20px',
                            cursor: 'pointer',
                            marginRight: '8px'
                          }}
                        />
                        <Typography variant="body1">
                          {car?.available_for_test_drive ? 'Sì' : 'No'}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        Posizione
                      </Typography>
                      <TextField
                        name="location"
                        value={car?.location || ''}
                        onChange={handleInputChange}
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Inserisci la posizione"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        Descrizione
                      </Typography>
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          maxHeight: '80px', 
                          overflow: 'auto',
                          backgroundColor: 'rgba(0,0,0,0.02)',
                          p: 1,
                          borderRadius: 1,
                          border: '1px solid rgba(0,0,0,0.05)'
                        }}
                      >
                        {car?.description || 'Nessuna descrizione disponibile'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CarEdit;

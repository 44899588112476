import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { 
  Container, Grid, Box, Typography, Card, CardContent, Button, CircularProgress,
  Snackbar, Alert, createTheme, ThemeProvider, CssBaseline, TextField, 
  Pagination, Select, MenuItem, FormControl, InputLabel, Slider, Dialog, DialogContent, Chip, DialogTitle, IconButton, useMediaQuery
} from '@mui/material';
import { Search, DirectionsCar, LocalGasStation, Speed, ExpandMore, ExpandLess, FilterList, Close } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import BrandPriorityManager from './BrandPriorityManager';
import SimilarModelsManager from './SimilarModelsManager';
import { useAuth } from '../contexts/AuthContext';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4361EE',
    },
    secondary: {
      main: '#4895EF',
    },
    background: {
      default: '#F8F9FA',
      paper: '#FFFFFF',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
          '&:hover': {
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
            transform: 'translateY(-5px)',
          },
        },
      },
    },
  },
});

const API_BASE_URL = 'https://admin.aitomotivelab.com/car_stock/api';

const CarCard = ({ car, canEdit }) => (
  <Card 
    component={Link}
    to={canEdit ? `/cars/${car.vehicleid}/edit` : `#`}
    sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      position: 'relative',
      width: '100%',
      textDecoration: 'none', // Remove underline from Link
      color: 'inherit', // Keep text color consistent
      cursor: 'pointer',
      overflow: 'hidden', // Keep overlay contained within card
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
      }
    }}
  >
    <Box
      sx={{
        height: 200,
        backgroundColor: '#f5f5f5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      {car.urlmainimage ? (
        <img
          src={car.urlmainimage}
          alt={`${car.brand} ${car.model}`}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = ''; // Reset to show car icon on error
          }}
        />
      ) : (
        <DirectionsCar sx={{ fontSize: 60, color: 'grey.400' }} />
      )}
    </Box>
    <CardContent sx={{ flexGrow: 1, p: 3 }}>
      <Typography 
        gutterBottom 
        variant="h6" 
        component="div" 
        sx={{ 
          fontWeight: 'bold',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
      >
        {car.brand} {car.model}
      </Typography>
      {/* Improved specs layout */}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <LocalGasStation sx={{ mr: 1, color: 'secondary.main', fontSize: 20 }} />
            <Typography variant="body2">{car.fuel}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Speed sx={{ mr: 1, color: 'secondary.main', fontSize: 20 }} />
            <Typography variant="body2">{car.km?.toLocaleString()} km</Typography>
          </Box>
        </Grid>
      </Grid>
      <Typography 
        variant="h6" 
        color="primary" 
        sx={{ 
          fontWeight: 'bold',
          fontSize: '1.5rem',
          textAlign: 'right' 
        }}
      >
        €{car.saleprice?.toLocaleString()}
      </Typography>
    </CardContent>
    
    {/* Hover overlay for "View and Edit" - only shows if canEdit is true */}
    {canEdit && (
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(67, 97, 238, 0.85)', // Primary color with opacity
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          opacity: 0,
          transition: 'opacity 0.3s ease',
          '&:hover': {
            opacity: 1,
          },
          zIndex: 2
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: 'white',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            letterSpacing: '1px',
            mb: 1
          }}
        >
          Visualizza e Modifica
        </Typography>
        <Box
          sx={{
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
          }}
        >
          <DirectionsCar sx={{ fontSize: 30, color: 'primary.main' }} />
        </Box>
      </Box>
    )}
  </Card>
);

const CarStockList = () => {
  const [carStocks, setCarStocks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    min_price: '',
    max_price: '',
    min_year: '',
    max_year: '',
    min_km: '',
    max_km: '',
    brand: '',
    model: '',
    fuel: '',
    bodystyle: '',
    transmission: '',
    color: '',
  });
  const [filterOptions, setFilterOptions] = useState({
    brands: [],
    fuel_types: [],
    body_styles: [],
    price_range: { min_price: 0, max_price: 100000 },
    year_range: { min_year: 2000, max_year: new Date().getFullYear() },
  });

  const [openBrandPriority, setOpenBrandPriority] = useState(false);
  const [openSimilarModels, setOpenSimilarModels] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const ITEMS_PER_PAGE = 20;

  const { userRole, appAccess } = useAuth();
  const permissions = appAccess['car_stock'] || {};
  const canEdit = permissions.can_edit || userRole === 'superadmin';
  const canCreate = permissions.can_create || userRole === 'superadmin';
  const canDelete = permissions.can_delete || userRole === 'superadmin';

  // Add these state variables
  const [totalCount, setTotalCount] = useState(0);
  const [hasMoreCars, setHasMoreCars] = useState(true);

  const fetchFilterOptions = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/carstocks/filter_options/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setFilterOptions(response.data);
    } catch (error) {
      console.error('Error fetching filter options:', error);
      setSnackbarMessage('Failed to fetch filter options. Please refresh the page.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, []);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_BASE_URL}/carstocks/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
          params: {
            ...filters,
            search: searchTerm,
            page,
            limit: ITEMS_PER_PAGE
          }
        });

        const newCars = response.data.results;
        
        // If the API provides a total count, use it (most RESTful APIs do)
        if (response.data.count !== undefined) {
          setTotalCount(response.data.count);
          // Check if we've loaded all available cars
          const totalLoaded = (page === 1) ? newCars.length : carStocks.length + newCars.length;
          setHasMoreCars(totalLoaded < response.data.count);
        } else {
          // Fallback: If no count is provided, use the length of returned items
          setHasMoreCars(newCars.length === ITEMS_PER_PAGE);
        }
        
        if (page > 1) {
          setCarStocks(prevCars => [...prevCars, ...newCars]);
        } else {
          setCarStocks(newCars);
        }

        console.log('Car data response:', response.data);
      } catch (error) {
        console.error('Error fetching car stocks:', error);
        setError('An error occurred while fetching car stocks. Please try again.');
        setSnackbarMessage('Failed to fetch results. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        // On error, assume no more cars to prevent infinite error loops
        setHasMoreCars(false);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [filters, searchTerm, page]);

  // Add a debug logging effect
  useEffect(() => {
    console.log('Current state - Cars count:', carStocks.length, 'hasMoreCars:', hasMoreCars, 'page:', page);
  }, [carStocks, hasMoreCars, page]);

  // Load more handler
  const handleLoadMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  useEffect(() => {
    fetchFilterOptions();
  }, [fetchFilterOptions]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSearchChange = (event) => {
    setCarStocks([]); // Clear existing results
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const handleFilterChange = (event) => {
    setCarStocks([]); // Clear existing results
    setFilters({ ...filters, [event.target.name]: event.target.value });
    setPage(1);
  };

  const handlePriceChange = (event, newValue) => {
    setCarStocks([]); // Clear existing results
    setFilters({ ...filters, min_price: newValue[0], max_price: newValue[1] });
    setPage(1);
  };

  const handleYearChange = (event, newValue) => {
    setCarStocks([]); // Clear existing results
    setFilters({ ...filters, min_year: newValue[0], max_year: newValue[1] });
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleOpenBrandPriority = () => {
    if (!canEdit) {
      setSnackbarMessage('Non hai il permesso di gestire le priorità delle marche.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }
    setOpenBrandPriority(true);
  };

  const handleCloseBrandPriority = () => {
    setOpenBrandPriority(false);
  };

  const handleCloseSimilarModels = () => {
    setOpenSimilarModels(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ 
        flexGrow: 1, 
        minHeight: '100vh', 
        backgroundColor: '#f8f9fa',
        overflowX: 'hidden'
      }}>
        <Container 
          maxWidth={false}
          sx={{ 
            pt: 4, 
            pb: 6,
            px: { xs: 2, sm: 3, md: 4 },
            maxWidth: {
              xs: '100%',
              lg: '1800px'
            },
            mx: 'auto'
          }}
        >
          {/* Header section */}
          <Box sx={{ mb: 4 }}>
            <Typography 
              variant="h4" 
              component="h1" 
              sx={{ 
                fontWeight: 'bold', 
                color: 'primary.main', 
                textAlign: 'center',
                mb: 2 
              }}
            >
              Gestione Stock Auto
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'center',
              gap: 2 // Add space between buttons
            }}>
              {canEdit && (
                <>
                  <Button 
                    variant="contained" 
                    color="secondary" 
                    onClick={handleOpenBrandPriority}
                    sx={{ 
                      borderRadius: 2,
                      px: 4,
                      py: 1,
                      fontSize: '1rem'
                    }}
                  >
                    Gestisci Priorità Marche
                  </Button>
                  <Button 
                    variant="contained" 
                    color="secondary" 
                    onClick={() => setOpenSimilarModels(true)}
                    sx={{ 
                      borderRadius: 2,
                      px: 4,
                      py: 1,
                      fontSize: '1rem'
                    }}
                  >
                    Gestisci Modelli Simili
                  </Button>
                </>
              )}
            </Box>
          </Box>

          {/* Search and Filter toggle */}
          <Card sx={{ 
            mb: 3, 
            p: { xs: 2, sm: 3 },
            width: '100%',
            boxSizing: 'border-box'
          }}>
            <Grid 
              container 
              spacing={2} 
              alignItems="center"
              sx={{ 
                width: '100%',
                m: 0,
                '& .MuiGrid-item': {
                  pl: { xs: 2, sm: 3 },
                  pr: { xs: 2, sm: 3 },
                  width: '100%',
                  boxSizing: 'border-box'
                }
              }}
            >
              <Grid item xs={12} md={8}>
                <TextField
                  fullWidth
                  label="Cerca auto"
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: <Search color="action" />,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => setShowFilters(!showFilters)}
                  startIcon={<FilterList />}
                  endIcon={showFilters ? <ExpandLess /> : <ExpandMore />}
                  sx={{ height: '56px' }} // Match height with search field
                >
                  {showFilters ? 'Nascondi Filtri' : 'Mostra Filtri'}
                </Button>
              </Grid>
            </Grid>
          </Card>

          {/* Collapsible Filters Section - Improved styling */}
          <Box sx={{
            maxHeight: showFilters ? '2000px' : '0px',
            overflow: 'hidden',
            transition: 'max-height 0.3s ease-in-out, opacity 0.3s ease-in-out',
            opacity: showFilters ? 1 : 0,
            width: '100%',
            boxSizing: 'border-box'
          }}>
            <Card sx={{ 
              mb: 4, 
              p: { xs: 2, sm: 3 },
              width: '100%',
              boxSizing: 'border-box',
              borderRadius: 2,
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)'
            }}>
              <Grid container spacing={3}>
                {/* Brand filter - with black bold label */}
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined" size="small" sx={{ '& .MuiOutlinedInput-root': { borderRadius: 1.5 } }}>
                    <InputLabel sx={{ fontWeight: 700, color: '#000000' }}>Marca</InputLabel>
                    <Select
                      name="brand"
                      value={filters.brand}
                      onChange={handleFilterChange}
                      label="Marca"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300
                          }
                        }
                      }}
                    >
                      <MenuItem value="">Tutte le Marche</MenuItem>
                      {filterOptions.brands.map((brand) => (
                        <MenuItem key={brand} value={brand}>{brand}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Fuel type filter - with black bold label */}
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined" size="small" sx={{ '& .MuiOutlinedInput-root': { borderRadius: 1.5 } }}>
                    <InputLabel sx={{ fontWeight: 700, color: '#000000' }}>Carburante</InputLabel>
                    <Select
                      name="fuel"
                      value={filters.fuel}
                      onChange={handleFilterChange}
                      label="Carburante"
                    >
                      <MenuItem value="">Tutti i Carburanti</MenuItem>
                      {filterOptions.fuel_types.map((fuel) => (
                        <MenuItem key={fuel} value={fuel}>{fuel}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Body style filter - with black bold label */}
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined" size="small" sx={{ '& .MuiOutlinedInput-root': { borderRadius: 1.5 } }}>
                    <InputLabel sx={{ fontWeight: 700, color: '#000000' }}>Carrozzeria</InputLabel>
                    <Select
                      name="bodystyle"
                      value={filters.bodystyle}
                      onChange={handleFilterChange}
                      label="Carrozzeria"
                    >
                      <MenuItem value="">Tutte le Carrozzerie</MenuItem>
                      {filterOptions.body_styles.map((style) => (
                        <MenuItem key={style} value={style}>{style}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Price range slider - with black bold title */}
                <Grid item xs={12}>
                  <Typography variant="subtitle2" sx={{ mb: 1, color: '#000000', fontWeight: 800 }}>
                    Fascia di Prezzo (€)
                  </Typography>
                  <Box sx={{ px: 2, mb: 1 }}>
                    <Slider
                      value={[
                        filters.min_price || filterOptions.price_range.min_price,
                        filters.max_price || filterOptions.price_range.max_price
                      ]}
                      onChange={handlePriceChange}
                      valueLabelDisplay="auto"
                      min={filterOptions.price_range.min_price}
                      max={filterOptions.price_range.max_price}
                      step={1000}
                      valueLabelFormat={(value) => `€${value.toLocaleString()}`}
                      sx={{
                        color: 'secondary.main',
                        '& .MuiSlider-thumb': {
                          height: 16,
                          width: 16,
                          backgroundColor: '#fff',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                          '&:hover, &.Mui-focusVisible': {
                            boxShadow: '0 0 0 8px rgba(67, 97, 238, 0.16)'
                          }
                        },
                        '& .MuiSlider-rail': {
                          opacity: 0.3,
                          backgroundColor: '#bdbdbd',
                        },
                        '& .MuiSlider-track': {
                          border: 'none',
                        },
                        '& .MuiSlider-valueLabel': {
                          fontSize: '0.75rem',
                          padding: '0.25rem 0.5rem',
                          backgroundColor: 'secondary.main'
                        }
                      }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 1 }}>
                    <Typography variant="caption" color="text.secondary">
                      €{(filters.min_price || filterOptions.price_range.min_price).toLocaleString()}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      €{(filters.max_price || filterOptions.price_range.max_price).toLocaleString()}
                    </Typography>
                  </Box>
                </Grid>

                {/* Year range slider - with black bold title */}
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle2" sx={{ mb: 1, color: '#000000', fontWeight: 800 }}>
                    Anno di Immatricolazione
                  </Typography>
                  <Box sx={{ px: 2, mb: 1 }}>
                    <Slider
                      value={[
                        filters.min_year || filterOptions.year_range.min_year,
                        filters.max_year || filterOptions.year_range.max_year
                      ]}
                      onChange={handleYearChange}
                      valueLabelDisplay="auto"
                      min={filterOptions.year_range.min_year}
                      max={filterOptions.year_range.max_year}
                      step={1}
                      sx={{
                        color: 'secondary.main',
                        '& .MuiSlider-thumb': {
                          height: 16,
                          width: 16,
                          backgroundColor: '#fff',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                          '&:hover, &.Mui-focusVisible': {
                            boxShadow: '0 0 0 8px rgba(67, 97, 238, 0.16)'
                          }
                        },
                        '& .MuiSlider-rail': {
                          opacity: 0.3,
                          backgroundColor: '#bdbdbd',
                        },
                        '& .MuiSlider-track': {
                          border: 'none',
                        },
                        '& .MuiSlider-valueLabel': {
                          fontSize: '0.75rem',
                          padding: '0.25rem 0.5rem',
                          backgroundColor: 'secondary.main'
                        }
                      }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 1 }}>
                    <Typography variant="caption" color="text.secondary">
                      {filters.min_year || filterOptions.year_range.min_year}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {filters.max_year || filterOptions.year_range.max_year}
                    </Typography>
                  </Box>
                </Grid>

                {/* Kilometer range slider - Enhanced styling to match others */}
                <Grid item xs={12}>
                  <Typography variant="subtitle2" sx={{ mb: 1, color: '#000000', fontWeight: 800 }}>
                    Chilometraggio
                  </Typography>
                  <Box sx={{ px: 2, mb: 1 }}>
                    <Slider
                      value={[
                        filters.min_km || 0,
                        filters.max_km || 300000
                      ]}
                      onChange={(event, newValue) => {
                        setFilters({
                          ...filters,
                          min_km: newValue[0],
                          max_km: newValue[1]
                        });
                      }}
                      valueLabelDisplay="auto"
                      min={0}
                      max={300000}
                      step={5000}
                      valueLabelFormat={(value) => `${value.toLocaleString()} km`}
                      sx={{
                        color: 'secondary.main',
                        '& .MuiSlider-thumb': {
                          height: 16,
                          width: 16,
                          backgroundColor: '#fff',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                          '&:hover, &.Mui-focusVisible': {
                            boxShadow: '0 0 0 8px rgba(67, 97, 238, 0.16)'
                          }
                        },
                        '& .MuiSlider-rail': {
                          opacity: 0.3,
                          backgroundColor: '#bdbdbd',
                        },
                        '& .MuiSlider-track': {
                          border: 'none',
                        },
                        '& .MuiSlider-valueLabel': {
                          fontSize: '0.75rem',
                          padding: '0.25rem 0.5rem',
                          backgroundColor: 'secondary.main'
                        }
                      }}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 1 }}>
                    <Typography variant="caption" color="text.secondary">
                      {(filters.min_km || 0).toLocaleString()} km
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {(filters.max_km || 300000).toLocaleString()} km
                    </Typography>
                  </Box>
                </Grid>

                {/* Reset filters button - Improved styling */}
                <Grid item xs={12}>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'flex-end', 
                    mt: 2,
                    pt: 2,
                    borderTop: '1px solid',
                    borderColor: 'divider'
                  }}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setFilters({
                          min_price: '',
                          max_price: '',
                          min_year: '',
                          max_year: '',
                          min_km: '',
                          max_km: '',
                          brand: '',
                          model: '',
                          fuel: '',
                          bodystyle: '',
                          transmission: '',
                          color: '',
                        });
                        setSearchTerm('');
                      }}
                      startIcon={<FilterList />}
                      sx={{ 
                        borderRadius: 2,
                        px: 3,
                        py: 1,
                        boxShadow: '0 2px 4px rgba(0,0,0,0.06)',
                        background: 'linear-gradient(to bottom, #ffffff, #f5f5f5)',
                        borderColor: 'divider',
                        '&:hover': {
                          background: 'linear-gradient(to bottom, #f5f5f5, #eeeeee)',
                          borderColor: 'primary.light'
                        }
                      }}
                    >
                      Azzera Filtri
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Box>

          {/* Active Filters Display - With fix for slider edge cases */}
          {(() => {
            // Determine if any filters are actually active
            const hasActiveFilters = Object.entries(filters).some(([key, value]) => {
              if (!value) return false;
              
              // Check if slider values are at their default limits
              switch(key) {
                case 'min_price':
                  return parseInt(value) > filterOptions.price_range.min_price;
                case 'max_price':
                  return parseInt(value) < filterOptions.price_range.max_price;
                case 'min_year':
                  return parseInt(value) > filterOptions.year_range.min_year;
                case 'max_year':
                  return parseInt(value) < filterOptions.year_range.max_year;
                case 'min_km':
                  return parseInt(value) > 0;
                case 'max_km':
                  return parseInt(value) < 300000;
                default:
                  return true; // For non-slider filters, any non-empty value is active
              }
            });
            
            return hasActiveFilters ? (
              <Box sx={{ mb: 3, mt: 1 }}>
                <Typography variant="subtitle2" sx={{ mb: 1, color: 'text.secondary', fontWeight: 600 }}>
                  Filtri Attivi:
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {Object.entries(filters).map(([key, value]) => {
                    if (!value) return null;
                    
                    // Skip displaying slider filters that are at their default limits
                    let isDefaultValue = false;
                    switch(key) {
                      case 'min_price':
                        isDefaultValue = parseInt(value) <= filterOptions.price_range.min_price;
                        break;
                      case 'max_price':
                        isDefaultValue = parseInt(value) >= filterOptions.price_range.max_price;
                        break;
                      case 'min_year':
                        isDefaultValue = parseInt(value) <= filterOptions.year_range.min_year;
                        break;
                      case 'max_year':
                        isDefaultValue = parseInt(value) >= filterOptions.year_range.max_year;
                        break;
                      case 'min_km':
                        isDefaultValue = parseInt(value) <= 0;
                        break;
                      case 'max_km':
                        isDefaultValue = parseInt(value) >= 300000;
                        break;
                    }
                    
                    if (isDefaultValue) return null;
                    
                    // Format the filter label nicely
                    let displayLabel = '';
                    let displayValue = value;
                    
                    switch(key) {
                      case 'min_price':
                        displayLabel = 'Prezzo Min';
                        displayValue = `€${parseInt(value).toLocaleString()}`;
                        break;
                      case 'max_price':
                        displayLabel = 'Prezzo Max';
                        displayValue = `€${parseInt(value).toLocaleString()}`;
                        break;
                      case 'min_year':
                        displayLabel = 'Anno Da';
                        break;
                      case 'max_year':
                        displayLabel = 'Anno A';
                        break;
                      case 'min_km':
                        displayLabel = 'KM Min';
                        displayValue = `${parseInt(value).toLocaleString()} km`;
                        break;
                      case 'max_km':
                        displayLabel = 'KM Max';
                        displayValue = `${parseInt(value).toLocaleString()} km`;
                        break;
                      case 'brand':
                        displayLabel = 'Marca';
                        break;
                      case 'model':
                        displayLabel = 'Modello';
                        break;
                      case 'fuel':
                        displayLabel = 'Carburante';
                        break;
                      case 'bodystyle':
                        displayLabel = 'Carrozzeria';
                        break;
                      case 'transmission':
                        displayLabel = 'Cambio';
                        break;
                      case 'color':
                        displayLabel = 'Colore';
                        break;
                      default:
                        displayLabel = key.replace('_', ' ');
                        displayLabel = displayLabel.charAt(0).toUpperCase() + displayLabel.slice(1);
                    }
                    
                    return (
                      <Chip
                        key={key}
                        label={`${displayLabel}: ${displayValue}`}
                        onDelete={() => handleFilterChange({
                          target: { name: key, value: '' }
                        })}
                        sx={{ 
                          bgcolor: 'rgba(67, 97, 238, 0.1)',
                          color: 'primary.main',
                          fontWeight: 500,
                          borderRadius: 1.5,
                          boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
                          '& .MuiChip-deleteIcon': {
                            color: 'primary.main',
                            '&:hover': {
                              color: 'primary.dark'
                            }
                          }
                        }}
                        size="small"
                      />
                    );
                  })}
                </Box>
              </Box>
            ) : null;
          })()}

          {/* Loading and results section */}
          {loading && carStocks.length === 0 ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 8 }}>
              <CircularProgress size={60} />
            </Box>
          ) : (
            <>
              {carStocks.length === 0 && !loading ? (
                <Card sx={{ p: 4, textAlign: 'center' }}>
                  <Typography variant="h6" color="text.secondary">
                    Nessun risultato trovato. Prova a modificare la ricerca o i filtri.
                  </Typography>
                </Card>
              ) : (
                <>
                  <Grid 
                    container 
                    spacing={{ xs: 2, sm: 3 }}
                    sx={{ 
                      width: '100%',
                      m: 0,
                      '& .MuiGrid-item': {
                        pl: { xs: 2, sm: 3 },
                        pr: { xs: 2, sm: 3 }
                      }
                    }}
                  >
                    {carStocks.map((car, index) => (
                      <Grid 
                        item 
                        xs={12} 
                        sm={6} 
                        lg={4} 
                        xl={3} 
                        key={`${car.vehicleid}-${index}`}
                        sx={{ mb: 3 }}
                      >
                        <CarCard car={car} canEdit={canEdit} />
                      </Grid>
                    ))}
                  </Grid>

                  {/* Improved Load More button */}
                  {carStocks.length > 0 && hasMoreCars && (
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'center', 
                      mt: 4, 
                      mb: 6 
                    }}>
                      <Button
                        variant="contained"
                        onClick={handleLoadMore}
                        disabled={loading}
                        sx={{ 
                          px: 4, 
                          py: 1,
                          borderRadius: 2,
                          minWidth: 200
                        }}
                      >
                        {loading ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          `Carica Altri (${carStocks.length}/${totalCount || '?'})`
                        )}
                      </Button>
                    </Box>
                  )}

                  {/* Improved "no more cars" message */}
                  {carStocks.length > 0 && !hasMoreCars && (
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'center', 
                      mt: 4, 
                      mb: 6 
                    }}>
                      <Typography variant="body2" color="text.secondary">
                        {carStocks.length === totalCount 
                          ? `Visualizzando tutte le ${totalCount} auto` 
                          : "Nessun'altra auto corrisponde ai tuoi filtri"}
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </>
          )}
        </Container>

        <Snackbar 
          open={snackbarOpen} 
          autoHideDuration={6000} 
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Dialog 
          open={openBrandPriority} 
          onClose={handleCloseBrandPriority} 
          fullWidth 
          maxWidth="lg"
          fullScreen={useMediaQuery(theme.breakpoints.down('sm'))}
          sx={{
            '& .MuiDialog-paper': {
              borderRadius: { xs: 0, sm: 3 },
              overflow: 'hidden',
              maxHeight: '100vh',
              height: { xs: '100vh', sm: '90vh' },
              m: { xs: 0, sm: 4 },
              display: 'flex',
              flexDirection: 'column',
            }
          }}
        >
          <DialogTitle sx={{ 
            px: { xs: 2, sm: 4 }, 
            pt: { xs: 2, sm: 3 }, 
            pb: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid',
            borderColor: 'divider',
            flexShrink: 0,
          }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
              Gestione Priorità Marche
            </Typography>
            <IconButton onClick={handleCloseBrandPriority} size="large">
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ 
            p: 0,
            overflow: 'hidden',
            flexGrow: 1,
            display: 'flex',
          }}>
            <BrandPriorityManager availableBrands={filterOptions.brands} />
          </DialogContent>
        </Dialog>

        <Dialog 
          open={openSimilarModels} 
          onClose={handleCloseSimilarModels} 
          fullWidth 
          maxWidth="lg"
          fullScreen={useMediaQuery(theme.breakpoints.down('sm'))}
          sx={{
            '& .MuiDialog-paper': {
              borderRadius: { xs: 0, sm: 3 },
              overflow: 'hidden',
              maxHeight: '100vh',
              height: { xs: '100vh', sm: '90vh' },
              m: { xs: 0, sm: 4 },
              display: 'flex',
              flexDirection: 'column',
            }
          }}
        >
          <DialogTitle sx={{ 
            px: { xs: 2, sm: 4 }, 
            pt: { xs: 2, sm: 3 }, 
            pb: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid',
            borderColor: 'divider',
            flexShrink: 0,
          }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
              Gestione Modelli Simili
            </Typography>
            <IconButton onClick={handleCloseSimilarModels} size="large">
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ 
            p: 0,
            overflow: 'hidden',
            flexGrow: 1,
            display: 'flex',
          }}>
            <SimilarModelsManager />
          </DialogContent>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default CarStockList;
import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, CircularProgress, Alert, TextField, Button, Box, Fade } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';
import { motion } from 'framer-motion';
import Chats from '../chats/Chats';
import { useAuth } from '../contexts/AuthContext';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://admin.aitomotivelab.com';

const AnimatedCard = ({ children, delay = 0 }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: delay * 0.1 }}
    whileHover={{ 
      scale: 1.03,
      boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
      transition: { duration: 0.2 }
    }}
  >
    <Card sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      borderRadius: '12px',
      background: 'linear-gradient(145deg, #ffffff, #f5f5f5)',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
    }}>
      {children}
    </Card>
  </motion.div>
);

const Analytics = ({ companyId, client }) => {
    const { user, appAccess } = useAuth();
    const canViewChats = appAccess['ai_app']?.can_view || user.role === 'superadmin';
    const [analyticsData, setAnalyticsData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(getDefaultStartDate());
    const [endDate, setEndDate] = useState(getDefaultEndDate());
  
    console.log("ANALYTICSSSS", client)
    function getDefaultStartDate() {
      const date = new Date();
      date.setMonth(date.getMonth() - 1);
      return date.toISOString().split('T')[0];
    }
  
    function getDefaultEndDate() {
      return new Date().toISOString().split('T')[0];
    }
  
    const fetchAnalytics = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token di autenticazione non trovato');
        }
  
        let apiUrl = `${API_BASE_URL}/ai_app/ai/analytics_company/?start_date=${startDate}&end_date=${endDate}`;
  
        if (client) {
          apiUrl += `&client=${client}`;
        }
  
        const response = await fetch(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new Error(`Errore HTTP! stato: ${response.status}`);
        }
  
        const data = await response.json();
        console.log(data);
        setAnalyticsData(data);
      } catch (error) {
        console.error('Errore nel recupero delle analitiche:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      fetchAnalytics();
    }, [companyId, client, startDate, endDate]);
  
    useEffect(() => {
      fetchAnalytics();
    }, [companyId]);
  
    const handleDateChange = () => {
      fetchAnalytics();
    };
  
    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress size={60} thickness={5} />
        </Box>
      );
    }
  
    if (error) {
      return <Alert severity="error" variant="filled">Errore: {error}</Alert>;
    }
  
    if (!analyticsData) {
      return <Alert severity="info" variant="filled">Nessun dato disponibile.</Alert>;
    }
  
    const chartData = analyticsData.daily_chat_count?.map(item => ({
      date: item.date,
      count: item.count
    })) || [];
  
    const pieData = analyticsData.top_categories?.map(category => ({
      id: category.category,
      value: category.count,
      label: category.category
    })) || [];
  
    const handleChatClick = (chatId) => {
      console.log(`Apertura chat con ID: ${chatId}`);
    };
  
    const getTitle = (defaultTitle) => defaultTitle;
  
    // Extract and process trigger analytics data
    const triggerPageData = analyticsData.trigger_page_analytics || [];
    const triggerModeData = analyticsData.trigger_mode_analytics || [];

    const triggerModeChartData = triggerModeData.map(trigger => ({
        mode: trigger.trigger_mode,
        triggers: trigger.total_triggers,
        engagements: trigger.successful_engagements,
        leads: trigger.successful_leads,
        successRate: (trigger.successful_engagements / trigger.total_triggers) * 100 || 0,
        leadRate: (trigger.successful_leads / trigger.successful_engagements) * 100 || 0
    }));

    const pageAnalyticsChartData = triggerPageData.map(trigger => ({
        page: trigger.page_url || 'Unknown',
        triggers: trigger.total_triggers,
        engagements: trigger.successful_engagements,
        leads: trigger.successful_leads,
        successRate: (trigger.successful_engagements / trigger.total_triggers) * 100 || 0,
        leadRate: (trigger.successful_leads / trigger.successful_engagements) * 100 || 0
    }));
  
    // Add gradient color schemes for charts
    const chartColors = {
      primary: ['#2196f3', '#64b5f6', '#90caf9', '#bbdefb'],
      success: ['#4caf50', '#81c784', '#a5d6a7', '#c8e6c9'],
      warning: ['#ff9800', '#ffb74d', '#ffcc80', '#ffe0b2'],
      secondary: ['#9c27b0', '#ba68c8', '#ce93d8', '#e1bee7'],
      error: ['#f44336', '#e57373', '#ef9a9a', '#ffcdd2'],
    };
    
    // Format numbers function
    const formatNumber = (num) => {
      return new Intl.NumberFormat('it-IT').format(num);
    };
  
    return (
      <Box sx={{ p: 3, background: '#f9fafc' }}>
        {/* Animated Title Section */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
        >
          <Typography 
            variant="h4" 
            sx={{ 
              mb: 4, 
              fontWeight: 700, 
              background: 'linear-gradient(90deg, #2196f3, #3f51b5)',
              backgroundClip: 'text',
              textFillColor: 'transparent',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Dashboard Analitica
          </Typography>
        </motion.div>
  
        {/* Date and Button Input Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <Grid container spacing={3} sx={{ mb: 4 }}>
            <Grid item xs={12} md={4}>
              <TextField
                label="Data Inizio"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                sx={{ 
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#3f51b5',
                    },
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Data Fine"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                fullWidth
                InputLabelProps={{ shrink: true }}
                sx={{ 
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#3f51b5',
                    },
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Button 
                variant="contained" 
                onClick={handleDateChange} 
                fullWidth 
                sx={{ 
                  height: '100%', 
                  borderRadius: '8px',
                  background: 'linear-gradient(45deg, #2196f3 30%, #3f51b5 90%)',
                  boxShadow: '0 3px 5px 2px rgba(33, 150, 243, .3)',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #1976d2 30%, #303f9f 90%)',
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 10px 2px rgba(33, 150, 243, .3)',
                  }
                }}
              >
                Aggiorna Analitiche
              </Button>
            </Grid>
          </Grid>
        </motion.div>
  
        {/* Analytics Cards Section */}
        <Fade in={!loading}>
          <Grid container spacing={3}>
            {/* KPI Cards */}
            <Grid item xs={12} md={6} lg={client ? 4 : 3}>
              <AnimatedCard delay={0}>
                <CardContent sx={{ position: 'relative', overflow: 'hidden' }}>
                  <Box sx={{ 
                    position: 'absolute', 
                    top: '-20px', 
                    right: '-20px', 
                    width: '100px', 
                    height: '100px', 
                    borderRadius: '50%', 
                    background: 'rgba(33, 150, 243, 0.1)', 
                    zIndex: 0 
                  }} />
                  <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 600, position: 'relative', zIndex: 1 }}>
                    {getTitle('Messaggi Totali')}
                  </Typography>
                  <Typography variant="h3" sx={{ 
                    color: '#2196f3', 
                    fontWeight: 700, 
                    position: 'relative',
                    zIndex: 1 
                  }}>
                    {formatNumber(analyticsData.total_messages)}
                  </Typography>
                </CardContent>
              </AnimatedCard>
            </Grid>
            
            <Grid item xs={12} md={6} lg={client ? 4 : 3}>
              <AnimatedCard delay={1}>
                <CardContent sx={{ position: 'relative', overflow: 'hidden' }}>
                  <Box sx={{ 
                    position: 'absolute', 
                    top: '-20px', 
                    right: '-20px', 
                    width: '100px', 
                    height: '100px', 
                    borderRadius: '50%', 
                    background: 'rgba(76, 175, 80, 0.1)', 
                    zIndex: 0 
                  }} />
                  <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 600, position: 'relative', zIndex: 1 }}>
                    {getTitle('Chat Totali')}
                  </Typography>
                  <Typography variant="h3" sx={{ 
                    color: '#4caf50', 
                    fontWeight: 700, 
                    position: 'relative',
                    zIndex: 1 
                  }}>
                    {formatNumber(analyticsData.total_chats)}
                  </Typography>
                </CardContent>
              </AnimatedCard>
            </Grid>
           
            {!client && (
              <Grid item xs={12} md={6} lg={3}>
                <AnimatedCard delay={2}>
                  <CardContent sx={{ position: 'relative', overflow: 'hidden' }}>
                    <Box sx={{ 
                      position: 'absolute', 
                      top: '-20px', 
                      right: '-20px', 
                      width: '100px', 
                      height: '100px', 
                      borderRadius: '50%', 
                      background: 'rgba(156, 39, 176, 0.1)', 
                      zIndex: 0 
                    }} />
                    <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 600, position: 'relative', zIndex: 1 }}>
                      {getTitle('Leads Totali')}
                    </Typography>
                    <Typography variant="h3" sx={{ 
                      color: '#9c27b0', 
                      fontWeight: 700, 
                      position: 'relative',
                      zIndex: 1 
                    }}>
                      {formatNumber(analyticsData.unique_clients)}
                    </Typography>
                  </CardContent>
                </AnimatedCard>
              </Grid>
            )}
            
            <Grid item xs={12} md={6} lg={client ? 4 : 3}>
              <AnimatedCard delay={3}>
                <CardContent sx={{ position: 'relative', overflow: 'hidden' }}>
                  <Box sx={{ 
                    position: 'absolute', 
                    top: '-20px', 
                    right: '-20px', 
                    width: '100px', 
                    height: '100px', 
                    borderRadius: '50%', 
                    background: 'rgba(255, 152, 0, 0.1)', 
                    zIndex: 0 
                  }} />
                  <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 600, position: 'relative', zIndex: 1 }}>
                    {getTitle('Prenotazioni Totali')}
                  </Typography>
                  <Typography variant="h3" sx={{ 
                    color: '#ff9800', 
                    fontWeight: 700, 
                    position: 'relative',
                    zIndex: 1 
                  }}>
                    {formatNumber(analyticsData.total_bookings)}
                  </Typography>
                </CardContent>
              </AnimatedCard>
            </Grid>

            {/* New KPI - Form leads total */}
            <Grid item xs={12} md={6} lg={client ? 4 : 3}>
              <AnimatedCard delay={4}>
                <CardContent sx={{ position: 'relative', overflow: 'hidden' }}>
                  <Box sx={{ 
                    position: 'absolute', 
                    top: '-20px', 
                    right: '-20px', 
                    width: '100px', 
                    height: '100px', 
                    borderRadius: '50%', 
                    background: 'rgba(63, 81, 181, 0.1)', 
                    zIndex: 0 
                  }} />
                  <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 600, position: 'relative', zIndex: 1 }}>
                    {getTitle('Lead Total')}
                  </Typography>
                  <Typography variant="h3" sx={{ 
                    color: '#3f51b5', 
                    fontWeight: 700, 
                    position: 'relative',
                    zIndex: 1 
                  }}>
                    {formatNumber(analyticsData.form_leads || 0)}
                  </Typography>
                </CardContent>
              </AnimatedCard>
            </Grid>

            {/* New KPI - Form leads with chat */}
            <Grid item xs={12} md={6} lg={client ? 4 : 3}>
              <AnimatedCard delay={5}>
                <CardContent sx={{ position: 'relative', overflow: 'hidden' }}>
                  <Box sx={{ 
                    position: 'absolute', 
                    top: '-20px', 
                    right: '-20px', 
                    width: '100px', 
                    height: '100px', 
                    borderRadius: '50%', 
                    background: 'rgba(244, 67, 54, 0.1)', 
                    zIndex: 0 
                  }} />
                  <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 600, position: 'relative', zIndex: 1 }}>
                    {getTitle('Lead da form con almeno un messagio')}
                  </Typography>
                  <Typography variant="h3" sx={{ 
                    color: '#f44336', 
                    fontWeight: 700, 
                    position: 'relative',
                    zIndex: 1 
                  }}>
                    {formatNumber(analyticsData.form_lead_from_chat || 0)}
                  </Typography>
                </CardContent>
              </AnimatedCard>
            </Grid>
  
            {client && false && (
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Chats chats={analyticsData.recent_chats} onChatClick={handleChatClick} client={client}/>
                  </CardContent>
                </Card>
              </Grid>
            )}
  
            {/* Bar Chart Card */}
            <Grid item xs={12} md={12}>
              <AnimatedCard delay={6}>
                <CardContent>
                  <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 600, mb: 2 }}>
                    {getTitle('Conteggio Chat Giornaliere')}
                  </Typography>
                  {chartData.length > 0 ? (
                    <BarChart
                      xAxis={[{
                        scaleType: 'band',
                        data: chartData.map((item) => {
                          const date = new Date(item.date);
                          return date.toLocaleDateString('it-IT', {
                            day: '2-digit',
                            month: '2-digit'
                          });
                        }),
                        tickLabelStyle: {
                          angle: 45,
                          textAnchor: 'start',
                          fontSize: 12
                        }
                      }]}
                      series={[{
                        data: chartData.map((item) => item.count),
                        color: '#2196f3',
                        label: 'Numero di chat'
                      }]}
                      height={300}
                      margin={{ left: 40, right: 40, top: 20, bottom: 40 }}
                      sx={{
                        '.MuiBarElement-root:hover': {
                          filter: 'brightness(0.9)',
                        },
                      }}
                    />
                  ) : (
                    <Typography>Nessun dato giornaliero di chat disponibile</Typography>
                  )}
                </CardContent>
              </AnimatedCard>
            </Grid>
  
            {/* Brand Distribution Cards */}
            <Grid item xs={12} md={12}>
              <AnimatedCard delay={7}>
                <CardContent>
                  <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 600, mb: 2 }}>
                    {getTitle('Distribuzione Marche')}
                  </Typography>
                  {analyticsData.brand_distribution?.length > 0 ? (
                    <BarChart
                      xAxis={[{ 
                        scaleType: 'band', 
                        data: analyticsData.brand_distribution.map((brand) => brand.brand),
                        tickLabelStyle: {
                          angle: 45,
                          textAnchor: 'start',
                          fontSize: 12
                        }
                      }]}
                      series={[{ 
                        data: analyticsData.brand_distribution.map((brand) => brand.count),
                        color: chartColors.secondary[0]
                      }]}
                      height={300}
                      margin={{ left: 40, right: 40, top: 20, bottom: 40 }}
                      sx={{
                        '.MuiBarElement-root:hover': {
                          filter: 'brightness(0.9)',
                        },
                      }}
                    />
                  ) : (
                    <Typography>Nessun dato di distribuzione marche disponibile</Typography>
                  )}
                </CardContent>
              </AnimatedCard>
            </Grid>
  
            {/* Wishlist Section with improved styling */}
            {client && (
              <Grid item xs={12}>
                <AnimatedCard delay={8}>
                  <CardContent>
                    <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 600, mb: 2 }}>
                      {getTitle('Wishlist')}
                    </Typography>
                    {analyticsData.wishlist?.length > 0 ? (
                      <Grid container spacing={2}>
                        {analyticsData.wishlist.map((item, index) => (
                          <Grid item xs={12} sm={6} md={4} key={item.id}>
                            <motion.div
                              initial={{ opacity: 0, scale: 0.9 }}
                              animate={{ opacity: 1, scale: 1 }}
                              transition={{ duration: 0.5, delay: index * 0.1 }}
                              whileHover={{ 
                                scale: 1.05,
                                transition: { duration: 0.2 }
                              }}
                            >
                              <Card sx={{ 
                                borderRadius: '12px',
                                overflow: 'hidden',
                                boxShadow: '0 5px 15px rgba(0,0,0,0.08)'
                              }}>
                                <Box sx={{ position: 'relative' }}>
                                  <img 
                                    src={item.car_stocks__urlmainimage} 
                                    alt={item.car_stocks__model} 
                                    style={{ 
                                      width: '100%', 
                                      height: '180px',
                                      objectFit: 'cover'
                                    }} 
                                  />
                                  <Box sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    background: 'linear-gradient(to bottom, transparent 60%, rgba(0,0,0,0.7))',
                                  }} />
                                </Box>
                                <CardContent>
                                  <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                                    {item.car_stocks__brand} {item.car_stocks__model}
                                  </Typography>
                                  <Typography variant="body2" sx={{ 
                                    color: 'text.secondary',
                                    display: 'inline-block',
                                    background: 'rgba(33, 150, 243, 0.1)',
                                    padding: '3px 8px',
                                    borderRadius: '4px',
                                    fontWeight: 500
                                  }}>
                                    ID Veicolo: {item.car_stocks__vehicleid}
                                  </Typography>
                                </CardContent>
                              </Card>
                            </motion.div>
                          </Grid>
                        ))}
                      </Grid>
                    ) : (
                      <Typography>Nessuna wishlist disponibile</Typography>
                    )}
                  </CardContent>
                </AnimatedCard>
              </Grid>
            )}
            
            {!client && canViewChats && false && (
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Chats chats={analyticsData.recent_chats} onChatClick={handleChatClick} client={client}/>
                  </CardContent>
                </Card>
              </Grid>
            )}

            {/* Trigger Mode Analytics Bar Chart */}
            <Grid item xs={12} md={12}>
                <AnimatedCard delay={9}>
                    <CardContent>
                        <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 600, mb: 2 }}>
                            {getTitle('Analisi per Modalità Trigger')}
                        </Typography>
                        {triggerModeChartData.length > 0 ? (
                            <BarChart
                                xAxis={[{
                                    scaleType: 'band',
                                    data: triggerModeChartData.map((item) => item.mode),
                                    tickLabelStyle: {
                                        angle: 45,
                                        textAnchor: 'start',
                                        fontSize: 12
                                    }
                                }]}
                                series={[
                                    {
                                        data: triggerModeChartData.map((item) => item.triggers),
                                        color: chartColors.success[0],
                                        label: 'Trigger Totali'
                                    },
                                    {
                                        data: triggerModeChartData.map((item) => item.engagements),
                                        color: chartColors.primary[0],
                                        label: 'Engagement'
                                    },
                                    {
                                        data: triggerModeChartData.map((item) => item.leads),
                                        color: chartColors.warning[0],
                                        label: 'Leads'
                                    },
                                    {
                                        data: triggerModeChartData.map((item) => item.successRate),
                                        color: chartColors.secondary[0],
                                        label: 'Tasso di Successo (%)'
                                    },
                                    {
                                        data: triggerModeChartData.map((item) => item.leadRate),
                                        color: chartColors.error[0],
                                        label: 'Tasso di Lead (%)'
                                    }
                                ]}
                                height={350}
                                margin={{ left: 40, right: 40, top: 20, bottom: 40 }}
                                sx={{
                                  '.MuiBarElement-root:hover': {
                                    filter: 'brightness(0.9)',
                                  },
                                }}
                            />
                        ) : (
                            <Typography>Nessun dato di trigger disponibile</Typography>
                        )}
                    </CardContent>
                </AnimatedCard>
            </Grid>

            {/* Page Analytics Bar Chart */}
            <Grid item xs={12} md={12}>
                <AnimatedCard delay={10}>
                    <CardContent>
                        <Typography variant="h6" color="text.secondary" sx={{ fontWeight: 600, mb: 2 }}>
                            {getTitle('Analisi per Pagina')}
                        </Typography>
                        {pageAnalyticsChartData.length > 0 ? (
                            <BarChart
                                xAxis={[{
                                    scaleType: 'band',
                                    data: pageAnalyticsChartData.map((item) => item.page),
                                    tickLabelStyle: {
                                        angle: 45,
                                        textAnchor: 'start',
                                        fontSize: 12
                                    }
                                }]}
                                series={[
                                    {
                                        data: pageAnalyticsChartData.map((item) => item.triggers),
                                        color: chartColors.success[0],
                                        label: 'Trigger Totali'
                                    },
                                    {
                                        data: pageAnalyticsChartData.map((item) => item.engagements),
                                        color: chartColors.primary[0],
                                        label: 'Engagement'
                                    },
                                    {
                                        data: pageAnalyticsChartData.map((item) => item.leads),
                                        color: chartColors.warning[0],
                                        label: 'Leads'
                                    },
                                    {
                                        data: pageAnalyticsChartData.map((item) => item.successRate),
                                        color: chartColors.secondary[0],
                                        label: 'Tasso di Successo (%)'
                                    },
                                    {
                                        data: pageAnalyticsChartData.map((item) => item.leadRate),
                                        color: chartColors.error[0],
                                        label: 'Tasso di Lead (%)'
                                    }
                                ]}
                                height={350}
                                margin={{ left: 40, right: 40, top: 20, bottom: 60 }}
                                sx={{
                                  '.MuiBarElement-root:hover': {
                                    filter: 'brightness(0.9)',
                                  },
                                }}
                            />
                        ) : (
                            <Typography>Nessun dato di pagina disponibile</Typography>
                        )}
                    </CardContent>
                </AnimatedCard>
            </Grid>
          </Grid>
        </Fade>
      </Box>
    );
  };
  
  export default Analytics;
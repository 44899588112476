import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Card, 
  CardContent, 
  Typography, 
  Alert, 
  Paper, 
  CircularProgress,
  Box,
  Container,
  List,
  ListItem,
  Avatar,
  CardMedia,
  Divider,
  IconButton,
  Dialog,
  DialogContent,
  Tooltip,
  Fade,
  Chip,
  Button
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileStepper from '@mui/material/MobileStepper';
import { 
  KeyboardArrowLeft, 
  KeyboardArrowRight,
  Description as DescriptionIcon,
  Close as CloseIcon,
  InsertDriveFile as FileIcon,
  Image as ImageIcon,
  AudioFile as AudioIcon,
  Person as PersonIcon,
  Phone as PhoneIcon,
  DirectionsCar,
  Assignment,
  Schedule,
  NoteAdd,
  Chat
} from '@mui/icons-material';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { alpha } from '@mui/material/styles';

const BASE_URL = 'https://admin.aitomotivelab.com';

// Add styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: 0,
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#f5f5f5',
  boxShadow: 'none',
  overflow: 'hidden',
  position: 'relative',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: 'linear-gradient(90deg, #FF6B6B, #4ECDC4, #45B7D1)',
  },
}));

const MessageList = styled(List)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
  padding: theme.spacing(1),
  paddingTop: `calc(${theme.spacing(8)} + 4px)`,
  paddingBottom: theme.spacing(10),
  margin: 0,
  [theme.breakpoints.down('sm')]: {
    paddingBottom: theme.spacing(12),
  },
  '&::-webkit-scrollbar': {
    width: '8px',
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.main,
    borderRadius: '4px',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  },
  '&:hover::-webkit-scrollbar-thumb': {
    opacity: 1,
  },
  scrollbarWidth: 'thin',
  scrollbarColor: `${theme.palette.primary.main} transparent`,
  '&:not(:hover)': {
    scrollbarColor: 'transparent transparent',
  },
}));

const MessagePaper = styled(Paper)(({ theme, isBot }) => ({
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(1),
  backgroundColor: isBot ? theme.palette.primary.light : theme.palette.background.paper,
  color: isBot ? theme.palette.primary.contrastText : theme.palette.text.primary,
  borderRadius: '12px',
  maxWidth: '100%',
  wordBreak: 'break-word',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
    borderRadius: '8px',
    marginLeft: 0,
    marginRight: 0,
    '& img': {
      borderRadius: '4px',
    }
  }
}));

const CarAttachmentsContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
  }
}));

const CarCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  backgroundColor: theme.palette.background.paper,
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: '0 12px 20px rgba(0,0,0,0.1)',
  },
}));

const FileDisplay = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  width: '100%',
  '& img': {
    maxWidth: '100%',
    height: 'auto',
  },
  '& audio': {
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }
}));

const MobileCarouselContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  overflowX: 'hidden',
  '& .carousel-content': {
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    touchAction: 'pan-y pinch-zoom',
  },
}));

const MobileCarCarousel = ({ cars }) => {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = cars.length;
  const touchStart = useRef(null);
  const touchEnd = useRef(null);

  // Minimum swipe distance (in px)
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    touchEnd.current = null;
    touchStart.current = e.targetTouches[0].clientX;
  };

  const onTouchMove = (e) => {
    touchEnd.current = e.targetTouches[0].clientX;
  };

  const onTouchEnd = () => {
    if (!touchStart.current || !touchEnd.current) return;
    
    const distance = touchStart.current - touchEnd.current;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe && activeStep < maxSteps - 1) {
      setActiveStep(prev => prev + 1);
    }
    if (isRightSwipe && activeStep > 0) {
      setActiveStep(prev => prev - 1);
    }
  };

  return (
    <MobileCarouselContainer>
      <Box 
        className="carousel-content" 
        sx={{ 
          width: '100%', 
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Box sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}>
          <AnimatePresence mode="wait">
            <motion.div
              key={activeStep}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.3 }}
              style={{ width: '100%' }}
              onTouchStart={onTouchStart}
              onTouchMove={onTouchMove}
              onTouchEnd={onTouchEnd}
            >
              <Card sx={{
                width: '94%',
                margin: '0 auto',
                borderRadius: 2,
                overflow: 'hidden',
                backgroundColor: 'background.paper',
                boxShadow: 3,
              }}>
                {cars[activeStep].urlmainimage && (
                  <Box sx={{ position: 'relative' }}>
                    <CardMedia
                      component="img"
                      height="240"
                      image={cars[activeStep].urlmainimage}
                      alt={`${cars[activeStep].brand} ${cars[activeStep].model}`}
                      sx={{ objectFit: 'cover' }}
                    />
                    <Box sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      background: 'linear-gradient(transparent, rgba(0,0,0,0.7))',
                      p: 2,
                    }}>
                      <Typography variant="h6" sx={{ 
                        color: 'white', 
                        textShadow: '1px 1px 2px rgba(0,0,0,0.6)',
                        fontSize: '1.25rem',
                        fontWeight: 'bold'
                      }}>
                        {cars[activeStep].brand} {cars[activeStep].model}
                      </Typography>
                    </Box>
                  </Box>
                )}
                <CardContent sx={{ p: 3 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      alignItems: 'center',
                      mb: 1
                    }}>
                      <Typography variant="h5" color="primary" sx={{ 
                        fontWeight: 'bold', 
                        fontSize: '1.75rem' 
                      }}>
                        €{cars[activeStep].saleprice?.toLocaleString()}
                      </Typography>
                    </Box>
                    <Box sx={{ 
                      display: 'flex', 
                      gap: 2, 
                      mt: 1,
                      justifyContent: 'space-between'
                    }}>
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        flex: 1,
                        p: 2,
                        bgcolor: 'background.default',
                        borderRadius: 2
                      }}>
                        <Typography variant="body2" color="text.secondary">Anno</Typography>
                        <Typography variant="h6" fontWeight="medium">
                          {cars[activeStep].registrationyear}
                        </Typography>
                      </Box>
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        flex: 1,
                        p: 2,
                        bgcolor: 'background.default',
                        borderRadius: 2
                      }}>
                        <Typography variant="body2" color="text.secondary">Chilometri</Typography>
                        <Typography variant="h6" fontWeight="medium">
                          {cars[activeStep].km?.toLocaleString()} km
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </motion.div>
          </AnimatePresence>
        </Box>
      </Box>

      {/* Enhanced MobileStepper */}
      <Box sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 2,
      }}>
        {maxSteps > 1 && (
          <>
            <Typography 
              variant="caption" 
              color="text.secondary" 
              sx={{ mb: 1 }}
            >
              Scorri per vedere altre auto
            </Typography>
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              sx={{
                background: 'transparent',
                '& .MuiMobileStepper-dots': {
                  gap: '8px',
                },
                '& .MuiMobileStepper-dot': {
                  width: 12,
                  height: 12,
                  margin: 0,
                  transition: 'all 0.3s ease',
                },
                '& .MuiMobileStepper-dotActive': {
                  width: 24,
                  backgroundColor: 'primary.main',
                  borderRadius: '6px',
                },
              }}
            />
          </>
        )}
      </Box>
    </MobileCarouselContainer>
  );
};

// Add this component for desktop grid view
const CarStockCard = ({ car }) => (
  <Card sx={{
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 2,
    overflow: 'hidden',
    backgroundColor: 'background.paper',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      transform: 'translateY(-8px)',
      boxShadow: '0 12px 20px rgba(0,0,0,0.1)',
    },
  }}>
    {car.urlmainimage && (
      <CardMedia
        component="img"
        height="160"
        image={car.urlmainimage}
        alt={`${car.brand} ${car.model}`}
        sx={{ objectFit: 'cover' }}
      />
    )}
    <CardContent sx={{ p: 2, flexGrow: 1 }}>
      <Typography variant="h6" gutterBottom noWrap>
        {car.brand} {car.model}
      </Typography>
      <Typography variant="h5" color="primary" gutterBottom>
        €{car.saleprice?.toLocaleString()}
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flex: 1,
          p: 1,
          bgcolor: 'background.default',
          borderRadius: 1
        }}>
          <Typography variant="caption" color="text.secondary">Year</Typography>
          <Typography variant="body1" fontWeight="medium">
            {car.registrationyear}
          </Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          flex: 1,
          p: 1,
          bgcolor: 'background.default',
          borderRadius: 1
        }}>
          <Typography variant="caption" color="text.secondary">Mileage</Typography>
          <Typography variant="body1" fontWeight="medium">
            {car.km?.toLocaleString()} km
          </Typography>
        </Box>
      </Box>
    </CardContent>
  </Card>
);

// Add a new styled component for the header
const HeaderBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  position: 'sticky',
  top: 0,
  zIndex: 1100,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
}));

// Add new styled components for client info
const ClientInfoSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const ClientInformation = ({ clientName, clientEmail }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const isValidPhoneNumber = (phone) => {
    if (!phone) return false;
    if (phone.includes('temporary') || phone.includes('_dcce')) return false;
    return /^\+?[\d\s-]{10,}$/.test(phone.replace(/\D/g, ''));
  };

  const formatPhoneNumber = (phone) => {
    if (!isValidPhoneNumber(phone)) return null;
    const cleaned = phone.replace(/\D/g, '');
    return cleaned.startsWith('39') ? cleaned : `39${cleaned}`;
  };

  const getWhatsAppLink = (phone) => {
    const formattedPhone = formatPhoneNumber(phone);
    return formattedPhone ? `https://wa.me/${formattedPhone}` : null;
  };

  return (
    <ClientInfoSection>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2
      }}>
        <Typography 
          variant="h6" 
          sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 1,
            color: theme.palette.primary.main,
            fontWeight: 600
          }}
        >
          <PersonIcon sx={{ fontSize: '1.5rem' }} />
          Informazioni Cliente
        </Typography>
      </Box>

      <Paper
        elevation={2}
        sx={{
          p: 2,
          borderRadius: 2,
          bgcolor: alpha(theme.palette.primary.main, 0.04),
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: theme.shadows[4],
          },
        }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row',
          gap: isSmallScreen ? 2 : 3,
          alignItems: isSmallScreen ? 'flex-start' : 'center',
          justifyContent: 'space-between'
        }}>
          {/* Client Name Section */}
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            flex: 1
          }}>
            <Avatar 
              sx={{ 
                width: 48, 
                height: 48,
                bgcolor: theme.palette.primary.main,
                boxShadow: theme.shadows[2]
              }}
            >
              {clientName ? clientName.charAt(0).toUpperCase() : <PersonIcon />}
            </Avatar>
            <Box>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  fontWeight: 600,
                  color: theme.palette.text.primary
                }}
              >
                {clientName || 'Cliente Anonimo'}
              </Typography>
              <Typography 
                variant="caption" 
                sx={{ 
                  color: theme.palette.text.secondary,
                  display: 'block'
                }}
              >
                {isValidPhoneNumber(clientEmail) ? 'Contatto telefonico' : 'Cliente ID'}: {clientEmail || 'N/A'}
              </Typography>
            </Box>
          </Box>

          {/* Contact Actions */}
          {clientEmail && isValidPhoneNumber(clientEmail) && (
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              [theme.breakpoints.down('sm')]: {
                width: '100%',
                pl: 7,
              }
            }}>
              <Chip
                icon={<PhoneIcon sx={{ fontSize: 16 }} />}
                label={clientEmail}
                variant="outlined"
                clickable
                component="a"
                href={`tel:${clientEmail}`}
                sx={{
                  borderRadius: '16px',
                  borderColor: theme.palette.primary.main,
                  color: theme.palette.primary.main,
                  '& .MuiChip-icon': {
                    color: theme.palette.primary.main,
                  },
                  '&:hover': {
                    bgcolor: alpha(theme.palette.primary.main, 0.1),
                    borderColor: theme.palette.primary.dark,
                  },
                }}
              />
              <Tooltip title="Send WhatsApp message">
                <IconButton
                  component="a"
                  href={getWhatsAppLink(clientEmail)}
                  target="_blank"
                  rel="noopener noreferrer"
                  size={isSmallScreen ? "small" : "medium"}
                  sx={{
                    color: '#25D366',
                    '&:hover': {
                      bgcolor: alpha('#25D366', 0.1),
                    },
                  }}
                >
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    width={isSmallScreen ? "20" : "24"} 
                    height={isSmallScreen ? "20" : "24"} 
                    viewBox="0 0 24 24"
                    style={{ fill: 'currentColor' }}
                  >
                    <path d="M12.031 6.172c-3.181 0-5.767 2.586-5.768 5.766-.001 1.298.38 2.27 1.019 3.287l-.582 2.128 2.182-.573c.978.58 1.911.928 3.145.929 3.178 0 5.767-2.587 5.768-5.766.001-3.187-2.575-5.77-5.764-5.771zm3.392 8.244c-.144.405-.837.774-1.17.824-.299.045-.677.063-1.092-.069-.252-.08-.575-.187-.988-.365-1.739-.751-2.874-2.502-2.961-2.617-.087-.116-.708-.94-.708-1.793s.448-1.273.607-1.446c.159-.173.346-.217.462-.217l.332.006c.106.005.249-.04.39.298.144.347.491 1.2.534 1.287.043.087.072.188.014.304-.058.116-.087.188-.173.289l-.26.304c-.087.086-.177.18-.076.354.101.174.449.741.964 1.201.662.591 1.221.774 1.394.86s.274.072.376-.043c.101-.116.433-.506.549-.68.116-.173.231-.145.39-.087s1.011.477 1.184.564.289.13.332.202c.045.072.045.419-.1.824zm-3.423-14.416c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm.029 18.88c-1.161 0-2.305-.292-3.318-.844l-3.677.964.984-3.595c-.607-1.052-.927-2.246-.926-3.468.001-3.825 3.113-6.937 6.937-6.937 1.856.001 3.598.723 4.907 2.034 1.31 1.311 2.031 3.054 2.03 4.908-.001 3.825-3.113 6.938-6.937 6.938z"/>
                  </svg>
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      </Paper>
    </ClientInfoSection>
  );
};

const SummarySection = ({ summary }) => {
  const [summaryViewMode, setSummaryViewMode] = useState('table');
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const theme = useTheme();

  const parseSummary = (text) => {
    const sections = {
      vehicles: '',
      mainRequest: '',
      timing: {
        status: 'REQUIRES CUSTOMER SERVICE ORGANIZATION',
        details: ''
      },
      additionalNotes: '',
      conversationSummary: ''
    };

    // For timing, get everything between "ORGANIZATION -" and "- Note Aggiuntive"
    const timingMatch = text.match(/ORGANIZATION -([^-]+)- Note Aggiuntive/);
    if (timingMatch && timingMatch[1]) {
      sections.timing.details = timingMatch[1].trim();
      sections.timing.status = ''; // Clear the status since we have details
    }

    // Parse other sections normally
    const parts = text.split(/[-\n]+/).map(part => part.trim()).filter(Boolean);
    parts.forEach(part => {
      if (part.startsWith('Veicoli:')) {
        sections.vehicles = part.replace('Veicoli:', '').trim();
      } else if (part.startsWith('Richiesta Principale:')) {
        sections.mainRequest = part.replace('Richiesta Principale:', '').trim();
      } else if (part.startsWith('Note Aggiuntive:')) {
        sections.additionalNotes = part.replace('Note Aggiuntive:', '').trim();
      } else if (part.startsWith('Riassunto conversazionale:')) {
        sections.conversationSummary = part.replace('Riassunto conversazionale:', '').trim();
      }
    });

    return sections;
  };

  const sections = parseSummary(summary);

  return (
    <Box sx={{ 
      width: '100%', 
      overflowX: 'auto',
      borderRadius: 2,
      bgcolor: 'background.paper',
      boxShadow: theme.shadows[3],
      p: isSmallScreen ? 1 : 2,
    }}>
      <Box sx={{
        display: 'grid',
        gap: 2,
        gridTemplateColumns: isSmallScreen ? '1fr' : 'repeat(2, 1fr)', // Two columns on desktop
      }}>
        {[
          { 
            icon: <DirectionsCar />, 
            label: 'Veicoli', 
            value: sections.vehicles,
            color: theme.palette.primary.main,
            bgColor: alpha(theme.palette.primary.main, 0.1)
          },
          { 
            icon: <Assignment />, 
            label: 'Richiesta Principale', 
            value: sections.mainRequest,
            color: theme.palette.secondary.main,
            bgColor: alpha(theme.palette.secondary.main, 0.1)
          },
          { 
            icon: <Schedule />, 
            label: 'Tempistiche',
            value: sections.timing.details || sections.timing.status,
            isStatus: !sections.timing.details,
            color: theme.palette.warning.main,
            bgColor: alpha(theme.palette.warning.main, 0.1)
          },
          { 
            icon: <NoteAdd />, 
            label: 'Note Aggiuntive', 
            value: sections.additionalNotes,
            color: theme.palette.info.main,
            bgColor: alpha(theme.palette.info.main, 0.1)
          },
          { 
            icon: <Chat />, 
            label: 'Riassunto Conversazione', 
            value: sections.conversationSummary,
            color: theme.palette.success.main,
            bgColor: alpha(theme.palette.success.main, 0.1),
            gridColumn: isSmallScreen ? 'auto' : '1 / -1' // Full width on desktop
          }
        ].map((row, index) => (
          <Paper
            key={index}
            elevation={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              borderRadius: 2,
              transition: 'all 0.3s ease',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: theme.shadows[8],
              },
            }}
          >
            {/* Header */}
            <Box
              sx={{
                p: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                borderBottom: `1px solid ${theme.palette.divider}`,
                bgcolor: row.bgColor,
              }}
            >
              <Avatar
                sx={{
                  bgcolor: row.color,
                  color: '#fff',
                  width: 40,
                  height: 40,
                }}
              >
                {React.cloneElement(row.icon, { sx: { fontSize: '1.5rem' } })}
              </Avatar>
              <Typography
                variant="h6"
                sx={{
                  color: row.color,
                  fontWeight: 600,
                  fontSize: isSmallScreen ? '1rem' : '1.25rem',
                }}
              >
                {row.label}
              </Typography>
            </Box>

            {/* Content */}
            <Box
              sx={{
                p: 2,
                minHeight: row.isStatus ? 'auto' : '100px',
                display: 'flex',
                alignItems: row.isStatus ? 'center' : 'flex-start',
                bgcolor: theme.palette.background.paper,
              }}
            >
              {row.isStatus ? (
                <Chip
                  label={row.value}
                  color="primary"
                  sx={{
                    fontWeight: 500,
                    fontSize: '0.875rem',
                    py: 2,
                    px: 1,
                    height: 'auto',
                    '& .MuiChip-label': {
                      px: 2,
                    },
                  }}
                />
              ) : (
                <Typography
                  variant="body1"
                  sx={{
                    color: theme.palette.text.primary,
                    lineHeight: 1.6,
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                  }}
                >
                  {row.value || 'Not specified'}
                </Typography>
              )}
            </Box>
          </Paper>
        ))}
      </Box>
    </Box>
  );
};

// Add these styled components
const ViewToggleButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2),
  position: 'sticky',
  bottom: 16,
  right: 16,
  zIndex: 1300,
  borderRadius: 20,
  boxShadow: theme.shadows[4],
  alignSelf: 'flex-end',
  '&:hover': {
    boxShadow: theme.shadows[8],
  },
}));

const ChatContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  paddingBottom: theme.spacing(8),
}));

// Add these new styled components near the other styled components
const MessageTimestamp = styled(Typography)(({ theme, isBot }) => ({
  fontSize: '0.75rem',
  color: theme.palette.mode === 'dark' 
    ? alpha(theme.palette.common.white, 0.6)
    : alpha(theme.palette.common.black, 0.6),
  position: 'absolute',
  bottom: -20,
  [isBot ? 'left' : 'right']: 8,
  whiteSpace: 'nowrap',
}));

const MessageContent = styled(Box)(({ theme }) => ({
  '& p': {
    margin: 0,
    lineHeight: 1.6,
  },
  '& p:not(:last-child)': {
    marginBottom: theme.spacing(1),
  },
}));

const CarouselWrapper = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, -1.5, -1.5, -1.5),
  borderTop: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  borderBottomLeftRadius: 'inherit',
  borderBottomRightRadius: 'inherit',
  overflow: 'hidden',
  backgroundColor: alpha(theme.palette.background.paper, 0.6),
  backdropFilter: 'blur(10px)',
}));

// Add these new styled components
const DateDivider = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: theme.spacing(3, 0),
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: 0,
    right: 0,
    height: '1px',
    backgroundColor: theme.palette.divider,
    opacity: 0.2,
  }
}));

const DateChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' 
    ? alpha(theme.palette.primary.main, 0.15)
    : alpha(theme.palette.primary.main, 0.1),
  color: theme.palette.text.secondary,
  fontSize: '0.75rem',
  height: 24,
  zIndex: 1,
}));

const OpenChat = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [view, setView] = useState('summary'); // 'summary' or 'chat'
  
  const { chatId } = useParams();
  const [chatData, setChatData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [chatSummary, setChatSummary] = useState('');

  useEffect(() => {
    const fetchChatData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/ai_app/chat-histories/full_chat/?chat_id=${chatId}`);
        setChatData(response.data);
        setChatSummary(response.data[0]?.chat?.summary || '');
        setLoading(false);
      } catch (error) {
        console.error("Error fetching chat:", error);
        setError(error.response?.data?.detail || error.message);
        setLoading(false);
      }
    };

    if (chatId) {
      fetchChatData();
    }
  }, [chatId]);

  const MarkdownContent = ({ content }) => (
    <ReactMarkdown
      components={{
        code: ({ node, inline, className, children, ...props }) => {
          const match = /language-(\w+)/.exec(className || '');
          return !inline && match ? (
            <SyntaxHighlighter
              style={vscDarkPlus}
              language={match[1]}
              PreTag="div"
              {...props}
            >
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
    >
      {content}
    </ReactMarkdown>
  );

  const toggleView = () => {
    setView(view === 'summary' ? 'chat' : 'summary');
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <ChatContainer>
      {/* Common Header with Toggle Button - This will be visible in both views */}
      <HeaderBox>
        <Button
          variant="contained"
          color="primary"
          onClick={toggleView}
          startIcon={view === 'summary' ? <Chat /> : <DescriptionIcon />}
          size={isSmallScreen ? "small" : "medium"}
          sx={{
            borderRadius: 2,
            boxShadow: theme.shadows[2],
            '&:hover': {
              boxShadow: theme.shadows[4],
            },
          }}
        >
          {view === 'summary' ? 'View Chat' : 'View Summary'}
        </Button>
      </HeaderBox>

      {/* Summary View */}
      <Box sx={{ 
        display: view === 'summary' ? 'block' : 'none',
        height: '100%',
        overflow: 'auto',
      }}>
        <ClientInformation 
          clientName={chatData[0]?.chat?.client_name}
          clientEmail={chatData[0]?.chat?.client_email}
        />
        
        {chatSummary && (
          <Box sx={{ p: 2 }}>
            <SummarySection summary={chatSummary} />
          </Box>
        )}
      </Box>

      {/* Chat View */}
      <Box sx={{ 
        display: view === 'chat' ? 'flex' : 'none',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
      }}>
        <MessageList>
          <AnimatePresence>
            {chatData.map((message, index) => {
              // Check if date has changed from previous message
              const currentDate = new Date(message.created_at);
              const previousDate = index > 0 
                ? new Date(chatData[index - 1].created_at)
                : null;
              
              const showDateDivider = !previousDate || 
                currentDate.toDateString() !== previousDate.toDateString();

              const formatDate = (date) => {
                const today = new Date();
                const yesterday = new Date(today);
                yesterday.setDate(yesterday.getDate() - 1);

                if (date.toDateString() === today.toDateString()) {
                  return 'Today';
                } else if (date.toDateString() === yesterday.toDateString()) {
                  return 'Yesterday';
                } else {
                  return date.toLocaleDateString('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  });
                }
              };

              return (
                <motion.div
                  key={message.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  {showDateDivider && (
                    <DateDivider>
                      <DateChip label={formatDate(currentDate)} />
                    </DateDivider>
                  )}

                  {/* User Message */}
                  {message.sender_message && (
                    <ListItem
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        p: 1,
                        mb: 3,
                      }}
                    >
                      <Box sx={{ 
                        maxWidth: '80%', 
                        minWidth: '20%',
                        position: 'relative'
                      }}>
                        <MessagePaper isBot={false} elevation={1}>
                          <MessageContent>
                            <MarkdownContent content={message.sender_message} />
                          </MessageContent>
                        </MessagePaper>
                        <MessageTimestamp variant="caption" isBot={false}>
                          {currentDate.toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true
                          })}
                        </MessageTimestamp>
                      </Box>
                    </ListItem>
                  )}

                  {/* AI Message */}
                  {(message.bot_message || message.car_stocks?.length > 0) && (
                    <ListItem
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        p: 1,
                        mb: 3,
                      }}
                    >
                      <Box sx={{ 
                        maxWidth: '80%', 
                        minWidth: '20%',
                        position: 'relative'
                      }}>
                        <MessagePaper isBot={true} elevation={1}>
                          {message.bot_message && (
                            <MessageContent>
                              <MarkdownContent content={message.bot_message} />
                            </MessageContent>
                          )}
                          
                          {message.car_stocks && message.car_stocks.length > 0 && (
                            <CarouselWrapper>
                              {isSmallScreen ? (
                                <MobileCarCarousel cars={message.car_stocks} />
                              ) : (
                                <CarAttachmentsContainer>
                                  {message.car_stocks.map((car) => (
                                    <CarStockCard
                                      key={car.vehicleid}
                                      car={car}
                                      isSmallScreen={isSmallScreen}
                                    />
                                  ))}
                                </CarAttachmentsContainer>
                              )}
                            </CarouselWrapper>
                          )}
                        </MessagePaper>
                        <MessageTimestamp variant="caption" isBot={true}>
                          {currentDate.toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true
                          })}
                        </MessageTimestamp>
                      </Box>
                    </ListItem>
                  )}
                </motion.div>
              );
            })}
          </AnimatePresence>
        </MessageList>
      </Box>
    </ChatContainer>
  );
};

export default OpenChat;
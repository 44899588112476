import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Button, Box, Typography, Tabs, Tab, Container, FormControl, Select, MenuItem, InputLabel, Grid, List, ListItem, ListItemText, IconButton
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  ArrowBack as ArrowBackIcon
} from '@mui/icons-material';
import axios from 'axios';
import ServiceForm from './components/ServiceForm';
import ServiceList from './components/ServiceList';
import AvailabilityForm from './components/AvailabilityForm';
import AvailabilityList from './components/AvailabilityList';
import HolidayForm from './components/HolidayForm';
import HolidayList from './components/HolidayList';
import AddtionalInformationForm from './components/AddtionalInformationForm';
import AddtionalInformationList from './components/AddtionalInformationList';
import EmailTemplateForm from './components/EmailTemplateForm';
import EmailTemplateList from './components/EmailTemplateList';
import SnackbarNotification from './components/SnackbarNotification';
import TabPanel from './components/TabPanel';
import { useMediaQuery, useTheme } from '@mui/material';
import BranchDefinitionForm from './components/BranchDefinitionForm';
import BranchDefinitionList from './components/BranchDefinitionList';
import { bookingApi } from '../../booking/api';

const API_BASE_URL = 'https://admin.aitomotivelab.com/booking';
const API_BASE_URL_LOCATION = 'https://admin.aitomotivelab.com/user_management/api';

const api = axios.create({
  baseURL: API_BASE_URL,
});

const locationApi = axios.create({
  baseURL: API_BASE_URL_LOCATION,
});

[api, locationApi].forEach(instance => {
  instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
});

const AvaibilityManagementPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [services, setServices] = useState([]);
  const [availabilities, setAvailabilities] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [addtionalInformation, setAddtionalInformation] = useState([]);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [selectedService, setSelectedService] = useState('');
  const [serviceFormData, setServiceFormData] = useState({ 
    name: '', 
    description: '', 
    duration: '', 
    price: '', 
    color: '#FFFFFF',
    test_drive: false,
    check_vehicleid_in_location: false 
  });
  const [availabilityFormData, setAvailabilityFormData] = useState({ 
    day_of_week: [], 
    start_time: '', 
    end_time: '', 
    max_slots: '',
    location: []
  });
  const [holidayFormData, setHolidayFormData] = useState({ 
    name: '', 
    start_date: '', 
    end_date: '', 
    services: [],
    locations: []
  });
  const [addtionalInformationFormData, setAddtionalInformationFormData] = useState({ name: '', description: '' });
  const [emailTemplateFormData, setEmailTemplateFormData] = useState({
    service: '',
    channel: 'email',
    status: 'pending',
    subject: '',
    body: '',
  });
  const [isEditingService, setIsEditingService] = useState(false);
  const [isEditingAvailability, setIsEditingAvailability] = useState(false);
  const [isEditingHoliday, setIsEditingHoliday] = useState(false);
  const [isEditingAddtionalInformation, setIsEditingAddtionalInformation] = useState(false);
  const [isEditingEmailTemplate, setIsEditingEmailTemplate] = useState(false);
  const [editServiceId, setEditServiceId] = useState(null);
  const [editAvailabilityId, setEditAvailabilityId] = useState(null);
  const [editHolidayId, setEditHolidayId] = useState(null);
  const [editAddtionalInformationId, setEditAddtionalInformationId] = useState(null);
  const [editEmailTemplateId, setEditEmailTemplateId] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [locations, setLocations] = useState([]);
  const [branchDefinitions, setBranchDefinitions] = useState([]);
  const [branchValues, setBranchValues] = useState([]);
  const [selectedBranchDefinition, setSelectedBranchDefinition] = useState(null);


  useEffect(() => {
    fetchServices();
    fetchHolidays();
    fetchEmailTemplates();
    fetchLocations();
  }, []);

  useEffect(() => {
    if (selectedService) {
      fetchAvailabilities(selectedService);
      fetchAddtionalInformation(selectedService);
    }
  }, [selectedService]);

  useEffect(() => {
    
    if (location.state?.editService) {
      const service = location.state.editService;
      console.log('location.state:', location.service);
      // Ensure boolean values are explicitly converted
      const testDrive = typeof service.test_drive === 'boolean' ? 
        service.test_drive : Boolean(service.test_drive);
      const checkVehicle = typeof service.check_vehicleid_in_location === 'boolean' ? 
        service.check_vehicleid_in_location : Boolean(service.check_vehicleid_in_location);
      
      setServiceFormData({
        name: service.name,
        description: service.description,
        duration: service.duration.replace('PT', '').replace('M', ''),
        price: service.price.toString(),
        color: service.color || '#FFFFFF',
        test_drive: testDrive,
        check_vehicleid_in_location: checkVehicle,
      });
      
      setIsEditingService(true);
      setEditServiceId(service.id);
      setActiveTab(location.state.activeTab || 0);
    }
  }, [location.state]);

  const fetchServices = async () => {
    try {
      const response = await api.get('/services/');
      setServices(response.data);
      console.log('services:', response.data);
    } catch (error) {
      setSnackbar({ open: true, message: 'Error fetching services', severity: 'error' });
    }
  };

  const fetchAvailabilities = async (serviceId) => {
    try {
      const response = await api.get(`/availabilities/?service=${serviceId}`);
      setAvailabilities(response.data);
    } catch (error) {
      setSnackbar({ open: true, message: 'Error fetching availabilities', severity: 'error' });
    }
  };

  const fetchHolidays = async () => {
    try {
      const response = await api.get('/holidays/');
      setHolidays(response.data);
    } catch (error) {
      setSnackbar({ open: true, message: 'Error fetching holidays', severity: 'error' });
    }
  };

  const fetchAddtionalInformation = async (serviceId) => {
    try {
      const response = await api.get(`/addtionalinformation/?service=${serviceId}`);
      setAddtionalInformation(response.data);
    } catch (error) {
      setSnackbar({ open: true, message: 'Error fetching additional information', severity: 'error' });
    }
  };

  const fetchEmailTemplates = async () => {
    try {
      const response = await api.get('/email-templates/');
      setEmailTemplates(response.data);
    } catch (error) {
      setSnackbar({ open: true, message: 'Error fetching email templates', severity: 'error' });
    }
  };

  const fetchLocations = async () => {
    try {
      const response = await locationApi.get('/locations/');
      setLocations(response.data);
    } catch (error) {
      console.error('Error fetching locations:', error);
      setSnackbar({ open: true, message: 'Error fetching locations', severity: 'error' });
    }
  };

  
  
  const handleServiceFormChange = (event) => {
    const { name, value } = event.target;
    setServiceFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAvailabilityFormChange = (event) => {
    const { name, value } = event.target;
    
    // Special handling for day_of_week to ensure it's always an array
    if (name === 'day_of_week') {
      // Convert value to array if it isn't already
      const days = Array.isArray(value) ? value : [value];
      setAvailabilityFormData(prevData => ({
        ...prevData,
        [name]: days
      }));
    } else {
      // Handle other form fields normally
      setAvailabilityFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleHolidayFormChange = (event) => {
    const { name, value } = event.target;
    setHolidayFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAddtionalInformationFormChange = (event) => {
    const { name, value } = event.target;
    setAddtionalInformationFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEmailTemplateFormChange = (event) => {
    const { name, value } = event.target;
    setEmailTemplateFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleHolidayServiceChange = (event) => {
    const { value } = event.target;
    setHolidayFormData((prevData) => ({ ...prevData, services: value }));
  };

  const handleServiceFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const formattedData = {
        ...serviceFormData,
        duration: `PT${serviceFormData.duration}M`,
        price: parseFloat(serviceFormData.price),
        color: serviceFormData.color,
      };

      if (isEditingService) {
        await api.put(`/services/${editServiceId}/`, formattedData);
        setSnackbar({ open: true, message: 'Service updated successfully!', severity: 'success' });
      } else {
        await api.post('/services/', formattedData);
        setSnackbar({ open: true, message: 'Service added successfully!', severity: 'success' });
      }
      fetchServices();
      resetServiceForm();
    } catch (error) {
      setSnackbar({ open: true, message: 'Error submitting service', severity: 'error' });
    }
  };

  const handleAvailabilityFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const { day_of_week, ...baseData } = availabilityFormData;
      
      // Ensure day_of_week is an array
      const selectedDays = Array.isArray(day_of_week) ? day_of_week : [day_of_week];

      if (isEditingAvailability) {
        // For editing, just update the single record
        await api.put(`/availabilities/${editAvailabilityId}/`, {
          ...baseData,
          day_of_week: parseInt(selectedDays[0], 10),
          service: selectedService,
          max_slots: parseInt(baseData.max_slots, 10)
        });
        setSnackbar({ open: true, message: 'Availability updated successfully', severity: 'success' });
      } else {
        // Create one availability for each selected day
        const createPromises = selectedDays.map(day => 
          api.post('/availabilities/', {
            ...baseData,
            day_of_week: parseInt(day, 10),
            service: selectedService,
            max_slots: parseInt(baseData.max_slots, 10)
          })
        );

        await Promise.all(createPromises);
        setSnackbar({ 
          open: true, 
          message: `Created availabilities for ${selectedDays.length} days`, 
          severity: 'success' 
        });
      }

      fetchAvailabilities(selectedService);
      resetAvailabilityForm();
    } catch (error) {
      console.error('Submission error:', error);
      setSnackbar({ 
        open: true, 
        message: `Error: ${error.response?.data?.detail || 'Failed to submit availability'}`, 
        severity: 'error' 
      });
    }
  };

  const handleHolidayFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const holidayData = {
        ...holidayFormData,
        locations: Array.isArray(holidayFormData.locations) ? holidayFormData.locations : [],
        location: Array.isArray(holidayFormData.locations) ? holidayFormData.locations : []
      };

      if (isEditingHoliday) {
        await api.put(`/holidays/${editHolidayId}/`, holidayData);
        setSnackbar({ open: true, message: 'Holiday updated successfully!', severity: 'success' });
      } else {
        await api.post('/holidays/', holidayData);
        setSnackbar({ open: true, message: 'Holiday added successfully!', severity: 'success' });
      }
      fetchHolidays();
      resetHolidayForm();
    } catch (error) {
      console.error('Submission error:', error);
      setSnackbar({ 
        open: true, 
        message: `Error: ${error.response?.data?.detail || error.response?.data?.location?.[0] || 'Failed to submit holiday'}`, 
        severity: 'error' 
      });
    }
  };

  const handleAddtionalInformationFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const formattedData = {
        ...addtionalInformationFormData,
        service: selectedService,
      };

      if (isEditingAddtionalInformation) {
        await api.put(`/addtionalinformation/${editAddtionalInformationId}/`, formattedData);
        setSnackbar({ open: true, message: 'Information updated successfully!', severity: 'success' });
      } else {
        await api.post('/addtionalinformation/', formattedData);
        setSnackbar({ open: true, message: 'Information added successfully!', severity: 'success' });
      }
      fetchAddtionalInformation(selectedService);
      resetAddtionalInformationForm();
    } catch (error) {
      setSnackbar({ open: true, message: 'Error submitting information', severity: 'error' });
    }
  };

  const handleEmailTemplateFormSubmit = async (event) => {
    event.preventDefault();
    try {
      if (isEditingEmailTemplate) {
        await api.put(`/email-templates/${editEmailTemplateId}/`, emailTemplateFormData);
        setSnackbar({ open: true, message: 'Email template updated successfully!', severity: 'success' });
      } else {
        await api.post('/email-templates/', emailTemplateFormData);
        setSnackbar({ open: true, message: 'Email template added successfully!', severity: 'success' });
      }
      fetchEmailTemplates();
      resetEmailTemplateForm();
    } catch (error) {
      setSnackbar({ open: true, message: 'Error submitting email template', severity: 'error' });
    }
  };

  const handleEditService = (serviceToEdit) => {
    console.log('Service passed to edit:', serviceToEdit);
    
    // Find the complete service object from the services array
    const completeService = services.find(s => s.id === serviceToEdit.id);
    console.log('Complete service from array:', completeService);
    
    if (!completeService) {
      console.error('Service not found in services array');
      return;
    }
    
    // For editing services with time duration in PT format
    let durationInMinutes;
    if (completeService.duration && completeService.duration.includes('PT')) {
      durationInMinutes = completeService.duration.replace('PT', '').replace('M', '');
    } else if (completeService.duration) {
      // Convert HH:MM:SS format to minutes
      const timeParts = completeService.duration.split(':');
      durationInMinutes = (parseInt(timeParts[0] || 0, 10) * 60) + parseInt(timeParts[1] || 0, 10);
    } else {
      durationInMinutes = '0';
    }

    // Extract boolean values directly from the service object - ensure they're preserved
    console.log('Original boolean values:', {
      test_drive: completeService.test_drive,
      check_vehicleid_in_location: completeService.check_vehicleid_in_location
    });

    // Create a new object with the exact same boolean values
    const updatedFormData = {
      name: completeService.name || '',
      description: completeService.description || '',
      duration: durationInMinutes.toString(),
      price: (completeService.price !== undefined && completeService.price !== null) 
        ? completeService.price.toString() 
        : '0',
      color: completeService.color || '#FFFFFF',
      // Use the direct boolean values with fallbacks
      test_drive: completeService.test_drive || false,
      check_vehicleid_in_location: completeService.check_vehicleid_in_location || false
    };
    
    console.log('Updated form data:', updatedFormData);
    
    setServiceFormData(updatedFormData);
    setIsEditingService(true);
    setEditServiceId(completeService.id);
  };

  const handleEditAvailability = (availability) => {
    setAvailabilityFormData({
      day_of_week: availability.day_of_week,
      start_time: availability.start_time,
      end_time: availability.end_time,
      max_slots: availability.max_slots.toString(),
      location: availability.location
    });
    setIsEditingAvailability(true);
    setEditAvailabilityId(availability.id);
  };

  const handleEditHoliday = (holiday) => {
    setHolidayFormData({
      name: holiday.name,
      start_date: holiday.start_date,
      end_date: holiday.end_date,
      services: holiday.services || [],
      locations: holiday.location || []
    });
    setIsEditingHoliday(true);
    setEditHolidayId(holiday.id);
  };

  const handleEditAddtionalInformation = (info) => {
    setAddtionalInformationFormData({
      name: info.name,
      description: info.description,
    });
    setIsEditingAddtionalInformation(true);
    setEditAddtionalInformationId(info.id);
  };

  const handleEditEmailTemplate = (template) => {
    setEmailTemplateFormData({
      service: template.service,
      channel: template.channel,
      status: template.status,
      subject: template.subject,
      body: template.body,
    });
    setIsEditingEmailTemplate(true);
    setEditEmailTemplateId(template.id);
  };

  const handleDeleteService = async (id) => {
    try {
      await api.delete(`/services/${id}/`);
      setSnackbar({ open: true, message: 'Service deleted successfully!', severity: 'success' });
      fetchServices();
      if (selectedService === id) {
        setSelectedService('');
        setAvailabilities([]);
      }
    } catch (error) {
      setSnackbar({ open: true, message: 'Error deleting service', severity: 'error' });
    }
  };

  const handleDeleteAvailability = async (id) => {
    try {
      await api.delete(`/availabilities/${id}/`);
      setSnackbar({ open: true, message: 'Availability deleted successfully!', severity: 'success' });
      fetchAvailabilities(selectedService);
    } catch (error) {
      setSnackbar({ open: true, message: 'Error deleting availability', severity: 'error' });
    }
  };

  const handleDeleteHoliday = async (id) => {
    try {
      await api.delete(`/holidays/${id}/`);
      setSnackbar({ open: true, message: 'Holiday deleted successfully!', severity: 'success' });
      fetchHolidays();
    } catch (error) {
      setSnackbar({ open: true, message: 'Error deleting holiday', severity: 'error' });
    }
  };

  const handleDeleteAddtionalInformation = async (id) => {
    try {
      await api.delete(`/addtionalinformation/${id}/`);
      setSnackbar({ open: true, message: 'Information deleted successfully!', severity: 'success' });
      fetchAddtionalInformation(selectedService);
    } catch (error) {
      setSnackbar({ open: true, message: 'Error deleting information', severity: 'error' });
    }
  };

  const handleDeleteEmailTemplate = async (id) => {
    try {
      await api.delete(`/email-templates/${id}/`);
      setSnackbar({ open: true, message: 'Email template deleted successfully!', severity: 'success' });
      fetchEmailTemplates();
    } catch (error) {
      setSnackbar({ open: true, message: 'Error deleting email template', severity: 'error' });
    }
  };

  const resetServiceForm = () => {
    setServiceFormData({ 
      name: '', 
      description: '', 
      duration: '', 
      price: '', 
      color: '#FFFFFF',
      test_drive: false,
      check_vehicleid_in_location: false 
    });
    setIsEditingService(false);
    setEditServiceId(null);
  };

  const resetAvailabilityForm = () => {
    setAvailabilityFormData({ 
      day_of_week: [], 
      start_time: '', 
      end_time: '', 
      max_slots: '', 
      location: []
    });
    setIsEditingAvailability(false);
    setEditAvailabilityId(null);
  };

  const resetHolidayForm = () => {
    setHolidayFormData({ name: '', start_date: '', end_date: '', services: [], locations: [] });
    setIsEditingHoliday(false);
    setEditHolidayId(null);
  };

  const resetAddtionalInformationForm = () => {
    setAddtionalInformationFormData({ name: '', description: '' });
    setIsEditingAddtionalInformation(false);
    setEditAddtionalInformationId(null);
  };

  const resetEmailTemplateForm = () => {
    setEmailTemplateFormData({
      service: '',
      channel: 'email',
      status: 'pending',
      subject: '',
      body: '',
    });
    setIsEditingEmailTemplate(false);
    setEditEmailTemplateId(null);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };


  const dayOfWeekOptions = [
    { value: 0, label: 'Monday' },
    { value: 1, label: 'Tuesday' },
    { value: 2, label: 'Wednesday' },
    { value: 3, label: 'Thursday' },
    { value: 4, label: 'Friday' },
    { value: 5, label: 'Saturday' },
    { value: 6, label: 'Sunday' },
  ];






  const resetBranchDefinitionForm = () => {
    setBranchDefinitionFormData({ name: '', description: '' });
    setIsEditingBranchDefinition(false);
    setEditBranchDefinitionId(null);
  };

  const handleBranchDefinitionChange = (definitionId) => {
    setSelectedBranchDefinition(definitionId);
    if (definitionId) {
      fetchBranchValues(definitionId);
    } else {
      setBranchValues([]);
    }
  };

  return (
    <Container 
      maxWidth={false} 
      sx={{ 
        p: isMobile ? 1 : 2,
        height: '100vh',
        overflow: 'auto'
      }}
    >
      <Box sx={{ 
        mb: 2,
        display: 'flex',
        alignItems: 'center',
        gap: 1
      }}>
        <IconButton 
          edge="start"
          onClick={() => navigate('/booking')}
          sx={{ display: isMobile ? 'flex' : 'none' }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant={isMobile ? 'h6' : 'h5'}>
          Calendar Settings
        </Typography>
      </Box>

      <Tabs 
        value={activeTab} 
        onChange={(e, newValue) => setActiveTab(newValue)}
        variant={isMobile ? "scrollable" : "standard"}
        scrollButtons={isMobile ? "auto" : false}
        sx={{
          mb: 2,
          borderBottom: 1,
          borderColor: 'divider',
          '& .MuiTab-root': {
            fontSize: isMobile ? '0.875rem' : 'inherit',
            minWidth: isMobile ? 'auto' : 120,
            px: isMobile ? 2 : 3
          }
        }}
      >
        <Tab label="Services" />
        <Tab label="Availability" />
        <Tab label="Holidays" />
        <Tab label="Additional Info" />
        <Tab label="Email Templates" />
      </Tabs>

      <Box sx={{ 
        '& .MuiFormControl-root': {
          mb: 2,
          width: '100%'
        },
        '& .MuiButton-root': {
          width: isMobile ? '100%' : 'auto',
          mb: isMobile ? 1 : 0
        }
      }}>
        <TabPanel value={activeTab} index={0}>
          <ServiceForm
            serviceFormData={serviceFormData}
            handleServiceFormChange={handleServiceFormChange}
            handleServiceFormSubmit={handleServiceFormSubmit}
            isEditingService={isEditingService}
            isMobile={isMobile}
          />
          <ServiceList
            services={services}
            handleEditService={handleEditService}
            handleDeleteService={handleDeleteService}
            isMobile={isMobile}
          />
        </TabPanel>

        <TabPanel value={activeTab} index={1}>
          <FormControl fullWidth>
            <InputLabel id="service-select-label">Select Service</InputLabel>
            <Select
              labelId="service-select-label"
              value={selectedService}
              onChange={(e) => setSelectedService(e.target.value)}
            >
              {services.map((service) => (
                <MenuItem key={service.id} value={service.id}>
                  {service.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedService && (
            <>
              <AvailabilityForm
                availabilityFormData={availabilityFormData}
                handleAvailabilityFormChange={handleAvailabilityFormChange}
                handleAvailabilityFormSubmit={handleAvailabilityFormSubmit}
                isEditingAvailability={isEditingAvailability}
                dayOfWeekOptions={dayOfWeekOptions}
                locations={locations}
                branchDefinitions={branchDefinitions}
                branchValues={branchValues}
                selectedBranchDefinition={selectedBranchDefinition}
                onBranchDefinitionChange={handleBranchDefinitionChange}
              />
              <AvailabilityList
                availabilities={availabilities}
                handleEditAvailability={handleEditAvailability}
                handleDeleteAvailability={handleDeleteAvailability}
                dayOfWeekOptions={dayOfWeekOptions}
                locations={locations}
              />
            </>
          )}
        </TabPanel>

        <TabPanel value={activeTab} index={2}>
          <HolidayForm
            holidayFormData={holidayFormData}
            handleHolidayFormChange={handleHolidayFormChange}
            handleHolidayServiceChange={handleHolidayServiceChange}
            handleHolidayFormSubmit={handleHolidayFormSubmit}
            isEditingHoliday={isEditingHoliday}
            services={services}
            locations={locations}
          />
          <HolidayList
            holidays={holidays}
            handleEditHoliday={handleEditHoliday}
            handleDeleteHoliday={handleDeleteHoliday}
            services={services}
            locations={locations}
          />
        </TabPanel>

        <TabPanel value={activeTab} index={3}>
          <FormControl fullWidth>
            <InputLabel id="service-select-label">Select Service</InputLabel>
            <Select
              labelId="service-select-label"
              value={selectedService}
              onChange={(e) => setSelectedService(e.target.value)}
            >
              {services.map((service) => (
                <MenuItem key={service.id} value={service.id}>
                  {service.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedService && (
            <>
              <AddtionalInformationForm
                addtionalInformationFormData={addtionalInformationFormData}
                handleAddtionalInformationFormChange={handleAddtionalInformationFormChange}
                handleAddtionalInformationFormSubmit={handleAddtionalInformationFormSubmit}
                isEditingAddtionalInformation={isEditingAddtionalInformation}
              />
              <AddtionalInformationList
                addtionalInformation={addtionalInformation}
                handleEditAddtionalInformation={handleEditAddtionalInformation}
                handleDeleteAddtionalInformation={handleDeleteAddtionalInformation}
              />
            </>
          )}
        </TabPanel>

        <TabPanel value={activeTab} index={4}>
          <EmailTemplateForm
            emailTemplateFormData={emailTemplateFormData}
            handleEmailTemplateFormChange={handleEmailTemplateFormChange}
            handleEmailTemplateFormSubmit={handleEmailTemplateFormSubmit}
            isEditingEmailTemplate={isEditingEmailTemplate}
            services={services}
          />
          <EmailTemplateList
            emailTemplates={emailTemplates}
            handleEditEmailTemplate={handleEditEmailTemplate}
            handleDeleteEmailTemplate={handleDeleteEmailTemplate}
            services={services}
          />
        </TabPanel>

        
      </Box>

      <SnackbarNotification snackbar={snackbar} handleSnackbarClose={handleSnackbarClose} />
    </Container>
  );
};

export default AvaibilityManagementPage;
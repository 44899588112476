import { createTheme } from '@mui/material/styles';
import { itIT } from '@mui/material/locale';
import { alpha } from '@mui/material/styles';

const theme = createTheme(
    {
      palette: {
        mode: 'light',
        primary: { main: '#3a86ff' },
        secondary: { main: '#ff006e' },
        background: { default: '#f8fafc', paper: '#ffffff' }
      },
      typography: {
        fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
        h2: {
          fontWeight: 800,
          fontSize: '2.75rem',
          background: 'linear-gradient(135deg, #3a86ff 0%, #ff006e 100%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          letterSpacing: '-0.02em'
        }
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: 12,
              textTransform: 'none',
              fontWeight: 600,
              padding: '8px 20px',
              transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 6px 20px rgba(58, 134, 255, 0.15)'
              }
            }
          }
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              borderRadius: 16,
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                boxShadow: '0 8px 30px rgba(0, 0, 0, 0.12)'
              }
            }
          }
        },
        MuiDataGrid: {
          styleOverrides: {
            root: {
              border: 'none',
              '& .MuiDataGrid-cell:focus': {
                outline: 'none'
              },
              '& .MuiDataGrid-row': {
                transition: 'all 0.2s ease',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: alpha('#3a86ff', 0.08),
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                }
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: alpha('#3a86ff', 0.04),
                borderRadius: '8px 8px 0 0',
                position: 'sticky',
                top: 0,
                zIndex: 2
              },
              '& .MuiDataGrid-virtualScroller': {
                '& .MuiDataGrid-row:nth-of-type(odd)': {
                  backgroundColor: alpha('#f8fafc', 0.5)
                }
              },
              '& .MuiDataGrid-cell': {
                borderBottom: '1px solid rgba(58, 134, 255, 0.08)'
              },
              '& .MuiDataGrid-footerContainer': {
                borderTop: 'none',
                backgroundColor: alpha('#3a86ff', 0.02),
                borderRadius: '0 0 8px 8px'
              }
            }
          }
        }
      }
    },
    itIT
  );

export default theme;
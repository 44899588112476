import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Tabs,
  Tab,
  Box,
  TextField
} from '@mui/material';
import { Zoom } from '@mui/material';
import { Plus, X } from 'lucide-react';
import CompanyFormMain from './CompanyFormMain';
import CompanyLocations from './CompanyLocations';
import CompanyBranches from './CompanyBranches';

// Helper component for tab panel content
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`company-tabpanel-${index}`}
      aria-labelledby={`company-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function CompanyDialog({
  open,
  onClose,
  company,
  onInputChange,
  onLogoChange,
  logoFile,
  validationErrors,
  onLocationChange,
  addLocation,
  removeLocation,
  onBranchChange,
  addBranch,
  removeBranch,
  removeBranchDefinition,
  onMainSave,
  onLocationsSave,
  onBranchesSave
}) {
  // Use state to control the current tab
  const [currentTab, setCurrentTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      TransitionComponent={Zoom}
      TransitionProps={{ 
        timeout: { enter: 500, exit: 500 }
      }}
    >
      <DialogTitle>
        {company.id ? 'Modifica Azienda' : 'Aggiungi Azienda'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          Compila i campi sottostanti per {company.id ? 'modificare' : 'aggiungere'} un'azienda.
        </DialogContentText>

        {/* Tabs header */}
        <Tabs value={currentTab} onChange={handleTabChange} sx={{ mb: 2 }}>
          <Tab label="Main" id="company-tab-0" aria-controls="company-tabpanel-0" />
          <Tab label="Locations" id="company-tab-1" aria-controls="company-tabpanel-1" />
          <Tab label="Branches" id="company-tab-2" aria-controls="company-tabpanel-2" />
        </Tabs>

        {/* Main information tab */}
        <TabPanel value={currentTab} index={0}>
          <CompanyFormMain 
            company={company}
            onInputChange={onInputChange}
            validationErrors={validationErrors}
            onLogoChange={onLogoChange}
            logoFile={logoFile}
          />
          <Box sx={{ mt: 2, textAlign: 'right' }}>
            <Button onClick={onMainSave} variant="contained" color="primary" startIcon={<Plus />}>
              {company.id ? 'Aggiorna' : 'Salva'}
            </Button>
          </Box>
        </TabPanel>

        {/* Locations tab */}
        <TabPanel value={currentTab} index={1}>
          <CompanyLocations 
            company={company}
            onLocationChange={onLocationChange}
            addLocation={addLocation}
            removeLocation={removeLocation}
          />
          
        </TabPanel>

        {/* Branches tab */}
        <TabPanel value={currentTab} index={2}>
          <CompanyBranches 
            company={company}
            onBranchChange={onBranchChange}
            addBranch={addBranch}
            removeBranch={removeBranch}
            removeBranchDefinition={removeBranchDefinition}
          />
         
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} startIcon={<X />}>
          Annulla
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CompanyDialog;
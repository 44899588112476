import React, { useState, useEffect, useCallback } from 'react';
import {
  TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Dialog, DialogTitle, DialogContent, DialogActions,
  Select, MenuItem, FormControl, InputLabel, Typography,
  Box, Card, CardContent, CardActions,
  Chip, IconButton, Tooltip, Snackbar, Alert, Slide,
  CircularProgress, Pagination, Switch, FormControlLabel, Grid, Avatar, useTheme, Autocomplete, InputAdornment, Checkbox,
  ToggleButtonGroup, ToggleButton, Skeleton, Menu, ListItemIcon
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
  Search as SearchIcon,
  FilterList as FilterListIcon,
  Delete as DeleteIcon,
  Refresh as RefreshIcon,
  Segment as SegmentIcon,
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
  FileDownload as FileDownloadIcon,
  Email as EmailIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ContentCopy as ContentCopyIcon,
  WhatsApp as WhatsAppIcon,
  Phone as PhoneIcon
} from '@mui/icons-material';
import { styled, alpha } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext';
import debounce from 'lodash.debounce';

// API configuration
const API_BASE_URL = 'https://admin.aitomotivelab.com/crm/api';

// Create an axios instance with default config
const api = axios.create({
  baseURL: API_BASE_URL,
});

// Add a request interceptor to include the token in every request
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Styled components
const StyledCard = styled(motion(Card))(({ theme }) => ({
  marginBottom: theme.spacing(2),
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0 16px 48px rgba(0, 0, 0, 0.2)',
    transform: 'translateY(-4px)',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
  borderBottom: `2px solid ${theme.palette.primary.main}`,
  padding: theme.spacing(1),
  whiteSpace: 'nowrap',
  fontSize: '0.875rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: alpha(theme.palette.primary.light, 0.05),
  },
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.light, 0.1),
    cursor: 'pointer',
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  },
  transition: 'all 0.2s ease-in-out',
  position: 'relative',
  
  // Add status indicator
  '&::before': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: '3px',
    backgroundColor: props => 
      props.hasGdpr ? theme.palette.success.main : theme.palette.warning.main,
    transition: 'width 0.2s ease',
  },
  '&:hover::before': {
    width: '5px',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
  border: 0,
  borderRadius: 25,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 40,
  padding: '0 20px',
  margin: theme.spacing(1),
  textTransform: 'none',
}));

const ClientAvatar = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40,
  backgroundColor: theme.palette.secondary.main,
  fontWeight: 'bold',
}));

const MobileSearchBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '& > *': {
      marginBottom: theme.spacing(1),
      width: '100%',
    }
  }
}));

const MobileTableCell = styled(TableCell)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    width: '100%',
    textAlign: 'left',
    padding: theme.spacing(2),
    borderBottom: 'none',
    wordBreak: 'break-word',
    '&:before': {
      content: 'attr(data-label)',
      display: 'inline-block',
      fontWeight: 500,
      minWidth: '120px',
      color: theme.palette.text.secondary
    },
    '&[data-label="Actions"]': {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  }
}));

const MobileContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    margin: '0 auto',
    overflowX: 'hidden',
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: 'auto'
  }
}));

const AssignSegmentDialog = ({ open, onClose, segments, onAssign }) => {
  const [selectedSegments, setSelectedSegments] = useState([]);

  // Reset selected segments when dialog opens
  useEffect(() => {
    if (open) {
      setSelectedSegments([]);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Assign Segments to Selected Clients</DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>Select Segments</InputLabel>
          <Select
            multiple
            value={selectedSegments}
            onChange={(e) => setSelectedSegments(e.target.value)}
            label="Select Segments"
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => {
                  const segment = segments.find(s => s.id === value);
                  return (
                    <Chip key={value} label={segment ? segment.name : 'Unknown'} />
                  );
                })}
              </Box>
            )}
          >
            {segments.map((segment) => (
              <MenuItem key={segment.id} value={segment.id}>
                {segment.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={() => {
            onAssign(selectedSegments);
            setSelectedSegments([]); // Reset selection after assigning
          }} 
          color="primary" 
          variant="contained"
          disabled={selectedSegments.length === 0}
        >
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const QuickActionButton = styled(Button)(({ theme }) => ({
  borderRadius: '50%',
  minWidth: '56px',
  width: '56px',
  height: '56px',
  position: 'fixed',
  bottom: '20px',
  right: '20px',
  boxShadow: '0 3px 10px rgba(0,0,0,0.2)',
  zIndex: 1000,
}));

const ClientCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[8],
  },
  '& .MuiCardContent-root': {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  }
}));

// Add animation for cards
const AnimatedGrid = motion(Grid);
const AnimatedCard = motion(ClientCard);

const TableRowSkeleton = () => (
  <TableRow>
    <TableCell>
      <Skeleton variant="circular" width={40} height={40} />
    </TableCell>
    <TableCell>
      <Skeleton variant="text" width={200} />
    </TableCell>
    {/* Add more cells as needed */}
  </TableRow>
);

const MobileTableRow = styled(StyledTableRow)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    
    '&::before': {
      left: 0,
      top: 0,
      bottom: 0,
      width: '4px',
      borderRadius: `${theme.spacing(1)} 0 0 ${theme.spacing(1)}`,
    }
  }
}));

const copyToClipboard = async (text, setSnackbar) => {
  try {
    await navigator.clipboard.writeText(text);
    setSnackbar({
      open: true,
      message: 'Phone number copied to clipboard!',
      severity: 'success'
    });
  } catch (err) {
    setSnackbar({
      open: true,
      message: 'Failed to copy phone number',
      severity: 'error'
    });
  }
};

// Add these styled components near the other styled components
const ActionButton = styled(IconButton)(({ theme }) => ({
  padding: '8px',
  borderRadius: '8px',
  transition: 'all 0.2s ease',
  margin: '0 2px',
  minWidth: 'auto',
  width: '36px',
  height: '36px',
  
  '&:hover': {
    transform: 'translateY(-2px)',
  },
  
  [theme.breakpoints.down('sm')]: {
    padding: '6px',
    margin: '2px',
    minWidth: 'auto',
    width: '32px',
    height: '32px',
  }
}));

const ActionButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  marginLeft: theme.spacing(1),
  
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    marginTop: theme.spacing(1),
    flexWrap: 'wrap',
    gap: theme.spacing(0.5),
  }
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  maxWidth: '100%',
  [theme.breakpoints.down('sm')]: {
    maxWidth: 'none',
    marginBottom: theme.spacing(0.5),
    '& .MuiChip-label': {
      whiteSpace: 'normal',
      height: 'auto',
    }
  }
}));

const ClientManagement = () => {
  const [clients, setClients] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterSegment, setFilterSegment] = useState('');
  const [segments, setSegments] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [hideEmptyNames, setHideEmptyNames] = useState(true);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    number: '',
    label_segmentation: [],
    introduzione: '',
    gdpr: false,
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [loading, setLoading] = useState(false);
  const [segmentsLoading, setSegmentsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [assignSegmentDialogOpen, setAssignSegmentDialogOpen] = useState(false);
  const [viewMode, setViewMode] = useState('table');
  const [anchorEl, setAnchorEl] = useState(null);
  const [bulkEditMode, setBulkEditMode] = useState(false);
  const [showActions, setShowActions] = useState(false);

  const { userRole, appAccess } = useAuth();
  const permissions = appAccess['crm_app'] || {};
  const canEdit = permissions.can_edit || userRole === 'superadmin';
  const canCreate = permissions.can_create || userRole === 'superadmin';
  const canDelete = permissions.can_delete || userRole === 'superadmin';
  const canView = permissions.can_view || userRole === 'superadmin';

  const navigate = useNavigate();
  const theme = useTheme();

  // Only force card view on mobile devices, keep table view on desktop
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 600; // sm breakpoint in MUI
      if (isMobile) {
        setViewMode('cards');
      }
    };

    // Set initial view mode based on current screen size
    handleResize();

    // Add listener for window resize
    window.addEventListener('resize', handleResize);
    
    // Clean up
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (canView) {
      fetchClients();
    } else {
      console.warn('User does not have permission to view clients.');
    }
  }, [canView]);

  const handleToggleEmptyNames = (event) => {
    const newValue = event.target.checked;
    setHideEmptyNames(newValue);
  };

  const fetchClients = useCallback(async () => {
    try {
        const response = await api.get('/Clients/', {
            params: {
                page,
                page_size: 50,
                segment: filterSegment,
                hide_empty_names: hideEmptyNames,
            }
        });
        setClients(response.data.results);
        console.log('Clients fetched', response.data.results);

        setTotalPages(Math.ceil(response.data.count / 50));
    } catch (error) {
        console.error('Error fetching clients:', error);
        handleApiError(error);
    } finally {
        setLoading(false);
    }
  }, [page, filterSegment, hideEmptyNames]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients, hideEmptyNames]);

  const fetchSegments = async () => {
    try {
      setSegmentsLoading(true);
      const response = await api.get('/Client-segments/');
      setSegments(response.data);
      setSegmentsLoading(false);
    } catch (error) {
      console.error('Error fetching segments:', error);
      handleApiError(error);
      setSegmentsLoading(false);
    }
  };

  useEffect(() => {
    fetchClients();
    fetchSegments();
  }, [fetchClients]);

  const handleSearch = useCallback(
    debounce(async (query) => {
      try {
        setLoading(true);
        const response = await api.get('/Clients/', {
          params: {
            search: query,
            page,
            segment: filterSegment,
            hide_empty_names: hideEmptyNames,
          }
        });
        setClients(response.data.results);
        setTotalPages(Math.ceil(response.data.count / 10));
      } catch (error) {
        console.error('Error searching clients:', error);
        handleApiError(error);
      } finally {
        setLoading(false);
      }
    }, 300),
    [page, filterSegment, hideEmptyNames]
  );

  const handleFilterChange = (event) => {
    setFilterSegment(event.target.value);
    setPage(1);
  };

  const handleFormOpen = (client = null) => {
    if (!canCreate && !client) {
        setSnackbar({ open: true, message: 'You do not have permission to add new clients.', severity: 'error' });
        return;
    }
    if (!canEdit && client) {
        setSnackbar({ open: true, message: 'You do not have permission to edit clients.', severity: 'error' });
        return;
    }
    
    if (client) {
        // Ensure all fields have valid initial values
        setFormData({
            id: client.id,
            first_name: client.first_name || '',
            last_name: client.last_name || '',
            email: client.email || '',
            number: client.number || '',
            label_segmentation: Array.isArray(client.label_segmentation) 
                ? client.label_segmentation 
                : [],
            introduzione: client.introduzione || '',
            gdpr: Boolean(client.gdpr),
            // Add any other fields that might be needed
        });
    } else {
        // Reset form with empty values
        setFormData({
            first_name: '',
            last_name: '',
            email: '',
            number: '',
            label_segmentation: [],
            introduzione: '',
            gdpr: false,
        });
    }
    setIsFormOpen(true);
  };

  const handleFormClose = () => {
    setFormData({
      first_name: '',
      last_name: '',
      email: '',
      number: '',
      label_segmentation: [],
      introduzione: '',
      gdpr: false,
    });
    setIsFormOpen(false);
    
    setTimeout(() => {
      const tableRows = document.querySelectorAll('tr');
      tableRows.forEach(row => {
        row.style.transform = 'none';
        row.style.position = 'static';
        row.style.left = '0';
      });
    }, 0);
  };

  const handleFormChange = (event) => {
    const { name, value, type } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? event.target.checked : 
              name === 'gdpr' ? Boolean(value) : 
              name === 'label_segmentation' ? (Array.isArray(value) ? value : [value]) :
              value
    }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
        // Create submission data, omitting email if it's not a valid email format
        const submissionData = {
            first_name: formData.first_name || '',
            last_name: formData.last_name || '',
            number: formData.number || '',
            label_segmentation: formData.label_segmentation || [],
            introduzione: formData.introduzione || '',
            gdpr: Boolean(formData.gdpr)
        };

        // Only include email if it looks like an email, otherwise leave it out
        if (formData.email && formData.email.includes('@')) {
            submissionData.email = formData.email;
        }

        console.log('Submitting data:', submissionData);

        let response;
        if (formData.id) {
            response = await api.put(`/Clients/${formData.id}/`, submissionData);
        } else {
            response = await api.post('/Clients/', submissionData);
        }

        if (response.status === 200 || response.status === 201) {
            setSnackbar({ 
                open: true, 
                message: formData.id ? 'Client updated successfully!' : 'New client added successfully!', 
                severity: 'success' 
            });
            handleFormClose();
            fetchClients();
        }
    } catch (error) {
        console.error('Error submitting form:', error);
        // Show the exact error from the server for debugging
        const errorDetail = error.response?.data?.email?.[0] || 
            error.response?.data?.detail || 
            error.response?.data?.message || 
            'An error occurred while saving the client.';
        setSnackbar({ 
            open: true, 
            message: errorDetail, 
            severity: 'error' 
        });
    }
  };

  const handleDeleteClick = (client) => {
    if (!canDelete) {
      setSnackbar({ open: true, message: 'You do not have permission to delete clients.', severity: 'error' });
      return;
    }
    setClientToDelete(client);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (clientToDelete) {
      try {
        await api.delete(`/Clients/${clientToDelete.id}/`);
        setSnackbar({ open: true, message: 'Client deleted successfully!', severity: 'success' });
        fetchClients();
      } catch (error) {
        console.error('Error deleting client:', error);
        handleApiError(error);
      }
    }
    setDeleteConfirmOpen(false);
  };

  const handleApiError = (error) => {
    if (error.response && error.response.status === 401) {
      setSnackbar({ open: true, message: 'Authentication failed. Please log in again.', severity: 'error' });
      navigate('/login');
    } else {
      setSnackbar({ open: true, message: 'An error occurred. Please try again.', severity: 'error' });
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const TransitionUp = (props) => {
    return <Slide {...props} direction="up" />;
  };

  const fetchSearchResults = useCallback(async (query) => {
    if (!query.trim()) {
      setSearchResults([]);
      return;
    }
    
    try {
      setIsSearching(true);
      const response = await api.get('/Clients/', {
        params: {
          search: query,
          page_size: 10 // Limit the number of search results
        }
      });
      setSearchResults(response.data.results);
    } catch (error) {
      console.error('Error fetching search results:', error);
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  }, []);

  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearchQuery(query);
      fetchSearchResults(query);
    }, 300),
    [fetchSearchResults]
  );

  const searchField = (
    <TextField
      fullWidth
      variant="outlined"
      placeholder="Search by name, email, or number..."
      value={searchQuery}
      onChange={(e) => {
        setSearchQuery(e.target.value);
        handleSearch(e.target.value);
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {loading && <CircularProgress size={20} />}
          </InputAdornment>
        ),
      }}
    />
  );

  const handleBulkOperation = async (operation, selectedClients) => {
    try {
      switch (operation) {
        case 'delete':
            // Show confirmation dialog first
            const confirmed = window.confirm(
              `Are you sure you want to delete ${selectedClients.length} clients?`
            );
            if (confirmed) {
              await Promise.all(
                selectedClients.map(clientId => api.delete(`/Clients/${clientId}/`))
              );
              setSnackbar({ 
                open: true, 
                message: 'Selected clients deleted successfully', 
                severity: 'success' 
              });
            }
            break;

        case 'segment':
            // Open segment assignment dialog without clearing selection
            setAssignSegmentDialogOpen(true);
            break;

        case 'export':
            // Export selected clients
            await api.post('/clients/export/', { clients: selectedClients });
            setSnackbar({ 
              open: true, 
              message: 'Export started. You will be notified when ready.', 
              severity: 'info' 
            });
            break;

        case 'email':
            // Email selected clients
            await api.post('/clients/email/', { clients: selectedClients });
            setSnackbar({ 
              open: true, 
              message: 'Emails sent successfully', 
              severity: 'success' 
            });
            break;
      }
      
      // Only clear menu anchor
      setAnchorEl(null);
      
      // Don't clear selection here anymore
      // Only refresh clients if needed
      if (operation !== 'segment') {
          fetchClients();
          setSelectedClients([]); // Clear selection only for non-segment operations
      }
    } catch (error) {
      console.error('Error performing bulk operation:', error);
      setSnackbar({ 
        open: true, 
        message: 'Error performing operation. Please try again.', 
        severity: 'error' 
      });
    }
  };

  const handleAssignSegment = async (segmentIds) => {
    try {
        const updatePromises = selectedClients.map(clientId =>
            api.patch(`/Clients/${clientId}/`, {
                label_segmentation: segmentIds
            })
        );

        await Promise.all(updatePromises);
        
        setSnackbar({ 
            open: true, 
            message: 'Segments assigned successfully', 
            severity: 'success' 
        });
        setAssignSegmentDialogOpen(false);
        // Clear selection and refresh only after successful assignment
        setSelectedClients([]); 
        fetchClients();
    } catch (error) {
        console.error('Error assigning segments:', error);
        setSnackbar({ 
            open: true, 
            message: error.response?.data?.detail || 'Error assigning segments', 
            severity: 'error' 
        });
    }
  };

  return (
    <MobileContainer>
      <Box sx={{ 
        maxWidth: '100%', 
        margin: 'auto', 
        padding: { xs: 2, sm: 3 },
        paddingTop: { xs: '60px', sm: 3 },
        paddingBottom: { xs: '80px', sm: 3 },
        width: { xs: '100%', sm: 'auto' }
      }}>
        <StyledCard>
          <CardContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} md={4}>
                {searchField}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={hideEmptyNames}
                      onChange={handleToggleEmptyNames}
                      color="primary"
                    />
                  }
                  label="Hide clients without names"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Filter by Segment</InputLabel>
                  <Select
                    value={filterSegment}
                    onChange={handleFilterChange}
                    label="Filter by Segment"
                    startAdornment={<FilterListIcon color="action" />}
                    disabled={segmentsLoading}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {segments.map((segment) => (
                      <MenuItem key={segment.id} value={segment.id}>
                        {segment.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Box 
                  sx={{ 
                    display: 'flex', 
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: { xs: 2, sm: 2 },
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    mb: 2,
                    mt: 1
                  }}
                >
                  {/* Left side: Primary actions */}
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    flexWrap: { xs: 'wrap', sm: 'nowrap' },
                    width: { xs: '100%', sm: 'auto' }
                  }}>
                    <StyledButton
                      component={Link}
                      to="/clients/segments"
                      startIcon={<AddIcon />}
                      size="small"
                      sx={{
                        fontSize: { xs: '0.7rem', sm: '0.875rem' },
                        padding: { xs: '6px 16px', sm: '8px 24px' }
                      }}
                    >
                      Add Segment
                    </StyledButton>
                    
                    {/* Fixed width container for the buttons that change labels */}
                    <Box sx={{ 
                      width: { xs: '100%', sm: 'auto' },
                      display: 'flex',
                      gap: 2,
                      mt: { xs: 1, sm: 0 }
                    }}>
                      <Button
                        variant="outlined"
                        onClick={() => setShowActions(!showActions)}
                        startIcon={showActions ? <VisibilityIcon /> : <EditIcon />}
                        sx={{ 
                          whiteSpace: 'nowrap',
                          flex: { xs: 1, sm: 'none' } // Make buttons take equal width on mobile
                        }}
                      >
                        {showActions ? 'Hide Actions' : 'Show Actions'}
                      </Button>
                      
                      <Button
                        variant="outlined"
                        color={bulkEditMode ? "secondary" : "primary"}
                        onClick={() => {
                          setBulkEditMode(!bulkEditMode);
                          setSelectedClients([]);
                        }}
                        startIcon={bulkEditMode ? <ViewListIcon /> : <SegmentIcon />}
                        sx={{ 
                          whiteSpace: 'nowrap',
                          flex: { xs: 1, sm: 'none' } // Make buttons take equal width on mobile
                        }}
                      >
                        {bulkEditMode ? 'Exit Bulk Edit' : 'Bulk Edit'}
                      </Button>
                    </Box>
                  </Box>
                  
                  {/* Right side: View mode toggle and refresh */}
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    justifyContent: 'flex-end',
                    width: { xs: '100%', sm: 'auto' }
                  }}>
                    <Typography variant="subtitle1" sx={{ display: { xs: 'none', sm: 'block' }, mr: 1 }}>
                      View as:
                    </Typography>
                    
                    {/* Hide the toggle buttons on mobile */}
                    <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                      <ToggleButtonGroup
                        value={viewMode}
                        exclusive
                        onChange={(e, newValue) => newValue && setViewMode(newValue)}
                      >
                        <ToggleButton value="table" aria-label="table view">
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <ViewListIcon fontSize="small" />
                            <Typography variant="body2" sx={{ display: { xs: 'none', sm: 'block' } }}>
                              Table
                            </Typography>
                          </Box>
                        </ToggleButton>
                        <ToggleButton value="cards" aria-label="card view">
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <ViewModuleIcon fontSize="small" />
                            <Typography variant="body2" sx={{ display: { xs: 'none', sm: 'block' } }}>
                              Cards
                            </Typography>
                          </Box>
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Box>
                    
                    {/* Set a default view mode for mobile */}
                    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                      {/* Force card view on mobile by using useEffect */}
                    </Box>
                    
                    <Tooltip title="Refresh">
                      <IconButton 
                        onClick={fetchClients} 
                        color="primary"
                        sx={{ 
                          border: '1px solid',
                          borderColor: 'divider',
                          borderRadius: 1,
                          height: '40px',
                          width: '40px'
                        }}
                      >
                        <RefreshIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
                
                {/* Floating action button for adding new clients */}
                <Box sx={{ 
                  position: 'fixed', 
                  bottom: 20, 
                  right: 20, 
                  display: 'flex', 
                  flexDirection: 'column', 
                  gap: 2 
                }}>
                  {canCreate && (
                    <Tooltip title="Add New Client" placement="left">
                      <QuickActionButton
                        color="primary"
                        onClick={() => handleFormOpen()}
                      >
                        <AddIcon />
                      </QuickActionButton>
                    </Tooltip>
                  )}
                </Box>
              </Grid>
            </Grid>

            {loading ? (
              Array.from(new Array(5)).map((_, index) => (
                <TableRowSkeleton key={index} />
              ))
            ) : (
              <>
                {/* Display bulk operation controls when in bulk edit mode with selections */}
                {bulkEditMode && (
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                      bgcolor: 'action.selected',
                      p: 2,
                      borderRadius: 1,
                      mb: 2,
                      boxShadow: 1,
                      flexWrap: { xs: 'wrap', sm: 'nowrap' },
                      gap: { xs: 2, sm: 0 }
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                      {selectedClients.length} clients selected
                    </Typography>
                    
                    <Box sx={{ 
                      display: 'flex', 
                      gap: 1.5,
                      flexWrap: { xs: 'wrap', sm: 'nowrap' },
                      justifyContent: { xs: 'flex-start', sm: 'flex-end' },
                      width: { xs: '100%', sm: 'auto' }
                    }}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleBulkOperation('delete', selectedClients)}
                        startIcon={<DeleteIcon />}
                        disabled={selectedClients.length === 0}
                        size="small"
                      >
                        Delete
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleBulkOperation('segment', selectedClients)}
                        startIcon={<SegmentIcon />}
                        disabled={selectedClients.length === 0}
                        size="small"
                      >
                        Assign Segment
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                        endIcon={<ArrowDropDownIcon />}
                        disabled={selectedClients.length === 0}
                        size="small"
                      >
                        More Actions
                      </Button>
                    </Box>
                  </Box>
                )}

                {viewMode === 'table' ? (
                  <TableContainer 
                    component={Paper} 
                    sx={{ 
                      mt: 2, 
                      overflow: 'auto',
                      boxShadow: { xs: 'none', sm: 1 },
                      backgroundColor: { xs: 'transparent', sm: 'background.paper' },
                      maxWidth: '100%',
                    }}
                  >
                    <Table 
                      sx={{ 
                        tableLayout: 'fixed', // Force fixed layout
                        borderCollapse: 'separate', // Ensure borders don't collapse
                        borderSpacing: '0', // No spacing between cells
                      }}
                    >
                      <TableHead sx={{ display: { xs: 'none', sm: 'table-header-group' } }}>
                        <TableRow>
                          {bulkEditMode && (
                            <StyledTableCell 
                              padding="checkbox"
                              width="48px"
                              sx={{ maxWidth: '48px' }}
                            >
                              <Checkbox
                                indeterminate={selectedClients.length > 0 && selectedClients.length < clients.length}
                                checked={clients.length > 0 && selectedClients.length === clients.length}
                                onChange={(event) => {
                                  if (event.target.checked) {
                                    setSelectedClients(clients.map(client => client.id));
                                  } else {
                                    setSelectedClients([]);
                                  }
                                }}
                              />
                            </StyledTableCell>
                          )}
                          <StyledTableCell sx={{ width: '40px', maxWidth: '40px' }}></StyledTableCell>
                          <StyledTableCell sx={{ width: '250px', maxWidth: '250px' }}>Cliente</StyledTableCell>
                          <StyledTableCell sx={{ width: '250px', maxWidth: '250px' }}>Telefono</StyledTableCell>
                          <StyledTableCell sx={{ width: '200px', maxWidth: '200px' }}>Segmento</StyledTableCell>
                          <StyledTableCell sx={{ width: '150px', maxWidth: '150px' }}>Last Message</StyledTableCell>
                          <StyledTableCell sx={{ width: '150px', maxWidth: '150px' }}>Last Navigation</StyledTableCell>
                          {showActions && (
                            <StyledTableCell align="center" sx={{ width: '100px', maxWidth: '100px' }}>Azioni</StyledTableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <AnimatePresence>
                          {clients.map((client) => (
                            <MobileTableRow 
                              key={client.id}
                              onClick={(e) => {
                                if (!bulkEditMode && !e.target.closest('button')) {
                                  navigate(`/clients/${client.id}`);
                                }
                              }}
                              sx={{ cursor: bulkEditMode ? 'default' : 'pointer' }}
                            >
                              {bulkEditMode && (
                                <MobileTableCell padding="checkbox" data-label="Select">
                                  <Checkbox
                                    checked={selectedClients.includes(client.id)}
                                    onChange={(event) => {
                                      const newSelected = event.target.checked
                                        ? [...selectedClients, client.id]
                                        : selectedClients.filter(id => id !== client.id);
                                      setSelectedClients(newSelected);
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                  />
                                </MobileTableCell>
                              )}
                              <MobileTableCell data-label="Client">
                                <Box sx={{ 
                                  display: 'flex', 
                                  alignItems: 'center',
                                  gap: 2,
                                  mb: { xs: 0, sm: 0 }
                                }}>
                                  <ClientAvatar>
                                    {client.first_name?.[0] || ''}{client.last_name?.[0] || ''}
                                  </ClientAvatar>
                                  <Box sx={{ width: '100%' }}>
                                    <Typography 
                                      variant="subtitle1" 
                                      sx={{ 
                                        fontWeight: 'medium',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        maxWidth: '100%'
                                      }}
                                    >
                                      {client.first_name} {client.last_name}
                                    </Typography>
                                    {client.email && (
                                      <Typography 
                                        variant="body2" 
                                        color="text.secondary"
                                        sx={{ 
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                          maxWidth: '100%',
                                          display: 'block'
                                        }}
                                      >
                                        {client.email}
                                      </Typography>
                                    )}
                                  </Box>
                                </Box>
                              </MobileTableCell>
                              <MobileTableCell data-label="Phone">
                                {client.number ? (
                                  <Box sx={{ 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    gap: 1,
                                    height: '100%'
                                  }}>
                                    <Tooltip title="Click to copy">
                                      <Box
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          copyToClipboard(client.number, setSnackbar);
                                        }}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          gap: 1,
                                          cursor: 'pointer',
                                          minWidth: '120px',
                                          '&:hover': {
                                            color: 'primary.main',
                                            '& .copyIcon': {
                                              opacity: 1,
                                            }
                                          }
                                        }}
                                      >
                                        <Typography 
                                          variant="body2" 
                                          sx={{ 
                                            fontWeight: 500,
                                            letterSpacing: '0.02em',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            maxWidth: '100%'
                                          }}
                                        >
                                          {client.number}
                                        </Typography>
                                        <ContentCopyIcon 
                                          className="copyIcon"
                                          sx={{ 
                                            fontSize: '16px',
                                            opacity: 0,
                                            transition: 'opacity 0.2s'
                                          }} 
                                        />
                                      </Box>
                                    </Tooltip>
                                    <ActionButtonsContainer>
                                      <Tooltip title="Call">
                                        <ActionButton
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            window.location.href = `tel:${client.number}`;
                                          }}
                                          sx={{ 
                                            backgroundColor: alpha(theme.palette.success.main, 0.1),
                                            color: 'success.main',
                                            '&:hover': { 
                                              backgroundColor: alpha(theme.palette.success.main, 0.2),
                                            }
                                          }}
                                        >
                                          <PhoneIcon sx={{ fontSize: { xs: '16px', sm: '18px' } }} />
                                        </ActionButton>
                                      </Tooltip>
                                      <Tooltip title="WhatsApp">
                                        <ActionButton
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            const formattedNumber = client.number.replace(/[\s()-]/g, '');
                                            window.open(`https://wa.me/${formattedNumber}`, '_blank');
                                          }}
                                          sx={{ 
                                            backgroundColor: alpha('#25D366', 0.1),
                                            color: '#25D366',
                                            '&:hover': { 
                                              backgroundColor: alpha('#25D366', 0.2),
                                            }
                                          }}
                                        >
                                          <WhatsAppIcon sx={{ fontSize: { xs: '16px', sm: '18px' } }} />
                                        </ActionButton>
                                      </Tooltip>
                                    </ActionButtonsContainer>
                                  </Box>
                                ) : (
                                  <Typography variant="body2" color="text.secondary">
                                    No phone number
                                  </Typography>
                                )}
                              </MobileTableCell>
                              <MobileTableCell data-label="Segments">
                                <Box sx={{ 
                                  display: 'flex', 
                                  flexWrap: 'wrap', 
                                  gap: 0.5,
                                  width: '100%'
                                }}>
                                  {client.label_segmentation.map((segmentId) => {
                                    const segment = segments.find(s => s.id === segmentId);
                                    return segment ? (
                                      <StyledChip
                                        key={segmentId}
                                        label={segment.name}
                                        color="primary"
                                        variant="outlined"
                                        size="small"
                                      />
                                    ) : null;
                                  })}
                                  {client.label_segmentation.length === 0 && (
                                    <Typography variant="body2" color="text.secondary">
                                      No segments
                                    </Typography>
                                  )}
                                </Box>
                              </MobileTableCell>
                              <MobileTableCell data-label="Last Message">
                                {client.last_message_date ? (
                                  <Typography variant="body2">
                                    {new Date(client.last_message_date).toLocaleString()}
                                  </Typography>
                                ) : (
                                  <Typography variant="body2" color="text.secondary">
                                    No messages
                                  </Typography>
                                )}
                              </MobileTableCell>
                              <MobileTableCell data-label="Last Navigation">
                                {client.last_navigation_date ? (
                                  <Typography variant="body2">
                                    {new Date(client.last_navigation_date).toLocaleString()}
                                  </Typography>
                                ) : (
                                  <Typography variant="body2" color="text.secondary">
                                    No navigation
                                  </Typography>
                                )}
                              </MobileTableCell>
                              {showActions && (
                                <MobileTableCell data-label="Actions" sx={{ textAlign: { xs: 'right', sm: 'center' } }}>
                                  <Box sx={{ 
                                    display: 'flex', 
                                    gap: 1,
                                    justifyContent: { xs: 'flex-end', sm: 'center' }
                                  }}>
                                    {canEdit && (
                                      <Tooltip title="Edit Client">
                                        <IconButton 
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleFormOpen(client);
                                          }} 
                                          sx={{ color: 'primary.main' }}
                                          size="small"
                                        >
                                          <EditIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                    {canDelete && (
                                      <Tooltip title="Delete Client">
                                        <IconButton 
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteClick(client);
                                          }} 
                                          sx={{ color: 'error.main' }}
                                          size="small"
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </Box>
                                </MobileTableCell>
                              )}
                            </MobileTableRow>
                          ))}
                        </AnimatePresence>
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <AnimatedGrid
                    container 
                    spacing={2} 
                    sx={{ mt: 2 }}
                    layout
                  >
                    {clients.map((client) => (
                      <Grid item xs={12} sm={6} md={4} key={client.id}>
                        <AnimatedCard
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -20 }}
                          transition={{ duration: 0.2 }}
                          onClick={(e) => {
                            if (!bulkEditMode && !e.target.closest('button')) {
                              navigate(`/clients/${client.id}`);
                            }
                          }}
                        >
                          <ClientCard>
                            <CardContent>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <ClientAvatar sx={{ mr: 2 }}>
                                    {client.first_name?.[0] || ''}{client.last_name?.[0] || ''}
                                  </ClientAvatar>
                                  <Box>
                                    <Typography variant="h6" sx={{ 
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      maxWidth: '100%'
                                    }}>
                                      {client.first_name} {client.last_name}
                                    </Typography>
                                    {client.email && (
                                      <Typography 
                                        variant="body2" 
                                        color="textSecondary"
                                        sx={{ 
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                          maxWidth: '100%',
                                          display: 'block'
                                        }}
                                      >
                                        {client.email}
                                      </Typography>
                                    )}
                                  </Box>
                                </Box>
                                {bulkEditMode && (
                                  <Checkbox
                                    checked={selectedClients.includes(client.id)}
                                    onChange={(event) => {
                                      event.stopPropagation();
                                      const newSelected = event.target.checked
                                        ? [...selectedClients, client.id]
                                        : selectedClients.filter(id => id !== client.id);
                                      setSelectedClients(newSelected);
                                    }}
                                  />
                                )}
                              </Box>

                              {/* Phone Number Section */}
                              <Box sx={{ mb: 2 }}>
                                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                  Phone Number
                                </Typography>
                                {client.number ? (
                                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                    <Typography 
                                      variant="body1" 
                                      sx={{ 
                                        fontWeight: 500,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                      }}
                                    >
                                      {client.number}
                                    </Typography>
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                      <Tooltip title="Call">
                                        <ActionButton
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            window.location.href = `tel:${client.number}`;
                                          }}
                                          sx={{ 
                                            backgroundColor: alpha(theme.palette.success.main, 0.1),
                                            color: 'success.main',
                                            '&:hover': { 
                                              backgroundColor: alpha(theme.palette.success.main, 0.2),
                                            }
                                          }}
                                        >
                                          <PhoneIcon sx={{ fontSize: { xs: '16px', sm: '18px' } }} />
                                        </ActionButton>
                                      </Tooltip>
                                      <Tooltip title="WhatsApp">
                                        <ActionButton
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            const formattedNumber = client.number.replace(/[\s()-]/g, '');
                                            window.open(`https://wa.me/${formattedNumber}`, '_blank');
                                          }}
                                          sx={{ 
                                            backgroundColor: alpha('#25D366', 0.1),
                                            color: '#25D366',
                                            '&:hover': { 
                                              backgroundColor: alpha('#25D366', 0.2),
                                            }
                                          }}
                                        >
                                          <WhatsAppIcon sx={{ fontSize: { xs: '16px', sm: '18px' } }} />
                                        </ActionButton>
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                ) : (
                                  <Typography variant="body2" color="text.secondary">
                                    No phone number
                                  </Typography>
                                )}
                              </Box>

                              {/* Last Message Section */}
                              <Box sx={{ mb: 2 }}>
                                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                  Last Message
                                </Typography>
                                {client.last_message_date ? (
                                  <Typography variant="body2">
                                    {new Date(client.last_message_date).toLocaleString()}
                                  </Typography>
                                ) : (
                                  <Typography variant="body2" color="text.secondary">
                                    No messages
                                  </Typography>
                                )}
                              </Box>

                              {/* Last Navigation Section */}
                              <Box sx={{ mb: 2 }}>
                                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                  Last Navigation
                                </Typography>
                                {client.last_navigation_date ? (
                                  <Typography variant="body2">
                                    {new Date(client.last_navigation_date).toLocaleString()}
                                  </Typography>
                                ) : (
                                  <Typography variant="body2" color="text.secondary">
                                    No navigation
                                  </Typography>
                                )}
                              </Box>

                              {/* Segments Section */}
                              <Box sx={{ mb: 2 }}>
                                <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                  Segments
                                </Typography>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {client.label_segmentation.map((segmentId) => {
                                    const segment = segments.find(s => s.id === segmentId);
                                    return segment ? (
                                      <StyledChip
                                        key={segmentId}
                                        label={segment.name}
                                        color="primary"
                                        variant="outlined"
                                        size="small"
                                      />
                                    ) : null;
                                  })}
                                  {client.label_segmentation.length === 0 && (
                                    <Typography variant="body2" color="text.secondary">
                                      No segments
                                    </Typography>
                                  )}
                                </Box>
                              </Box>
                            </CardContent>

                            {/* Actions Section */}
                            {showActions && (
                              <CardActions sx={{ 
                                justifyContent: 'flex-end', 
                                pt: 0,
                                borderTop: '1px solid',
                                borderColor: 'divider',
                                mt: 'auto',
                                display: 'flex',
                                visibility: 'visible',
                                [theme.breakpoints.down('sm')]: {
                                  position: 'relative',
                                  bottom: 0,
                                  width: '100%',
                                  padding: theme.spacing(1, 2)
                                }
                              }}>
                                <Box sx={{ 
                                  display: 'flex', 
                                  gap: 1,
                                  visibility: 'visible',
                                  opacity: 1
                                }}>
                                  {canEdit && (
                                    <Tooltip title="Edit Client">
                                      <IconButton 
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleFormOpen(client);
                                        }} 
                                        size="small"
                                        sx={{ 
                                          color: 'primary.main',
                                          visibility: 'visible',
                                          display: 'flex',
                                          '&:hover': { 
                                            backgroundColor: alpha(theme.palette.primary.main, 0.1),
                                            transform: 'scale(1.1)',
                                          },
                                          transition: 'transform 0.2s ease'
                                        }}
                                      >
                                        <EditIcon sx={{ fontSize: '20px' }} />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                  {canDelete && (
                                    <Tooltip title="Delete Client">
                                      <IconButton 
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleDeleteClick(client);
                                        }} 
                                        size="small"
                                        sx={{ 
                                          color: 'error.main',
                                          visibility: 'visible',
                                          display: 'flex',
                                          '&:hover': { 
                                            backgroundColor: alpha(theme.palette.error.main, 0.1),
                                            transform: 'scale(1.1)',
                                          },
                                          transition: 'transform 0.2s ease'
                                        }}
                                      >
                                        <DeleteIcon sx={{ fontSize: '20px' }} />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Box>
                              </CardActions>
                            )}
                          </ClientCard>
                        </AnimatedCard>
                      </Grid>
                    ))}
                  </AnimatedGrid>
                )}
                <Box 
                  sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    mt: 2,
                    pb: { xs: 2, sm: 0 },
                    position: { xs: 'sticky', sm: 'static' },
                    bottom: 0,
                    backgroundColor: 'background.default',
                    zIndex: 1,
                    width: '100%',
                    '& .MuiPagination-ul': {
                      flexWrap: 'nowrap',
                      overflowX: 'auto',
                      pb: 1,
                      px: 2,
                      width: '100%',
                      justifyContent: 'center',
                      '&::-webkit-scrollbar': {
                        display: 'none'
                      }
                    }
                  }}
                >
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                    size="large"
                    showFirstButton
                    showLastButton
                    sx={{
                      '& .MuiPaginationItem-root': {
                        minWidth: { xs: '32px', sm: '40px' },
                        height: { xs: '32px', sm: '40px' },
                        fontSize: { xs: '0.875rem', sm: '1rem' }
                      },
                      '& .MuiPaginationItem-firstLast': {
                        mx: 0.5
                      }
                    }}
                  />
                </Box>
              </>
            )}
          </CardContent>
        </StyledCard>
      </Box>

      <Dialog 
        open={isFormOpen} 
        onClose={handleFormClose}
        maxWidth="sm" 
        fullWidth
        TransitionProps={{
          onExited: () => {
            setFormData({
              first_name: '',
              last_name: '',
              email: '',
              number: '',
              label_segmentation: [],
              introduzione: '',
              gdpr: false,
            });
            const tableRows = document.querySelectorAll('tr');
            tableRows.forEach(row => {
              row.style.transform = 'none';
              row.style.position = 'static';
              row.style.left = '0';
            });
          }
        }}
      >
        <DialogTitle>{formData.id ? 'Edit Client' : 'Add New Client'}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleFormSubmit}>
            <TextField
              name="first_name"
              label="First Name"
              value={formData.first_name || ''}
              onChange={handleFormChange}
              fullWidth
              margin="normal"
            />
            <TextField
              name="last_name"
              label="Last Name"
              value={formData.last_name || ''}
              onChange={handleFormChange}
              fullWidth
              margin="normal"
            />
            <TextField
              name="email"
              label="Email"
              value={formData.email || ''}
              onChange={handleFormChange}
              fullWidth
              margin="normal"
            />
            <TextField
              name="number"
              label="Phone Number"
              value={formData.number || ''}
              onChange={handleFormChange}
              fullWidth
              margin="normal"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Segment</InputLabel>
              <Select
                multiple
                name="label_segmentation"
                value={formData.label_segmentation}
                onChange={handleFormChange}
                disabled={segmentsLoading}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => {
                      const segment = segments.find(s => s.id === value);
                      return (
                        <Chip key={value} label={segment ? segment.name : 'Unknown'} />
                      );
                    })}
                  </Box>
                )}
              >
                {segments.map((segment) => (
                  <MenuItem key={segment.id} value={segment.id}>
                    {segment.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              name="introduzione"
              label="Introduction"
              value={formData.introduzione || ''}
              onChange={handleFormChange}
              fullWidth
              margin="normal"
              multiline
              rows={4}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>GDPR Consent</InputLabel>
              <Select
                name="gdpr"
                value={formData.gdpr}
                onChange={handleFormChange}
                label="GDPR Consent"
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFormClose}>Cancel</Button>
          <Button onClick={handleFormSubmit} color="primary" variant="contained">
            {formData.id ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this client? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <AssignSegmentDialog
        open={assignSegmentDialogOpen}
        onClose={() => setAssignSegmentDialogOpen(false)}
        segments={segments}
        onAssign={handleAssignSegment}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        TransitionComponent={TransitionUp}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }} variant="filled">
          {snackbar.message}
        </Alert>
      </Snackbar>
    </MobileContainer>
  );
};

export default ClientManagement;
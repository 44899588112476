
// File: api.js
import axios from 'axios';

const BOOKING_API_BASE_URL = 'https://admin.aitomotivelab.com/booking';
const CRM_API_BASE_URL = 'https://admin.aitomotivelab.com/crm/api/';
const ADDITIONAL_INFORMATION_API_BASE_URL = 'https://admin.aitomotivelab.com/booking/additionalinformation';

const createApi = (baseURL) => {
  const api = axios.create({ baseURL });
  api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });
  return api;
};

export const bookingApi = createApi(BOOKING_API_BASE_URL);
export const crmApi = createApi(CRM_API_BASE_URL);
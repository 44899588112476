import React from 'react';
import { Paper, Box, Typography, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Edit, Trash2, Bot } from 'lucide-react';

const CustomLoadingOverlay = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      gap: 2
    }}
  >
    <CircularProgress />
    <Typography variant="body2" color="text.secondary">
      Caricamento dati...
    </Typography>
  </Box>
);

const CustomNoRowsOverlay = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      gap: 2,
      p: 3
    }}
  >
    <Typography variant="body2" color="text.secondary">
      Nessuna azienda trovata
    </Typography>
  </Box>
);

function CompanyGrid({
  rows,
  isLoading,
  currentUser,
  onEdit,
  onDelete,
  onManageAI
}) {
  const columns = [
    { field: 'name', headerName: 'Nome', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    {
      field: 'car_stock_sync_status',
      headerName: 'Stock status',
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <CircularProgress
            variant="determinate"
            value={
              params.row.car_stock_sync_status === 'COMPLETED'
                ? 100
                : params.row.car_stock_sync_status === 'PROCESSING'
                ? 50
                : params.row.car_stock_sync_status === 'FAILED'
                ? 100
                : 0
            }
            size={20}
            sx={{
              color:
                params.row.car_stock_sync_status === 'COMPLETED'
                  ? 'success.main'
                  : params.row.car_stock_sync_status === 'PROCESSING'
                  ? 'primary.main'
                  : params.row.car_stock_sync_status === 'FAILED'
                  ? 'error.main'
                  : 'text.disabled'
            }}
          />
          <Typography variant="caption">
            {params.row.car_stock_sync_status || 'NOT_STARTED'}
          </Typography>
        </Box>
      )
    },
    {
      field: 'last_updates',
      headerName: 'Stock sync',
      flex: 1.5,
      renderCell: (params) => (
        <Box>
          {params.row.last_car_stock_task_update && (
            <Typography variant="caption" display="block">
              {new Date(params.row.last_car_stock_task_update).toLocaleString()}
            </Typography>
          )}
        </Box>
      )
    },
    {
      field: 'document_sync_status',
      headerName: 'Knowledge status',
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <CircularProgress
            variant="determinate"
            value={
              params.row.document_sync_status === 'COMPLETED'
                ? 100
                : params.row.document_sync_status === 'PROCESSING'
                ? 50
                : params.row.document_sync_status === 'FAILED'
                ? 100
                : 0
            }
            size={20}
            sx={{
              color:
                params.row.document_sync_status === 'COMPLETED'
                  ? 'success.main'
                  : params.row.document_sync_status === 'PROCESSING'
                  ? 'primary.main'
                  : params.row.document_sync_status === 'FAILED'
                  ? 'error.main'
                  : 'text.disabled'
            }}
          />
          <Typography variant="caption">
            {params.row.document_sync_status || 'NOT_STARTED'}
          </Typography>
        </Box>
      )
    },
    {
      field: 'last_document_task_update',
      headerName: 'Knowledge sync',
      flex: 1.5,
      renderCell: (params) => (
        <Box>
          {params.row.last_document_task_update && (
            <Typography variant="caption" display="block">
              {new Date(params.row.last_document_task_update).toLocaleString()}
            </Typography>
          )}
        </Box>
      )
    },
    {
      field: 'actions',
      headerName: 'Azioni',
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Tooltip title="Edit">
            <span>
              <IconButton
                onClick={() => onEdit(params.row)}
                disabled={currentUser !== 'superadmin' && currentUser !== 'admin'}
              >
                <Edit size={20} />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Delete">
            <span>
              <IconButton
                onClick={() => onDelete(params.row.id)}
                disabled={currentUser !== 'superadmin'}
              >
                <Trash2 size={20} />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      )
    },
    {
      field: 'ai',
      headerName: 'Manage AI',
      flex: 1,
      renderCell: (params) => (
        <Tooltip title="Manage AI">
          <span>
            <IconButton
              onClick={() => onManageAI(params.row.id)}
              disabled={currentUser !== 'superadmin' && currentUser !== 'admin'}
            >
              <Bot size={20} />
            </IconButton>
          </span>
        </Tooltip>
      )
    }
  ];

  return (
    <Paper
      elevation={3}
      sx={{
        borderRadius: 2,
        overflow: 'hidden',
        transition: 'all 0.3s ease',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: '0 12px 40px rgba(0,0,0,0.12)'
        }
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 25]}
        autoHeight
        disableSelectionOnClick
        loading={isLoading}
        components={{
          LoadingOverlay: CustomLoadingOverlay,
          NoRowsOverlay: CustomNoRowsOverlay
        }}
        sx={{
          '& .MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          minHeight: 400
        }}
      />
    </Paper>
  );
}

export default CompanyGrid;
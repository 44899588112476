import React, { useState, useEffect, useRef } from 'react';
import { 
  Card, CardContent, Typography, Button, Grid, CircularProgress, Alert,
  Select, MenuItem, FormControl, InputLabel, Box, TextField, Slider,
  Paper, Divider, Fade, Zoom, Tooltip, IconButton, LinearProgress
} from '@mui/material';
import { 
  Add, Delete, Edit, Save, Cancel, EmojiEvents, 
  ArrowUpward, ArrowDownward, LocalFireDepartment, Flag,
  Flare, Speed
} from '@mui/icons-material';
import { alpha } from '@mui/material/styles';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://admin.aitomotivelab.com';

const getPriorityColor = (priority) => {
  if (priority <= 25) return '#2e7d32'; // Green - highest priority
  if (priority <= 50) return '#1976d2'; // Blue
  if (priority <= 75) return '#ed6c02'; // Orange
  return '#d32f2f'; // Red - lowest priority
};

// Add this PrioritySelector component before BrandPriorityManager
const PrioritySelector = ({ value, onChange }) => {
  return (
    <Box sx={{ px: 1 }}>
      <Slider
        value={value}
        onChange={(_, newValue) => onChange(newValue)}
        min={1}
        max={100}
        step={1}
        marks={[
          { value: 1, label: 'Alta' },
          { value: 25, label: '25' },
          { value: 50, label: '50' },
          { value: 75, label: '75' },
          { value: 100, label: 'Bassa' },
        ]}
        sx={{
          '& .MuiSlider-thumb': {
            backgroundColor: getPriorityColor(value),
          },
          '& .MuiSlider-track': {
            backgroundColor: getPriorityColor(value),
          },
          '& .MuiSlider-rail': {
            backgroundColor: alpha(getPriorityColor(value), 0.3),
          },
          '& .MuiSlider-mark': {
            backgroundColor: getPriorityColor(value),
          },
          '& .MuiSlider-markLabel': {
            fontSize: '0.75rem',
            fontWeight: 'bold',
          },
        }}
      />
    </Box>
  );
};

const BrandPriorityManager = ({ availableBrands }) => {
  const [brands, setBrands] = useState([]);
  const [newBrand, setNewBrand] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [editingPriority, setEditingPriority] = useState('');
  const [editingBrand, setEditingBrand] = useState('');
  const [addingBrand, setAddingBrand] = useState(false);
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    fetchBrands();
  }, []);

  const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    return {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    };
  };

  const fetchBrands = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_BASE_URL}/car_stock/api/brand-priorities/`, {
        headers: getAuthHeaders(),
      });
      if (!response.ok) throw new Error('Impossibile recuperare le marche');
      const data = await response.json();
      setBrands(data);
    } catch (error) {
      setError(`Errore nel recupero delle marche: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleAddBrand = async () => {
    if (!newBrand.trim()) {
      setError('Il nome della marca non può essere vuoto');
      return;
    }

    try {
      setAddingBrand(true);
      const response = await fetch(`${API_BASE_URL}/car_stock/api/brand-priorities/`, {
        method: 'POST',
        headers: getAuthHeaders(),
        body: JSON.stringify({ brand: newBrand.trim(), priority: 100 }),
      });
      if (!response.ok) throw new Error('Impossibile aggiungere la marca');
      fetchBrands();
      setNewBrand('');
    } catch (error) {
      setError(`Errore nell'aggiunta della marca: ${error.message}`);
    } finally {
      setAddingBrand(false);
    }
  };

  const handleDeleteBrand = async (id) => {
    if (window.confirm('Sei sicuro di voler eliminare questa marca?')) {
      try {
        const response = await fetch(`${API_BASE_URL}/car_stock/api/brand-priorities/${id}/`, {
          method: 'DELETE',
          headers: getAuthHeaders(),
        });
        if (!response.ok) throw new Error('Impossibile eliminare la marca');
        fetchBrands();
      } catch (error) {
        setError(`Errore nell'eliminazione della marca: ${error.message}`);
      }
    }
  };

  const handleEditBrand = (id, brand, priority) => {
    setEditingId(id);
    setEditingBrand(brand);
    setEditingPriority(priority.toString());
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setEditingBrand('');
    setEditingPriority('');
  };

  const handleUpdateBrand = async (id) => {
    if (editingPriority !== null && !isNaN(editingPriority) && editingPriority.trim() !== '') {
      try {
        const response = await fetch(`${API_BASE_URL}/car_stock/api/brand-priorities/${id}/`, {
          method: 'PUT',
          headers: getAuthHeaders(),
          body: JSON.stringify({ 
            brand: editingBrand,
            priority: parseInt(editingPriority, 10) 
          }),
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(`Impossibile aggiornare la priorità della marca: ${JSON.stringify(errorData)}`);
        }
        fetchBrands();
        setEditingId(null);
        setEditingBrand('');
        setEditingPriority('');
      } catch (error) {
        setError(`Errore nell'aggiornamento della priorità della marca: ${error.message}`);
      }
    }
  };

  const toggleSortDirection = () => {
    setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
  };

  const sortedBrands = [...brands].sort((a, b) => {
    return sortDirection === 'asc' 
      ? a.priority - b.priority 
      : b.priority - a.priority;
  });

  if (loading && brands.length === 0) return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '400px',
      background: 'linear-gradient(to right, #f5f7fa, #e4ecfb)',
      borderRadius: 4
    }}>
      <LocalFireDepartment sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
      <Typography variant="h6" color="primary.main" gutterBottom sx={{ fontWeight: 'bold' }}>
        Caricamento Priorità Marche
      </Typography>
      <Box sx={{ width: '80%', maxWidth: 400, mt: 2 }}>
        <LinearProgress color="secondary" sx={{ height: 10, borderRadius: 5 }} />
      </Box>
    </Box>
  );

  if (error) return (
    <Fade in={true}>
      <Alert 
        severity="error" 
        sx={{ 
          mt: 2, 
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          borderRadius: 2,
          border: '1px solid #ef9a9a'
        }}
        action={
          <Button color="inherit" size="small" onClick={fetchBrands}>
            Riprova
          </Button>
        }
      >
        {error}
      </Alert>
    </Fade>
  );

  return (
    <Box 
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          p: { xs: 2, sm: 4 },
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        {/* Header section - stays fixed */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: { xs: 'flex-start', sm: 'center' },
          flexDirection: { xs: 'column', sm: 'row' },
          mb: 3, 
          flexShrink: 0 
        }}>
          <EmojiEvents sx={{ fontSize: { xs: 32, sm: 42 }, color: 'primary.main', mr: 2, mb: { xs: 1, sm: 0 } }} />
          <Box>
            <Typography variant="h5" sx={{ fontSize: { xs: '1.5rem', sm: '2rem' }, fontWeight: 800, color: 'primary.main', mb: 0.5 }}>
              Gestisci Priorità Marche
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Definisci l'ordine di importanza dei marchi nel tuo catalogo
            </Typography>
          </Box>
        </Box>

        {/* Sort controls - stays fixed */}
        <Paper 
          elevation={2} 
          sx={{ 
            mb: 3, 
            py: 1.5,
            px: { xs: 2, sm: 3 }, 
            borderRadius: 2,
            background: alpha('#fff', 0.7), 
            backdropFilter: 'blur(8px)',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'flex-start', sm: 'center' },
            gap: { xs: 1, sm: 0 },
            justifyContent: 'space-between',
            flexShrink: 0,
            zIndex: 5,
          }}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: 'text.secondary' }}>
            Priorità bassa = visualizzato per ultimo nel catalogo
          </Typography>
          <Button
            variant="outlined"
            size="small"
            onClick={toggleSortDirection}
            sx={{ borderRadius: 2 }}
            endIcon={sortDirection === 'asc' ? <ArrowUpward /> : <ArrowDownward />}
          >
            Priorità {sortDirection === 'asc' ? 'Crescente' : 'Decrescente'}
          </Button>
        </Paper>

        {/* Add brand form - stays fixed */}
        <Paper 
          elevation={4} 
          sx={{ 
            p: { xs: 2, sm: 3 }, 
            mb: 3, 
            borderRadius: 3,
            background: 'linear-gradient(145deg, #ffffff, #f0f4f8)',
            boxShadow: '0 10px 20px rgba(0,0,0,0.08)',
            flexShrink: 0,
          }}
        >
          <Typography variant="h6" gutterBottom sx={{ color: 'primary.dark', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
            <Add sx={{ mr: 1 }} /> Aggiungi Nuova Marca
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, mt: 2 }}>
            <FormControl fullWidth variant="outlined" sx={{ flex: 3 }}>
              <InputLabel sx={{ fontWeight: 500 }}>Seleziona una marca</InputLabel>
              <Select
                value={newBrand}
                onChange={(e) => setNewBrand(e.target.value)}
                label="Seleziona una marca"
                sx={{ 
                  borderRadius: 2,
                  bgcolor: 'white',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: alpha('#1976d2', 0.2),
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: alpha('#1976d2', 0.5),
                  },
                }}
              >
                {availableBrands.map((brand) => (
                  <MenuItem key={brand} value={brand}>{brand}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              onClick={handleAddBrand}
              disabled={addingBrand}
              sx={{ 
                flex: 1,
                height: { xs: 48, sm: 56 },
                borderRadius: 2,
                boxShadow: '0 4px 10px rgba(25, 118, 210, 0.2)',
                background: 'linear-gradient(45deg, #1976d2 30%, #2196f3 90%)',
                fontWeight: 'bold',
              }}
              startIcon={addingBrand ? <CircularProgress size={20} color="inherit" /> : <Add />}
            >
              {addingBrand ? 'Aggiunta...' : 'Aggiungi Marca'}
            </Button>
          </Box>
        </Paper>

        {/* Scrollable content area */}
        <Box 
          sx={{ 
            mb: 2, 
            overflow: 'auto',
            flexGrow: 1,
            '-webkit-overflow-scrolling': 'touch',
            width: '100%',
          }}
        >
          <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', color: 'text.primary', fontWeight: 'bold' }}>
            <Flag sx={{ mr: 1 }} /> Priorità Configurate ({sortedBrands.length})
          </Typography>
          <Divider sx={{ mb: 3 }} />
          
          {/* This Grid needs to be contained in the scrollable area */}
          <Grid container spacing={2}>
            {sortedBrands.map((brand, index) => (
              <Grid item xs={12} sm={6} md={4} key={brand.id}>
                <Zoom in={true} style={{ transitionDelay: `${index * 50}ms` }}>
                  <Card 
                    elevation={3} 
                    sx={{
                      height: '100%',
                      borderRadius: 3,
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        transform: { xs: 'none', sm: 'translateY(-8px)' },
                        boxShadow: { xs: 3, sm: '0 12px 28px rgba(0,0,0,0.15)' },
                      },
                      overflow: 'visible',
                      position: 'relative',
                      border: '1px solid',
                      borderColor: alpha(getPriorityColor(brand.priority), 0.3),
                      background: `linear-gradient(135deg, white 0%, ${alpha(getPriorityColor(brand.priority), 0.05)} 100%)`,
                    }}
                  >
                    {/* Priority indicator */}
                    <Tooltip title={`Priorità: ${brand.priority}`}>
                      <Box 
                        sx={{
                          position: 'absolute',
                          top: -12,
                          right: 16,
                          width: 32,
                          height: 32,
                          borderRadius: '50%',
                          backgroundColor: getPriorityColor(brand.priority),
                          color: 'white',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontWeight: 'bold',
                          fontSize: 14,
                          boxShadow: `0 4px 8px ${alpha(getPriorityColor(brand.priority), 0.4)}`,
                          border: '2px solid white',
                          zIndex: 2
                        }}
                      >
                        {brand.priority}
                      </Box>
                    </Tooltip>

                    <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
                      {/* Brand logo and name */}
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                        <Box 
                          sx={{ 
                            width: 48, 
                            height: 48, 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            backgroundColor: alpha(getPriorityColor(brand.priority), 0.2),
                            borderRadius: '12px',
                            mr: 2
                          }}
                        >
                          <Typography variant="h5" sx={{ fontWeight: 'bold', color: getPriorityColor(brand.priority) }}>
                            {brand.brand.charAt(0)}
                          </Typography>
                        </Box>
                        <Typography 
                          variant="h6" 
                          sx={{ 
                            fontWeight: 'bold', 
                            color: 'text.primary',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            ml: 2,
                            fontSize: { xs: '1rem', sm: '1.25rem' }
                          }}
                        >
                          {brand.brand}
                        </Typography>
                      </Box>

                      {/* Priority section - interactive when editing */}
                      <Box sx={{ mb: 3 }}>
                        <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
                          Livello di Priorità:
                        </Typography>
                        {editingId === brand.id ? (
                          <PrioritySelector 
                            value={parseInt(editingPriority, 10)} 
                            onChange={(newValue) => setEditingPriority(newValue.toString())}
                          />
                        ) : (
                          <Box sx={{ position: 'relative', pt: 1 }}>
                            <LinearProgress 
                              variant="determinate" 
                              value={(100 - brand.priority) + 1} 
                              sx={{ 
                                height: 10, 
                                borderRadius: 5,
                                backgroundColor: alpha(getPriorityColor(brand.priority), 0.2),
                                '& .MuiLinearProgress-bar': {
                                  backgroundColor: getPriorityColor(brand.priority),
                                },
                              }}
                            />
                            <Box 
                              sx={{ 
                                position: 'absolute', 
                                top: 14, 
                                left: 0,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Typography variant="caption" sx={{ fontWeight: 'bold', color: 'text.secondary' }}>
                                Alta
                              </Typography>
                              <Typography variant="caption" sx={{ fontWeight: 'bold', color: 'text.secondary' }}>
                                Bassa
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </Box>

                      {/* Action buttons */}
                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between',
                        borderTop: '1px solid',
                        borderColor: 'divider',
                        pt: 2,
                        mt: 'auto',
                        flexWrap: { xs: 'wrap', sm: 'nowrap' },
                        gap: { xs: 1, sm: 0 }
                      }}>
                        {editingId === brand.id ? (
                          <>
                            <Tooltip title="Salva modifiche">
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleUpdateBrand(brand.id)}
                                startIcon={<Save />}
                                size="small"
                                sx={{ 
                                  borderRadius: 6, 
                                  px: 2,
                                  boxShadow: '0 4px 8px rgba(25, 118, 210, 0.2)',
                                  flex: { xs: '1 0 45%', sm: 'inherit' }
                                }}
                              >
                                Salva
                              </Button>
                            </Tooltip>
                            <Tooltip title="Annulla modifiche">
                              <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleCancelEdit}
                                startIcon={<Cancel />}
                                size="small"
                                sx={{ 
                                  borderRadius: 6, 
                                  px: 2,
                                  flex: { xs: '1 0 45%', sm: 'inherit' }
                                }}
                              >
                                Annulla
                              </Button>
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            <Tooltip title="Modifica priorità">
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => handleEditBrand(brand.id, brand.brand, brand.priority)}
                                startIcon={<Edit />}
                                size="small"
                                sx={{ 
                                  borderRadius: 6, 
                                  px: 2,
                                  borderColor: alpha('#1976d2', 0.5),
                                  '&:hover': {
                                    borderColor: '#1976d2',
                                    backgroundColor: alpha('#1976d2', 0.04),
                                  },
                                  flex: { xs: '1 0 65%', sm: 'inherit' }
                                }}
                              >
                                Modifica
                              </Button>
                            </Tooltip>
                            <Tooltip title="Elimina marca">
                              <IconButton
                                color="error"
                                onClick={() => handleDeleteBrand(brand.id)}
                                size="small"
                                sx={{ 
                                  boxShadow: '0 2px 8px rgba(211, 47, 47, 0.2)',
                                  bgcolor: 'white',
                                  '&:hover': {
                                    bgcolor: '#fef6f6',
                                  }
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                </Zoom>
              </Grid>
            ))}
          </Grid>
          
          {/* Empty state */}
          {sortedBrands.length === 0 && (
            <Box 
              sx={{ 
                textAlign: 'center', 
                py: { xs: 4, sm: 8 },
                px: { xs: 2, sm: 3 },
                borderRadius: 4,
                border: '2px dashed',
                borderColor: 'divider',
                backgroundColor: alpha('#f5f5f5', 0.5)
              }}
            >
              <Typography variant="h6" color="text.secondary" gutterBottom>
                Nessuna marca configurata
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ maxWidth: 500, mx: 'auto', mb: 3 }}>
                Aggiungi la tua prima marca usando il selettore sopra. Le marche con priorità più bassa verranno mostrate per ultime nel catalogo.
              </Typography>
              <Button 
                variant="outlined" 
                color="primary"
                startIcon={<Add />}
                onClick={() => document.querySelector('select').focus()}
              >
                Aggiungi la prima marca
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BrandPriorityManager;
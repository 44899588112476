import React, { useState, useEffect, useCallback, useRef } from 'react';
import { 
  Dialog, DialogContent, Box, Typography, List, ListItem, 
  ListItemAvatar, Avatar, ListItemText, Divider, TextField, 
  IconButton, InputAdornment, CircularProgress, Paper, useMediaQuery, useTheme,
  Tabs, Tab, Badge, Drawer, AppBar, Toolbar, Button, Menu, MenuItem, Tooltip,
  DialogTitle, DialogActions, Chip
} from '@mui/material';
import { 
  Send, X, Search, Minimize2, Users, Plus, MessageSquare, ChevronLeft,
  Star, Settings, Bell, Menu as MenuIcon, User, UserPlus, ArrowLeft
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { fetchUsers } from '../api/user';
import MessageList from './MessageList';
import ConversationList from './ConversationList';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Messaging = ({ open, onClose, darkMode }) => {
  const { user } = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [conversations, setConversations] = useState([]);
  const [minimized, setMinimized] = useState(false);
  const [activeChats, setActiveChats] = useState([]);
  const [activeTab, setActiveTab] = useState('conversations'); // 'conversations', 'starred', or 'users'
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [chatRooms, setChatRooms] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [groupDialogOpen, setGroupDialogOpen] = useState(false);
  const [selectedGroupMembers, setSelectedGroupMembers] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [creatingGroup, setCreatingGroup] = useState(false);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  // Function to refresh conversations
  const refreshConversations = useCallback(() => {
    setRefreshTrigger(prev => prev + 1);
  }, []);

  useEffect(() => {
    if (open) {
      loadConversations();
      loadUsers();
      setMinimized(false);
      // For mobile, open the drawer by default when no conversation is selected
      if (isMobile && !selectedUser) {
        setDrawerOpen(true);
      }
    }
  }, [open, refreshTrigger, isMobile, selectedUser]);

  const loadConversations = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const baseUrl = 'https://admin.aitomotivelab.com'; // Fix the base URL to point to your backend
      
      const response = await axios.get(
        `${baseUrl}/company-chat/api/chat-rooms/`, // Use the correct endpoint path
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      // Sort conversations by updated_at time
      const sortedConversations = response.data.sort((a, b) => {
        return new Date(b.updated_at) - new Date(a.updated_at);
      });
      
      setConversations(sortedConversations);
      
      // Calculate total unread messages
      const totalUnread = sortedConversations.reduce(
        (sum, convo) => sum + (convo.unread_count || 0), 0
      );
      setUnreadCount(totalUnread);
      
      setLoading(false);
    } catch (error) {
      console.error('Error loading conversations:', error);
      setError('Failed to load conversations');
      setLoading(false);
    }
  };

  const loadUsers = async () => {
    try {
      const usersList = await fetchUsers();
      setUsers(usersList.filter(u => u.id !== user?.id));
    } catch (error) {
      console.error('Error loading users:', error);
      setError('Failed to load users');
    }
  };

  const handleSearch = async (query) => {
    if (query.trim() === '') {
      setSearchResults([]);
      setSearching(false);
      return;
    }
    
    try {
      setSearching(true);
      const token = localStorage.getItem('token');
      const baseUrl = 'https://admin.aitomotivelab.com'; // Use the correct backend URL
      
      // Use the correct endpoint path as defined in your Django views
      const response = await axios.get(
        `${baseUrl}/company-chat/api/users/`, {
          params: { search: query },
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      console.log('Search results:', response.data);
      
      // Filter out the current user from results
      const filteredResults = response.data.filter(
        u => u.id !== (user?.id || user?.user_id)
      );
      
      setSearchResults(filteredResults);
    } catch (error) {
      console.error('Error searching users:', error);
      setErrorMessage('Failed to search users');
      setSearchResults([]);
    } finally {
      setSearching(false);
    }
  };

  const selectUser = (user) => {
    setSelectedUser(user);
    // For mobile, close the drawer when selecting a user
    if (isMobile) {
      setDrawerOpen(false);
    }
  };

  const selectConversation = (conversation) => {
    // Create a user object from the conversation
    let selectedUser;
    
    if (conversation.is_group) {
      // For group conversations
      selectedUser = {
        id: conversation.id,
        first_name: conversation.name,
        is_group: true,
        participants: conversation.participants
      };
    } else {
      // For direct conversations, find the other user
      const otherParticipant = conversation.participants.find(
        p => p.user && p.user.id !== (user?.id || user?.user_id)
      )?.user;
      
      if (otherParticipant) {
        selectedUser = {
          id: otherParticipant.id,
          first_name: otherParticipant.first_name,
          last_name: otherParticipant.last_name,
          email: otherParticipant.email,
          username: otherParticipant.username,
          chatRoomId: conversation.id
        };
      } else {
        // Self-chat
        selectedUser = {
          id: user.id,
          first_name: 'My Notes',
          chatRoomId: conversation.id,
          isSelfChat: true
        };
      }
    }
    
    // Clear active chats before setting new one
    setActiveChats([]);
    
    // Set as selected user
    setSelectedUser(selectedUser);
    
    // For mobile, close the drawer when selecting a conversation
    if (isMobile) {
      setDrawerOpen(false);
    }
  };

  const toggleStarred = async (chatId) => {
    try {
      const token = localStorage.getItem('token');
      const baseUrl = 'https://admin.aitomotivelab.com'; // Adjust as needed
      
      const response = await axios.post(
        `${baseUrl}/company-chat/api/chat-rooms/${chatId}/toggle_star/`,
        {},
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      if (response.data) {
        console.log("Star toggle response:", response.data);
        
        // Update the conversations in state to reflect the new starred status
        setConversations(prevConversations => 
          prevConversations.map(conversation => {
            if (conversation.id === chatId) {
              // Create a new conversation object with updated properties
              return {
                ...conversation,
                is_starred: response.data.is_starred,
                // Update the participants to reflect the starred status
                participants: conversation.participants.map(p => {
                  if (p.user && p.user.id === user.id) {
                    return { ...p, is_starred: response.data.is_starred };
                  }
                  return p;
                })
              };
            }
            return conversation;
          })
        );
        
        // If we're also maintaining a separate chatRooms state
        if (setChatRooms) {
          setChatRooms(prevChatRooms => 
            prevChatRooms.map(room => 
              room.id === chatId 
                ? { ...room, is_starred: response.data.is_starred } 
                : room
            )
          );
        }
        
        // Force the conversation lists to update
        setRefreshTrigger(prev => prev + 1);
      }
      
      return response.data;
    } catch (error) {
      console.error('Error toggling star:', error);
      setErrorMessage('Failed to update starred status. Please try again.');
      setTimeout(() => setErrorMessage(''), 5000);
      return null;
    }
  };

  const handleMinimize = () => {
    setMinimized(true);
  };

  const handleMaximize = () => {
    setMinimized(false);
  };

  const startNewChat = (selectedUser) => {
    // Clear any existing chats first
    setActiveChats([]);
    
    // Set the selected user directly without adding to activeChats
    setSelectedUser(selectedUser);
    
    // Reset search query
    setSearchQuery('');
    
    // For mobile, close the drawer
    if (isMobile) {
      setDrawerOpen(false);
    }
  };

  const closeChat = (userId) => {
    // Remove from active chats
    setActiveChats(prev => prev.filter(chat => chat.id !== userId));
    
    // If this was the selected user, clear the selection
    if (selectedUser && selectedUser.id === userId) {
      setSelectedUser(null);
      // For mobile, show the drawer when closing the current chat
      if (isMobile) {
        setDrawerOpen(true);
      }
    }
  };

  const openMenu = (event) => {
    console.log("Opening menu");
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    console.log("Closing menu");
    setAnchorEl(null);
  };

  const createNewGroupChat = () => {
    console.log("Creating new group chat");
    closeMenu();
    setGroupDialogOpen(true);
    console.log("Dialog should be open:", true);
    setSelectedGroupMembers([]);
    setGroupName('');
  };

  const handleCreateGroup = async () => {
    try {
      setCreatingGroup(true);
      const token = localStorage.getItem('token');
      const baseUrl = 'https://admin.aitomotivelab.com';
      
      const response = await axios.post(
        `${baseUrl}/company-chat/api/chat-rooms/create-group/`,
        {
          name: groupName,
          participant_ids: selectedGroupMembers.map(p => p.id),
          company: user.primary_company
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      console.log('Group created:', response.data);
      
      // Update the conversations list and close the dialog
      refreshConversations();
      setGroupDialogOpen(false);
      
      // Reset state
      setGroupName('');
      setSelectedGroupMembers([]);
      
      // Select the newly created group
      if (response.data && response.data.id) {
        selectConversation({
          id: response.data.id,
          name: response.data.name,
          is_group: true,
          participants: response.data.participants || []
        });
      }
      
    } catch (error) {
      console.error('Error creating group:', error);
      setErrorMessage('Failed to create group chat');
    } finally {
      setCreatingGroup(false);
    }
  };

  const handleBack = () => {
    // Clear the selected user
    setSelectedUser(null);
    
    // Also clear any active chats to prevent background chats
    setActiveChats([]);
    
    // Refresh conversations when going back
    refreshConversations();
    
    // For mobile, open the drawer when going back
    if (isMobile) {
      setDrawerOpen(true);
    }
  };

  // Function to handle going back to chat list
  const handleGoBack = () => {
    // Clear the selected user
    setSelectedUser(null);
    
    // Also clear any active chats to prevent background chats
    setActiveChats([]);
    
    // Refresh conversations when going back
    refreshConversations();
    
    // For mobile, ensure drawer is open when returning to chat list
    if (isMobile) {
      setDrawerOpen(true);
    }
  };

  // Render the main messaging window
  const renderMainWindow = () => {
    if (minimized) {
      return (
        <Paper
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            width: 'auto',
            zIndex: 1300,
            borderRadius: '50%',
            cursor: 'pointer',
            boxShadow: 5
          }}
          onClick={handleMaximize}
        >
          <Badge badgeContent={unreadCount} color="primary">
            <IconButton
              color="primary"
              sx={{ p: 1.5 }}
            >
              <MessageSquare />
            </IconButton>
          </Badge>
        </Paper>
      );
    }

    const mainContent = selectedUser ? (
      <MessageList
        selectedUser={selectedUser}
        onBack={handleBack}
        currentUser={user}
        darkMode={darkMode}
        refreshConversations={refreshConversations}
      />
    ) : (
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        height: '100%',
        overflow: 'hidden'
      }}>
        {/* Content Area for Tabs */}
        <Box sx={{ 
          flex: 1, 
          overflow: 'auto',
          pb: '56px' // Add padding to account for bottom navigation
        }}>
          {activeTab === 'conversations' && (
            <ConversationList
              conversations={conversations}
              onSelectConversation={selectConversation}
              darkMode={darkMode}
              onToggleStarred={toggleStarred}
              currentUserId={user?.id || user?.user_id}
            />
          )}
          
          {activeTab === 'starred' && (
            <ConversationList
              conversations={conversations.filter(c => 
                c.is_starred || 
                c.participants?.some(p => 
                  (p.user?.id === (user?.id || user?.user_id) && p.is_starred)
                )
              )}
              onSelectConversation={selectConversation}
              darkMode={darkMode}
              onToggleStarred={toggleStarred}
              currentUserId={user?.id || user?.user_id}
            />
          )}
          
          {activeTab === 'users' && (
            <List>
              {users.map((user) => (
                <ListItem 
                  key={user.id}
                  button
                  onClick={() => startNewChat(user)}
                >
                  <ListItemAvatar>
                    <Avatar>
                      {user.first_name?.charAt(0) || user.username?.charAt(0) || '?'}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText 
                    primary={`${user.first_name || ''} ${user.last_name || ''}`} 
                    secondary={user.email || user.username}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </Box>
    );

    if (isMobile) {
      return (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1300,
            bgcolor: darkMode ? '#121212' : 'background.paper',
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
          }}
        >
          {/* Header */}
          <AppBar 
            position="static" 
            color={darkMode ? 'inherit' : 'primary'} 
            sx={{ 
              backgroundColor: darkMode ? '#1E1E1E' : undefined,
              boxShadow: 'none',
              borderBottom: '1px solid',
              borderColor: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'
            }}
          >
            <Toolbar sx={{ minHeight: '56px !important' }}>
              {selectedUser ? (
                <IconButton edge="start" color="inherit" onClick={handleBack} sx={{ mr: 2 }}>
                  <ChevronLeft />
                </IconButton>
              ) : (
                <IconButton edge="start" color="inherit" onClick={() => setDrawerOpen(true)} sx={{ mr: 2 }}>
                  <MenuIcon />
                </IconButton>
              )}
              <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: '1.1rem' }}>
                {selectedUser?.first_name ? `${selectedUser.first_name} ${selectedUser.last_name || ''}` : 'Messages'}
              </Typography>
              {!selectedUser && (
                <IconButton color="inherit" onClick={openMenu}>
                  <Plus />
                </IconButton>
              )}
              <IconButton color="inherit" onClick={onClose}>
                <X />
              </IconButton>
            </Toolbar>
          </AppBar>
          
          {/* Main Content */}
          <Box sx={{ flex: 1, overflow: 'hidden' }}>
            {mainContent}
          </Box>

          {/* Bottom Navigation */}
          {!selectedUser && (
            <Paper
              sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 1400,
                borderTop: '1px solid',
                borderColor: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
                bgcolor: darkMode ? '#1E1E1E' : 'background.paper'
              }}
            >
              <Tabs
                value={activeTab}
                onChange={(e, newValue) => setActiveTab(newValue)}
                variant="fullWidth"
                sx={{
                  '& .MuiTab-root': {
                    minHeight: '56px',
                    textTransform: 'none',
                    fontSize: '0.875rem'
                  }
                }}
              >
                <Tab 
                  icon={<MessageSquare size={20} />} 
                  iconPosition="top"
                  label="Chats" 
                  value="conversations" 
                />
                <Tab 
                  icon={<Star size={20} />} 
                  iconPosition="top"
                  label="Starred" 
                  value="starred" 
                />
                <Tab 
                  icon={<User size={20} />} 
                  iconPosition="top"
                  label="Users" 
                  value="users" 
                />
              </Tabs>
            </Paper>
          )}
        </Box>
      );
    }

    // Desktop view
    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            height: '80vh',
            maxHeight: '80vh',
            bgcolor: darkMode ? '#121212' : 'background.paper'
          }
        }}
      >
        <DialogContent 
          sx={{ 
            p: 0, 
            display: 'flex', 
            flex: 1, 
            overflow: 'hidden', 
            bgcolor: darkMode ? '#121212' : 'background.paper' 
          }}
        >
          {/* Always show the sidebar on desktop */}
          <Box sx={{ 
            width: 300, 
            borderRight: '1px solid', 
            borderColor: darkMode ? 'rgba(255,255,255,0.1)' : 'divider',
            display: 'flex',
            flexDirection: 'column',
          }}>
            <Tabs
              value={activeTab}
              onChange={(e, newValue) => setActiveTab(newValue)}
              variant="fullWidth"
              sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
              <Tab 
                icon={<MessageSquare size={16} />} 
                iconPosition="start"
                label="Chats" 
                value="conversations" 
              />
              <Tab 
                icon={<Star size={16} />} 
                iconPosition="start"
                label="Starred" 
                value="starred" 
              />
              <Tab 
                icon={<User size={16} />} 
                iconPosition="start"
                label="Users" 
                value="users" 
              />
            </Tabs>
            
            <Box sx={{ flex: 1, overflow: 'auto' }}>
              {activeTab === 'conversations' && (
                <ConversationList
                  conversations={conversations}
                  onSelectConversation={selectConversation}
                  darkMode={darkMode}
                  onToggleStarred={toggleStarred}
                  currentUserId={user?.id || user?.user_id}
                />
              )}
              
              {activeTab === 'starred' && (
                <ConversationList
                  conversations={conversations.filter(c => 
                    c.is_starred || 
                    c.participants?.some(p => 
                      (p.user?.id === (user?.id || user?.user_id) && p.is_starred)
                    )
                  )}
                  onSelectConversation={selectConversation}
                  darkMode={darkMode}
                  onToggleStarred={toggleStarred}
                  currentUserId={user?.id || user?.user_id}
                />
              )}
              
              {activeTab === 'users' && (
                <List>
                  {users.map((user) => (
                    <ListItem 
                      key={user.id}
                      button
                      onClick={() => startNewChat(user)}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          {user.first_name?.charAt(0) || user.username?.charAt(0) || '?'}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText 
                        primary={`${user.first_name || ''} ${user.last_name || ''}`} 
                        secondary={user.email || user.username}
                      />
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
          </Box>
          
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden', position: 'relative' }}>
            {/* Add a header with back button for desktop selected chat */}
            {selectedUser && (
              <Box 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  p: 1, 
                  borderBottom: '1px solid', 
                  borderColor: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
                  bgcolor: darkMode ? '#1E1E1E' : '#f5f5f5'
                }}
              >
                <IconButton onClick={handleBack}>
                  <ArrowLeft size={20} />
                </IconButton>
                <Typography variant="h6" sx={{ ml: 1 }}>
                  {selectedUser.first_name} {selectedUser.last_name || ''}
                </Typography>
              </Box>
            )}
            
            <Box sx={{ flex: 1, overflow: 'hidden' }}>
              {selectedUser ? (
                <MessageList
                  selectedUser={selectedUser}
                  onBack={handleBack}
                  currentUser={user}
                  darkMode={darkMode}
                  refreshConversations={refreshConversations}
                />
              ) : (
                <Box 
                  sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    height: '100%',
                    p: 3,
                    color: darkMode ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)',
                    flexDirection: 'column',
                    textAlign: 'center'
                  }}
                >
                  <MessageSquare size={40} style={{ marginBottom: 16, opacity: 0.5 }} />
                  <Typography>
                    Select a conversation from the sidebar or start a new chat
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

  // Render additional floating chat windows - MODIFIED to ensure no background chats
  const renderAdditionalChats = () => {
    // Don't render any additional chats - all chats go through the main window
    return null;
  };

  // Cleanup effect when component unmounts
  useEffect(() => {
    // Cleanup function
    return () => {
      // Clear all chats and selected users when component unmounts
      setSelectedUser(null);
      setActiveChats([]);
    };
  }, []);

  // Ensure the component respects the open prop from parent
  if (!open) return null;

  return (
    <>
      {/* Group Chat Creation Dialog - Place at the top level outside other components */}
      <Dialog 
        open={groupDialogOpen} 
        onClose={() => {
          console.log("Closing dialog");
          setGroupDialogOpen(false);
        }}
        maxWidth="sm"
        fullWidth
        sx={{ zIndex: 1400 }} // Ensure high z-index
      >
        <DialogTitle>Create New Group</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Group Name"
            type="text"
            fullWidth
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            sx={{ mb: 3 }}
          />
          
          <Typography variant="subtitle1" gutterBottom>
            Add Members
          </Typography>
          
          <TextField
            placeholder="Search users..."
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search size={20} />
                </InputAdornment>
              )
            }}
            sx={{ mb: 2 }}
          />
          
          <Typography variant="subtitle2" gutterBottom sx={{ mt: 2 }}>
            Selected Members ({selectedGroupMembers.length})
          </Typography>
          
          <Box sx={{ 
            display: 'flex', 
            flexWrap: 'wrap', 
            gap: 1, 
            mb: 2 
          }}>
            {selectedGroupMembers.map(member => (
              <Chip
                key={member.id}
                avatar={<Avatar>{member.first_name?.charAt(0) || member.username?.charAt(0) || '?'}</Avatar>}
                label={`${member.first_name || ''} ${member.last_name || ''}`}
                onDelete={() => setSelectedGroupMembers(prev => prev.filter(m => m.id !== member.id))}
                sx={{ m: 0.5 }}
              />
            ))}
          </Box>
          
          <List sx={{ maxHeight: 250, overflow: 'auto' }}>
            {searching ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                <CircularProgress size={24} />
              </Box>
            ) : (
              (searchResults.length > 0 ? searchResults : users)
              .filter(u => !selectedGroupMembers.some(member => member.id === u.id))
              .map(user => (
                <ListItem 
                  key={user.id} 
                  button 
                  onClick={() => {
                    if (!selectedGroupMembers.some(member => member.id === user.id)) {
                      setSelectedGroupMembers(prev => [...prev, user]);
                    }
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      {user.first_name?.charAt(0) || user.username?.charAt(0) || '?'}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText 
                    primary={`${user.first_name || ''} ${user.last_name || ''}`}
                    secondary={user.email || user.username}
                  />
                </ListItem>
              ))
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setGroupDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleCreateGroup}
            variant="contained"
            color="primary"
            disabled={!groupName.trim() || selectedGroupMembers.length === 0}
          >
            Create Group
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Main messaging content */}
      <div className="messaging-container">
        {renderMainWindow()}
        {renderAdditionalChats()}
      </div>
    </>
  );
};

export default Messaging;
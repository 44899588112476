import React, { useState, useEffect, useMemo } from 'react';
import { Grid, TextField, Button, FormControl, Select, MenuItem, InputLabel, Paper, Typography, Checkbox, ListItemText } from '@mui/material';
import { useAuth } from '../../../contexts/AuthContext';
import { fetchBranches, fetchBranchesByID } from '../../../api/branch';

const AvailabilityForm = ({
  availabilityFormData,
  handleAvailabilityFormChange,
  handleAvailabilityFormSubmit,
  isEditingAvailability,
  dayOfWeekOptions,
  locations = [],
  companyId,
}) => {
  const [branchValues, setBranchValues] = useState([]);

  // Determine which locations are selected.
  console.log('locations', locations);
  const formLocations = Array.isArray(availabilityFormData.location)
    ? availabilityFormData.location
    : (availabilityFormData.locations || []);

  // Memoize uniqueBranchIds so that the dependency won't change on every render.
  const uniqueBranchIds = useMemo(() => {
    const selectedLocationBranchIds = formLocations.reduce((acc, locationId) => {
      const location = locations.find((l) => l.id === locationId);
      if (location && Array.isArray(location.branch)) {
        acc.push(...location.branch);
      }
      return acc;
    }, []);
    return [...new Set(selectedLocationBranchIds)];
  }, [formLocations, locations]);

  useEffect(() => {
    if (uniqueBranchIds.length > 0) {
      fetchBranchesByID(uniqueBranchIds)
        .then((data) => {
          console.log('Fetched branch values:', data);
          setBranchValues(data);
        })
        .catch((err) => console.error('Error fetching branch values:', err));
    }
  }, [uniqueBranchIds]);

  console.log('Form Data:', availabilityFormData);

  // From the selected locations, grab the city (or other unique property) from each location.
  const selectedLocationCities = formLocations
    .map((locationId) => {
      const loc = locations.find((l) => l.id === locationId);
      return loc ? loc.citta : null;
    })
    .filter(Boolean);

  // Update the filtering logic to match the actual data structure
  const filteredBranchValues = branchValues.filter((branch) => {
    // Check if the branch exists in any of the selected locations' branch arrays
    return formLocations.some(locationId => {
      const location = locations.find(l => l.id === locationId);
      return location && Array.isArray(location.branch) && location.branch.includes(branch.id);
    });
  });

  // Authorization/Permissions logic.
  const { userRole, appAccess } = useAuth();
  const permissions = appAccess['booking_settings'] || {};
  const setting_canCreate = permissions.can_create || userRole === 'superadmin';
  const setting_canEdit = permissions.can_edit || userRole === 'superadmin';
  const canSubmitForm = isEditingAvailability ? setting_canEdit : setting_canCreate;

  return (
    <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
      <Typography variant="h6" gutterBottom>
        {isEditingAvailability ? 'Edit Availability' : 'Add New Availability'}
      </Typography>
      <form onSubmit={handleAvailabilityFormSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="day-of-week-label">
                {isEditingAvailability ? 'Day of Week' : 'Days of Week'}
              </InputLabel>
              <Select
                labelId="day-of-week-label"
                multiple={!isEditingAvailability}
                name="day_of_week"
                value={
                  Array.isArray(availabilityFormData.day_of_week)
                    ? availabilityFormData.day_of_week
                    : []
                }
                onChange={handleAvailabilityFormChange}
                renderValue={(selected) =>
                  selected
                    .map((value) =>
                      dayOfWeekOptions.find(
                        (option) => option.value === parseInt(value, 10)
                      )?.label
                    )
                    .filter(Boolean)
                    .join(', ')
                }
                required
                disabled={!canSubmitForm}
              >
                {dayOfWeekOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Checkbox
                      checked={
                        availabilityFormData.day_of_week.indexOf(
                          option.value.toString()
                        ) > -1
                      }
                    />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              name="start_time"
              label="Start Time"
              type="time"
              value={availabilityFormData.start_time}
              onChange={handleAvailabilityFormChange}
              fullWidth
              required
              disabled={!canSubmitForm}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              name="end_time"
              label="End Time"
              type="time"
              value={availabilityFormData.end_time}
              onChange={handleAvailabilityFormChange}
              fullWidth
              required
              disabled={!canSubmitForm}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              name="max_slots"
              label="Max Slots"
              type="number"
              value={availabilityFormData.max_slots}
              onChange={handleAvailabilityFormChange}
              fullWidth
              required
              disabled={!canSubmitForm}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth disabled={!canSubmitForm} required>
              <InputLabel id="select-multiple-locations-label">
                Select Location
              </InputLabel>
              <Select
                labelId="select-multiple-locations-label"
                multiple={false}
                name="location"
                value={formLocations.length > 0 ? formLocations[0] : ''}
                onChange={(e) => handleAvailabilityFormChange({
                  target: {
                    name: 'location',
                    value: [e.target.value] // Wrap the single value in an array to maintain compatibility
                  }
                })}
                renderValue={(selected) => {
                  const location = locations.find((l) => l.id === selected);
                  return location ? location.citta : '';
                }}
                error={!Array.isArray(formLocations) || formLocations.length === 0}
              >
                {locations.map((location) => (
                  <MenuItem key={location.id} value={location.id}>
                    <ListItemText primary={location.citta} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {formLocations.length > 0 && (
            <Grid item xs={12}>
              <FormControl fullWidth disabled={!canSubmitForm}>
                <InputLabel>Branches</InputLabel>
                <Select
                  multiple
                  name="branch_ids"
                  value={availabilityFormData.branch_ids || []}
                  onChange={(e) => {
                    const selectedIds = e.target.value;
                    const selectedBranches = filteredBranchValues.filter((branch) =>
                      selectedIds.includes(branch.id)
                    );
                    // Update branch_ids in the form state.
                    handleAvailabilityFormChange({
                      target: { name: 'branch_ids', value: selectedIds },
                    });
                    // Optionally, update a secondary field (e.g. branch_values) if needed.
                    handleAvailabilityFormChange({
                      target: {
                        name: 'branch_values',
                        value: selectedBranches.map((b) => b.value),
                      },
                    });
                  }}
                  label="Branches"
                  renderValue={(selected) =>
                    selected
                      .map((id) => {
                        const branch = filteredBranchValues.find((v) => v.id === id);
                        return branch ? branch.value : '';
                      })
                      .filter(Boolean)
                      .join(', ')
                  }
                >
                  {filteredBranchValues.map((branch) => (
                    <MenuItem key={branch.id} value={branch.id}>
                      <Checkbox
                        checked={(availabilityFormData.branch_ids || []).indexOf(branch.id) > -1}
                      />
                      <ListItemText primary={branch.value} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            {canSubmitForm && (
              <Button type="submit" variant="contained" color="primary">
                {isEditingAvailability ? 'Update Availability' : 'Add Availability'}
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default AvailabilityForm;

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { 
  Box, Typography, IconButton, Avatar, TextField, 
  Paper, CircularProgress, InputAdornment, Snackbar, Alert,
  List, ListItem, ListItemAvatar, ListItemText, Divider, Button,
  Dialog, DialogTitle, DialogContent, DialogActions, Chip, AvatarGroup,
  Menu, MenuItem, Tooltip, Badge
} from '@mui/material';
import { 
  ArrowLeft, Send, Paperclip, Users, MessageSquare, Plus, X,
  MoreVertical, Edit, Trash2, Reply, Star, Pin, ThumbsUp, 
  Heart, Smile, Copy, UserPlus, RefreshCw
} from 'lucide-react';
import { useWebSocket } from './WebSocketManager';
import axios from 'axios';
import { 
  StyledMessageListContainer, 
  StyledMessageBubble, 
  StyledTimeStamp,
  StyledChatHeader
} from './styleinternalmessages';

const MessageList = ({ selectedUser, onBack, currentUser, darkMode, isPopout = false }) => {
  const [newMessage, setNewMessage] = useState('');
  const [chatRooms, setChatRooms] = useState([]);
  const [loadingRooms, setLoadingRooms] = useState(false);
  const [chatRoom, setChatRoom] = useState(null);
  const [loadingRoom, setLoadingRoom] = useState(false);
  const [creatingRoom, setCreatingRoom] = useState(false);
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const [editingMessage, setEditingMessage] = useState(null);
  const [editContent, setEditContent] = useState('');
  const [messageMenuAnchor, setMessageMenuAnchor] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [replyingTo, setReplyingTo] = useState(null);
  const [emojiMenuAnchor, setEmojiMenuAnchor] = useState(null);
  const [pendingAttachments, setPendingAttachments] = useState([]);
  
  // Group chat related states
  const [isGroupChatDialogOpen, setIsGroupChatDialogOpen] = useState(false);
  const [isAddParticipantsDialogOpen, setIsAddParticipantsDialogOpen] = useState(false);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [groupChatName, setGroupChatName] = useState('');
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [isAddingParticipants, setIsAddingParticipants] = useState(false);
  const [showParticipantsDialog, setShowParticipantsDialog] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  
  // New state variable for removing a participant
  const [isRemovingParticipant, setIsRemovingParticipant] = useState(false);
  
  // Add state for delete confirmation dialog
  const [deleteGroupDialogOpen, setDeleteGroupDialogOpen] = useState(false);
  const [deletingGroup, setDeletingGroup] = useState(false);
  
  const chatId = chatRoom?.id || '';
  const userId = currentUser?.user_id || currentUser?.id || '';
  const recipientId = selectedUser?.id || '';

  const {
    messages: wsMessages,
    loading: wsLoading,
    connectionStatus,
    errorMessage,
    sendMessage,
    markAsRead,
    requestChatHistory,
    setErrorMessage
  } = useWebSocket(chatId, userId);

  // Common emojis for reactions
  const commonEmojis = ["👍", "❤️", "😂", "😮", "😢", "👏", "🎉", "🙏"];

  // New useMemo hook to check admin status
  const isCurrentUserAdmin = useMemo(() => {
    if (!chatRoom?.participants || !currentUser) return false;
    
    const currentUserId = currentUser.id || currentUser.user_id;
    const currentParticipant = chatRoom.participants.find(p => {
      // Check if p is a user object directly
      if (p.id === currentUserId) return true;
      // Check if p has a nested user object
      if (p.user && p.user.id === currentUserId) return true;
      return false;
    });
    
    return currentParticipant?.is_admin || false;
  }, [chatRoom, currentUser]);

  useEffect(() => {
    if (selectedUser?.id && (currentUser?.user_id || currentUser?.id)) {
      console.log("Calling findOrCreateChatRoom()");
      findOrCreateChatRoom(selectedUser);
    }
  }, [selectedUser, currentUser]);

  useEffect(() => {
    // Scroll to bottom when messages change
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [wsMessages]);

  useEffect(() => {
    // Mark messages as read when user views them
    if (chatId && wsMessages && wsMessages.length > 0) {
      markAsRead(chatId);
    }
  }, [chatId, wsMessages]);

  // Function to create a chat room with a user
  const findOrCreateChatRoom = async (user) => {
    try {
      setLoadingRoom(true);
      const token = localStorage.getItem('token');
      const baseUrl = 'https://admin.aitomotivelab.com';
      
      // Log the user object to debug
      console.log('Creating/finding chat room with user:', user);
      
      // Check if this is a group chat
      if (user?.is_group) {
        console.log('This is a group chat, using the group chat ID directly');
        // For group chats, we already have the chat room ID
        setChatRoom({
          id: user.id,
          name: user.first_name || 'Group Chat',
          is_group: true,
          participants: user.participants || []
        });
        
        // Request chat history for this group
        requestChatHistory(user.id);
        setLoadingRoom(false);
        return;
      }
      
      // For direct chats, continue with the regular flow
      const userId = user?.id;
      if (!userId) {
        console.error('Invalid user ID:', userId);
        setErrorMessage('Cannot create chat: missing user ID');
        setLoadingRoom(false);
        return;
      }
      
      // Use the correct endpoint for direct chats
      const response = await axios.post(
        `${baseUrl}/company-chat/api/chat-rooms/create_or_get_direct_chat/`,
        {
          user_id: userId,
          company: currentUser?.primary_company
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      console.log('Chat room created/found:', response.data);
      setChatRoom(response.data);
      
      // Re-request chat history from websocket after getting room
      if (response.data.id) {
        requestChatHistory(response.data.id);
      }
      
    } catch (error) {
      console.error('Error creating/finding chat room:', error);
      // Provide more detailed error information
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Server error data:', error.response.data);
        console.error('Server error status:', error.response.status);
        setErrorMessage(`Server error: ${error.response.status}. ${error.response.data?.detail || ''}`);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received:', error.request);
        setErrorMessage('No response from server');
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Request setup error:', error.message);
        setErrorMessage(`Error: ${error.message}`);
      }
    } finally {
      setLoadingRoom(false);
    }
  };

  // Function to load all available users (for creating group chats)
  const loadUsers = async () => {
    try {
      setLoadingUsers(true);
      const token = localStorage.getItem('token');
      const baseUrl = 'https://admin.aitomotivelab.com';
      
      const response = await axios.get(
        `${baseUrl}/company-chat/api/users/`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
          }
        }
      );
      
      // Filter out current user from available users
      const filteredUsers = response.data.filter(
        user => user.id !== (currentUser?.id || currentUser?.user_id)
      );
      
      setAvailableUsers(filteredUsers);
    } catch (error) {
      console.error('Error loading users:', error);
      setErrorMessage('Error loading users');
    } finally {
      setLoadingUsers(false);
    }
  };

  // Helper function to read file as base64
  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file); // This will generate a data URL with format "data:mimetype;base64,..."
    });
  };

  // Function to handle file selection
  const handleFileSelect = async (event) => {
    const files = Array.from(event.target.files);
    const newAttachments = [];
    
    for (const file of files) {
      try {
        // Read file as base64
        const base64Data = await readFileAsBase64(file);
        console.log(`Read file: ${file.name}, size: ${file.size}, type: ${file.type}`);
        
        newAttachments.push({
          id: Math.random().toString(36).substring(2),
          file_name: file.name,
          file_type: file.type || 'application/octet-stream',
          file_size: file.size || 0,
          file_data: base64Data, // The full data URL with prefix
          // For UI display
          name: file.name,
          type: file.type,
          size: file.size,
          // For preview
          preview: URL.createObjectURL(file)
        });
      } catch (error) {
        console.error(`Error reading file ${file.name}:`, error);
        setErrorMessage(`Failed to process file: ${file.name}`);
      }
    }
    
    console.log(`Adding ${newAttachments.length} attachments`, newAttachments);
    setPendingAttachments([...pendingAttachments, ...newAttachments]);
    
    // Reset file input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  // Function to handle sending a message
  const handleSendMessage = async () => {
    if (newMessage.trim() === '' && pendingAttachments.length === 0) return;
    
    if (editingMessage) {
      await handleEditMessage();
      return;
    }

    try {
      const messageContent = newMessage.trim();
      const replyToId = replyingTo ? replyingTo.id : null;
      
      console.log("Sending message with reply to:", replyToId);
      console.log("Reply data:", replyingTo);
      
      // Use the WebSocket's sendMessage function
      const success = await sendMessage(
        chatRoom.id,
        messageContent,
        pendingAttachments,
        replyToId // Pass the reply_to parameter
      );
      
      if (success) {
        setNewMessage('');
        setPendingAttachments([]);
        setReplyingTo(null); // Clear the reply after sending
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setErrorMessage("Failed to send message. Please try again.");
    }
  };

  // Function to handle editing a message
  const handleEditMessage = async () => {
    if (!editingMessage || !editContent.trim()) return;
    
    const token = localStorage.getItem('token');
    const baseUrl = 'https://admin.aitomotivelab.com';
    
    try {
      const response = await axios.post(
        `${baseUrl}/company-chat/api/messages/${editingMessage.id}/edit/`,
        { content: editContent },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      // Clear editing state
      setEditingMessage(null);
      setEditContent('');
      setNewMessage('');
    } catch (error) {
      console.error('Error editing message:', error);
      setErrorMessage('Error editing message');
    }
  };

  // Function to handle deleting a message
  const handleDeleteMessage = (messageId) => {
    if (!messageId) return;
    
    const token = localStorage.getItem('token');
    const baseUrl = 'https://admin.aitomotivelab.com';
    
    axios.post(
      `${baseUrl}/company-chat/api/messages/${messageId}/delete/`,
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    ).then(() => {
      handleCloseMessageMenu();
    }).catch(error => {
      console.error('Error deleting message:', error);
      setErrorMessage('Error deleting message');
    });
  };

  // Function to toggle reaction on a message
  const handleReaction = async (messageId, emoji) => {
    try {
      if (!messageId || !emoji) return;
      
      const token = localStorage.getItem('token');
      const baseUrl = 'https://admin.aitomotivelab.com';
      
      // Add debug logging
      console.log('Sending reaction:', emoji, 'to message:', messageId);
      
      const response = await axios.post(
        `${baseUrl}/company-chat/api/messages/${messageId}/react/`,
        { emoji },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      console.log('Reaction added - server response:', response.data);
      
      // Force reload of chat history immediately
      if (chatRoom?.id) {
        console.log("Requesting updated chat history after reaction");
        requestChatHistory(chatRoom.id);
        
        // Add a secondary refresh after a slight delay to ensure we get updated data
        setTimeout(() => {
          console.log("Secondary refresh of chat history");
          requestChatHistory(chatRoom.id);
        }, 500);
      }
      
    } catch (error) {
      console.error('Error sending reaction:', error);
    } finally {
      handleCloseEmojiMenu();
    }
  };

  // Function to pin/unpin a message
  const handlePinMessage = (messageId) => {
    if (!messageId) return;
    
    const token = localStorage.getItem('token');
    const baseUrl = 'https://admin.aitomotivelab.com';
    
    axios.post(
      `${baseUrl}/company-chat/api/messages/${messageId}/pin/`,
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    ).then(() => {
      handleCloseMessageMenu();
    }).catch(error => {
      console.error('Error pinning message:', error);
      setErrorMessage('Error pinning message');
    });
  };

  // Function to remove a pending attachment
  const removePendingAttachment = (attachmentId) => {
    setPendingAttachments(pendingAttachments.filter(att => att.id !== attachmentId));
  };

  // Helper function to check if user can edit a message
  const canEditMessage = (message) => {
    if (!message) return false;
    const ownMessage = message.sender_id === (currentUser?.id || currentUser?.user_id);
    return ownMessage;
  };

  // Handle emoji menu opening
  const handleOpenEmojiMenu = (event, message) => {
    setSelectedMessage(message);
    setEmojiMenuAnchor(event.currentTarget);
  };

  // Handle emoji menu closing
  const handleCloseEmojiMenu = () => {
    setEmojiMenuAnchor(null);
    setSelectedMessage(null);
  };

  // Start replying to a message
  const startReplyingToMessage = (message) => {
    console.log("Starting reply to message:", message);
    setReplyingTo(message);
  };

  // Cancel replying to a message
  const cancelReplyingToMessage = () => {
    setReplyingTo(null);
  };

  // Handle key press for sending messages
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  // Format timestamp for display
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    
    const date = new Date(timestamp);
    const now = new Date();
    const isToday = date.toDateString() === now.toDateString();
    
    if (isToday) {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else {
      return date.toLocaleDateString([], { 
        month: 'short', 
        day: 'numeric', 
        hour: '2-digit', 
        minute: '2-digit' 
      });
    }
  };

  // Improved getUserDisplayName function
  const getUserDisplayName = (userId) => {
    // Special case for current user
    if (userId === (currentUser?.id || currentUser?.user_id)) {
      return 'You';
    }
    
    if (!userId || !chatRoom || !chatRoom.participants) {
      return 'Unknown User';
    }
    
    // Loop through participants to find the matching user
    for (const participant of chatRoom.participants) {
      // Check different possible structures for finding the user ID
      const participantUserId = participant.user?.id || participant.id || null;
      
      if (participantUserId && participantUserId.toString() === userId.toString()) {
        // Found the user, now get their name
        const user = participant.user || participant;
        
        // Check if first_name and last_name exist
        if (user.first_name || user.last_name) {
          return `${user.first_name || ''} ${user.last_name || ''}`.trim();
        }
        
        // Fall back to username if available
        if (user.username) {
          return user.username;
        }
        
        // Last resort - return just the ID
        return `User ${userId}`;
      }
    }
    
    // For debugging purposes, log the participants and the userId
    console.log('Could not find user with ID:', userId);
    console.log('Available participants:', chatRoom.participants);
    
    // For users not found in participants
    return `User ${userId}`;
  };

  // Render message content with proper formatting
  const renderMessageContent = (message) => {
    console.log("Rendering message content for:", message.id);
    console.log("Message reactions:", message.reactions);
    
    if (message.is_deleted) {
      return (
        <Typography 
          variant="body2" 
          sx={{ 
            fontStyle: 'italic',
            color: darkMode ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'
          }}
        >
          This message was deleted
        </Typography>
      );
    }
    
    return (
      <>
        {/* Show reply reference if this is a reply */}
        {message.parent_message_details && (
          <Box
            sx={{
              borderLeft: '2px solid',
              borderColor: darkMode ? 'primary.light' : 'primary.main',
              pl: 1,
              ml: 1,
              mb: 1,
              fontSize: '0.85rem',
              color: darkMode ? 'rgba(255,255,255,0.7)' : 'rgb(255, 255, 255)'
            }}
          >
            <Typography variant="caption" sx={{ fontWeight: 'medium' }}>
              Reply to {message.parent_message_details.sender_name}:
            </Typography>
            <Typography variant="caption" sx={{ display: 'block' }} noWrap>
              {message.parent_message_details.content}
              {message.parent_message_details.has_attachments && (
                <span style={{ marginLeft: '4px' }}> 
                  <Paperclip size={12} style={{ verticalAlign: 'middle' }} />
                  {message.parent_message_details.attachments && message.parent_message_details.attachments.length > 0 
                    ? message.parent_message_details.attachments.map(att => att.file_name).join(', ')
                    : 'Attachment'}
                </span>
              )}
            </Typography>
          </Box>
        )}
        
        {/* Actual message content */}
        <Typography variant="body1">
          {message.content}
        </Typography>
        
        {/* Show edited indicator */}
        {message.is_edited && (
          <Typography 
            variant="caption"
            sx={{ 
              display: 'inline-block',
              ml: 1,
              color: darkMode ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'
            }}
          >
            (edited)
          </Typography>
        )}

        {/* Show attachments if any */}
        {message.attachments && message.attachments.length > 0 && (
          <Box sx={{ mt: 1 }}>
            {message.attachments.map(attachment => (
              <Box 
                key={attachment.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: 1,
                  border: '1px solid',
                  borderColor: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
                  borderRadius: 1,
                  mb: 1
                }}
              >
                <Box sx={{ mr: 1, maxWidth: '100%' }}>
                  {attachment.is_image ? (
                    <Box sx={{ position: 'relative' }}>
                      <img 
                        src={attachment.file_url} 
                        alt={attachment.file_name}
                        style={{ 
                          maxWidth: '100%', 
                          maxHeight: 150,
                          borderRadius: 4,
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          // For images, create a download link
                          const link = document.createElement('a');
                          link.href = attachment.file_url;
                          link.setAttribute('download', attachment.file_name);
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        }}
                      />
                    </Box>
                  ) : (
                    <Box
                      onClick={() => {
                        // For non-images, use the download endpoint
                        const baseUrl = 'https://admin.aitomotivelab.com';
                        const downloadUrl = `${baseUrl}/company-chat/api/attachments/${attachment.id}/download/`;
                        
                        // Create a temporary link element for download
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        
                        // Add token to download URL
                        const token = localStorage.getItem('token');
                        if (token) {
                          link.href = `${downloadUrl}?token=${token}`;
                        }
                        
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                      }}
                      sx={{
                        cursor: 'pointer',
                        textDecoration: 'none',
                        color: darkMode ? '#fff' : 'inherit',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <Paperclip size={16} />
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        {attachment.file_name}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        )}
        
        {/* Show reactions if any - with better error handling and debug info */}
        {message.reactions && message.reactions.length > 0 && (
          <>
            {console.log("Found reactions to render:", message.reactions)}
            <div style={{ 
              display: 'flex', 
              flexWrap: 'wrap',
              marginTop: '8px',
              gap: '4px'
            }}>
              {Object.entries(
                message.reactions.reduce((acc, reaction) => {
                  console.log("Processing reaction in UI:", reaction);
                  // Handle different possible reaction formats
                  const emoji = reaction.emoji || reaction.content || reaction;
                  acc[emoji] = (acc[emoji] || 0) + 1;
                  return acc;
                }, {})
              ).map(([emoji, count]) => (
                <span 
                  key={emoji}
                  style={{
                    backgroundColor: 'rgba(255,255,255,0.3)',
                    borderRadius: '12px',
                    padding: '4px 8px',
                    fontSize: '0.85rem',
                    marginRight: '4px',
                    marginBottom: '4px',
                    display: 'inline-flex',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleReaction(message.id, emoji)}
                >
                  {emoji} {count}
                </span>
              ))}
            </div>
          </>
        )}
      </>
    );
  };

  // Function to toggle participant in group chat
  const toggleParticipant = (user) => {
    if (!chatRoom || !chatRoom.is_group) return;
    
    const newParticipants = chatRoom.participants.some(p => p.id === user.id)
      ? chatRoom.participants.filter(p => p.id !== user.id)
      : [...chatRoom.participants, { id: user.id, user }];
    
    setSelectedParticipants(newParticipants.map(p => p.user));
  };

  // Function to handle adding participants to group chat
  const handleAddParticipantsToGroup = async () => {
    if (selectedParticipants.length === 0) return;
    
    console.log("Adding participants to group:", selectedParticipants);
    setIsAddingParticipants(true);
    setErrorMessage(""); // Clear previous error messages
    
    try {
      const token = localStorage.getItem('token');
      const baseUrl = 'https://admin.aitomotivelab.com';
      
      // Get the participant IDs
      const participantIds = selectedParticipants.map(user => user.id);
      
      // Fix the API endpoint format to match what the backend expects
      const response = await axios.post(
        `${baseUrl}/company-chat/api/chat-rooms/${chatRoom.id}/participants/`,
        { user_ids: participantIds },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      console.log("Participants added successfully:", response.data);
      
      // Clear selected participants
      setSelectedParticipants([]);
      
      // Close the dialog
      setIsAddParticipantsDialogOpen(false);
      
      // Force refresh the chat room data immediately
      await refreshChatRoom();
      
      // Show a success message instead of an error
      setSuccessMessage("Participants added successfully");
      setTimeout(() => setSuccessMessage(""), 3000); // Clear after 3 seconds
      
    } catch (error) {
      console.error("Error adding participants:", error);
      setErrorMessage(error.response?.data?.message || "Failed to add participants");
    } finally {
      setIsAddingParticipants(false);
    }
  };

  // Helper function to format file size
  const formatFileSize = (fileSize) => {
    if (fileSize === undefined || isNaN(fileSize)) {
      return '(Unknown size)';
    }
    
    if (fileSize < 1024) {
      return `${fileSize} bytes`;
    } else if (fileSize < 1024 * 1024) {
      return `${(fileSize / 1024).toFixed(1)} KB`;
    } else if (fileSize < 1024 * 1024 * 1024) {
      return `${(fileSize / (1024 * 1024)).toFixed(1)} MB`;
    } else {
      return `${(fileSize / (1024 * 1024 * 1024)).toFixed(1)} GB`;
    }
  };

  // Update the isCurrentUser logic to handle undefined sender_id for new messages
  const isCurrentUser = (message) => {
    // If sender_id is undefined, this is likely a new message from the current user
    if (message.sender_id === undefined) {
      console.log('Message with undefined sender_id - assuming it is from current user');
      return true;
    }
    
    const currentUserId = currentUser?.id || currentUser?.user_id;
    const senderIdString = String(message.sender_id);
    const currentUserIdString = String(currentUserId);
    
    console.log(`Comparing sender_id: ${senderIdString} (${typeof message.sender_id}) with currentUserId: ${currentUserIdString} (${typeof currentUserId})`);
    
    return senderIdString === currentUserIdString;
  };

  const refreshChatRoom = async () => {
    if (!chatRoom?.id) return;
    
    try {
      console.log("Refreshing chat room data for room ID:", chatRoom.id);
      const token = localStorage.getItem('token');
      const baseUrl = 'https://admin.aitomotivelab.com';
      
      const response = await axios.get(
        `${baseUrl}/company-chat/api/chat-rooms/${chatRoom.id}/`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      console.log("Updated chat room data received:", response.data);
      
      // Update the chat room data
      setChatRoom(response.data);
      
      return response.data;
    } catch (error) {
      console.error("Error refreshing chat room data:", error);
      // Don't set an error message here to avoid confusing the user
      return null;
    }
  };

  // New function to handle removing a participant
  const handleRemoveParticipant = async (participantId) => {
    if (!chatRoom?.id || !participantId) return;
    
    setIsRemovingParticipant(true);
    setErrorMessage(""); // Clear previous errors
    
    try {
      const token = localStorage.getItem('token');
      const baseUrl = 'https://admin.aitomotivelab.com';
      
      // Make DELETE request to remove participant
      const response = await axios.delete(
        `${baseUrl}/company-chat/api/chat-rooms/${chatRoom.id}/participants/${participantId}/`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      console.log("Participant removed successfully:", response.data);
      
      // Refresh chat room data to update participants list
      await refreshChatRoom();
      
      // Show success message
      setSuccessMessage("Participant removed successfully");
      setTimeout(() => setSuccessMessage(""), 3000);
      
    } catch (error) {
      console.error("Error removing participant:", error);
      setErrorMessage(error.response?.data?.message || "Failed to remove participant");
    } finally {
      setIsRemovingParticipant(false);
    }
  };

  // Add function to handle group deletion
  const handleDeleteGroup = async () => {
    if (!chatRoom?.id) return;
    
    setDeletingGroup(true);
    setErrorMessage("");
    
    try {
      const token = localStorage.getItem('token');
      const baseUrl = 'https://admin.aitomotivelab.com';
      
      // Make DELETE request to remove the entire chat room
      await axios.delete(
        `${baseUrl}/company-chat/api/chat-rooms/${chatRoom.id}/`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      console.log("Group chat deleted successfully");
      
      // Close dialogs
      setDeleteGroupDialogOpen(false);
      setShowParticipantsDialog(false);
      
      // Show success message
      setSuccessMessage("Group chat deleted successfully");
      
      // Go back to conversations list
      if (onBack) {
        setTimeout(() => {
          onBack();
        }, 500);
      }
      
    } catch (error) {
      console.error("Error deleting group chat:", error);
      setErrorMessage(error.response?.data?.message || "Failed to delete group chat");
    } finally {
      setDeletingGroup(false);
    }
  };

  if (wsLoading || loadingRoom) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          height: '100%',
          flexDirection: 'column',
          p: 3
        }}
      >
        <CircularProgress />
        <Typography variant="body2" sx={{ mt: 2 }}>
          Loading messages...
        </Typography>
        {errorMessage && (
          <Typography variant="body2" color="error" sx={{ mt: 1 }}>
            {errorMessage}
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: darkMode ? '#242526' : '#fff',
        position: 'relative',
        width: '100%'
      }}
    >
      {/* Chat header */}
      <Box
        sx={{
          p: 2,
          borderBottom: '1px solid',
          borderColor: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: darkMode ? '#323436' : '#f5f5f5'
        }}
      >
        {!isPopout && (
          <IconButton 
            onClick={onBack} 
            edge="start" 
            sx={{ mr: 1, display: { xs: 'flex', sm: 'none' } }}
          >
            <ArrowLeft />
          </IconButton>
        )}
        
        <Avatar
          sx={{ mr: 2 }}
        >
          {selectedUser.first_name?.charAt(0) || selectedUser.username?.charAt(0) || '?'}
        </Avatar>
        
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6" component="div">
            {selectedUser.first_name && selectedUser.last_name 
              ? `${selectedUser.first_name} ${selectedUser.last_name}` 
              : selectedUser.username}
          </Typography>
          
          <Typography variant="caption" color={darkMode ? 'rgba(255,255,255,0.6)' : 'text.secondary'}>
            {connectionStatus === 'connected' 
              ? chatRoom?.is_group ? 'Group chat' : 'Online'
              : connectionStatus === 'connecting' 
                ? 'Connecting...' 
                : 'Offline'}
          </Typography>
        </Box>
        
        {/* Action buttons */}
        <Box sx={{ display: 'flex' }}>
          {/* View participants button (for group chats) */}
          {chatRoom?.is_group && (
            <Tooltip title="View participants">
              <IconButton onClick={() => setShowParticipantsDialog(true)}>
                <Users size={20} />
              </IconButton>
            </Tooltip>
          )}
          
          {/* Add users button (for group chats) */}
          {chatRoom?.is_group && isCurrentUserAdmin && (
            <Tooltip title="Add participants">
              <IconButton onClick={() => {
                loadUsers();
                setIsAddParticipantsDialogOpen(true);
              }}>
                <UserPlus size={20} />
              </IconButton>
            </Tooltip>
          )}
          
          {/* Delete group button (for admins only) */}
          {chatRoom?.is_group && isCurrentUserAdmin && (
            <Tooltip title="Delete group">
              <IconButton 
                onClick={() => setDeleteGroupDialogOpen(true)}
                sx={{ color: 'error.main' }}
              >
                <Trash2 size={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>

      {/* Messages container */}
      <Box
        sx={{
          flexGrow: 1,
          overflow: 'auto',
          p: 2,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {wsMessages.length === 0 ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              color: darkMode ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'
            }}
          >
            <Typography variant="body1">
              No messages yet. Start the conversation!
            </Typography>
          </Box>
        ) : (
          wsMessages.map((message, index) => {
            // Log the entire message object to see all available data
            console.log("Message object:", message);
            console.log("Reactions data:", message.reactions);
            
            const messageIsFromCurrentUser = isCurrentUser(message);
            const previousMessage = index > 0 ? wsMessages[index - 1] : null;
            const showSenderInfo = true; // Always show sender info
            
            return (
              <Box
                key={message.id}
                sx={{
                  mb: 2,
                  display: 'flex',
                  flexDirection: messageIsFromCurrentUser ? 'row-reverse' : 'row',
                  alignItems: 'flex-end',
                }}
              >
                {/* Always show sender name */}
                <Typography 
                  variant="caption" 
                  sx={{ 
                    ml: 1, 
                    mb: 0.5,
                    fontWeight: 'medium',
                    color: messageIsFromCurrentUser
                      ? (darkMode ? '#ff6b6b' : '#d32f2f') // Red for current user
                      : (darkMode ? '#4fc3f7' : '#2196f3'), // Blue for others
                  }}
                >
                  {messageIsFromCurrentUser ? 'You' : getUserDisplayName(message.sender_id)}
                </Typography>
                
                {/* User avatar */}
                {!messageIsFromCurrentUser && showSenderInfo && (
                  <Avatar
                    sx={{
                      width: 28,
                      height: 28,
                      mr: 1,
                      fontSize: '0.8rem'
                    }}
                  >
                    {getUserDisplayName(message.sender_id).charAt(0)}
                  </Avatar>
                )}
                
                {/* Message bubble */}
                <div
                  style={{
                    backgroundColor: messageIsFromCurrentUser ? 'blue' : 'grey',
                    color: 'white',
                    borderRadius: '20px',
                    padding: '15px',
                    maxWidth: '100%',
                    position: 'relative',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.2)'
                  }}
                >
                  {/* Message content */}
                  {renderMessageContent(message)}
                  
                  {/* Message timestamp */}
                  <Typography 
                    variant="caption" 
                    style={{ 
                      display: 'block',
                      textAlign: 'right',
                      marginTop: '4px',
                      fontSize: '0.7rem',
                      color: 'white',
                    }}
                  >
                    {formatTimestamp(message.timestamp)}
                  </Typography>
                </div>
                
                {/* Message actions - reply and emoji reaction buttons */}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    ml: messageIsFromCurrentUser ? 0 : 0.5,
                    mr: messageIsFromCurrentUser ? 0.5 : 0,
                  }}
                >
                  {/* Reply button - added above emoji button */}
                  <IconButton 
                    size="small" 
                    sx={{ 
                      mb: 0.5, 
                      bgcolor: darkMode ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.9)',
                      boxShadow: 1,
                      '&:hover': { 
                        bgcolor: darkMode ? 'rgba(0,0,0,0.5)' : 'rgba(255,255,255,1)' 
                      }
                    }}
                    onClick={() => startReplyingToMessage(message)}
                  >
                    <Reply size={16} />
                  </IconButton>
                  
                  {/* Existing emoji button */}
                  <IconButton 
                    size="small" 
                    sx={{ 
                      mb: 0.5, 
                      bgcolor: darkMode ? 'rgba(0,0,0,0.3)' : 'rgba(255,255,255,0.9)',
                      boxShadow: 1,
                      '&:hover': { 
                        bgcolor: darkMode ? 'rgba(0,0,0,0.5)' : 'rgba(255,255,255,1)' 
                      }
                    }}
                    onClick={(e) => handleOpenEmojiMenu(e, message)}
                  >
                    <Smile size={16} />
                  </IconButton>
                </Box>
              </Box>
            );
          })
        )}
        <div ref={messagesEndRef} />
      </Box>

      {/* Compose new message */}
      <Box
        sx={{
          p: 2,
          borderTop: '1px solid',
          borderColor: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)',
          backgroundColor: darkMode ? '#323436' : '#f5f5f5'
        }}
      >
        {/* Editing indicator */}
        {editingMessage && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 1,
              py: 1,
              px: 2,
              backgroundColor: darkMode ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.03)',
              borderRadius: 1
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Edit size={16} style={{ marginRight: 8 }} />
              <Typography variant="body2">
                Editing message
              </Typography>
            </Box>
            <IconButton size="small" onClick={cancelEditingMessage}>
              <X size={16} />
            </IconButton>
          </Box>
        )}
        
        {/* Reply indicator */}
        {replyingTo && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 1,
              py: 1,
              px: 2,
              backgroundColor: darkMode ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.03)',
              borderRadius: 1
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Reply size={16} style={{ marginRight: 8 }} />
              <Typography variant="body2">
                Replying to{' '}
                <Typography component="span" fontWeight="medium">
                  {getUserDisplayName(replyingTo.sender_id)}
                </Typography>: {replyingTo.content.substring(0, 30)}{replyingTo.content.length > 30 ? '...' : ''}
              </Typography>
            </Box>
            <IconButton size="small" onClick={cancelReplyingToMessage}>
              <X size={16} />
            </IconButton>
          </Box>
        )}
        
        {/* Pending attachments */}
        {pendingAttachments.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 1,
              mb: 1
            }}
          >
            {pendingAttachments.map(attachment => (
              <Chip
                key={attachment.id}
                label={attachment.name}
                onDelete={() => removePendingAttachment(attachment.id)}
                size="small"
                sx={{
                  backgroundColor: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)'
                }}
              />
            ))}
          </Box>
        )}
        
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileSelect}
            style={{ display: 'none' }}
            multiple
          />
          <IconButton 
            color="primary" 
            onClick={() => fileInputRef.current?.click()}
          >
            <Paperclip />
          </IconButton>
          
          <TextField
            fullWidth
            placeholder="Type a message..."
            variant="outlined"
            size="small"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            multiline
            maxRows={4}
            sx={{
              mx: 1,
              backgroundColor: darkMode ? 'rgba(255,255,255,0.05)' : '#fff',
              '& .MuiOutlinedInput-root': {
                borderRadius: 3
              }
            }}
            InputProps={{
              sx: { 
                px: 2, 
                py: 1
              }
            }}
          />
          
          <IconButton 
            color="primary"
            onClick={handleSendMessage}
            disabled={newMessage.trim() === '' && pendingAttachments.length === 0}
          >
            <Send />
          </IconButton>
        </Box>
      </Box>

      {/* Emoji reaction menu */}
      <Menu
        anchorEl={emojiMenuAnchor}
        open={Boolean(emojiMenuAnchor)}
        onClose={handleCloseEmojiMenu}
      >
        <Box 
          sx={{ 
            display: 'flex', 
            flexWrap: 'wrap',
            width: 200,
            p: 1
          }}
        >
          {commonEmojis.map(emoji => (
            <Box 
              key={emoji}
              sx={{
                p: 0.5,
                fontSize: '1.5rem',
                cursor: 'pointer',
                borderRadius: '50%',
                '&:hover': {
                  backgroundColor: darkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)'
                }
              }}
              onClick={() => handleReaction(selectedMessage?.id, emoji)}
            >
              {emoji}
            </Box>
          ))}
        </Box>
      </Menu>

      {/* Group chat dialog */}
      <Dialog
        open={isGroupChatDialogOpen}
        onClose={() => setIsGroupChatDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Create Group Chat</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Group Name"
            fullWidth
            variant="outlined"
            value={groupChatName}
            onChange={(e) => setGroupChatName(e.target.value)}
            sx={{ mb: 2 }}
          />
          
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Selected Participants ({selectedParticipants.length})
          </Typography>
          
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 3 }}>
            {selectedParticipants.length === 0 ? (
              <Typography variant="body2" color="text.secondary">
                No participants selected
              </Typography>
            ) : (
              selectedParticipants.map(user => (
                <Chip
                  key={user.id}
                  avatar={<Avatar>{user.first_name?.charAt(0) || '?'}</Avatar>}
                  label={`${user.first_name} ${user.last_name}`}
                  onDelete={() => toggleParticipant(user)}
                  sx={{ mb: 1 }}
                />
              ))
            )}
          </Box>
          
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Available Users
          </Typography>
          
          {loadingUsers ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
              <CircularProgress size={24} />
            </Box>
          ) : (
            <List sx={{ maxHeight: 300, overflow: 'auto' }}>
              {availableUsers
                .filter(user => {
                  // Filter out users who are already participants
                  // Make sure we handle both direct participants and nested participant structures
                  if (!chatRoom || !chatRoom.participants) return true;
                  
                  return !chatRoom.participants.some(p => {
                    // Check if p is a user object directly
                    if (p.id === user.id) return true;
                    // Check if p has a nested user object
                    if (p.user && p.user.id === user.id) return true;
                    return false;
                  });
                })
                .map(user => (
                  <ListItem 
                    key={user.id}
                    button
                    onClick={() => toggleParticipant(user)}
                    selected={selectedParticipants.some(p => p.id === user.id)}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        {user.first_name?.charAt(0) || user.username?.charAt(0) || '?'}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText 
                      primary={`${user.first_name || ''} ${user.last_name || ''}`} 
                      secondary={user.email || user.username}
                    />
                  </ListItem>
                ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsGroupChatDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleAddParticipantsToGroup}
            variant="contained"
            disabled={selectedParticipants.length === 0 || isAddingParticipants}
          >
            {isAddingParticipants ? <CircularProgress size={24} /> : 'Add to Group'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add participants to group dialog */}
      <Dialog
        open={isAddParticipantsDialogOpen}
        onClose={() => setIsAddParticipantsDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add Participants</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Selected Participants
          </Typography>
          
          <Box sx={{ mb: 2 }}>
            {selectedParticipants.map(user => (
              <Chip
                key={user.id}
                avatar={<Avatar>{user.first_name?.charAt(0) || '?'}</Avatar>}
                label={`${user.first_name} ${user.last_name}`}
                onDelete={() => toggleParticipant(user)}
                sx={{ mr: 1, mb: 1 }}
              />
            ))}
          </Box>
          
          <Typography variant="subtitle2" sx={{ mb: 1 }}>
            Available Users
          </Typography>
          
          {loadingUsers ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
              <CircularProgress size={24} />
            </Box>
          ) : (
            <List sx={{ maxHeight: 300, overflow: 'auto' }}>
              {availableUsers
                .filter(user => {
                  // Filter out users who are already participants
                  // Make sure we handle both direct participants and nested participant structures
                  if (!chatRoom || !chatRoom.participants) return true;
                  
                  return !chatRoom.participants.some(p => {
                    // Check if p is a user object directly
                    if (p.id === user.id) return true;
                    // Check if p has a nested user object
                    if (p.user && p.user.id === user.id) return true;
                    return false;
                  });
                })
                .map(user => (
                  <ListItem 
                    key={user.id}
                    button
                    onClick={() => toggleParticipant(user)}
                    selected={selectedParticipants.some(p => p.id === user.id)}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        {user.first_name?.charAt(0) || user.username?.charAt(0) || '?'}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText 
                      primary={`${user.first_name || ''} ${user.last_name || ''}`} 
                      secondary={user.email || user.username}
                    />
                  </ListItem>
                ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddParticipantsDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleAddParticipantsToGroup}
            variant="contained"
            disabled={selectedParticipants.length === 0 || isAddingParticipants}
          >
            {isAddingParticipants ? <CircularProgress size={24} /> : 'Add to Group'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Participants dialog */}
      <Dialog
        open={showParticipantsDialog}
        onClose={() => setShowParticipantsDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Group Participants
          <IconButton
            size="small"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
            onClick={() => refreshChatRoom()}
          >
            <RefreshCw size={16} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {!chatRoom?.participants ? (
            <CircularProgress />
          ) : chatRoom.participants.length > 0 ? (
            <List>
              {chatRoom.participants.map(participant => {
                const user = participant.user || participant;
                const participantId = user.id;
                const currentUserId = currentUser?.id || currentUser?.user_id;
                const isSelf = participantId === currentUserId;
                
                return (
                  <ListItem 
                    key={participantId}
                    secondaryAction={
                      isCurrentUserAdmin && !isSelf ? (
                        <Tooltip title="Remove from group">
                          <IconButton 
                            edge="end" 
                            aria-label="remove" 
                            onClick={() => handleRemoveParticipant(participantId)}
                            disabled={isRemovingParticipant}
                          >
                            <X size={18} />
                          </IconButton>
                        </Tooltip>
                      ) : null
                    }
                  >
                    <ListItemAvatar>
                      <Avatar>
                        {user.first_name?.charAt(0) || user.username?.charAt(0) || '?'}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography sx={{ mr: 1 }}>
                            {`${user.first_name || ''} ${user.last_name || ''}`.trim() || user.username || 'Unknown User'}
                          </Typography>
                          {isSelf && (
                            <Chip size="small" label="You" variant="outlined" sx={{ mr: 1 }} />
                          )}
                          {participant.is_admin && (
                            <Chip
                              size="small"
                              label="Admin"
                              color="primary"
                              variant="outlined"
                            />
                          )}
                        </Box>
                      }
                      secondary={user.email || user.username}
                    />
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <Typography variant="body2" sx={{ p: 2, textAlign: 'center' }}>
              No participants found
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowParticipantsDialog(false)}>Close</Button>
          
          {/* Add Delete Group button for admins */}
          {isCurrentUserAdmin && (
            <Button 
              color="error"
              startIcon={<Trash2 size={16} />}
              onClick={() => {
                setShowParticipantsDialog(false);
                setDeleteGroupDialogOpen(true);
              }}
            >
              Delete Group
            </Button>
          )}
        </DialogActions>
      </Dialog>
      
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteGroupDialogOpen}
        onClose={() => setDeleteGroupDialogOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Delete Group Chat</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this group chat? This action cannot be undone and all messages will be permanently deleted.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setDeleteGroupDialogOpen(false)}
            disabled={deletingGroup}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleDeleteGroup}
            color="error"
            variant="contained"
            disabled={deletingGroup}
            startIcon={deletingGroup ? <CircularProgress size={16} /> : <Trash2 size={16} />}
          >
            {deletingGroup ? "Deleting..." : "Delete Group"}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Error message snackbar */}
      <Snackbar 
        open={!!errorMessage} 
        autoHideDuration={6000} 
        onClose={() => setErrorMessage('')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      {/* Success message notification */}
      {successMessage && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 20,
            left: '50%',
            transform: 'translateX(-50%)',
            bgcolor: 'success.main',
            color: 'white',
            py: 1,
            px: 3,
            borderRadius: 1,
            boxShadow: 2,
            zIndex: 2000
          }}
        >
          <Typography>{successMessage}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default MessageList; 
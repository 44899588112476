import axios from 'axios';
import qs from 'qs';

const API_URL = 'https://admin.aitomotivelab.com/user_management/api/';

// Helper to get our auth headers.
const getAuthHeaders = () => ({
  Authorization: `Bearer ${localStorage.getItem('token')}`
});

// Branch Definitions API
export const fetchBranchDefinitions = async (companyId) => {
  const response = await axios.get(`${API_URL}branch-definitions/`, {
    params: { company: companyId },
    headers: getAuthHeaders()
  });
  console.log(response.data);
  return response.data;
};

export const createBranchDefinition = async (branchDefinitionData) => {
  const response = await axios.post(`${API_URL}branch-definitions/`, branchDefinitionData, {
    headers: getAuthHeaders()
  });
  return response.data;
};

export const updateBranchDefinition = async (id, branchDefinitionData) => {
  const response = await axios.put(`${API_URL}branch-definitions/${id}/`, branchDefinitionData, {
    headers: getAuthHeaders()
  });
  return response.data;
};

export const deleteBranchDefinition = async (id) => {
  await axios.delete(`${API_URL}branch-definitions/${id}/`, {
    headers: getAuthHeaders()
  });
};

export const fetchBranchesByID = async (branchids) => {
  console.log(branchids);
  const response = await axios.get(`${API_URL}branches/`, {
    params: { 
      id__in: branchids.join(',')
    },
    headers: getAuthHeaders()
  });
  console.log(response.data);
  return response.data;
};

// Branches API
export const fetchBranches = async (branchDefinitionId, companyId) => {
  const response = await axios.get(`${API_URL}branches/`, {
    params: { 
      branch_definition: branchDefinitionId,
      company: companyId
    },
    headers: getAuthHeaders()
  });
  console.log(response.data);
  return response.data;
};

export const createBranch = async (branchData) => {
  const response = await axios.post(`${API_URL}branches/`, branchData, {
    headers: getAuthHeaders()
  });
  return response.data;
};

export const createBranchesForDefinition = async (branchDefinitionId, values, companyId) => {
  const promises = values.map(value => 
    createBranch({
      branch_definition: branchDefinitionId,
      value: value,
      company: companyId
    })
  );
  const responses = await Promise.all(promises);
  return responses;
};

export const updateBranch = async (id, branchData) => {
  const response = await axios.put(`${API_URL}branches/${id}/`, branchData, {
    headers: getAuthHeaders()
  });
  return response.data;
};

export const deleteBranch = async (id) => {
  await axios.delete(`${API_URL}branches/${id}/`, {
    headers: getAuthHeaders()
  });
}; 
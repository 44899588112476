import React, { useState, useEffect } from 'react';
import {
  TextField, Button, Grid, Card, CardContent, Typography,
  CircularProgress, Alert, Dialog, DialogTitle, DialogContent,
  DialogActions, List, ListItem, ListItemText, Checkbox, Chip, Box,
  Paper, Divider, IconButton, InputAdornment, Tabs, Tab, FormControl,
  InputLabel, Select, MenuItem
} from '@mui/material';
import { Search, Add, Close } from '@mui/icons-material';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://admin.aitomotivelab.com';

const SimilarModelsManager = () => {
  const [models, setModels] = useState([]);
  const [filteredModels, setFilteredModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [similarModelInput, setSimilarModelInput] = useState('');
  const [modelSearchTerm, setModelSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modelDialogOpen, setModelDialogOpen] = useState(false);
  const [availableModels, setAvailableModels] = useState([]);
  const [priorityValue, setPriorityValue] = useState(100);
  const [activeTab, setActiveTab] = useState(0);
  
  useEffect(() => {
    fetchModels();
  }, []);
  
  // Filter models when search term changes
  useEffect(() => {
    if (modelSearchTerm.trim() === '') {
      setFilteredModels(models);
    } else {
      const filtered = models.filter(model => 
        model.model.toLowerCase().includes(modelSearchTerm.toLowerCase())
      );
      setFilteredModels(filtered);
    }
  }, [modelSearchTerm, models]);

  const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    return {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    };
  };

  const fetchModels = async () => {
    try {
      setLoading(true);
      console.log("Fetching models from API...");
      
      const response = await fetch(`${API_BASE_URL}/car_stock/api/model-priorities/`, {
        headers: getAuthHeaders(),
      });
      
      if (!response.ok) throw new Error(`Failed to fetch models: ${response.status} ${response.statusText}`);
      
      const data = await response.json();
      console.log("API Response data:", data);
      
      setModels(data);
      setFilteredModels(data);
    } catch (error) {
      console.error("Error in fetchModels:", error);
      setError(`Error fetching models: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectModel = (model) => {
    console.log("Selected model:", model);
    
    // Create a copy of the model to work with
    const selectedModelCopy = { ...model };
    
    // Parse similar_models from string to array
    if (typeof selectedModelCopy.similar_models === 'string') {
      // Split the string by commas and trim each item
      selectedModelCopy.similar_models = selectedModelCopy.similar_models
        .split(',')
        .map(item => item.trim())
        .filter(item => item); // Remove any empty strings
    } else if (!selectedModelCopy.similar_models) {
      // Initialize as empty array if undefined or null
      selectedModelCopy.similar_models = [];
    } else if (!Array.isArray(selectedModelCopy.similar_models)) {
      // Handle any other case by defaulting to empty array
      selectedModelCopy.similar_models = [];
    }
    
    setPriorityValue(selectedModelCopy.priority || 100);
    setSelectedModel(selectedModelCopy);
  };

  const handleAddSimilarModel = async () => {
    if (!selectedModel || !similarModelInput.trim()) {
      setError('Both model and similar model must be selected/entered');
      return;
    }

    try {
      console.log("Adding similar model:", similarModelInput);
      
      // Get the current similar models as array
      const currentSimilarModels = selectedModel.similar_models || [];
      
      // Check if model already exists
      if (currentSimilarModels.includes(similarModelInput.trim())) {
        setError('This model is already in the similar models list');
        return;
      }
      
      // Add the new model
      const updatedSimilarModels = [...currentSimilarModels, similarModelInput.trim()];
      
      // Convert back to comma-separated string for API
      const similarModelsString = updatedSimilarModels.join(', ');
      
      const requestBody = {
        model: selectedModel.model,
        priority: priorityValue,
        similar_models: similarModelsString // Send as string to API
      };
      
      const response = await fetch(`${API_BASE_URL}/car_stock/api/model-priorities/${selectedModel.id}/`, {
        method: 'PUT',
        headers: getAuthHeaders(),
        body: JSON.stringify(requestBody),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || `Failed to update similar models: ${response.status}`);
      }
      
      // Update the models state
      fetchModels();
      setSimilarModelInput('');
      
      // Update the selected model with the new similar models
      setSelectedModel({
        ...selectedModel,
        similar_models: updatedSimilarModels, // Keep as array in state
        priority: priorityValue
      });
    } catch (error) {
      console.error("Error in handleAddSimilarModel:", error);
      setError(`Error adding similar model: ${error.message}`);
    }
  };

  const handleUpdatePriority = async () => {
    if (!selectedModel) return;

    try {
      console.log("Updating priority to:", priorityValue);
      
      // Get the current similar models as string (preserving the format)
      const similarModelsString = selectedModel.similar_models && Array.isArray(selectedModel.similar_models)
        ? selectedModel.similar_models.join(', ')
        : selectedModel.similar_models || '';
      
      const requestBody = {
        model: selectedModel.model,
        priority: priorityValue,
        similar_models: similarModelsString
      };
      
      const response = await fetch(`${API_BASE_URL}/car_stock/api/model-priorities/${selectedModel.id}/`, {
        method: 'PUT',
        headers: getAuthHeaders(),
        body: JSON.stringify(requestBody),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || `Failed to update priority: ${response.status}`);
      }
      
      // Update the models state
      fetchModels();
      
      // Update the selected model with the new priority
      setSelectedModel({
        ...selectedModel,
        priority: priorityValue
      });
    } catch (error) {
      console.error("Error in handleUpdatePriority:", error);
      setError(`Error updating priority: ${error.message}`);
    }
  };

  const handleRemoveSimilarModel = async (modelToRemove) => {
    if (!selectedModel) return;
    
    try {
      console.log("Removing similar model:", modelToRemove);
      
      // Get the current similar models as array
      const currentSimilarModels = selectedModel.similar_models || [];
      
      // Remove the specified model
      const updatedSimilarModels = currentSimilarModels.filter(
        model => model !== modelToRemove
      );
      
      // Convert back to comma-separated string for API
      const similarModelsString = updatedSimilarModels.join(', ');
      
      const requestBody = {
        model: selectedModel.model,
        priority: selectedModel.priority,
        similar_models: similarModelsString // Send as string to API
      };
      
      const response = await fetch(`${API_BASE_URL}/car_stock/api/model-priorities/${selectedModel.id}/`, {
        method: 'PUT',
        headers: getAuthHeaders(),
        body: JSON.stringify(requestBody),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || `Failed to update similar models: ${response.status}`);
      }
      
      fetchModels();
      
      // Update the selected model with the new similar models
      setSelectedModel({
        ...selectedModel,
        similar_models: updatedSimilarModels // Keep as array in state
      });
    } catch (error) {
      console.error("Error in handleRemoveSimilarModel:", error);
      setError(`Error removing similar model: ${error.message}`);
    }
  };

  const handleModelDialogOpen = async () => {
    try {
      console.log("Opening model dialog, fetching available models...");
      
      const response = await fetch(`${API_BASE_URL}/car_stock/api/available-models/`, {
        headers: getAuthHeaders(),
      });
      
      if (!response.ok) throw new Error(`Failed to fetch available models: ${response.status}`);
      
      const data = await response.json();
      
      setAvailableModels(data);
      setModelDialogOpen(true);
    } catch (error) {
      console.error("Error in handleModelDialogOpen:", error);
      setError(`Error fetching available models: ${error.message}`);
    }
  };

  const handleModelDialogClose = () => {
    setModelDialogOpen(false);
  };

  const handleSelectModelFromDialog = (model) => {
    setSimilarModelInput(model);
    setModelDialogOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && similarModelInput.trim()) {
      handleAddSimilarModel();
    }
  };

  // Function to clear error message
  const clearError = () => {
    setError(null);
  };

  if (loading && models.length === 0) return <CircularProgress />;

  return (
    <Grid container spacing={2} sx={{ height: '100%', p: 2 }}>
      {/* Left Panel - Model Selection */}
      <Grid item xs={12} md={4} sx={{ height: '100%' }}>
        <Paper sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h6" gutterBottom>
            Models
          </Typography>
          
          {/* Search Bar */}
          <TextField
            fullWidth
            placeholder="Search models..."
            variant="outlined"
            value={modelSearchTerm}
            onChange={(e) => setModelSearchTerm(e.target.value)}
            sx={{ mb: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: modelSearchTerm && (
                <InputAdornment position="end">
                  <IconButton onClick={() => setModelSearchTerm('')} edge="end">
                    <Close />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          
          {/* Model List */}
          <Box 
            sx={{ 
              flexGrow: 1, 
              overflow: 'auto',
              border: '1px solid #e0e0e0',
              borderRadius: 1,
              '& .MuiListItem-root': {
                borderBottom: '1px solid #f0f0f0'
              },
              '& .MuiListItem-root:last-child': {
                borderBottom: 'none'
              }
            }}
          >
            {filteredModels.length > 0 ? (
              <List disablePadding>
                {filteredModels.map((model) => (
                  <ListItem 
                    key={model.id}
                    button 
                    selected={selectedModel?.id === model.id}
                    onClick={() => handleSelectModel(model)}
                    sx={{
                      transition: 'background-color 0.2s',
                      '&.Mui-selected': {
                        bgcolor: 'primary.light',
                        color: 'primary.contrastText',
                        '&:hover': {
                          bgcolor: 'primary.light',
                        }
                      }
                    }}
                  >
                    <ListItemText 
                      primary={model.model}
                      secondary={`Priority: ${model.priority}`}
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography color="text.secondary">
                  {loading ? 'Loading models...' : 'No models found'}
                </Typography>
              </Box>
            )}
          </Box>
        </Paper>
      </Grid>
      
      {/* Right Panel - Similar Models Management */}
      <Grid item xs={12} md={8} sx={{ height: '100%' }}>
        <Paper sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
          {selectedModel ? (
            <>
              <Typography variant="h6" gutterBottom>
                {selectedModel.model.toUpperCase()} - Similar Models
              </Typography>
              
              {/* Priority Slider */}
              <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', gap: 2 }}>
                <Typography variant="subtitle2">
                  Priority:
                </Typography>
                <TextField
                  type="number"
                  value={priorityValue}
                  onChange={(e) => setPriorityValue(Number(e.target.value))}
                  inputProps={{ min: 1, max: 1000 }}
                  size="small"
                  sx={{ width: 100 }}
                />
                <Button 
                  variant="outlined" 
                  size="small"
                  onClick={handleUpdatePriority}
                >
                  Update Priority
                </Button>
              </Box>
              
              <Divider sx={{ mb: 2 }} />
              
              <Tabs 
                value={activeTab} 
                onChange={handleTabChange}
                sx={{ mb: 2 }}
              >
                <Tab label="View Similar Models" />
                <Tab label="Add Similar Models" />
              </Tabs>
              
              {activeTab === 0 ? (
                // Similar Models Display
                <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                  <Typography variant="subtitle2" gutterBottom>
                    This model is similar to:
                  </Typography>
                  {selectedModel.similar_models && selectedModel.similar_models.length > 0 ? (
                    <Box sx={{ 
                      display: 'flex', 
                      flexWrap: 'wrap', 
                      gap: 1, 
                      p: 2, 
                      bgcolor: '#f9f9f9',
                      borderRadius: 1,
                      maxHeight: '500px',
                      overflow: 'auto'
                    }}>
                      {selectedModel.similar_models.map((similarModel, index) => (
                        <Chip 
                          key={index} 
                          label={similarModel} 
                          onDelete={() => handleRemoveSimilarModel(similarModel)}
                          sx={{ 
                            bgcolor: '#ffffff',
                            boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
                          }}
                        />
                      ))}
                    </Box>
                  ) : (
                    <Box sx={{ p: 2, textAlign: 'center', bgcolor: '#f9f9f9', borderRadius: 1 }}>
                      <Typography color="text.secondary">
                        No similar models defined for this model
                      </Typography>
                    </Box>
                  )}
                </Box>
              ) : (
                // Add Similar Models Form
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Add a new similar model:
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                    <TextField
                      fullWidth
                      label="Similar Model Name"
                      value={similarModelInput}
                      onChange={(e) => setSimilarModelInput(e.target.value)}
                      onKeyPress={handleKeyPress}
                      placeholder="Type model name and press Enter"
                      helperText="Type a model name or use the browse button to select"
                    />
                    <Button 
                      variant="outlined" 
                      onClick={handleModelDialogOpen}
                      sx={{ minWidth: '120px' }}
                    >
                      Browse
                    </Button>
                    <Button 
                      variant="contained" 
                      color="primary"
                      onClick={handleAddSimilarModel}
                      disabled={!similarModelInput.trim()}
                      startIcon={<Add />}
                      sx={{ minWidth: '120px' }}
                    >
                      Add
                    </Button>
                  </Box>
                </Box>
              )}
            </>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <Typography variant="subtitle1" color="text.secondary">
                Select a model from the list to manage similar models
              </Typography>
            </Box>
          )}
        </Paper>
      </Grid>
      
      {/* Error Alert */}
      {error && (
        <Grid item xs={12}>
          <Alert 
            severity="error" 
            onClose={clearError}
            sx={{ mb: 2 }}
          >
            {error}
          </Alert>
        </Grid>
      )}
      
      {/* Dialog for selecting from available models */}
      <Dialog 
        open={modelDialogOpen} 
        onClose={handleModelDialogClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Typography variant="h6">Select a Model</Typography>
          <TextField
            autoFocus
            margin="dense"
            placeholder="Search available models..."
            fullWidth
            variant="outlined"
            value={modelSearchTerm}
            onChange={(e) => setModelSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
            sx={{ mt: 2 }}
          />
        </DialogTitle>
        <DialogContent dividers>
          <List sx={{ pt: 0 }}>
            {availableModels
              .filter(model => model.toLowerCase().includes(modelSearchTerm.toLowerCase()))
              .map((model) => (
                <ListItem 
                  button 
                  onClick={() => handleSelectModelFromDialog(model)}
                  key={model}
                >
                  <ListItemText primary={model} />
                </ListItem>
              ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModelDialogClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default SimilarModelsManager; 
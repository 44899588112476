import React from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  Grid,
  Button,
  CircularProgress
} from '@mui/material';
import { Grow, Zoom } from '@mui/material';
import {
  MapPin,
  Mail,
  Building,
  Edit,
  ChevronDown,
  Phone,
  Trash2
} from 'lucide-react';

function CompanyCard({ company, currentUser, onEdit }) {
  if (!company) return null;

  return (
    <Grow in={true} timeout={1000}>
      <Box
        sx={{
          bgcolor: 'background.paper',
          borderRadius: 4,
          overflow: 'hidden',
          boxShadow: '0 10px 40px rgba(0,0,0,0.12)',
          margin: 'auto',
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: 180,
            background: 'linear-gradient(135deg, #3a86ff 0%, #ff006e 100%)',
            zIndex: 0
          }
        }}
      >
        <Box
          sx={{
            height: 180,
            position: 'relative',
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {company.logo_url ? (
            <Zoom in={true} timeout={1500}>
              <Box
                component="img"
                src={company.logo_url}
                alt={`${company.name} logo`}
                sx={{
                  width: '50%',
                  height: '50%',
                  objectFit: 'contain',
                  filter: 'drop-shadow(0px 4px 8px rgba(0,0,0,0.2))'
                }}
              />
            </Zoom>
          ) : (
            <Typography variant="h4" sx={{ color: 'white' }}>
              {company.name.charAt(0)}
            </Typography>
          )}
        </Box>
        <Box sx={{ position: 'relative', zIndex: 1, p: 3 }}>
          <Typography variant="h5" align="center" gutterBottom>
            {company.name}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Mail size={18} style={{ marginRight: 8 }} />
              <Typography
                variant="body2"
                component="a"
                href={`mailto:${company.email}`}
                sx={{
                  color: 'primary.main',
                  textDecoration: 'none',
                  '&:hover': { textDecoration: 'underline' }
                }}
              >
                {company.email}
              </Typography>
            </Box>
          </Box>
          {company.locations && company.locations.length > 0 && (
            <Accordion
              sx={{
                mt: 3,
                '&:before': { display: 'none' },
                background: 'transparent',
                boxShadow: 'none'
              }}
            >
              <AccordionSummary
                expandIcon={<ChevronDown />}
                sx={{
                  background:
                    'linear-gradient(135deg, rgba(58, 134, 255, 0.08) 0%, rgba(255, 0, 110, 0.08) 100%)',
                  borderRadius: '12px',
                  '&.Mui-expanded': {
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0
                  }
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <MapPin size={18} />
                  <Typography sx={{ fontWeight: 600 }}>
                    Locations ({company.locations.length})
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  p: 0,
                  background: 'rgba(255, 255, 255, 0.8)',
                  borderBottomLeftRadius: '12px',
                  borderBottomRightRadius: '12px'
                }}
              >
                <List sx={{ p: 0 }}>
                  {company.locations.map((location, index) => (
                    <ListItem
                      key={index}
                      sx={{
                        borderBottom:
                          index !== company.locations.length - 1
                            ? '1px solid rgba(0,0,0,0.08)'
                            : 'none',
                        transition: 'all 0.2s ease',
                        '&:hover': {
                          background: 'rgba(58, 134, 255, 0.04)'
                        }
                      }}
                    >
                      <Box sx={{ width: '100%', p: 1 }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 1
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: 600, color: 'primary.main' }}
                          >
                            {location.citta}
                          </Typography>
                          {location.partita_iva && (
                            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                              P.IVA: {location.partita_iva}
                            </Typography>
                          )}
                        </Box>
                        <Grid container spacing={2} sx={{ color: 'text.secondary' }}>
                          <Grid item xs={12} sm={6}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                              <MapPin size={16} />
                              <Typography variant="body2">
                                {location.inidirizzo}, {location.cap}
                              </Typography>
                            </Box>
                          </Grid>
                          {location.email && (
                            <Grid item xs={12} sm={6}>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                                <Mail size={16} />
                                <Typography
                                  variant="body2"
                                  component="a"
                                  href={`mailto:${location.email}`}
                                  sx={{
                                    color: 'primary.main',
                                    textDecoration: 'none',
                                    '&:hover': { textDecoration: 'underline' }
                                  }}
                                >
                                  {location.email}
                                </Typography>
                              </Box>
                            </Grid>
                          )}
                          {location.numero_di_telefono && (
                            <Grid item xs={12} sm={6}>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Phone size={16} />
                                <Typography
                                  variant="body2"
                                  component="a"
                                  href={`tel:${location.numero_di_telefono}`}
                                  sx={{
                                    color: 'primary.main',
                                    textDecoration: 'none',
                                    '&:hover': { textDecoration: 'underline' }
                                  }}
                                >
                                  {location.numero_di_telefono}
                                </Typography>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          )}
          {currentUser === 'admin' && (
            <Button
              variant="contained"
              onClick={() => onEdit(company)}
              startIcon={<Edit />}
              fullWidth
              sx={{ mt: 3 }}
            >
              Modifica Azienda
            </Button>
          )}
        </Box>

        {/* Update sync status section */}
        <Box sx={{ mt: 3, px: 3 }}>
          <Typography variant="h6" gutterBottom>
            Sync Status
          </Typography>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2">Car Stock Sync</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <CircularProgress
                  variant="determinate"
                  value={
                    company.car_stock_sync_status === 'COMPLETED'
                      ? 100
                      : company.car_stock_sync_status === 'PROCESSING'
                      ? 50
                      : company.car_stock_sync_status === 'FAILED'
                      ? 100
                      : 0
                  }
                  size={24}
                  sx={{
                    color:
                      company.car_stock_sync_status === 'COMPLETED'
                        ? 'success.main'
                        : company.car_stock_sync_status === 'PROCESSING'
                        ? 'primary.main'
                        : company.car_stock_sync_status === 'FAILED'
                        ? 'error.main'
                        : 'text.disabled'
                  }}
                />
              </Box>
              <Typography variant="body2">
                {company.car_stock_sync_status || 'NOT_STARTED'}
              </Typography>
            </Box>
            {company.last_car_stock_task_update && (
              <Typography variant="caption" color="text.secondary">
                Last sync: {new Date(company.last_car_stock_task_update).toLocaleString()}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Grow>
  );
}

export default CompanyCard; 
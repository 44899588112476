import { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';

// Convert a file to base64 string
const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    // Validate that we have a proper file/blob
    if (!(file instanceof Blob || file instanceof File)) {
      console.error('Invalid file object:', file);
      reject(new Error('Invalid file object provided'));
      return;
    }
    
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => {
      console.error('FileReader error:', error);
      reject(error);
    };
  });
};

export const useWebSocket = (selectedChatId, currentUserId) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [connectionStatus, setConnectionStatus] = useState('connecting'); // 'connecting', 'connected', 'disconnected'
  const [errorMessage, setErrorMessage] = useState('');
  const [reconnectAttempts, setReconnectAttempts] = useState(0);
  const socketRef = useRef(null);
  const reconnectTimeoutRef = useRef(null);
  const pingIntervalRef = useRef(null);
  const pingTimeoutRef = useRef(null);
  const maxReconnectDelay = 30000; // Max 30 seconds
  const isActiveRef = useRef(true); // Add this to track if the hook is active

  // Fix: Safely convert IDs to strings and then trim them
  const chatIdStr = selectedChatId ? String(selectedChatId) : '';
  const userIdStr = currentUserId ? String(currentUserId) : '';
  
  // Use the string versions for validation
  const hasValidIds = chatIdStr.trim() !== '' && userIdStr.trim() !== '';

  console.log(`WebSocketManager: Checking IDs - Chat ID: "${chatIdStr}", User ID: "${userIdStr}", Valid: ${hasValidIds}`);
  
  // Prepare a file for upload by converting to base64 and adding metadata
  const prepareFileForUpload = useCallback(async (file) => {
    try {
      // If this is already a processed attachment object with file_data, return it as is
      if (file && typeof file === 'object' && file.file_data && typeof file.file_data === 'string') {
        console.log('File is already processed, returning as is');
        return file;
      }
      
      // Skip processing if we don't have a valid file
      if (!file || !(file instanceof Blob || file instanceof File)) {
        console.error('Invalid file:', file);
        throw new Error('Invalid file provided');
      }
      
      console.log(`Processing file: ${file.name}, size: ${file.size}, type: ${file.type}`);
      
      // Convert file to base64
      const base64Data = await fileToBase64(file);
      
      // Return formatted attachment object
      return {
        id: Math.random().toString(36).substring(2), // Generate a temporary ID
        file_name: file.name || 'unnamed_file',
        file_type: file.type || 'application/octet-stream',
        file_size: file.size || 0,
        file_data: base64Data, // This includes the data URL prefix
        // Also include the original properties for consistency
        name: file.name,
        type: file.type,
        size: file.size
      };
    } catch (error) {
      console.error('Error preparing file for upload:', error);
      throw error;
    }
  }, []);

  // Join a chat room
  const joinChatRoom = useCallback((chatId) => {
    if (!chatId) return;
    
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      console.log(`Sending join_chat command for room: ${chatId}`);
      socketRef.current.send(JSON.stringify({
        command: "join_chat",
        chat_id: chatId
      }));
    }
  }, []);

  // Request chat history for a specific chat room
  const requestChatHistory = useCallback((chatId) => {
    if (!chatId || !isActiveRef.current) {
      console.log('Skipping chat history request - component inactive or invalid chat ID');
      return;
    }
    
    console.log('Requesting chat history for room:', chatId);
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      try {
        joinChatRoom(chatId);
        console.log('Join chat command sent for room:', chatId);
      } catch (error) {
        console.error('Error sending join chat command:', error);
        setErrorMessage('Error loading chat history');
      }
    } else {
      console.error('Socket not open when trying to request chat history');
      // Only attempt reconnect if the component is still active
      if (isActiveRef.current && reconnectAttempts < 3) {
        console.log('Attempting to reconnect before requesting chat history');
        initializeWebSocket();
        setTimeout(() => requestChatHistory(chatId), 1000);
      } else {
        setErrorMessage('Connection not established');
        setLoading(false);
      }
    }
  }, [reconnectAttempts, joinChatRoom]);

  // Send a message to a chat room with improved attachment handling
  const sendMessage = useCallback(async (chatId, content, attachments = [], replyToId = null) => {
    if (!chatId) {
      console.error('Chat ID is required to send a message');
      return false;
    }
    
    console.log(`Sending message to room ${chatId}: ${content}`);
    console.log(`With ${attachments.length} attachments and replyToId: ${replyToId}`);
    
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      try {
        // Process attachments as needed
        const processedAttachments = [];
        
        // Process each attachment, handling both already-processed objects and raw files
        for (const attachment of attachments) {
          try {
            // Here we use the smarter prepareFileForUpload that can handle both cases
            const processed = await prepareFileForUpload(attachment);
            processedAttachments.push({
              file_name: processed.file_name || processed.name || '',
              file_type: processed.file_type || processed.type || '',
              file_size: processed.file_size || processed.size || 0,
              file_data: processed.file_data || processed.data || ''
            });
          } catch (error) {
            console.error('Error processing attachment:', error);
            // Continue with other attachments
          }
        }
        
        console.log(`Processed ${processedAttachments.length} attachments`);
        
        // Format message data with reply_to
        const messageData = {
          command: 'send_message',
          chat_id: chatId,
          content: content || '',  // Ensure content is never null
          attachments: processedAttachments,
        };
        
        // Only add reply_to if it exists
        if (replyToId) {
          messageData.reply_to = replyToId;
          console.log(`Including reply to message: ${replyToId}`);
        }
        
        socketRef.current.send(JSON.stringify(messageData));
        return true;
      } catch (error) {
        console.error('Error sending message with attachments:', error);
        setErrorMessage('Failed to send message');
        return false;
      }
    } else {
      console.error('Socket not open when trying to send message');
      setErrorMessage('Connection not established');
      return false;
    }
  }, [prepareFileForUpload]);

  // Mark messages as read
  const markAsRead = useCallback((chatId, messageId = null) => {
    if (!chatId) return;
    
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      console.log(`Marking messages as read for chat: ${chatId}`);
      socketRef.current.send(JSON.stringify({
        command: "mark_as_read",
        chat_id: chatId,
        message_id: messageId
      }));
    }
  }, []);

  // Edit a message
  const editMessage = useCallback(async (messageId, content) => {
    if (!messageId || !content) {
      console.error('Invalid message ID or content for editing');
      return false;
    }
    
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      try {
        console.log(`Editing message ${messageId} via WebSocket`);
        // Send the edit message command via WebSocket
        socketRef.current.send(JSON.stringify({
          command: 'edit_message',
          message_id: messageId,
          content: content
        }));
        
        // Also update the message locally in the UI
        setMessages(prevMessages => 
          prevMessages.map(msg => 
            msg.id === messageId 
              ? { ...msg, content: content, is_edited: true } 
              : msg
          )
        );
        
        return true;
      } catch (error) {
        console.error('Error editing message via WebSocket:', error);
        throw error;
      }
    } else {
      // If WebSocket is not connected, try HTTP API
      try {
        console.log(`Editing message ${messageId} via HTTP API (fallback)`);
        const token = localStorage.getItem('token');
        const baseUrl = 'https://admin.aitomotivelab.com';
        
        const response = await axios.post(
          `${baseUrl}/company-chat/api/messages/${messageId}/edit/`,
          { content },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );
        
        // Update the message locally after successful API call
        setMessages(prevMessages => 
          prevMessages.map(msg => 
            msg.id === messageId 
              ? { ...msg, content: content, is_edited: true } 
              : msg
          )
        );
        
        return response.data;
      } catch (error) {
        console.error('Error editing message via HTTP API:', error);
        // Add specific error handling for unauthorized edits
        if (error.response && error.response.status === 403) {
          throw new Error('You can only edit your own messages');
        }
        throw error;
      }
    }
  }, []);

  // Delete a message
  const deleteMessage = useCallback((messageId) => {
    if (!messageId) return false;
    
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      console.log(`Deleting message ${messageId}`);
      socketRef.current.send(JSON.stringify({
        command: "delete_message",
        message_id: messageId
      }));
      return true;
    }
    return false;
  }, []);

  // React to a message
  const reactToMessage = useCallback((messageId, emoji) => {
    if (!messageId || !emoji) return false;
    
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      console.log(`Reacting to message ${messageId} with emoji: ${emoji}`);
      socketRef.current.send(JSON.stringify({
        command: "react_to_message",
        message_id: messageId,
        emoji: emoji
      }));
      return true;
    }
    return false;
  }, []);

  // Pin/unpin a message
  const togglePinMessage = useCallback((messageId) => {
    if (!messageId) return false;
    
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      console.log(`Toggling pin status for message ${messageId}`);
      socketRef.current.send(JSON.stringify({
        command: "pin_message",
        message_id: messageId
      }));
      return true;
    }
    return false;
  }, []);

  // Star/unstar a chat
  const toggleStarChat = useCallback((chatId) => {
    if (!chatId) return false;
    
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      console.log(`Toggling star status for chat ${chatId}`);
      socketRef.current.send(JSON.stringify({
        command: "star_chat",
        chat_id: chatId
      }));
      return true;
    }
    return false;
  }, []);

  // Attempt to reconnect the WebSocket
  const attemptReconnect = useCallback(() => {
    if (!isActiveRef.current) {
      console.log('Skipping reconnect - component inactive');
      return;
    }

    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
      reconnectTimeoutRef.current = null;
    }
    
    const delay = Math.min(
      1000 * Math.pow(2, reconnectAttempts) + Math.random() * 1000,
      maxReconnectDelay
    );
    
    console.log(`Attempting to reconnect in ${Math.round(delay / 1000)} seconds...`);
    
    reconnectTimeoutRef.current = setTimeout(() => {
      if (isActiveRef.current) {
        setReconnectAttempts(prev => prev + 1);
        initializeWebSocket();
      }
    }, delay);
  }, [reconnectAttempts]);

  // Process received WebSocket messages
  const processWebSocketMessage = useCallback((data) => {
    if (data.command === 'ping') {
      console.log("Received ping from server, responding with pong");
      
      // Respond to server pings with a pong
      if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
        socketRef.current.send(JSON.stringify({
          command: 'pong'
        }));
      }
      return; // Don't process ping messages further
    }
    
    if (data.command === 'pong') {
      console.log("Received pong response");
      // Clear ping timeout if it exists
      if (pingTimeoutRef.current) {
        clearTimeout(pingTimeoutRef.current);
        pingTimeoutRef.current = null;
      }
      return; // Don't process pong messages further
    }
    
    // Handle different message types
    if (data.command === 'chat_joined') {
      console.log(`Successfully joined chat ${data.chat_id}`);
      setLoading(false);
      
      // Store chat name information
      if (data.chat_info) {
        if (data.chat_info.name) {
          console.log(`Chat name: ${data.chat_info.name}`);
        }
      }
    } else if (data.command === 'chat_history') {
      console.log(`Received chat history for ${data.chat_id} with ${data.messages.length} messages`);
      setMessages(data.messages || []);
      setLoading(false);
      
      // If chat_info is provided, capture name and other metadata
      if (data.chat_info) {
        console.log("Chat info received:", data.chat_info);
      }
    } else if (data.type === 'new_message' || data.command === 'message_sent') {
      const newMessage = data.message;
      setMessages(prev => {
        // Check if message already exists
        if (prev.some(m => m.id === newMessage.id)) {
          return prev;
        }
        return [...prev, newMessage];
      });
    } else if (data.command === 'message_edited') {
      setMessages(prev => 
        prev.map(msg => 
          msg.id === data.message_id 
            ? { ...msg, content: data.content, is_edited: true }
            : msg
        )
      );
    } else if (data.command === 'message_deleted') {
      setMessages(prev => 
        prev.map(msg => 
          msg.id === data.message_id 
            ? { ...msg, is_deleted: true, content: "This message was deleted" }
            : msg
        )
      );
    } else if (data.command === 'message_reaction') {
      setMessages(prev => 
        prev.map(msg => {
          if (msg.id === data.message_id) {
            let updatedReactions = [...(msg.reactions || [])];
            
            if (data.action === 'added') {
              // Add reaction
              updatedReactions.push({
                emoji: data.emoji,
                user_id: data.user_id
              });
            } else if (data.action === 'removed') {
              // Remove reaction
              updatedReactions = updatedReactions.filter(
                r => !(r.emoji === data.emoji && r.user_id === data.user_id)
              );
            }
            
            return { ...msg, reactions: updatedReactions };
          }
          return msg;
        })
      );
    } else if (data.command === 'message_pinned') {
      setMessages(prev => 
        prev.map(msg => 
          msg.id === data.message_id 
            ? { ...msg, is_pinned: data.is_pinned, pinned_at: data.pinned_at }
            : msg
        )
      );
    } else if (data.command === 'read_receipt') {
      // Handle read receipts - typically handled at the ChatRoom level
      console.log(`User ${data.user_id} read messages in chat ${data.chat_id}`);
    } else if (data.command === 'error') {
      console.error("WebSocket error:", data.message);
      setErrorMessage(data.message);
    }
  }, []);

  // Initialize WebSocket connection
  const initializeWebSocket = useCallback(() => {
    if (!hasValidIds || !isActiveRef.current) {
      console.log("Missing valid IDs or component inactive, not initializing WebSocket");
      return;
    }
    
    // Clean up any existing socket
    if (socketRef.current) {
      console.log("Closing existing WebSocket connection");
      socketRef.current.close();
      socketRef.current = null;
    }
    
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("No authentication token found");
      setErrorMessage("Authentication required");
      setConnectionStatus('disconnected');
      return;
    }
    
    const wsUrl = `wss://admin.aitomotivelab.com/ws/chat/?token=${token}`;
    
    console.log(`Connecting to WebSocket at ${wsUrl}`);
    setConnectionStatus('connecting');
    
    try {
      const socket = new WebSocket(wsUrl);
      socketRef.current = socket;
      
      socket.onopen = () => {
        if (!isActiveRef.current) {
          console.log("Component inactive, closing newly opened connection");
          socket.close();
          return;
        }

        console.log("WebSocket connection established");
        setConnectionStatus('connected');
        setReconnectAttempts(0);
        setErrorMessage('');
        
        if (chatIdStr) {
          setTimeout(() => {
            if (isActiveRef.current) {
              joinChatRoom(chatIdStr);
              requestChatHistory(chatIdStr);
            }
          }, 500);
        }
      };
      
      socket.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          console.log("WebSocket message received:", data);
          
          // Process the message with the dedicated function
          processWebSocketMessage(data);
        } catch (error) {
          console.error("Error processing WebSocket message:", error);
        }
      };
      
      socket.onclose = (event) => {
        console.log(`WebSocket closed with code ${event.code}`);
        setConnectionStatus('disconnected');
        
        // Only attempt to reconnect for non-normal closures and when we have valid IDs
        if (event.code !== 1000 && hasValidIds) {
          attemptReconnect();
        }
        
        // Clear ping timeout
        if (pingTimeoutRef.current) {
          clearTimeout(pingTimeoutRef.current);
          pingTimeoutRef.current = null;
        }
      };
      
      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
        setErrorMessage("Connection error occurred");
      };
    } catch (error) {
      console.error("Error creating WebSocket:", error);
      setConnectionStatus('disconnected');
      setErrorMessage(`Failed to connect: ${error.message}`);
    }
  }, [hasValidIds, chatIdStr, processWebSocketMessage, attemptReconnect, joinChatRoom, requestChatHistory]);

  // Initialize WebSocket when component mounts or when IDs change
  useEffect(() => {
    console.log("Initializing WebSocket with IDs:", {chatId: selectedChatId, userId: currentUserId});
    isActiveRef.current = true;
    
    if (hasValidIds) {
      setLoading(true);
      initializeWebSocket();
    } else {
      setMessages([]);
      setLoading(false);
    }
    
    // Cleanup function
    return () => {
      console.log("Cleaning up WebSocket connection");
      isActiveRef.current = false;
      
      if (socketRef.current) {
        socketRef.current.close();
        socketRef.current = null;
      }
      
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
        reconnectTimeoutRef.current = null;
      }
      
      if (pingTimeoutRef.current) {
        clearTimeout(pingTimeoutRef.current);
        pingTimeoutRef.current = null;
      }
      
      if (pingIntervalRef.current) {
        clearInterval(pingIntervalRef.current);
        pingIntervalRef.current = null;
      }

      // Reset states
      setMessages([]);
      setConnectionStatus('disconnected');
      setErrorMessage('');
      setReconnectAttempts(0);
    };
  }, [hasValidIds, selectedChatId, currentUserId, initializeWebSocket]);

  // Handle visibility change (tab focus/blur)
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        console.log("Tab is now visible");
        
        // If connection was disconnected, try to reconnect
        if (connectionStatus === 'disconnected' && hasValidIds) {
          console.log("Reconnecting WebSocket due to tab becoming visible");
          initializeWebSocket();
        }
      }
    };
    
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [connectionStatus, hasValidIds, initializeWebSocket]);

  return {
    messages,
    loading,
    connectionStatus,
    errorMessage,
    sendMessage,
    markAsRead,
    joinChatRoom,
    requestChatHistory,
    editMessage,
    deleteMessage,
    reactToMessage,
    togglePinMessage,
    toggleStarChat,
    prepareFileForUpload, // Export the file preparation function
    leaveChatRoom: (chatId) => {
      if (!chatId) return;
      if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
        try {
          socketRef.current.send(JSON.stringify({
            command: "leave_chat",
            chat_id: chatId
          }));
        } catch (error) {
          console.error('Error leaving chat room:', error);
        }
      }
    },
    setErrorMessage
  };
};
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
  Snackbar,
  Alert
} from '@mui/material';
import { Plus, Trash2 } from 'lucide-react';
import {
  fetchBranchDefinitions,
  createBranchDefinition,
  updateBranchDefinition,
  deleteBranchDefinition,
  fetchBranches,
  createBranch,
  updateBranch,
  deleteBranch
} from '../api/branch';

const CompanyBranches = ({
  company
}) => {
  const [fetchedBranchDefinitions, setFetchedBranchDefinitions] = useState([]);
  const [branches, setBranches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editedValues, setEditedValues] = useState({});

  // Add snackbar state
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // Helper to generate temporary IDs for new items
  const generateTempId = () => `temp-${new Date().getTime()}-${Math.random()}`;

  // Load both branch definitions and branches on component mount
  const loadData = useCallback(async () => {
    if (company?.id) {
      try {
        setIsLoading(true);
        const [definitionsData, branchesData] = await Promise.all([
          fetchBranchDefinitions(company.id),
          fetchBranches(null, company.id) // Fetch all branches for the company
        ]);
        setFetchedBranchDefinitions(definitionsData);
        setBranches(branchesData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [company?.id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  // Add helper function to show snackbar
  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };

  // Branch Definition CRUD handlers
  const handleCreateBranchDefinition = () => {
    const tempId = generateTempId();
    const newDefinition = {
      id: tempId, // temporary id
      name: '',
      description: '',
      company: company.id,
      is_new: true // flag this as not yet saved in backend
    };
    setFetchedBranchDefinitions(prev => [...prev, newDefinition]);
  };

  const handleSaveBranchDefinition = async (definition) => {
    try {
      if (definition.is_new) {
        const createdDefinition = await createBranchDefinition({
          name: definition.name || 'New Branch Definition',
          description: definition.description || '',
          company: company.id,
          is_active: true
        });
        // Replace the temporary definition with the one returned by the API
        setFetchedBranchDefinitions(prev =>
          prev.map(def => def.id === definition.id ? createdDefinition : def)
        );
        showSnackbar('Branch definition created successfully');
      } else {
        const updatedDefinition = await updateBranchDefinition(definition.id, definition);
        setFetchedBranchDefinitions(prev =>
          prev.map(def => def.id === definition.id ? updatedDefinition : def)
        );
        showSnackbar('Branch definition updated successfully');
      }
    } catch (error) {
      console.error('Error saving branch definition:', error);
      showSnackbar('Failed to save branch definition', 'error');
    }
  };

  const handleDeleteBranchDefinition = async (definition) => {
    if (definition.is_new) {
      setFetchedBranchDefinitions(prev => prev.filter(def => def.id !== definition.id));
      // Also remove associated branch values
      setBranches(prev => prev.filter(branch => branch.branch_definition.id !== definition.id));
      return;
    }
    try {
      await deleteBranchDefinition(definition.id);
      setFetchedBranchDefinitions(prev => prev.filter(def => def.id !== definition.id));
      setBranches(prev => prev.filter(branch => branch.branch_definition.id !== definition.id));
      showSnackbar('Branch definition deleted successfully');
    } catch (error) {
      console.error('Error deleting branch definition:', error);
      showSnackbar('Failed to delete branch definition', 'error');
    }
  };

  // Branch Values CRUD handlers
  const handleAddBranchValue = (branchDefinitionId) => {
    const branchDefinition = fetchedBranchDefinitions.find(def => def.id === branchDefinitionId);
    const tempId = generateTempId();
    const newBranch = {
      tempId, // temporary id
      branch_definition: branchDefinition,
      value: '',
      smilenet_id: '',
      company: company.id,
      is_new: true
    };
    setBranches(prev => [...prev, newBranch]);
  };

  const handleSaveBranchValue = async (branch) => {
    try {
      const branchKey = branch.id || branch.tempId;
      if (branch.is_new) {
        const branchData = {
          value: branch.value,
          smilenet_id: branch.smilenet_id,
          branch_definition: branch.branch_definition.id,
          company: company.id,
          is_active: true
        };
        const createdBranch = await createBranch(branchData);
        const branchWithDefinition = {
          ...createdBranch,
          branch_definition: branch.branch_definition
        };
        setBranches(prev =>
          prev.map(b => ((b.id || b.tempId) === branchKey ? branchWithDefinition : b))
        );
        setEditedValues(prev => {
          const newState = { ...prev };
          delete newState[branchKey];
          return newState;
        });
        showSnackbar('Branch value created successfully');
      } else {
        const branchData = {
          id: branch.id,
          value: branch.value,
          smilenet_id: branch.smilenet_id,
          branch_definition: branch.branch_definition.id,
          company: company.id
        };
        const updatedBranch = await updateBranch(branch.id, branchData);
        setBranches(prev =>
          prev.map(b => (b.id === branch.id ? updatedBranch : b))
        );
        setEditedValues(prev => {
          const newState = { ...prev };
          delete newState[branch.id];
          return newState;
        });
        showSnackbar('Branch value updated successfully');
      }
    } catch (error) {
      console.error('Error saving branch value:', error);
      showSnackbar('Failed to save branch value', 'error');
    }
  };

  const handleBranchValueChange = (branch, field, value) => {
    const branchKey = branch.id || branch.tempId;
    setEditedValues(prev => ({
      ...prev,
      [branchKey]: {
        ...branch,
        ...prev[branchKey],
        [field]: value
      }
    }));
  };

  const handleDeleteBranch = async (branch) => {
    const branchKey = branch.id || branch.tempId;
    if (branch.is_new) {
      setBranches(prev => prev.filter(b => (b.id || b.tempId) !== branchKey));
      return;
    }
    try {
      await deleteBranch(branch.id);
      setBranches(prev => prev.filter(b => b.id !== branch.id));
      showSnackbar('Branch value deleted successfully');
    } catch (error) {
      console.error('Error deleting branch value:', error);
      showSnackbar('Failed to delete branch value', 'error');
    }
  };

  // Group branch definitions from the API together with branch values from the company state.
  const branchDefinitionsMemo = useMemo(() => {
    const groups = {};
    // Include fetched definitions (even if they have no branch values)
    fetchedBranchDefinitions.forEach(def => {
      groups[def.id] = {
        branch_definition_id: def.id,
        branch_definition_name: def.name,
        branch_definition_description: def.description,
        values: []
      };
    });
    // Merge in branch values from local state
    branches.forEach((branch, index) => {
      const key = branch.branch_definition.id;
      if (!groups[key]) {
        groups[key] = {
          branch_definition_id: key,
          branch_definition_name: branch.branch_definition.name || '',
          branch_definition_description: branch.branch_definition.description || '',
          values: []
        };
      }
      groups[key].values.push({
        index,
        id: branch.id || branch.tempId,
        value: branch.value
      });
    });
    return Object.values(groups);
  }, [branches, fetchedBranchDefinitions]);

  // When the user edits a branch definition field (name or description), update all branch values of that group.
  const handleDefinitionChange = (groupKey, field, value) => {
    const group = branchDefinitionsMemo.find(
      (g) =>
        (g.branch_definition_id && g.branch_definition_id === groupKey) ||
        (!g.branch_definition_id && g.tempKey === groupKey)
    );
    if (group && group.values) {
      group.values.forEach(branch => {
        onBranchChange(branch.index, field, value);
      });
    }
  };

  return (
    <Box>
      {fetchedBranchDefinitions.map((definition) => (
        <Paper
          key={definition.id}
          sx={{
            p: 2,
            mb: 2,
            transition: 'all 0.3s ease',
            '&:hover': {
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
              transform: 'translateY(-2px)'
            }
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={10}>
              <TextField
                fullWidth
                label="Branch Definition Name"
                value={definition.name || ''}
                onChange={(e) => {
                  const newDef = { ...definition, name: e.target.value };
                  setFetchedBranchDefinitions(prev =>
                    prev.map(def => (def.id === definition.id ? newDef : def))
                  );
                }}
                variant="outlined"
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Branch Definition Description"
                value={definition.description || ''}
                onChange={(e) => {
                  const newDef = { ...definition, description: e.target.value };
                  setFetchedBranchDefinitions(prev =>
                    prev.map(def => (def.id === definition.id ? newDef : def))
                  );
                }}
                variant="outlined"
                sx={{ mb: 2 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSaveBranchDefinition(definition)}
                sx={{ mb: 2 }}
              >
                {definition.is_new ? "Create Definition" : "Save Definition"}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={() => handleDeleteBranchDefinition(definition)}
                color="error"
              >
                <Trash2 />
              </IconButton>
            </Grid>

            {/* Branch Values Section */}
            <Grid item xs={12}>
              <Typography variant="subtitle1" sx={{ mb: 2 }}>
                Values:
              </Typography>
              {branches
                .filter(branch => branch.branch_definition.id === definition.id)
                .map((branch) => {
                  const branchKey = branch.id || branch.tempId;
                  return (
                    <Grid
                      container
                      spacing={2}
                      key={branchKey}
                      alignItems="center"
                    >
                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          label="Value"
                          value={(editedValues[branchKey]?.value ?? branch.value) || ''}
                          onChange={(e) => handleBranchValueChange(branch, 'value', e.target.value)}
                          variant="outlined"
                          sx={{ mb: 2 }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          label="SmileNet ID"
                          value={(editedValues[branchKey]?.smilenet_id ?? branch.smilenet_id) || ''}
                          onChange={(e) => handleBranchValueChange(branch, 'smilenet_id', e.target.value)}
                          variant="outlined"
                          sx={{ mb: 2 }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleSaveBranchValue(editedValues[branchKey] || branch)}
                          disabled={!editedValues[branchKey]}
                          sx={{ mb: 2 }}
                        >
                          Save
                        </Button>
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() => handleDeleteBranch(branch)}
                          color="error"
                        >
                          <Trash2 />
                        </IconButton>
                      </Grid>
                    </Grid>
                  );
                })}
              <Button
                startIcon={<Plus />}
                onClick={() => handleAddBranchValue(definition.id)}
                variant="outlined"
                size="small"
                sx={{ mb: 2 }}
              >
                Add Value
              </Button>
            </Grid>
          </Grid>
        </Paper>
      ))}

      <Button
        startIcon={<Plus />}
        onClick={handleCreateBranchDefinition}
        variant="outlined"
        sx={{
          mt: 2,
          borderRadius: 2,
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
          }
        }}
      >
        Add Branch Definition
      </Button>

      {/* Add Snackbar at the end of the component */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CompanyBranches;
import React from 'react';
import { 
  List, ListItem, ListItemAvatar, Avatar, 
  ListItemText, Typography, Badge, Box,
  AvatarGroup, Tooltip, IconButton
} from '@mui/material';
import { Star, MessageSquare } from 'lucide-react';

const ConversationList = ({ conversations, onSelectConversation, darkMode, onToggleStarred, currentUserId }) => {
  // Format timestamp for display based on recency
  const formatTime = (timestamp) => {
    if (!timestamp) return '';
    
    const now = new Date();
    const messageDate = new Date(timestamp);
    
    // If today, show time
    if (messageDate.toDateString() === now.toDateString()) {
      return messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
    
    // If this week, show day name
    const diffDays = Math.floor((now - messageDate) / (1000 * 60 * 60 * 24));
    if (diffDays < 7) {
      return messageDate.toLocaleDateString([], { weekday: 'short' });
    }
    
    // Otherwise show date
    return messageDate.toLocaleDateString([], { day: 'numeric', month: 'short' });
  };

  // Get display name for a conversation
  const getConversationName = (conversation) => {
    if (conversation.is_group && conversation.name) {
      return conversation.name;
    }
    
    // For direct chats, show the other user's name
    if (conversation.participants && conversation.participants.length > 0) {
      // Find the other participant (not the current user)
      const otherParticipant = conversation.participants.find(
        p => p.user && p.user.id !== currentUserId
      )?.user;
      
      if (otherParticipant) {
        return `${otherParticipant.first_name || ''} ${otherParticipant.last_name || ''}`.trim() || 
               otherParticipant.username || 'Unknown User';
      }
    }
    
    return 'Chat';
  };

  // Get avatar for a conversation
  const renderAvatar = (conversation) => {
    if (conversation.is_group) {
      // For group chats, show avatar group or group icon
      return (
        <Avatar 
          sx={{ 
            bgcolor: darkMode ? 'primary.dark' : 'primary.main',
            color: 'white'
          }}
        >
          <MessageSquare size={20} />
        </Avatar>
      );
    } else if (conversation.participants && conversation.participants.length > 0) {
      // For direct chats, show the other user's avatar
      const otherParticipant = conversation.participants[0]?.user;
      if (otherParticipant) {
        return (
          <Avatar>
            {otherParticipant.first_name?.charAt(0) || ''}
            {otherParticipant.last_name?.charAt(0) || ''}
          </Avatar>
        );
      }
    }
    
    // Fallback avatar
    return <Avatar />;
  };

  // Handle star toggle with stopPropagation to prevent selecting the conversation
  const handleStarToggle = async (e, chatId, isStarred) => {
    e.stopPropagation(); // Prevent selecting the conversation
    
    try {
      const result = await onToggleStarred(chatId);
      if (result) {
        // Update was successful
        console.log(`Chat ${chatId} starred status toggled to: ${result.is_starred}`);
      }
    } catch (error) {
      console.error('Error toggling star:', error);
    }
  };

  if (!conversations || conversations.length === 0) {
    return (
      <Box sx={{ 
        p: 2, 
        textAlign: 'center', 
        color: darkMode ? 'rgba(255,255,255,0.7)' : 'text.secondary'
      }}>
        No conversations yet
      </Box>
    );
  }

  return (
    <List sx={{ width: '100%', p: 0 }}>
      {conversations.map((conversation) => {
        const conversationName = getConversationName(conversation);
        const lastMessage = conversation.last_message?.content || '';
        const timestamp = conversation.last_message?.timestamp || conversation.updated_at;
        const unreadCount = conversation.unread_count || 0;
        const isStarred = conversation.is_starred || false;
        
        return (
          <ListItem 
            button 
            key={conversation.id}
            onClick={() => onSelectConversation(conversation)}
            sx={{
              py: 1.5,
              px: 2,
              borderBottom: '1px solid',
              borderColor: darkMode ? 'rgba(255,255,255,0.08)' : 'rgba(0,0,0,0.06)',
              '&:hover': {
                backgroundColor: darkMode ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.04)',
              },
              backgroundColor: unreadCount > 0 
                ? (darkMode ? 'rgba(67, 97, 238, 0.1)' : 'rgba(67, 97, 238, 0.05)') 
                : 'transparent',
            }}
          >
            <ListItemAvatar>
              <Badge
                overlap="circular"
                badgeContent={unreadCount}
                color="primary"
                invisible={unreadCount === 0}
              >
                {renderAvatar(conversation)}
              </Badge>
            </ListItemAvatar>
            
            <ListItemText 
              primary={
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: '70%' }}>
                    <Typography 
                      component="span" 
                      variant="body1"
                      fontWeight={unreadCount > 0 ? 'bold' : 'normal'}
                      color={darkMode ? 'white' : 'inherit'}
                      noWrap
                      sx={{ marginRight: 1 }}
                    >
                      {conversationName}
                    </Typography>
                    
                    <Tooltip title={isStarred ? "Unstar conversation" : "Star conversation"}>
                      <IconButton 
                        size="small" 
                        onClick={(e) => handleStarToggle(e, conversation.id, isStarred)}
                        sx={{ 
                          p: 0.5, 
                          color: isStarred 
                            ? (darkMode ? 'warning.light' : 'warning.main') 
                            : (darkMode ? 'action.disabled' : 'text.disabled')
                        }}
                      >
                        {isStarred ? <Star size={14} /> : <Star size={14} strokeWidth={1.5} />}
                      </IconButton>
                    </Tooltip>
                  </Box>
                  
                  <Typography 
                    component="span" 
                    variant="caption" 
                    color={darkMode ? 'rgba(255,255,255,0.6)' : 'text.secondary'}
                    sx={{ minWidth: '60px', textAlign: 'right' }}
                  >
                    {formatTime(timestamp)}
                  </Typography>
                </Box>
              }
              secondary={
                <Typography 
                  variant="body2" 
                  color={unreadCount > 0 
                    ? (darkMode ? 'white' : 'text.primary') 
                    : (darkMode ? 'rgba(255,255,255,0.6)' : 'text.secondary')}
                  noWrap
                  fontWeight={unreadCount > 0 ? 'medium' : 'normal'}
                >
                  {lastMessage || "No messages yet"}
                </Typography>
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default ConversationList; 
import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Checkbox, ListItemText, Typography } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { useAuth } from '../../../contexts/AuthContext';

const AvailabilityList = ({ availabilities, handleEditAvailability, handleDeleteAvailability, dayOfWeekOptions, locations = [], branchValues = [] }) => {
  const { userRole, appAccess } = useAuth();
  const permissions = appAccess['booking_settings'] || {};
  const setting_canEdit = permissions.can_edit || userRole === 'superadmin';
  const setting_canDelete = permissions.can_delete || userRole === 'superadmin';

  // Group availabilities by city
  const groupedAvailabilities = React.useMemo(() => {
    const groups = {};
    
    // Sort function for days of the week (Monday = 1, Sunday = 7)
    const getDayOrder = (dayNum) => {
      const num = parseInt(dayNum);
      return num === 0 ? 7 : num; // Convert Sunday (0) to 7 for proper sorting
    };
    
    availabilities.forEach(availability => {
      const cityIds = Array.isArray(availability.location) ? availability.location : [];
      cityIds.forEach(cityId => {
        const city = locations.find(l => l.id === cityId)?.citta || 'Uncategorized';
        if (!groups[city]) {
          groups[city] = [];
        }
        groups[city].push(availability);
      });
      
      // Handle availabilities with no cities
      if (cityIds.length === 0) {
        const city = 'Uncategorized';
        if (!groups[city]) {
          groups[city] = [];
        }
        groups[city].push(availability);
      }
    });

    // Sort cities alphabetically and sort availabilities by day of week
    return Object.keys(groups)
      .sort()
      .reduce((acc, city) => {
        acc[city] = groups[city].sort((a, b) => 
          getDayOrder(a.day_of_week) - getDayOrder(b.day_of_week)
        );
        return acc;
      }, {});
  }, [availabilities, locations]);

  // Improved status check with more precise time comparison
  const getAvailabilityStatus = (availability) => {
    const now = new Date();
    const [startHour, startMin] = availability.start_time.split(':').map(Number);
    const [endHour, endMin] = availability.end_time.split(':').map(Number);
    const currentTime = now.getHours() * 60 + now.getMinutes();
    const startTime = startHour * 60 + startMin;
    const endTime = endHour * 60 + endMin;
    
    return {
      isActive: currentTime >= startTime && currentTime <= endTime,
      isPast: currentTime > endTime,
      isUpcoming: currentTime < startTime
    };
  };

  // Improved formatting for edit handler
  const handleEdit = (availability) => {
    const formattedAvailability = {
      ...availability,
      location: Array.isArray(availability.location) 
        ? availability.location 
        : availability.location?.split(',').map(id => parseInt(id, 10)) || [],
      day_of_week: [availability.day_of_week?.toString() || ''],
      branch_ids: availability.branch?.map(b => b.id) || [],
      branch_values: availability.branch?.map(b => b.value) || [],
      branch_definition: availability.branch?.[0]?.branch_definition || ''
    };
    
    handleEditAvailability(formattedAvailability);
  };

  return (
    <TableContainer component={Paper} sx={{ mb: 3, maxHeight: '70vh', overflow: 'auto' }}>
      <Table size="medium" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'primary.main', color: 'white' }}>
              Day & Time
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'primary.main', color: 'white' }}>
              Capacity
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'primary.main', color: 'white' }}>
              Location Details
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'primary.main', color: 'white', width: '120px' }}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(groupedAvailabilities).map(([city, cityAvailabilities]) => (
            <React.Fragment key={city}>
              <TableRow>
                <TableCell
                  colSpan={4}
                  sx={{
                    backgroundColor: 'grey.100',
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                    py: 1
                  }}
                >
                  {city}
                </TableCell>
              </TableRow>
              {cityAvailabilities.map((availability) => {
                const { isActive, isPast, isUpcoming } = getAvailabilityStatus(availability);
                const dayLabel = dayOfWeekOptions.find(
                  (option) => option.value === availability.day_of_week
                )?.label || 'Unknown Day';
                
                return (
                  <TableRow 
                    key={availability.id}
                    sx={{
                      '&:hover': { 
                        backgroundColor: isActive ? 'success.main' : 
                                       isPast ? 'grey.200' : 
                                       isUpcoming ? 'info.main' : 'action.hover',
                        transition: 'background-color 0.2s'
                      }
                    }}
                  >
                    <TableCell>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        {dayLabel}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {availability.start_time} - {availability.end_time}
                      </Typography>
                      
                    </TableCell>
                    
                    <TableCell>
                      <Typography variant="h6" component="div">
                        {availability.max_slots}
                        <Typography variant="caption" sx={{ ml: 1, color: 'text.secondary' }}>
                          slots
                        </Typography>
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography variant="body2" sx={{ mb: 1 }}>
                        <strong>Cities: </strong>
                        {Array.isArray(availability.location) 
                          ? availability.location
                              .map(locationId => locations.find(l => l.id === locationId)?.citta)
                              .filter(Boolean)
                              .join(', ') || 'No cities selected'
                          : 'No cities selected'}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <strong>Branches: </strong>
                        {Array.isArray(availability.branch) && availability.branch.length > 0
                          ? availability.branch.map(branch => branch.value).join(', ')
                          : 'No branches selected'}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
                        {setting_canEdit && (
                          <IconButton 
                            onClick={() => handleEdit(availability)} 
                            color="primary"
                            size="small"
                            title="Edit availability"
                            sx={{ '&:hover': { transform: 'scale(1.1)' } }}
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                        {setting_canDelete && (
                          <IconButton 
                            onClick={() => handleDeleteAvailability(availability.id)} 
                            color="error"
                            size="small"
                            title="Delete availability"
                            sx={{ '&:hover': { transform: 'scale(1.1)' } }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AvailabilityList;

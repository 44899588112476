import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import ReactMarkdown from 'react-markdown';
import { alpha } from '@mui/material/styles';
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Snackbar,
  CircularProgress,
  Chip,
  List,
  ListItem,
  Divider,
  Box,
  Paper,
  Avatar,
  IconButton,
  Tooltip,
  Fade,
  Zoom,
  Slide,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableRow,
  FormControlLabel,
  Switch,
  Collapse,
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Person as PersonIcon,
  SmartToy as AIIcon,
  DirectionsCar as CarIcon,
  CheckCircle as CheckIcon,
  Error as ErrorIcon,
  ThumbUp,
  ThumbDown,
  Comment as CommentIcon,
  ExpandMore as ExpandMoreIcon,
  Label as LabelIcon,
  ArrowBack as ArrowBackIcon,
  Description as DescriptionIcon,
  Close as CloseIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  InsertDriveFile as FileIcon,
  Image as ImageIcon,
  AudioFile as AudioIcon,
  BrokenImage as BrokenImageIcon,
  DirectionsCar,
  Assignment,
  Schedule,
  NoteAdd,
  Chat,
  TableChart,
  Article,
  Person,
  Phone as PhoneIcon,
  Assignment as AssignmentIcon,
  PersonStanding,
  KeyboardArrowUp,
  KeyboardArrowDown,
  Notifications as NotificationsIcon,
  NotificationsOff as NotificationsOffIcon,
  NotificationsActive as NotificationsActiveIcon,
} from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import MuiAlert from '@mui/material/Alert';
import { useAuth } from '../contexts/AuthContext';
import SendMessageForm from './SendMessageForm';
import { useNavigate } from 'react-router-dom';
import MobileStepper from '@mui/material/MobileStepper';
// Import the FormAttachmentCard component
import { FormAttachmentCard } from './CarAttachmentCard';
import { fetchCurrentUserRole } from '../api/user';

const WSS_BASE_URL = process.env.REACT_APP_WSS_BASE_URL || 'wss://admin.aitomotivelab.com';
const API_DOMAIN = process.env.REACT_APP_API_DOMAIN || 'https://admin.aitomotivelab.com';



const formatMessageTime = (date, previousDate) => {
  const messageDate = new Date(date);
  const prevDate = previousDate ? new Date(previousDate) : null;
  
  const timeString = messageDate.toLocaleTimeString('it-IT', {
    hour: '2-digit',
    minute: '2-digit',
  });

  // Check if we need to show the date (first message or different day)
  const showDate = !prevDate || 
    messageDate.getDate() !== prevDate.getDate() ||
    messageDate.getMonth() !== prevDate.getMonth() ||
    messageDate.getFullYear() !== prevDate.getFullYear();

  return {
    time: timeString,
    showDate: showDate,
    dateString: showDate ? messageDate.toLocaleDateString('it-IT', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    }) : null
  };
};

const formatMessageContent = (message) => {
  if (!message) return '';
  // Add a newline at the end of each message to force line breaks
  return message
    .replace(/\r\n/g, '\n') // Normalize line endings
    .replace(/\n{3,}/g, '\n\n') // Replace 3+ newlines with 2
    .trim() // Remove leading/trailing whitespace
    + '\n'; // Add newline at the end to force line break
};

// Styled Components
const RootContainer = styled(Container)(({ theme, isSmallScreen }) => ({
  height: '100vh',
  overflow: 'hidden',
  padding: isSmallScreen ? 0 : theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: 0,
  padding: 0,
  margin: 0,
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  }
}));

const ChatContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
}));

const HeaderSection = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: 10,
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3),
  },
}));

const MessageList = styled(List)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
  padding: theme.spacing(2),
  margin: 0,
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.background.default,
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.main,
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: theme.palette.primary.dark,
  },
}));

const FixedBottomBox = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2, 3),
  },
  zIndex: 10,
}));

const AnimatedIconButton = styled(IconButton)(({ theme }) => ({
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  '&:hover': {
    transform: 'scale(1.2) rotate(5deg)',
    backgroundColor: theme.palette.primary.light,
  },
}));

const MessagePaper = styled(Paper)(({ theme, isBot, isWhatsApp }) => ({
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  backgroundColor: isBot
    ? isWhatsApp
      ? '#DCF8C6'
      : theme.palette.mode === 'dark'
        ? alpha(theme.palette.primary.dark, 0.9)
        : alpha(theme.palette.primary.main, 0.85)
    : isWhatsApp
    ? '#E2E2E2'
    : theme.palette.mode === 'dark'
      ? alpha(theme.palette.background.paper, 0.8)
      : alpha(theme.palette.background.paper, 0.95),
  color: isBot
    ? isWhatsApp
      ? '#000000'
      : theme.palette.mode === 'dark'
        ? '#ffffff'
        : '#ffffff'
    : theme.palette.text.primary,
  borderRadius: isBot ? '16px 16px 4px 16px' : '16px 16px 16px 4px',
  boxShadow: isBot 
    ? '0px 4px 15px rgba(0, 0, 0, 0.15)'
    : '0px 2px 10px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease',
  position: 'relative',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: isBot 
      ? '0px 8px 20px rgba(0, 0, 0, 0.20)'
      : '0px 4px 15px rgba(0, 0, 0, 0.15)',
  },
  maxWidth: '95%',
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
  whiteSpace: 'pre-wrap',
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '12px',
    height: '12px',
    bottom: isBot ? 'auto' : '0',
    top: !isBot ? 'auto' : 'auto', // Fixed this line - no triangle on top for bot messages
    left: isBot ? '0' : 'auto',
    right: isBot ? 'auto' : '0',
    backgroundColor: 'inherit',
    transform: isBot ? 'translateY(-50%)' : 'translateY(50%)',
    clipPath: isBot 
      ? 'polygon(0 50%, 50% 0, 100% 50%)' 
      : 'polygon(0 50%, 50% 100%, 100% 50%)',
    display: isWhatsApp ? 'none' : 'block',
  },
  '& p': {
    margin: 0,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    display: 'block',
    minHeight: '1.2em',
    color: isWhatsApp ? '#000000' : 'inherit',
  },
  '& > *:last-child': {
    marginBottom: 0,
  },
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2.5),
    maxWidth: '100%',
    '&:hover': {
      transform: 'translateY(-5px)',
    },
  },
}));

const SummaryAccordion = styled(Accordion)(({ theme }) => ({
  margin: 0,
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 0,
  },
}));

const SummaryAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#2A2F3B' : '#E8F0FE',
  minHeight: 48,
  '& .MuiAccordionSummary-content': {
    margin: '8px 0',
  },
}));

const SummaryAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#F9FAFB',
  padding: theme.spacing(1, 2),
}));

const CarAttachmentsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  gap: theme.spacing(2),
  padding: theme.spacing(1),
  marginTop: theme.spacing(1),
  '&::-webkit-scrollbar': {
    height: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.background.default,
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.main,
    borderRadius: '3px',
  },
  [theme.breakpoints.up('md')]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
    overflowX: 'visible',
  },
}));

const CarStockCard = ({ car, isSmallScreen }) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    transition={{ duration: 0.2 }}
  >
    <Card
      sx={{
        width: isSmallScreen ? '200px' : '100%',
        flexShrink: 0,
        m: 0,
        boxShadow: 3,
        borderRadius: 2,
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardMedia
        component="img"
        height="120"
        image={car.urlmainimage || '/api/placeholder/345/140'}
        alt={`${car.brand} ${car.model}`}
        sx={{ objectFit: 'cover' }}
      />
      <CardContent sx={{ p: 1.5, '&:last-child': { pb: 1.5 } }}>
        <Typography variant="subtitle2" noWrap>
          {car.brand} {car.model}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          €{car.saleprice?.toLocaleString()}
        </Typography>
        <Typography variant="caption" color="text.secondary" display="block">
          {car.registrationyear} • {car.km?.toLocaleString()} km
        </Typography>
      </CardContent>
    </Card>
  </motion.div>
);

const MobileCarouselContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  overflowX: 'hidden',
  '& .carousel-content': {
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const CarouselArrowButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  zIndex: 2,
  boxShadow: theme.shadows[2],
}));

const MobileCarCarousel = ({ cars }) => {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = cars.length;
  
  const handleNext = (e) => {
    // Prevent event propagation to parent elements
    e.stopPropagation();
    setActiveStep((prevStep) => prevStep + 1);
  };
  
  const handleBack = (e) => {
    // Prevent event propagation to parent elements
    e.stopPropagation();
    setActiveStep((prevStep) => prevStep - 1);
  };
  
  return (
    <MobileCarouselContainer onClick={(e) => e.stopPropagation()}>
      <Box className="carousel-content" sx={{ width: '100%', position: 'relative' }}>
        <CarouselArrowButton
          size="small"
          onClick={handleBack}
          disabled={activeStep === 0}
          sx={{ left: 8 }}
        >
          <KeyboardArrowLeft />
        </CarouselArrowButton>
  
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: '0 40px',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <AnimatePresence mode="wait">
            <motion.div
              key={activeStep}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.3 }}
              onClick={(e) => e.stopPropagation()}
            >
              <CarStockCard car={cars[activeStep]} isSmallScreen={true} />
            </motion.div>
          </AnimatePresence>
        </Box>
  
        <CarouselArrowButton
          size="small"
          onClick={handleNext}
          disabled={activeStep === maxSteps - 1}
          sx={{ right: 8 }}
        >
          <KeyboardArrowRight />
        </CarouselArrowButton>
      </Box>
  
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        sx={{
          width: '100%',
          justifyContent: 'center',
          background: 'transparent',
          mt: 1,
          '& .MuiMobileStepper-dot': {
            margin: '0 4px',
          },
        }}
        onClick={(e) => e.stopPropagation()}
      />
    </MobileCarouselContainer>
  );
};

const SummaryTable = ({ summary }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  const parseSummary = (text) => {
    const sections = {
      vehicles: '',
      mainRequest: '',
      timing: {
        status: 'REQUIRES CUSTOMER SERVICE ORGANIZATION',
        details: ''
      },
      additionalNotes: '',
      conversationSummary: ''
    };

    // For timing, get everything between "ORGANIZATION -" and "- Note Aggiuntive"
    const timingMatch = text.match(/ORGANIZATION -([^-]+)- Note Aggiuntive/);
    if (timingMatch && timingMatch[1]) {
      sections.timing.details = timingMatch[1].trim();
      sections.timing.status = ''; // Clear the status since we have details
    }

    // Parse other sections normally
    const parts = text.split(/[-\n]+/).map(part => part.trim()).filter(Boolean);
    parts.forEach(part => {
      if (part.startsWith('Veicoli:')) {
        sections.vehicles = part.replace('Veicoli:', '').trim();
      } else if (part.startsWith('Richiesta Principale:')) {
        sections.mainRequest = part.replace('Richiesta Principale:', '').trim();
      } else if (part.startsWith('Note Aggiuntive:')) {
        sections.additionalNotes = part.replace('Note Aggiuntive:', '').trim();
      } else if (part.startsWith('Riassunto conversazionale:')) {
        sections.conversationSummary = part.replace('Riassunto conversazionale:', '').trim();
      }
    });

    return sections;
  };

  const sections = parseSummary(summary);

  return (
    <Box sx={{ 
      width: '100%', 
      overflowX: 'auto',
      borderRadius: 2,
      bgcolor: 'background.paper',
      boxShadow: theme.shadows[3],
      p: isSmallScreen ? 1 : 2,
    }}>
      <Box sx={{
        display: 'grid',
        gap: 2,
        gridTemplateColumns: isSmallScreen ? '1fr' : 'repeat(2, 1fr)', // Two columns on desktop
      }}>
        {[
          { 
            icon: <DirectionsCar />, 
            label: 'Veicoli', 
            value: sections.vehicles,
            color: theme.palette.primary.main,
            bgColor: alpha(theme.palette.primary.main, 0.1)
          },
          { 
            icon: <Assignment />, 
            label: 'Richiesta Principale', 
            value: sections.mainRequest,
            color: theme.palette.secondary.main,
            bgColor: alpha(theme.palette.secondary.main, 0.1)
          },
          { 
            icon: <Schedule />, 
            label: 'Tempistiche',
            value: sections.timing.details || sections.timing.status,
            isStatus: !sections.timing.details,
            color: theme.palette.warning.main,
            bgColor: alpha(theme.palette.warning.main, 0.1)
          },
          { 
            icon: <NoteAdd />, 
            label: 'Note Aggiuntive', 
            value: sections.additionalNotes,
            color: theme.palette.info.main,
            bgColor: alpha(theme.palette.info.main, 0.1)
          },
          { 
            icon: <Chat />, 
            label: 'Riassunto Conversazione', 
            value: sections.conversationSummary,
            color: theme.palette.success.main,
            bgColor: alpha(theme.palette.success.main, 0.1),
            gridColumn: isSmallScreen ? 'auto' : '1 / -1' // Full width on desktop
          }
        ].map((row, index) => (
          <Paper
            key={index}
            elevation={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              borderRadius: 2,
              transition: 'all 0.3s ease',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: theme.shadows[8],
              },
            }}
          >
            {/* Header */}
            <Box
              sx={{
                p: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                borderBottom: `1px solid ${theme.palette.divider}`,
                bgcolor: row.bgColor,
              }}
            >
              <Avatar
                sx={{
                  bgcolor: row.color,
                  color: '#fff',
                  width: 40,
                  height: 40,
                }}
              >
                {React.cloneElement(row.icon, { sx: { fontSize: '1.5rem' } })}
              </Avatar>
              <Typography
                variant="h6"
                sx={{
                  color: row.color,
                  fontWeight: 600,
                  fontSize: isSmallScreen ? '1rem' : '1.25rem',
                }}
              >
                {row.label}
              </Typography>
            </Box>

            {/* Content */}
            <Box
              sx={{
                p: 2,
                minHeight: row.isStatus ? 'auto' : '100px',
                display: 'flex',
                alignItems: row.isStatus ? 'center' : 'flex-start',
                bgcolor: theme.palette.background.paper,
              }}
            >
              {row.isStatus ? (
                <Chip
                  label={row.value}
                  color="primary"
                  sx={{
                    fontWeight: 500,
                    fontSize: '0.875rem',
                    py: 2,
                    px: 1,
                    height: 'auto',
                    '& .MuiChip-label': {
                      px: 2,
                    },
                  }}
                />
              ) : (
                <Typography
                  variant="body1"
                  sx={{
                    color: theme.palette.text.primary,
                    lineHeight: 1.6,
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                  }}
                >
                  {row.value || 'Not specified'}
                </Typography>
              )}
            </Box>
          </Paper>
        ))}
      </Box>
    </Box>
  );
};

const SummarySection = ({ summary }) => {
  const [summaryViewMode, setSummaryViewMode] = useState('table');
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const theme = useTheme();

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ 
        position: 'absolute', 
        top: isSmallScreen ? 4 : 8, 
        right: isSmallScreen ? 4 : 8, 
        zIndex: 1,
        display: 'flex',
        gap: 1,
        bgcolor: 'background.paper',
        borderRadius: 2,
        p: 1,
        boxShadow: theme.shadows[2],
      }}>
        <Tooltip title="Table View">
          <IconButton 
            size={isSmallScreen ? "small" : "medium"}
            onClick={() => setSummaryViewMode('table')}
            color={summaryViewMode === 'table' ? 'primary' : 'default'}
            sx={{
              '&:hover': {
                bgcolor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
              },
            }}
          >
            <TableChart fontSize={isSmallScreen ? "small" : "medium"} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Text View">
          <IconButton
            size={isSmallScreen ? "small" : "medium"}
            onClick={() => setSummaryViewMode('text')}
            color={summaryViewMode === 'text' ? 'primary' : 'default'}
            sx={{
              '&:hover': {
                bgcolor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
              },
            }}
          >
            <Article fontSize={isSmallScreen ? "small" : "medium"} />
          </IconButton>
        </Tooltip>
      </Box>
      
      <Fade in={true}>
        <Box sx={{ mt: isSmallScreen ? 5 : 6 }}>
          {summaryViewMode === 'table' ? (
            <SummaryTable summary={summary} />
          ) : (
            <Paper
              elevation={3}
              sx={{
                whiteSpace: 'pre-wrap',
                p: isSmallScreen ? 2 : 3,
                borderRadius: 2,
                fontSize: isSmallScreen ? '0.875rem' : '1rem',
                lineHeight: 1.6,
                '& p': {
                  mb: 2,
                },
              }}
            >
              <Typography variant="body1">
                {summary}
              </Typography>
            </Paper>
          )}
        </Box>
      </Fade>
    </Box>
  );
};

const ClientInfoSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const ClientInformation = ({ clientName, clientEmail, chatSummary }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [expanded, setExpanded] = useState(false);

  const isValidPhoneNumber = (phone) => {
    if (!phone) return false;
    // Check if it's not a temporary ID
    if (phone.includes('temporary') || phone.includes('_dcce')) return false;
    // Basic phone number validation (can be enhanced based on your needs)
    return /^\+?[\d\s-]{10,}$/.test(phone.replace(/\D/g, ''));
  };

  const formatPhoneNumber = (phone) => {
    if (!isValidPhoneNumber(phone)) return null;
    const cleaned = phone.replace(/\D/g, '');
    return cleaned.startsWith('39') ? cleaned : `39${cleaned}`;
  };

  const getWhatsAppLink = (phone) => {
    const formattedPhone = formatPhoneNumber(phone);
    return formattedPhone ? `https://wa.me/${formattedPhone}` : null;
  };

  // Determine if client is anonymous or has valid details
  const isAnonymous = !clientName || clientName.trim() === '';
  const hasValidPhone = isValidPhoneNumber(clientEmail);
  const isEmail = !hasValidPhone && clientEmail && clientEmail.includes('@');

  // Format the phone number for display
  const formatPhoneNumberForDisplay = (phone) => {
    if (!hasValidPhone) return null;
    
    // Basic formatting for Italian phone numbers
    const cleaned = phone.replace(/\D/g, '');
    
    // Format: +39 XXX XXX XXXX
    if (cleaned.startsWith('39') && cleaned.length >= 11) {
      return `+${cleaned.slice(0, 2)} ${cleaned.slice(2, 5)} ${cleaned.slice(5, 8)} ${cleaned.slice(8)}`;
    } 
    // If not starting with country code, just format as XXX XXX XXXX
    else {
      return `+39 ${cleaned.slice(0, 3)} ${cleaned.slice(3, 6)} ${cleaned.slice(6)}`;
    }
  };

  const displayPhone = formatPhoneNumberForDisplay(clientEmail);

  return (
    <ClientInfoSection>
      {/* Animated header card that indicates it's clickable */}
      <Paper
        elevation={3}
        onClick={() => setExpanded(!expanded)}
        sx={{
          p: 2,
          borderRadius: 2,
          cursor: 'pointer',
          bgcolor: theme.palette.mode === 'dark' 
            ? alpha(theme.palette.primary.dark, 0.15) 
            : alpha(theme.palette.primary.light, 0.15),
          transition: 'all 0.3s ease',
          position: 'relative',
          overflow: 'hidden',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: theme.shadows[8],
            bgcolor: theme.palette.mode === 'dark' 
              ? alpha(theme.palette.primary.dark, 0.25) 
              : alpha(theme.palette.primary.light, 0.25),
          },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 2,
          flexGrow: 1
        }}>
          <Avatar 
            sx={{ 
              width: 48, 
              height: 48,
              bgcolor: theme.palette.primary.main,
              boxShadow: theme.shadows[2]
            }}
          >
            {!isAnonymous ? clientName.charAt(0).toUpperCase() : <PersonIcon />}
          </Avatar>
          <Box>
            <Typography 
              variant="subtitle1" 
              sx={{ 
                fontWeight: 600,
                color: theme.palette.text.primary
              }}
            >
              {!isAnonymous ? clientName : 'Cliente Anonimo'}
            </Typography>
            {hasValidPhone && (
              <Typography 
                variant="caption" 
                sx={{ 
                  color: theme.palette.text.secondary,
                  display: 'block'
                }}
              >
                {displayPhone}
              </Typography>
            )}
            {isEmail && (
              <Typography 
                variant="caption" 
                sx={{ 
                  color: theme.palette.text.secondary,
                  display: 'block'
                }}
              >
                {clientEmail}
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          flexShrink: 0,
          ml: 1
        }}>
          <Chip 
            label={expanded ? "Meno" : "Più dettagli"} 
            color="primary" 
            variant="outlined"
            size="small"
            icon={expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            sx={{ 
              minWidth: { xs: 'auto', sm: '120px' }
            }}
          />
        </Box>
      </Paper>

      {/* Expandable details section */}
      <Collapse in={expanded} timeout="auto">
        <Paper
          elevation={2}
          sx={{
            p: 2,
            mt: 1,
            borderRadius: 2,
            bgcolor: alpha(theme.palette.background.paper, 0.8),
            transition: 'all 0.3s ease',
          }}
        >
          {/* Contact Information Section */}
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2
          }}>
            {/* Contact Actions - Only show if valid phone number */}
            {hasValidPhone && (
              <>
                <Typography variant="body2" sx={{ fontWeight: 600, color: theme.palette.text.secondary }}>
                  Azioni Rapide
                </Typography>
                
                <Box sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 1,
                  justifyContent: 'flex-start'
                }}>
                  <Chip
                    icon={<PhoneIcon />}
                    label="Chiama"
                    clickable
                    component="a"
                    href={`tel:${clientEmail}`}
                    color="primary"
                    variant="outlined"
                    sx={{
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        bgcolor: alpha(theme.palette.primary.main, 0.1),
                        transform: 'translateY(-2px)',
                      },
                    }}
                  />
                  <Chip
                    icon={
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="18" 
                        height="18" 
                        viewBox="0 0 24 24"
                        style={{ fill: 'currentColor' }}
                      >
                        <path d="M12.031 6.172c-3.181 0-5.767 2.586-5.768 5.766-.001 1.298.38 2.27 1.019 3.287l-.582 2.128 2.182-.573c.978.58 1.911.928 3.145.929 3.178 0 5.767-2.587 5.768-5.766.001-3.187-2.575-5.77-5.764-5.771zm3.392 8.244c-.144.405-.837.774-1.17.824-.299.045-.677.063-1.092-.069-.252-.08-.575-.187-.988-.365-1.739-.751-2.874-2.502-2.961-2.617-.087-.116-.708-.94-.708-1.793s.448-1.273.607-1.446c.159-.173.346-.217.462-.217l.332.006c.106.005.249-.04.39.298.144.347.491 1.2.534 1.287.043.087.072.188.014.304-.058.116-.087.188-.173.289l-.26.304c-.087.086-.177.18-.076.354.101.174.449.741.964 1.201.662.591 1.221.774 1.394.86s.274.072.376-.043c.101-.116.433-.506.549-.68.116-.173.231-.145.39-.087s1.011.477 1.184.564.289.13.332.202c.045.072.045.419-.1.824z"/>
                      </svg>
                    }
                    label="WhatsApp"
                    clickable
                    component="a"
                    href={getWhatsAppLink(clientEmail)}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      bgcolor: alpha('#25D366', 0.1),
                      color: '#25D366',
                      borderColor: '#25D366',
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        bgcolor: alpha('#25D366', 0.2),
                        transform: 'translateY(-2px)',
                      },
                      '& .MuiChip-icon': {
                        color: '#25D366',
                      },
                    }}
                  />
                  <Chip
                    icon={<Chat />}
                    label="Invia SMS"
                    clickable
                    component="a"
                    href={`sms:${clientEmail}`}
                    sx={{
                      bgcolor: alpha(theme.palette.info.main, 0.1),
                      color: theme.palette.info.main,
                      borderColor: theme.palette.info.main,
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        bgcolor: alpha(theme.palette.info.main, 0.2),
                        transform: 'translateY(-2px)',
                      },
                      '& .MuiChip-icon': {
                        color: theme.palette.info.main,
                      },
                    }}
                  />
                </Box>
                
                <Divider />
              </>
            )}
            
            {/* Client Contact Information */}
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1
            }}>
              <Typography variant="body2" sx={{ fontWeight: 600, color: theme.palette.text.secondary }}>
                Informazioni Contatto
              </Typography>
              
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" sx={{ fontWeight: 600, width: '30%', borderBottom: 'none', pl: 0 }}>
                      Nome:
                    </TableCell>
                    <TableCell sx={{ borderBottom: 'none' }}>
                      {!isAnonymous ? clientName : 'Cliente Anonimo'}
                    </TableCell>
                  </TableRow>
                  {hasValidPhone && (
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: 600, borderBottom: 'none', pl: 0 }}>
                        Telefono:
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }}>
                        {displayPhone || clientEmail}
                      </TableCell>
                    </TableRow>
                  )}
                  {isEmail && (
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: 600, borderBottom: 'none', pl: 0 }}>
                        Email:
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }}>
                        {clientEmail}
                      </TableCell>
                    </TableRow>
                  )}
                  {!isAnonymous && clientEmail && !hasValidPhone && !isEmail && (
                    <TableRow>
                      <TableCell component="th" sx={{ fontWeight: 600, borderBottom: 'none', pl: 0 }}>
                        ID Cliente:
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }}>
                        {clientEmail}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
            
            {/* Chat Summary Information */}
            {chatSummary && (
              <>
                <Divider sx={{ my: 1 }} />
                <Box sx={{ mt: 1 }}>
                  <Typography variant="body2" sx={{ fontWeight: 600, color: theme.palette.text.secondary, mb: 1 }}>
                    Informazioni Chat
                  </Typography>
                  <SummarySection summary={chatSummary} />
                </Box>
              </>
            )}
          </Box>
        </Paper>
      </Collapse>
    </ClientInfoSection>
  );
};

const MessageContainer = ({ 
  children, 
  isAI, 
  isClient, 
  isAdmin,
  isAdminPredict,
  hasImage,
  showAuthor = false,
  authorName = null
}) => {
  const theme = useTheme();
  
  // Get background color based on message type
  const getBgColor = () => {
    if (isAI) return theme.palette.mode === 'dark' ? 'rgba(25, 118, 210, 0.08)' : 'rgba(25, 118, 210, 0.08)';
    if (isClient) return theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.05)';
    if (isAdmin) return theme.palette.mode === 'dark' ? 'rgba(76, 175, 80, 0.2)' : 'rgba(76, 175, 80, 0.1)';
    if (isAdminPredict) return theme.palette.mode === 'dark' ? 'rgba(156, 39, 176, 0.2)' : 'rgba(156, 39, 176, 0.1)';
    return 'transparent';
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignSelf: isClient ? 'flex-end' : 'flex-start',
        maxWidth: { xs: '90%', sm: '70%' },
        mb: 2,
        position: 'relative'
      }}
    >
      {showAuthor && authorName && (
        <Typography
          variant="caption"
          sx={{
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            fontSize: '0.75rem',
            display: 'block',
            mb: 0.5,
            ml: 1
          }}
        >
          {authorName}
        </Typography>
      )}
      <Box
        sx={{
          backgroundColor: getBgColor(),
          padding: hasImage ? 1 : 2,
          borderRadius: 2,
          position: 'relative',
          wordBreak: 'break-word',
          '& img': {
            maxWidth: '100%',
            borderRadius: 1
          },
          '& pre': {
            whiteSpace: 'pre-wrap',
            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0.05)',
            padding: 1,
            borderRadius: 1,
            overflowX: 'auto'
          }
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

const ChatHistoryDetail = ({ 
  chatId, 
  humanControl, 
  onClose,
  clientName,
  clientEmail,
  wsConnection = null,
  whatsapp,
  isConnected,
  onPredictMessage,
  setHumanControl,
  isCurrentUserController
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const [chatHistory, setChatHistory] = useState([]);
  const [chatSummary, setChatSummary] = useState('');
  const [labelSegmentation, setLabelSegmentation] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editing, setEditing] = useState(false);
  const [editingMessage, setEditingMessage] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', type: 'success' });
  const [feedbackDialog, setFeedbackDialog] = useState({ open: false, messageId: null });
  const [feedback, setFeedback] = useState({ category: '', comment: '' });
  const lastMessageRef = useRef(null);
  const [unsentMessage, setUnsentMessage] = useState(null);
  const [summaryExpanded, setSummaryExpanded] = useState(false);
  const wsRef = useRef(null);
  const [authError, setAuthError] = useState(false);
  const isManuallyClosed = useRef(false);
  const reconnectionAttempts = useRef(0);
  const tempIdCounter = useRef(0);
  const [summaryDialogOpen, setSummaryDialogOpen] = useState(false);
  const [summaryViewMode, setSummaryViewMode] = useState('table');
  const [isInitialized, setIsInitialized] = useState(false);
  const [wsStatus, setWsStatus] = useState('connecting');
  const messageQueue = useRef([]);
  const [isPredicting, setIsPredicting] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [feedbackStates, setFeedbackStates] = useState({});
  // Add state to track if we've already processed a full_chat for this chat
  const [fullChatProcessed, setFullChatProcessed] = useState(false);

  const { userRole, appAccess, token = localStorage.getItem('token') } = useAuth();
  const permissions = appAccess['ai_app'] || {};
  const canEdit = permissions.can_edit || userRole === 'superadmin';

  const FileDisplay = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  }));

  const DateDivider = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(3, 0),
    '&::before, &::after': {
      content: '""',
      flex: 1,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  }));

  const DateChip = styled(Chip)(({ theme }) => ({
    margin: theme.spacing(0, 2),
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.text.secondary,
    fontSize: '0.75rem',
    height: 24,
  }));

  // Modify the FeedbackContainer styled component to be responsive
  const FeedbackContainer = styled(Box)(({ theme, isSmallScreen }) => ({
    display: 'flex',
    // Only hide on desktop, make it visible on mobile
    opacity: isSmallScreen ? 1 : 0,
    transition: 'opacity 0.2s ease-in-out',
    '& .MuiIconButton-root': {
      padding: isSmallScreen ? 8 : 4,
      backgroundColor: alpha(theme.palette.background.paper, 0.9),
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    ...(isSmallScreen
      ? {
          // Mobile styles
          flexDirection: 'row',
          justifyContent: 'center',
          width: '100%',
          gap: theme.spacing(1),
          marginTop: theme.spacing(0.5),
          // Add bottom margin for better spacing
          marginBottom: theme.spacing(1),
        }
      : {
          // Desktop styles
          position: 'absolute',
          right: '-40px',
          top: '50%',
          transform: 'translateY(-50%)',
          flexDirection: 'column',
          gap: theme.spacing(0.5),
        })
  }));

  // Add a responsive version of the MessageWrapper
  const MessageWrapper = styled(Box)(({ theme, isSmallScreen }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    // Only make hover show icons on desktop, not on mobile
    ...(isSmallScreen 
      ? {}  // No hover effect on mobile
      : {
          '&:hover .feedback-container': {
            opacity: 1,
          },
        }),
    width: isSmallScreen ? '100%' : 'auto',
  }));

  // Effect for handling WebSocket messages
  useEffect(() => {
    if (!wsConnection || !chatId) return;

    const handleMessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        handleWebSocketMessage(data);
      } catch (error) {
        console.error('Failed to parse WebSocket message:', error);
      }
    };

    wsConnection.addEventListener('message', handleMessage);
    
    // Send initial request only once when connection is ready
    if (!isInitialized && wsConnection.readyState === WebSocket.OPEN) {
      console.log(`Requesting chat data for chat ${chatId}`);
      const requestData = {
        action: 'full_chat',
        pk: chatId,
        context: {
          user_role: userRole,
          permissions: permissions
        }
      };
      try {
        wsConnection.send(JSON.stringify(requestData));
        setIsInitialized(true);
      } catch (error) {
        console.error(`Failed to send request for chat ${chatId}:`, error);
        setError('Failed to send request. Please try again.');
      }
    }

    return () => {
      wsConnection.removeEventListener('message', handleMessage);
    };
  }, [wsConnection, chatId, isInitialized, userRole, permissions]);

  // Effect for handling local WebSocket if no shared connection
  useEffect(() => {
    if (wsConnection) {
      setWsStatus('connected');
      return;
    }

    let localWs = null;
    let isComponentMounted = true;

    const initializeWebSocket = () => {
      try {
        localWs = new WebSocket(`${WSS_BASE_URL}/ws/admin_chat/?token=${token}`);
        wsRef.current = localWs;

        localWs.onopen = () => {
          console.log('Local WebSocket Connected');
          if (isComponentMounted) {
            setWsStatus('connected');
            setAuthError(false);
            
            // Process any queued messages
            if (messageQueue.current.length > 0) {
              messageQueue.current.forEach(message => {
                try {
                  localWs.send(JSON.stringify(message));
                } catch (error) {
                  console.error('Failed to send queued message:', error);
                }
              });
              messageQueue.current = [];
            }
          }
        };

        localWs.onerror = (error) => {
          console.error('WebSocket Error:', error);
          if (isComponentMounted) {
            setWsStatus('error');
            setError('Connection error. Please try again.');
          }
        };

        localWs.onclose = (event) => {
          console.log(`WebSocket closed with code: ${event.code}, reason: ${event.reason}, wasClean: ${event.wasClean}`);
          if (isComponentMounted) {
            setWsStatus('closed');
            if (!isManuallyClosed.current) {
              setError('Connection closed. Please refresh the page.');
            }
          }
        };

        localWs.onmessage = (event) => {
          try {
            const data = JSON.parse(event.data);
            if (isComponentMounted) {
              handleWebSocketMessage(data);
            }
          } catch (error) {
            console.error('Failed to parse WebSocket message:', error);
          }
        };
      } catch (error) {
        console.error('Failed to create WebSocket:', error);
        if (isComponentMounted) {
          setWsStatus('error');
          setError('Failed to connect to chat server');
        }
      }
    };

    initializeWebSocket();

    return () => {
      console.log('Cleaning up WebSocket connection');
      isComponentMounted = false;
      isManuallyClosed.current = true;
      
      if (wsRef.current && wsRef.current.readyState !== WebSocket.CLOSED) {
        try {
          wsRef.current.close(1000, 'Component unmounted');
        } catch (e) {
          console.error('Error closing WebSocket:', e);
        }
      }
      
      wsRef.current = null;
      setWsStatus('closed');
    };
  }, [token, wsConnection]);

  // Reset initialization when chatId changes
  useEffect(() => {
    setIsInitialized(false);
    setLoading(true);
    setChatHistory([]);
    setChatSummary('');
    setLabelSegmentation('');
    setError(null);
    // Reset fullChatProcessed flag when changing chats
    setFullChatProcessed(false);
    
    // Reset messageQueue when chatId changes
    messageQueue.current = [];
  }, [chatId]);

  useEffect(() => {
    // Fetch current user information when component mounts
    const getCurrentUser = async () => {
      try {
        const userData = await fetchCurrentUserRole();
        setCurrentUser(userData);
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    };
    
    getCurrentUser();
  }, []);

  const handleWebSocketMessage = useCallback((data) => {
    console.log('Received WebSocket message:', data);
    
    // Handle specific chat-in-use error
    if (data.error === 'Chat is currently in use by another user') {
      console.log('Chat is in use by another user');
      if (setHumanControl) {
        setHumanControl(false);
      }
      return;
    }
    
    const action = data.action || data.type;
    
    // Extract chat_id correctly from different message structures
    let messageChatId = null;
    if (data.data) {
      // First check if chat is nested inside data
      if (data.data.chat && data.data.chat.id) {
        messageChatId = data.data.chat.id;
      } 
      // Then check if chat_id is directly on the data object
      else if (data.data.chat_id) {
        messageChatId = data.data.chat_id;
      }
    }
    
    // For some message types, check chat_id at root level
    if (!messageChatId && data.pk) {
      messageChatId = data.pk;
    }
    
    // Prevent excessive console logging
    if (action !== 'ping') {
      console.log(`Message type: ${action}, Message chat ID: ${messageChatId}, Current chat ID: ${chatId}`);
    }
    
    // Skip processing messages for other chats - except for full_chat which we need to process
    if (messageChatId && Number(messageChatId) !== Number(chatId) && action !== 'full_chat') {
      console.log(`Skipping message for chat ${messageChatId} - not relevant to current chat ${chatId}`);
      return;
    }
    
    // Skip processing irrelevant message types to prevent re-renders
    if (action === 'ping' || action === 'chat_joined' || action === 'joined_chat') {
      return;
    }
    
    switch (action) {
      case 'full_chat':
        // Only process the first full_chat message for a given chat ID
        if (fullChatProcessed) {
          console.log('Skipping duplicate full_chat message - already processed');
          return;
        }
        
        // For full_chat, extra verification
        if (data.pk && Number(data.pk) !== Number(chatId)) {
          console.log(`Skipping full chat data for chat ${data.pk} - not relevant to current chat ${chatId}`);
          return;
        }
        
        // Rest of existing full_chat handling
        if (data.error) {
          console.error('Error fetching chat:', data.error);
          setError(data.error);
          setChatHistory([]);
          setLoading(false);
          return;
        }
        
        if (!data.data) {
          console.error('Invalid data structure received');
          setError('Invalid data received from server');
          setChatHistory([]);
          setLoading(false);
          return;
        }

        try {
          const chatData = Array.isArray(data.data) ? data.data : [];
          console.log('Processing chat data:', chatData);
          
          // Get whatsapp status from chat info and apply to all messages
          const chatInfo = chatData[0]?.chat || {};
          const isWhatsApp = chatInfo.whatsapp || false;
          
          // Apply whatsapp property to all messages and initialize feedback states
          const processedChatData = chatData.map(message => ({
            ...message,
            whatsapp: isWhatsApp
          }));
          
          // Initialize feedback states from message data using vote and comment fields
          const initialFeedbackStates = {};
          processedChatData.forEach(message => {
            if (message.vote !== null || message.comment) {
              initialFeedbackStates[message.id] = {
                vote: message.vote,
                comment: message.comment
              };
            }
          });
          
          console.log('Initial feedback states:', initialFeedbackStates);
          
          setChatHistory(processedChatData);
          setFeedbackStates(initialFeedbackStates);
          setChatSummary(chatInfo.summary || '');
          setLabelSegmentation(data.label_segmentation || '');
          setLoading(false);
          
          // Mark full_chat as processed to prevent duplicate processing
          setFullChatProcessed(true);
          
        } catch (err) {
          console.error('Error processing chat data:', err);
          setError('Error processing chat data');
          setChatHistory([]);
          setLoading(false);
        }
        break;

      case 'new_message':
      case 'update_chat':
        console.log("update_chat/new_message received:", data);
        if (data.data) {
          const incomingMessage = {
            id: data.data.id || data.data.messageId,
            chat_id: data.data.chat?.id || chatId,
            sender_message: data.data.sender_message?.replace(/\r\n/g, '\n').replace(/\s+/g, ' ').trim(),
            bot_message: data.data.bot_message?.replace(/\r\n/g, '\n').replace(/\s+/g, ' ').trim(),
            created_at: data.data.created_at || new Date().toISOString(),
            sent: data.data.sent !== false,
            sources: data.data.sources || [],
            car_stocks: data.data.car_stocks || data.data.sources || [],
            tool: data.data.tool,
            whatsapp: data.data.whatsapp,
            vote: data.data.vote,
            comment: data.data.comment
          };

          // Update feedback states if the message has feedback
          if (incomingMessage.vote !== null || incomingMessage.comment) {
            setFeedbackStates(prev => ({
              ...prev,
              [incomingMessage.id]: {
                vote: incomingMessage.vote,
                comment: incomingMessage.comment
              }
            }));
          }

          setChatHistory((prevHistory) => {
            if (!prevHistory) return [incomingMessage];
            
            const index = prevHistory.findIndex(
              (msg) => msg.id === incomingMessage.id || 
                     (msg.id === data.data.messageId) ||
                     (msg.isTemp && msg.created_at === incomingMessage.created_at)
            );
            
            if (index !== -1) {
              const updatedHistory = [...prevHistory];
              updatedHistory[index] = {
                ...updatedHistory[index],
                ...incomingMessage,
                isTemp: updatedHistory[index].isTemp
              };
              return updatedHistory;
            } else {
              return [...prevHistory, incomingMessage];
            }
          });
        }
        break;

      case 'message_sent':
        const { chatHistoryId, temp_id } = data;
        setChatHistory((prevHistory) => {
          const index = prevHistory.findIndex((msg) => msg.id === temp_id);
          if (index !== -1) {
            const updatedMessage = {
              ...prevHistory[index],
              id: chatHistoryId,
              isTemp: false,
            };
            const newHistory = [...prevHistory];
            newHistory[index] = updatedMessage;
            return newHistory;
          }
          return prevHistory;
        });
        setUnsentMessage(null);
        break;
      case 'message_status_updated':
        const { chatHistoryId: updatedId, sent } = data;
        setChatHistory((prevHistory) => {
          const index = prevHistory.findIndex((msg) => msg.id === updatedId);
          if (index !== -1) {
            const updatedMessage = {
              ...prevHistory[index],
              sent: sent,
            };
            const newHistory = [...prevHistory];
            newHistory[index] = updatedMessage;
            return newHistory;
          }
          return prevHistory;
        });
        break;
        case 'admin_message':
          console.log("admin_message received:", data);
          if (data.data) {
            // Log the author information for debugging
            console.log("Author data:", {
              authorName: data.data.author_message,
              authorId: data.data.author_message_id,
              currentUserId: currentUser?.user_id
            });
            
            const isFromCurrentUser = currentUser && 
              String(data.data.author_message_id) === String(currentUser.user_id);
            
            const adminMessage = {
              id: data.data.id,
              chat_id: data.data.chat_id,
              message_type: 'admin',
              admin_message: data.data.admin_message,
              created_at: data.data.created_at,
              author_full_name: data.data.author_message, // Always store the author name
              author_id: data.data.author_message_id,
              is_from_current_user: isFromCurrentUser
            };
            
            console.log("Processed admin message:", adminMessage);
            setChatHistory(prevHistory => [...prevHistory, adminMessage]);
          }
          break;
      case 'predict_message':
        if (data.data) {
          const predictedMessage = data.data;
          console.log('Processing predicted message:', predictedMessage);
          
          // Create a standardized message object, preserving sender_message if it exists
          const messageObject = {
            id: predictedMessage.id,
            chat_id: predictedMessage.chat_id,
            sender_message: predictedMessage.sender_message, // Preserve sender message
            bot_message: predictedMessage.bot_message,
            sent: predictedMessage.sent,
            status: predictedMessage.status,
            sources: predictedMessage.sources || [],
            tool: predictedMessage.tool,
            car_stocks: predictedMessage.sources || [], // Use sources as car_stocks
            created_at: new Date().toISOString(), // Add timestamp if not present
          };

          // Add the message to chat history, preserving existing sender message if updating
          setChatHistory(prevHistory => {
            const index = prevHistory.findIndex(msg => msg.id === messageObject.id);
            if (index !== -1) {
              const updatedHistory = [...prevHistory];
              // Merge with existing message to preserve sender_message
              updatedHistory[index] = {
                ...updatedHistory[index],
                ...messageObject,
                sender_message: updatedHistory[index].sender_message || messageObject.sender_message,
              };
              return updatedHistory;
            }
            return [...prevHistory, messageObject];
          });

          // If message is not sent, also set it as unsent message
          if (predictedMessage.sent === false) {
            console.log('Setting unsent message:', messageObject);
            setUnsentMessage(messageObject);
            setPredicting(false);
          }
        }
        break;
      case 'feedback_updated':
        if (data.data) {
          const { message_id, vote, comment } = data.data;
          setFeedbackStates(prev => ({
            ...prev,
            [message_id]: { vote, comment }
          }));
          
          // Also update the message in chat history
          setChatHistory(prev => 
            prev.map(msg => 
              msg.id === message_id 
                ? { ...msg, vote, comment }
                : msg
            )
          );
        }
        break;
      default:
        console.log('Unhandled WebSocket message:', data);
    }
  }, [chatId, setHumanControl, fullChatProcessed]);

  useEffect(() => {
    if (Array.isArray(chatHistory) && chatHistory.length > 0 && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatHistory]);

  useEffect(() => {
    const lastMessage = chatHistory[chatHistory.length - 1];
    if (lastMessage && lastMessage.sent === false && lastMessage.sender_message) {
      setUnsentMessage(lastMessage);
    } else {
      setUnsentMessage(null);
    }
  }, [chatHistory]);

  const sendWebSocketMessage = (message) => {
    const ws = wsConnection || wsRef.current;
    
    if (ws?.readyState === WebSocket.OPEN) {
      try {
        ws.send(JSON.stringify(message));
        return true;
      } catch (error) {
        console.error('Error sending WebSocket message:', error);
        messageQueue.current.push(message);
        return false;
      }
    } else {
      console.log('WebSocket not ready, queueing message:', message);
      messageQueue.current.push(message);
      return false;
    }
  };

  useEffect(() => {
    const ws = wsConnection || wsRef.current;
    
    if (ws?.readyState === WebSocket.OPEN && messageQueue.current.length > 0) {
      console.log('Processing queued messages');
      messageQueue.current.forEach(message => {
        ws.send(JSON.stringify(message));
      });
      messageQueue.current = [];
    }
  }, [wsStatus, wsConnection]);

  const handleSendMessage = async (messageData) => {
    try {
      // Create a temporary message with the correct properties
      const tempMessage = {
        id: `temp-${Date.now()}`,
        created_at: new Date().toISOString(),
        bot_message: messageData.text,
        car_stocks: messageData.cars || [],
        // Set the tool property for form messages - this is what was missing
        tool: messageData.include_registration_form ? "customer_registration" : null,
        sent: true,
        isTemp: true
      };

      // Add the temporary message to the UI immediately
      setChatHistory(prev => [...prev, tempMessage]);

      // Send the message to the WebSocket
      if (wsConnection && wsConnection.readyState === WebSocket.OPEN) {
        wsConnection.send(JSON.stringify({
          action: 'send_message',
          pk: chatId,
          message: messageData.text,
          car_ids: messageData.cars ? messageData.cars.map(car => car.vehicleid) : [],
          include_registration_form: messageData.include_registration_form || false
        }));
      } else {
        setError('Connection to server lost. Please refresh the page.');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setError('Failed to send message. Please try again.');
    }
  };

  const handleFeedback = (messageId, vote) => {
    const ws = wsConnection || wsRef.current;
    
    if (ws?.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        action: 'update_feedback',
        message_id: messageId,
        vote: vote,
      }));

      // Update local state
      setFeedbackStates(prev => ({
        ...prev,
        [messageId]: {
          ...prev[messageId],
          vote: vote
        }
      }));
    }
  };

  const handleOpenFeedbackDialog = (messageId, message) => {
    setFeedbackDialog({
      open: true,
      messageId,
      message,
      currentFeedback: feedbackStates[messageId]
    });
  };

  const handleSubmitFeedback = (comment) => {
    const ws = wsConnection || wsRef.current;
    
    if (ws?.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({
        action: 'update_feedback',
        message_id: feedbackDialog.messageId,
        comment: comment,
        vote: feedbackStates[feedbackDialog.messageId]?.vote || 0
      }));

      // Update local state
      setFeedbackStates(prev => ({
        ...prev,
        [feedbackDialog.messageId]: {
          ...prev[feedbackDialog.messageId],
          comment: comment
        }
      }));
    }
    
    setFeedbackDialog(prev => ({ ...prev, open: false }));
  };

  const handleEdit = (message) => {
    setEditingMessage(message);
    setEditing(true);
  };

  const handleSave = () => {
    sendWebSocketMessage({
      action: 'edit_message',
      message: editingMessage,
    });
    setEditing(false);
    setEditingMessage(null);
  };

  const handleChange = (e) => {
    setEditingMessage({ ...editingMessage, [e.target.name]: e.target.value });
  };

  const MarkdownContent = ({ content }) => (
    <ReactMarkdown
      components={{
        h1: ({ node, ...props }) => <Typography variant="h4" gutterBottom {...props} />,
        h2: ({ node, ...props }) => <Typography variant="h5" gutterBottom {...props} />,
        h3: ({ node, ...props }) => <Typography variant="h6" gutterBottom {...props} />,
        p: ({ node, ...props }) => (
          <Typography 
            variant="body1" 
            sx={{ 
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word',
              mb: 1,
              '&:last-child': {
                mb: 0
              }
            }} 
            {...props} 
          />
        ),
        a: ({ node, ...props }) => <a style={{ color: theme.palette.primary.main }} {...props} />,
        code: ({ node, inline, className, children, ...props }) => {
          const match = /language-(\w+)/.exec(className || '');
          return !inline && match ? (
            <SyntaxHighlighter
              style={vscDarkPlus}
              language={match[1]}
              PreTag="div"
              {...props}
            >
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
    >
      {content}
    </ReactMarkdown>
  );

  const handleSummaryClick = () => {
    setSummaryDialogOpen(true);
  };

  const handleCloseSummaryDialog = () => {
    setSummaryDialogOpen(false);
  };

  const getFileUrl = (fileObject) => {
    if (!fileObject) return null;

    // If fileObject is a string, return it directly
    if (typeof fileObject === 'string') return fileObject;

    // If fileObject is an object with a file_url property, return that
    if (typeof fileObject === 'object' && fileObject.file_url) {
      return fileObject.file_url;
    }

    return null;
  };

  const getAbsoluteUrl = (relativeUrl, token, chatId) => {
    if (!relativeUrl) return null;

    const urlString = String(relativeUrl);

    // If URL is already absolute, return it without adding parameters
    if (urlString.startsWith('http://') || urlString.startsWith('https://')) {
      return urlString;
    }

    // Build the base URL
    const baseUrl = `${API_DOMAIN}${urlString.startsWith('/') ? '' : '/'}${urlString}`;

    // Add token and chat_id as query parameters if they exist
    const params = new URLSearchParams();
    if (token) params.append('token', token);
    if (chatId) params.append('chat_id', chatId);

    const queryString = params.toString();
    console.log(queryString);
    return queryString ? `${baseUrl}?${queryString}` : baseUrl;
  };

  const getFileDetails = (fileUrl) => {
    if (!fileUrl) return { fileName: 'Unknown File', fileExtension: '' };

    const urlString = String(fileUrl);
    const urlWithoutParams = urlString.split('?')[0];
    const urlParts = urlWithoutParams.split('/');
    const fileName = urlParts[urlParts.length - 1];
    const fileExtension = fileName.split('.').pop()?.toLowerCase() || '';

    return { fileName, fileExtension };
  };

  const renderFile = (fileObject, isBot, currentToken, currentChatId) => {
    const fileUrl = getFileUrl(fileObject);
    if (!fileUrl) {
      console.log('No file URL provided:', fileObject);
      return null;
    }

    // Get file details from the original URL (without parameters)
    const { fileName, fileExtension } = getFileDetails(fileUrl);

    // Generate the absolute URL with parameters
    const absoluteUrl = getAbsoluteUrl(fileUrl, currentToken, currentChatId);
    if (!absoluteUrl) {
      console.error('Unable to generate absolute URL for:', fileUrl);
      return null;
    }

    // Log the URL being used
    console.log('Attempting to load file from:', absoluteUrl);

    // Expanded file type checks
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'bmp'];
    const isImage = imageExtensions.includes(fileExtension.toLowerCase());
    const isAudio = ['mp3', 'wav', 'ogg', 'm4a', 'aac'].includes(fileExtension.toLowerCase());

    // Create URL object to check validity
    let isValidUrl = false;
    try {
      new URL(absoluteUrl);
      isValidUrl = true;
    } catch (e) {
      console.error('Invalid URL:', absoluteUrl, e);
    }

    return (
      <FileDisplay>
        {isImage ? (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Box
              sx={{
                width: '100%',
                maxWidth: '500px',
                minHeight: '200px',
                position: 'relative',
                bgcolor: 'background.paper',
                borderRadius: 1,
                overflow: 'hidden',
                boxShadow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {isValidUrl && (
                <>
                  <img
                    src={absoluteUrl}
                    alt={fileName}
                    style={{
                      display: 'block',
                      width: '100%',
                      height: 'auto',
                      maxHeight: '400px',
                      objectFit: 'contain',
                    }}
                    onError={(e) => {
                      console.error('Image load error for URL:', absoluteUrl);
                      console.error('File object:', fileObject);
                      console.error('Error event:', e);

                      e.target.onerror = null; // Prevent infinite loop
                      e.target.src = '/api/placeholder/400/300';
                      e.target.style.opacity = '0.5'; // Show it's a fallback
                    }}
                    onLoad={(e) => {
                      console.log('Image loaded successfully:', absoluteUrl);
                      e.target.style.opacity = '1';
                    }}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'rgba(255, 255, 255, 0.8)',
                      opacity: 0,
                      transition: 'opacity 0.3s',
                      '&.loading': {
                        opacity: 1,
                      },
                    }}
                    className="image-loading"
                  >
                    <CircularProgress size={40} />
                  </Box>
                </>
              )}
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 1,
              }}
            >
              <Typography variant="caption" color="text.secondary">
                {fileName} {!isValidUrl && '(Invalid URL)'}
              </Typography>
              {isValidUrl && (
                <Tooltip title="Download image">
                  <IconButton
                    component="a"
                    href={absoluteUrl}
                    download={fileName}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                  >
                    <ImageIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        ) : isAudio ? (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Box
              sx={{
                width: '100%',
                maxWidth: '500px',
                bgcolor: 'background.paper',
                borderRadius: 1,
                p: 2,
                boxShadow: 1,
              }}
            >
              <audio
                controls
                style={{ width: '100%' }}
                src={absoluteUrl}
                onError={(e) => {
                  console.error('Audio load error:', e);
                  console.error('Audio URL:', absoluteUrl);
                }}
              >
                Your browser does not support the audio element.
              </audio>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                maxWidth: '500px',
                px: 1,
              }}
            >
              <Typography variant="caption" color="text.secondary">
                {fileName}
              </Typography>
              {isValidUrl && (
                <Tooltip title="Download audio">
                  <IconButton
                    component="a"
                    href={absoluteUrl}
                    download={fileName}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                  >
                    <AudioIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              bgcolor: 'background.paper',
              borderRadius: 1,
              p: 2,
              boxShadow: 1,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <FileIcon color="primary" />
              <Typography variant="body2">{fileName}</Typography>
            </Box>
            {isValidUrl && (
              <Tooltip title="Download file">
                <IconButton
                  component="a"
                  href={absoluteUrl}
                  download={fileName}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="small"
                >
                  <FileIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )}
      </FileDisplay>
    );
  };

  const LabelSegmentationAccordion = () => (
    <SummaryAccordion>
      <SummaryAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="label-segmentation-content"
        id="label-segmentation-header"
      >
        <Typography variant="h6">
          <LabelIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
          Label Segmentation
        </Typography>
      </SummaryAccordionSummary>
      <SummaryAccordionDetails>
        <Typography>{labelSegmentation}</Typography>
      </SummaryAccordionDetails>
    </SummaryAccordion>
  );

  const handleCloseClick = () => {
    // Check if there's a state in history (our effect added one when opening the chat)
    if (window.history.state && window.history.state.chatId) {
      // Use browser's back button functionality
      window.history.back();
    } else if (onClose) {
      // Fall back to the onClose prop
      onClose();
    }
  };

  const handlePredictMessage = useCallback((message = '') => {
    const ws = wsConnection || wsRef.current;
    
    if (ws?.readyState === WebSocket.OPEN) {
      console.log('Sending predict message request for chat:', chatId);
      const payload = {
        action: 'predict_message',
        chat_id: chatId
      };
      
      // Only add suggestion_ai if message is not empty
      if (message.trim()) {
        payload.suggestion_ai = message;
      }
      
      ws.send(JSON.stringify(payload));
    } else {
      setError('Connection to server lost. Please refresh the page.');
    }
  }, [chatId, wsConnection]);

  const FeedbackDialog = ({ open, onClose, onSubmit, currentFeedback }) => {
    const [comment, setComment] = useState('');

    useEffect(() => {
      if (open) {
        setComment(currentFeedback?.comment || '');
      }
    }, [open, currentFeedback]);

    return (
      <Dialog 
        open={open} 
        onClose={onClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Feedback sulla risposta
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Come possiamo migliorare questa risposta?"
            fullWidth
            multiline
            rows={4}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Annulla</Button>
          <Button 
            onClick={() => onSubmit(comment)} 
            variant="contained"
            color="primary"
          >
            Invia Feedback
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // Add a function to handle showing feedback icons on mobile
  const handleShowFeedbackIcons = (messageId) => {
    if (isSmallScreen) {
      // For mobile, show a dialog with feedback options instead of inline icons
      handleOpenFeedbackDialog(messageId, chatHistory.find(msg => msg.id === messageId)?.bot_message);
    }
  };

  // Add useMemo to prevent unnecessary re-renders
  const memoizedChatHistory = useMemo(() => (
    chatHistory.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
  ), [chatHistory]);

  // Memoize the render function for each message to prevent re-renders
  const MessageItem = useMemo(() => ({ message, index }) => {
    const date = new Date(message.created_at);
    const time = formatMessageTime(date);
    const isFirstMessage = index === 0;
    const previousMessage = index > 0 ? chatHistory[index - 1] : null;
    const previousDate = previousMessage ? new Date(previousMessage.created_at) : null;
    const showDate = isFirstMessage || (previousDate && !isSameDay(date, previousDate));
    const dateString = formatDate(date);

    // Get message author info for admin messages
    const isAdminMessageFromOthers = 
      message.message_type === 'admin' && 
      message.author_full_name && 
      !message.is_from_current_user;

    return (
      <React.Fragment key={message.id || index}>
        {showDate && (
          <DateDivider>
            <DateChip label={dateString} />
          </DateDivider>
        )}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          ref={index === chatHistory.length - 1 ? lastMessageRef : null}
        >
          <ListItem
            sx={{
              flexDirection: 'column',
              alignItems: 'stretch',
              p: 0,
              mb: 1,
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: isAdminMessageFromOthers ? 'flex-start' : 'stretch',
              }}
            >
              {/* Display all message types here */}
              {/* Client Messages */}
              {message.sender_message && (
                <Box 
                  sx={{ 
                    position: 'relative', 
                    width: 'fit-content',
                    maxWidth: '100%',
                    alignSelf: 'flex-start',
                    ml: 1,
                  }}
                >
                  <motion.div
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.4, ease: "easeOut" }}
                  >
                    <MessagePaper 
                      elevation={1} 
                      isBot={false} 
                      isWhatsApp={whatsapp || message.whatsapp}
                    >
                      <MarkdownContent content={formatMessageContent(message.sender_message)} />
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          mt: 1,
                          opacity: 0.6,
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            fontSize: '0.7rem',
                            fontWeight: 400,
                          }}
                        >
                          {time.time}
                        </Typography>
                      </Box>
                    </MessagePaper>
                  </motion.div>
                  
                  {/* Form submission indicator */}
                  {message.include_registration_form && (
                    <Box sx={{ 
                      mt: 0.5, 
                      ml: 1, 
                      display: 'flex', 
                      alignItems: 'center',
                      gap: 0.5
                    }}>
                      <AssignmentIcon 
                        color="primary" 
                        fontSize="small" 
                        sx={{ fontSize: '0.9rem' }} 
                      />
                      <Typography 
                        variant="caption" 
                        color="primary.main"
                        sx={{ 
                          fontStyle: 'italic',
                          fontSize: '0.75rem'
                        }}
                      >
                        Registration form included with this message
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}

              {/* Bot Messages */}
              {message.bot_message && (message.sent !== false || message.isTemp) && (
                <Box 
                  sx={{ 
                    position: 'relative', 
                    width: 'fit-content',
                    maxWidth: '100%',
                    alignSelf: 'flex-end',
                    mr: isSmallScreen ? 0 : 1,
                    width: isSmallScreen ? '100%' : 'auto',
                  }}
                >
                  <MessageWrapper isSmallScreen={isSmallScreen}>
                    <motion.div
                      initial={{ opacity: 0, x: 20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.4, ease: "easeOut" }}
                      onClick={() => isSmallScreen ? handleShowFeedbackIcons(message.id) : null}
                      sx={{ 
                        cursor: isSmallScreen ? 'pointer' : 'default',
                        width: isSmallScreen ? '100%' : 'auto'
                      }}
                    >
                      <MessagePaper 
                        elevation={1} 
                        isBot={true} 
                        isWhatsApp={whatsapp || message.whatsapp}
                      >
                        <MarkdownContent content={formatMessageContent(message.bot_message)} />
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mt: 1,
                          }}
                        >
                          {/* Show feedback status if exists */}
                          {feedbackStates[message.id] && (
                            <Box sx={{ 
                              display: 'flex', 
                              alignItems: 'center', 
                              gap: 1,
                              opacity: 0.7
                            }}>
                              {feedbackStates[message.id].vote === 1 && (
                                <ThumbUp fontSize="small" color="primary" sx={{ opacity: 0.7 }} />
                              )}
                              {feedbackStates[message.id].vote === -1 && (
                                <ThumbDown fontSize="small" color="error" sx={{ opacity: 0.7 }} />
                              )}
                              {feedbackStates[message.id].comment && (
                                <CommentIcon fontSize="small" color="primary" sx={{ opacity: 0.7 }} />
                              )}
                            </Box>
                          )}
                          <Typography
                            variant="caption"
                            sx={{
                              fontSize: '0.7rem',
                              ml: 'auto',
                              color: (whatsapp || message.whatsapp) 
                                ? 'rgba(0, 0, 0, 0.7)' 
                                : 'rgba(255, 255, 255, 0.9)',
                            }}
                          >
                            {time.time}
                          </Typography>
                        </Box>
                        
                        {/* Car stocks section with special handling for mobile */}
                        {message.car_stocks && Array.isArray(message.car_stocks) && message.car_stocks.length > 0 && (
                          <Box 
                            onClick={(e) => isSmallScreen ? e.stopPropagation() : null}
                            sx={{ width: '100%' }}
                          >
                            {isSmallScreen ? (
                              <MobileCarCarousel cars={message.car_stocks} />
                            ) : (
                              <CarAttachmentsContainer>
                                {message.car_stocks.map((car) => (
                                  <CarStockCard
                                    key={car.vehicleid}
                                    car={car}
                                    isSmallScreen={isSmallScreen}
                                  />
                                ))}
                              </CarAttachmentsContainer>
                            )}
                          </Box>
                        )}
                      </MessagePaper>
                    </motion.div>

                    {/* Feedback buttons with responsive positioning */}
                    <FeedbackContainer 
                      className="feedback-container" 
                      isSmallScreen={isSmallScreen}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Tooltip title="Mi piace">
                        <IconButton
                          size={isSmallScreen ? "small" : "small"}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleFeedback(message.id, 1);
                          }}
                          color={feedbackStates[message.id]?.vote === 1 ? 'primary' : 'default'}
                        >
                          <ThumbUp fontSize={isSmallScreen ? "small" : "small"} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Non mi piace">
                        <IconButton
                          size={isSmallScreen ? "small" : "small"}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleFeedback(message.id, -1);
                          }}
                          color={feedbackStates[message.id]?.vote === -1 ? 'error' : 'default'}
                        >
                          <ThumbDown fontSize={isSmallScreen ? "small" : "small"} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={feedbackStates[message.id]?.comment ? 'Modifica commento' : 'Aggiungi commento'}>
                        <IconButton
                          size={isSmallScreen ? "small" : "small"}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenFeedbackDialog(message.id, message.bot_message);
                          }}
                          color={feedbackStates[message.id]?.comment ? 'primary' : 'default'}
                        >
                          <CommentIcon fontSize={isSmallScreen ? "small" : "small"} />
                        </IconButton>
                      </Tooltip>
                    </FeedbackContainer>
                  </MessageWrapper>
                </Box>
              )}

              {/* Admin Messages */}
              {message.message_type === 'admin' && !message.is_from_current_user && (
                <Box 
                  sx={{ 
                    position: 'relative', 
                    width: 'fit-content',
                    maxWidth: '80%',
                    alignSelf: 'flex-end',
                    mr: 1,
                  }}
                >
                  <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.4, ease: "easeOut" }}
                  >
                    <MessagePaper 
                      elevation={1} 
                      isBot={true} 
                      isWhatsApp={whatsapp || message.whatsapp}
                    >
                      <MarkdownContent content={formatMessageContent(message.admin_message)} />
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          mt: 1,
                          opacity: 0.7,
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            fontSize: '0.7rem',
                            fontWeight: 400,
                            color: (whatsapp || message.whatsapp) 
                              ? 'rgba(0, 0, 0, 0.7)' 
                              : 'rgba(255, 255, 255, 0.9)',
                          }}
                        >
                          {time.time}
                        </Typography>
                      </Box>
                      {message.car_stocks && Array.isArray(message.car_stocks) && message.car_stocks.length > 0 && (
                        isSmallScreen ? (
                          <MobileCarCarousel cars={message.car_stocks} />
                        ) : (
                          <CarAttachmentsContainer>
                            {message.car_stocks.map((car) => (
                              <CarStockCard
                                key={car.vehicleid}
                                car={car}
                                isSmallScreen={isSmallScreen}
                              />
                            ))}
                          </CarAttachmentsContainer>
                        )
                      )}
                    </MessagePaper>
                  </motion.div>
                </Box>
              )}

              {/* Message actions - align with their respective messages */}
              {!message.isTemp && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: message.sender_message ? 'flex-start' : 'flex-end',
                    width: '100%',
                    mt: 0.5,
                    mb: 1,
                    px: 1,
                  }}
                >
                  {canEdit && false &&(
                    <>
                      <Tooltip title="Helpful">
                        <IconButton
                          size="small"
                          onClick={() => handleFeedback(message.id, 'helpful')}
                        >
                          <ThumbUp fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Not Helpful">
                        <IconButton
                          size="small"
                          onClick={() => handleFeedback(message.id, 'not_helpful')}
                        >
                          <ThumbDown fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Add Comment">
                        <IconButton
                          size="small"
                          onClick={() => handleFeedback(message.id, 'comment')}
                        >
                          <CommentIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </Box>
              )}
            </Box>
          </ListItem>
        </motion.div>
      </React.Fragment>
    );
  }, [chatHistory, feedbackStates, isSmallScreen]);

  if (loading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress size={60} thickness={4} />
      </Box>
    );

  // Check for the "in use" error first
  if (error === 'Chat is currently in use by another user') {
    return (
      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        justifyContent="center" 
        height="100vh"
        gap={2}
        p={3}
      >
        <Typography 
          variant="h6" 
          align="center"
          color="warning.main"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            mb: 1
          }}
        >
          <PersonIcon />
          This chat is currently being managed by another user
        </Typography>
        <Typography 
          variant="body1" 
          color="text.secondary"
          align="center"
        >
          Please select another chat to manage
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCloseClick}
          startIcon={<ArrowBackIcon />}
          sx={{ mt: 2 }}
        >
          Back to Chat List
        </Button>
      </Box>
    );
  }

  // Then check for other errors
  if (error)
    return (
      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        justifyContent="center" 
        height="100vh"
        gap={2}
        p={3}
      >
        <Typography 
          color="error" 
          variant="h6" 
          align="center"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <ErrorIcon color="error" />
          {error}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCloseClick}
          startIcon={<ArrowBackIcon />}
        >
          Back to Chat List
        </Button>
      </Box>
    );

  if (chatHistory.length === 0) {
    return (
      <Typography variant="h5" align="center">
        No chat history found
      </Typography>
    );
  }

  return (
    <Container maxWidth={isSmallScreen ? 'sm' : 'lg'}>
      <StyledPaper elevation={3}>
        {/* Header Section */}
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 10,
            backgroundColor: theme.palette.background.paper,
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          {/* Header with controls */}
          <Box sx={{ 
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}>
            {/* Top row with close button and actions */}
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}>
              <IconButton 
                onClick={handleCloseClick} 
                sx={{ 
                  mr: 1,
                  bgcolor: 'action.hover',
                  '&:hover': { bgcolor: 'action.selected' }
                }} 
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>

              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center',
                gap: 1,
                bgcolor: 'background.default',
                borderRadius: 2,
                p: 0.5,
              }}>
                {/* Control Mode Switch with Animation */}
                {isSmallScreen && (
                  <Tooltip 
                    title={
                      humanControl 
                        ? `Switch to AI Control${!isCurrentUserController ? ' (Locked)' : ''}`
                        : 'Switch to Human Control'
                    } 
                    arrow
                    placement="bottom"
                  >
                    <Box
                      sx={{
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        bgcolor: humanControl ? alpha(theme.palette.primary.main, 0.1) : 'transparent',
                        borderRadius: 2,
                        p: 0.5,
                        transition: 'all 0.2s ease',
                      }}
                    >
                      <Switch
                        size="small"
                        checked={humanControl}
                        onChange={(event) => {
                          event.stopPropagation();
                          if (wsConnection && wsConnection.readyState === WebSocket.OPEN) {
                            wsConnection.send(JSON.stringify({
                              action: 'set_human_control',
                              pk: chatId,
                              human_control: event.target.checked
                            }));
                          }
                        }}
                        color="primary"
                        disabled={humanControl && !isCurrentUserController}
                        sx={{
                          '& .MuiSwitch-switchBase.Mui-checked': {
                            transform: 'translateX(20px)',
                            color: theme.palette.primary.main,
                          },
                          '& .MuiSwitch-track': {
                            opacity: 0.2,
                          },
                        }}
                      />
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 0.5,
                        }}
                      >
                        {humanControl ? (
                          <Person size={16} color={theme.palette.primary.main} />
                        ) : (
                          <AIIcon sx={{ fontSize: 16, color: theme.palette.text.secondary }} />
                        )}
                        <Typography
                          variant="caption"
                          sx={{
                            color: humanControl ? theme.palette.primary.main : theme.palette.text.secondary,
                            fontWeight: 'medium',
                          }}
                        >
                          {humanControl ? 'Human' : 'AI'}
                        </Typography>
                      </Box>
                    </Box>
                  </Tooltip>
                )}

                {/* Summary Button for small screens only - open dialog instead of dropdown */}
                {isSmallScreen && chatSummary && (
                  <Tooltip title="View Summary" arrow placement="bottom">
                    <IconButton 
                      onClick={() => setSummaryDialogOpen(true)}
                      sx={{ 
                        bgcolor: 'background.default',
                        '&:hover': { bgcolor: 'action.hover' }
                      }}
                    >
                      <DescriptionIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </Box>
          </Box>

          {/* Title and Status */}
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 1,
          }}>
            <Typography variant="h6" sx={{ fontWeight: 'medium' }}>
              Chat Details
            </Typography>
            {isConnected !== undefined && (
              <Chip
                size="small"
                label={isConnected ? 'Connected' : 'Disconnected'}
                color={isConnected ? 'success' : 'default'}
                variant="outlined"
                sx={{ 
                  height: 24,
                  '& .MuiChip-label': { px: 1 },
                }}
              />
            )}
          </Box>
        </Box>

        {/* Client Information Section with Integrated Chat Summary */}
        <ClientInformation 
          clientName={clientName}
          clientEmail={clientEmail}
          chatSummary={chatSummary}
        />

        {/* Remove the separate chat summary accordion section */}
        {labelSegmentation && <LabelSegmentationAccordion />}
      </StyledPaper>

      {/* Scrollable Message List */}
      <MessageList>
        <AnimatePresence initial={false}>
          {memoizedChatHistory.map((message, index) => {
            // Avoid rendering calculations that could trigger re-renders
            const previousMessage = index > 0 ? memoizedChatHistory[index - 1] : null;
            const { time, showDate, dateString } = formatMessageTime(
              message.created_at,
              previousMessage?.created_at
            );
            
            const isAdminMessageFromOthers = 
              message.message_type === 'admin' && 
              message.author_full_name && 
              !message.is_from_current_user;

            return (
              <React.Fragment key={message.id || index}>
                {showDate && (
                  <DateDivider>
                    <DateChip label={dateString} />
                  </DateDivider>
                )}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  ref={index === chatHistory.length - 1 ? lastMessageRef : null}
                >
                  <ListItem
                    sx={{
                      flexDirection: 'column',
                      alignItems: 'stretch',
                      p: 0,
                      mb: 1,
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: isAdminMessageFromOthers ? 'flex-start' : 'stretch',
                      }}
                    >
                      {/* Display all message types here */}
                      {/* Client Messages */}
                      {message.sender_message && (
                        <Box 
                          sx={{ 
                            position: 'relative', 
                            width: 'fit-content',
                            maxWidth: '100%',
                            alignSelf: 'flex-start',
                            ml: 1,
                          }}
                        >
                          <motion.div
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.4, ease: "easeOut" }}
                          >
                            <MessagePaper 
                              elevation={1} 
                              isBot={false} 
                              isWhatsApp={whatsapp || message.whatsapp}
                            >
                              <MarkdownContent content={formatMessageContent(message.sender_message)} />
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  alignItems: 'center',
                                  mt: 1,
                                  opacity: 0.6,
                                }}
                              >
                                <Typography
                                  variant="caption"
                                  sx={{
                                    fontSize: '0.7rem',
                                    fontWeight: 400,
                                  }}
                                >
                                  {time.time}
                                </Typography>
                              </Box>
                            </MessagePaper>
                          </motion.div>
                          
                          {/* Form submission indicator */}
                          {message.include_registration_form && (
                            <Box sx={{ 
                              mt: 0.5, 
                              ml: 1, 
                              display: 'flex', 
                              alignItems: 'center',
                              gap: 0.5
                            }}>
                              <AssignmentIcon 
                                color="primary" 
                                fontSize="small" 
                                sx={{ fontSize: '0.9rem' }} 
                              />
                              <Typography 
                                variant="caption" 
                                color="primary.main"
                                sx={{ 
                                  fontStyle: 'italic',
                                  fontSize: '0.75rem'
                                }}
                              >
                                Registration form included with this message
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      )}

                      {/* Bot Messages */}
                      {message.bot_message && (message.sent !== false || message.isTemp) && (
                        <Box 
                          sx={{ 
                            position: 'relative', 
                            width: 'fit-content',
                            maxWidth: '100%',
                            alignSelf: 'flex-end',
                            mr: isSmallScreen ? 0 : 1,
                            width: isSmallScreen ? '100%' : 'auto',
                          }}
                        >
                          <MessageWrapper isSmallScreen={isSmallScreen}>
                            <motion.div
                              initial={{ opacity: 0, x: 20 }}
                              animate={{ opacity: 1, x: 0 }}
                              transition={{ duration: 0.4, ease: "easeOut" }}
                              onClick={() => isSmallScreen ? handleShowFeedbackIcons(message.id) : null}
                              sx={{ 
                                cursor: isSmallScreen ? 'pointer' : 'default',
                                width: isSmallScreen ? '100%' : 'auto'
                              }}
                            >
                              <MessagePaper 
                                elevation={1} 
                                isBot={true} 
                                isWhatsApp={whatsapp || message.whatsapp}
                              >
                                <MarkdownContent content={formatMessageContent(message.bot_message)} />
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    mt: 1,
                                  }}
                                >
                                  {/* Show feedback status if exists */}
                                  {feedbackStates[message.id] && (
                                    <Box sx={{ 
                                      display: 'flex', 
                                      alignItems: 'center', 
                                      gap: 1,
                                      opacity: 0.7
                                    }}>
                                      {feedbackStates[message.id].vote === 1 && (
                                        <ThumbUp fontSize="small" color="primary" sx={{ opacity: 0.7 }} />
                                      )}
                                      {feedbackStates[message.id].vote === -1 && (
                                        <ThumbDown fontSize="small" color="error" sx={{ opacity: 0.7 }} />
                                      )}
                                      {feedbackStates[message.id].comment && (
                                        <CommentIcon fontSize="small" color="primary" sx={{ opacity: 0.7 }} />
                                      )}
                                    </Box>
                                  )}
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      fontSize: '0.7rem',
                                      ml: 'auto',
                                      color: (whatsapp || message.whatsapp) 
                                        ? 'rgba(0, 0, 0, 0.7)' 
                                        : 'rgba(255, 255, 255, 0.9)',
                                    }}
                                  >
                                    {time.time}
                                  </Typography>
                                </Box>
                                
                                {/* Car stocks section with special handling for mobile */}
                                {message.car_stocks && Array.isArray(message.car_stocks) && message.car_stocks.length > 0 && (
                                  <Box 
                                    onClick={(e) => isSmallScreen ? e.stopPropagation() : null}
                                    sx={{ width: '100%' }}
                                  >
                                    {isSmallScreen ? (
                                      <MobileCarCarousel cars={message.car_stocks} />
                                    ) : (
                                      <CarAttachmentsContainer>
                                        {message.car_stocks.map((car) => (
                                          <CarStockCard
                                            key={car.vehicleid}
                                            car={car}
                                            isSmallScreen={isSmallScreen}
                                          />
                                        ))}
                                      </CarAttachmentsContainer>
                                    )}
                                  </Box>
                                )}
                              </MessagePaper>
                            </motion.div>

                            {/* Feedback buttons with responsive positioning */}
                            <FeedbackContainer 
                              className="feedback-container" 
                              isSmallScreen={isSmallScreen}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <Tooltip title="Mi piace">
                                <IconButton
                                  size={isSmallScreen ? "small" : "small"}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleFeedback(message.id, 1);
                                  }}
                                  color={feedbackStates[message.id]?.vote === 1 ? 'primary' : 'default'}
                                >
                                  <ThumbUp fontSize={isSmallScreen ? "small" : "small"} />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Non mi piace">
                                <IconButton
                                  size={isSmallScreen ? "small" : "small"}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleFeedback(message.id, -1);
                                  }}
                                  color={feedbackStates[message.id]?.vote === -1 ? 'error' : 'default'}
                                >
                                  <ThumbDown fontSize={isSmallScreen ? "small" : "small"} />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={feedbackStates[message.id]?.comment ? 'Modifica commento' : 'Aggiungi commento'}>
                                <IconButton
                                  size={isSmallScreen ? "small" : "small"}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleOpenFeedbackDialog(message.id, message.bot_message);
                                  }}
                                  color={feedbackStates[message.id]?.comment ? 'primary' : 'default'}
                                >
                                  <CommentIcon fontSize={isSmallScreen ? "small" : "small"} />
                                </IconButton>
                              </Tooltip>
                            </FeedbackContainer>
                          </MessageWrapper>
                        </Box>
                      )}

                      {/* Admin Messages */}
                      {message.message_type === 'admin' && !message.is_from_current_user && (
                        <Box 
                          sx={{ 
                            position: 'relative', 
                            width: 'fit-content',
                            maxWidth: '80%',
                            alignSelf: 'flex-end',
                            mr: 1,
                          }}
                        >
                          <motion.div
                            initial={{ opacity: 0, x: 20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.4, ease: "easeOut" }}
                          >
                            <MessagePaper 
                              elevation={1} 
                              isBot={true} 
                              isWhatsApp={whatsapp || message.whatsapp}
                            >
                              <MarkdownContent content={formatMessageContent(message.admin_message)} />
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  alignItems: 'center',
                                  mt: 1,
                                  opacity: 0.7,
                                }}
                              >
                                <Typography
                                  variant="caption"
                                  sx={{
                                    fontSize: '0.7rem',
                                    fontWeight: 400,
                                    color: (whatsapp || message.whatsapp) 
                                      ? 'rgba(0, 0, 0, 0.7)' 
                                      : 'rgba(255, 255, 255, 0.9)',
                                  }}
                                >
                                  {time.time}
                                </Typography>
                              </Box>
                              {message.car_stocks && Array.isArray(message.car_stocks) && message.car_stocks.length > 0 && (
                                isSmallScreen ? (
                                  <MobileCarCarousel cars={message.car_stocks} />
                                ) : (
                                  <CarAttachmentsContainer>
                                    {message.car_stocks.map((car) => (
                                      <CarStockCard
                                        key={car.vehicleid}
                                        car={car}
                                        isSmallScreen={isSmallScreen}
                                      />
                                    ))}
                                  </CarAttachmentsContainer>
                                )
                              )}
                            </MessagePaper>
                          </motion.div>
                        </Box>
                      )}

                      {/* Message actions - align with their respective messages */}
                      {!message.isTemp && (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: message.sender_message ? 'flex-start' : 'flex-end',
                            width: '100%',
                            mt: 0.5,
                            mb: 1,
                            px: 1,
                          }}
                        >
                          {canEdit && false &&(
                            <>
                              <Tooltip title="Helpful">
                                <IconButton
                                  size="small"
                                  onClick={() => handleFeedback(message.id, 'helpful')}
                                >
                                  <ThumbUp fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Not Helpful">
                                <IconButton
                                  size="small"
                                  onClick={() => handleFeedback(message.id, 'not_helpful')}
                                >
                                  <ThumbDown fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Add Comment">
                                <IconButton
                                  size="small"
                                  onClick={() => handleFeedback(message.id, 'comment')}
                                >
                                  <CommentIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        </Box>
                      )}
                    </Box>
                  </ListItem>
                </motion.div>
              </React.Fragment>
            );
          })}
        </AnimatePresence>
      </MessageList>

      {/* Fixed Bottom Input Section - Only show if humanControl is true */}
      {canEdit && humanControl && (
        <FixedBottomBox>
          <SendMessageForm
            onSendMessage={handleSendMessage}
            unsentMessage={unsentMessage}
            onPredictMessage={handlePredictMessage}
            isPredicting={isPredicting}
            isCurrentUserController={isCurrentUserController}
            humanControl={humanControl}
          />
        </FixedBottomBox>
      )}

      {/* Show a message when human control is not activated */}
      {canEdit && !humanControl && (
        <FixedBottomBox>
          <Typography variant="body2" color="text.secondary" align="center" sx={{ py: 1 }}>
            Human control must be activated to send messages
          </Typography>
        </FixedBottomBox>
      )}

      {/* Summary Dialog for mobile */}
      <Dialog
        fullScreen
        open={summaryDialogOpen}
        onClose={() => setSummaryDialogOpen(false)}
        TransitionComponent={Fade}
        keepMounted={false} // Prevent aria-hidden issues by not keeping unmounted components in the DOM
        disableRestoreFocus // Prevent focus restoration issues when dialog closes
      >
        <Box sx={{
          p: 2,
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.divider}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <Typography variant="h6">Informazioni Chat</Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setSummaryDialogOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <SummarySection summary={chatSummary} />
        </DialogContent>
      </Dialog>

      {/* Feedback Dialog */}
      <FeedbackDialog
        open={feedbackDialog.open}
        onClose={() => setFeedbackDialog(prev => ({ ...prev, open: false }))}
        onSubmit={handleSubmitFeedback}
        currentFeedback={feedbackDialog.currentFeedback}
      />
    </Container>
  );
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default ChatHistoryDetail;

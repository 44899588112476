import React, { useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material';
import { Fade } from '@mui/material';
import { Trash2, X } from 'lucide-react';

function ConfirmDeleteDialog({ open, onClose, onConfirm }) {
  const nodeRef = useRef(null);
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ ref: nodeRef, style: { borderRadius: '16px' } }}
      TransitionComponent={Fade}
      TransitionProps={{
        nodeRef: nodeRef,
        timeout: { enter: 500, exit: 500 },
        mountOnEnter: true,
        unmountOnExit: true
      }}
    >
      <>
        <DialogTitle>Conferma eliminazione</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sei sicuro di voler eliminare questa azienda? Questa azione non può essere annullata.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} startIcon={<X />}>
            Annulla
          </Button>
          <Button onClick={onConfirm} color="error" variant="contained" startIcon={<Trash2 />}>
            Conferma
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}

export default ConfirmDeleteDialog;
import React from 'react';
import { Box, Typography, Paper, Chip, Avatar, useMediaQuery, useTheme } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { 
  Assignment as AssignmentIcon,
  DirectionsCar as CarIcon
} from '@mui/icons-material';

const AttachmentContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  width: '100%',
}));

const FormAttachmentCard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  return (
    <AttachmentContainer>
      <Paper
        elevation={1}
        sx={{
          p: isMobile ? 1 : 1.5,
          borderRadius: 1,
          backgroundColor: alpha('#2196f3', 0.08),
          border: '1px solid',
          borderColor: alpha('#2196f3', 0.2),
          display: 'flex',
          alignItems: 'center',
          gap: isMobile ? 1 : 1.5,
        }}
      >
        <Avatar
          sx={{
            bgcolor: alpha('#2196f3', 0.15),
            color: '#2196f3',
            width: isMobile ? 28 : 36,
            height: isMobile ? 28 : 36,
          }}
        >
          <AssignmentIcon fontSize={isMobile ? "small" : "medium"} />
        </Avatar>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant={isMobile ? "body2" : "subtitle2"} color="primary.main">
            Registration Form 
          </Typography>
          <Typography 
            variant="caption" 
            color="text.secondary"
            sx={{ fontSize: isMobile ? '0.7rem' : '0.75rem' }}
          >
            A customer registration form was included with this message
          </Typography>
        </Box>
        {!isMobile && (
          <Chip
            size="small"
            label="Form"
            sx={{
              bgcolor: alpha('#2196f3', 0.15),
              color: '#2196f3',
              height: 24,
            }}
          />
        )}
      </Paper>
    </AttachmentContainer>
  );
};

export { FormAttachmentCard };

// We can also keep the car attachment functionality in this file
const CarAttachmentCard = ({ car }) => {
  // Car attachment implementation goes here
  return null; // Implement actual car card UI here
};

export default CarAttachmentCard; 
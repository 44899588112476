import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowBack } from '@mui/icons-material';

// Full screen container
const FullScreenContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1300,
  backgroundColor: theme.palette.background.default,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column'
}));

// Header bar
const ChatAppBar = styled(AppBar)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  boxShadow: theme.shadows[1],
  borderBottom: `1px solid ${theme.palette.divider}`
}));

// Content area
const ChatContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflow: 'hidden',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column'
}));

const ClientChat = () => {
  const { chatId } = useParams();
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(-1);
  };

  // Construct the URL to directly open the conversation section
  const chatUrl = `/chat/${chatId}#conversation`;  // Add #conversation hash to skip to conversation section

  return (
    <FullScreenContainer>
      <ChatAppBar elevation={0}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{ mr: 2 }}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" component="div">
            Chat
          </Typography>
        </Toolbar>
      </ChatAppBar>
      
      <ChatContent>
        <iframe
          src={chatUrl}
          style={{ 
            width: '100%', 
            height: '100%', 
            border: 'none',
            flexGrow: 1
          }}
          title="Chat Frame"
        />
      </ChatContent>
    </FullScreenContainer>
  );
};

export default ClientChat; 
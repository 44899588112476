import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Alert,
  CircularProgress,
  Slide,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  FormControlLabel,
  Skeleton,
  Tabs,
  Tab,
  Breadcrumbs,
  Link,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Rating,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { 
  ArrowBack, 
  Phone, 
  Segment, 
  Edit as EditIcon, 
  Add as AddIcon, 
  Message as MessageIcon, 
  Schedule as ScheduleIcon, 
  NoteAdd as NoteAddIcon, 
  Send as SendIcon, 
  Phone as PhoneIcon, 
  WhatsApp as WhatsAppIcon, 
  LocationOn as LocationIcon, 
  Close as CloseIcon,
  Link as LinkIcon,
  Chat,
  Description as DescriptionIcon,
  Search,
} from '@mui/icons-material';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import Analytics from '../analytics/Analytics';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactMarkdown from 'react-markdown';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  BarChart,
  Bar,
} from 'recharts';

const API_BASE_URL = 'https://admin.aitomotivelab.com/crm/api';

// Add both helper functions at the top level
const convertTimeToSeconds = (timeString) => {
  if (!timeString) return 0;
  try {
    const [time, ms] = timeString.split('.');
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  } catch (error) {
    console.error('Error converting time:', error);
    return 0;
  }
};

const formatTime = (seconds) => {
  if (!seconds || isNaN(seconds)) return '0s';
  if (seconds < 60) return `${seconds}s`;
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}m ${remainingSeconds}s`;
};

const formatTimeDisplay = (seconds) => {
  if (!seconds || isNaN(seconds)) return '0m';
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  
  if (hours > 0) {
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  }
  return `${minutes}m`;
};

// First, modify the getTimeColor function to include interest level labels
const getTimeColor = (timeSpent, theme) => {
  if (!theme) {
    // Fallback colors if theme is not available
    if (timeSpent > 3600) return '#9e9e9e'; // Sleep/inactive - gray
    if (timeSpent < 30) return '#f44336'; // Low interest - red
    if (timeSpent < 300) return '#ff9800'; // Medium interest - orange
    if (timeSpent < 3600) return '#4caf50'; // High interest - green
    return '#9e9e9e'; // Sleep/inactive - gray
  }
  
  if (timeSpent > 3600) return theme.palette.grey[500]; // Sleep/inactive
  if (timeSpent < 30) return theme.palette.error.main; // Low interest
  if (timeSpent < 300) return theme.palette.warning.main; // Medium interest
  if (timeSpent < 3600) return theme.palette.success.main; // High interest
  return theme.palette.grey[500]; // Sleep/inactive
};

// Add a helper function to get interest level label
const getInterestLabel = (timeSpent) => {
  if (timeSpent > 3600) return 'Inattivo';
  if (timeSpent < 30) return 'Interesse Basso';
  if (timeSpent < 300) return 'Interesse Medio';
  return 'Interesse Alto';
};

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
  },
  position: 'relative',
  overflow: 'visible',
}));

const IconText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.default,
  '& svg': {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));

const MobileCard = styled(Card)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  }
}));

const MobileActionButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginBottom: theme.spacing(1),
  }
}));

const ResponsiveTableContainer = styled(TableContainer)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '& table': {
      '& td': {
        display: 'block',
        width: '100%',
        textAlign: 'left',
        paddingLeft: '50%',
        position: 'relative',
        '&:before': {
          content: 'attr(data-label)',
          position: 'absolute',
          left: theme.spacing(2),
          fontWeight: 'bold',
        }
      },
      '& th': {
        display: 'none'
      },
      '& tr': {
        marginBottom: theme.spacing(2),
        display: 'block',
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  }
}));

const FormResponsesComponent = ({ formResponses }) => {
  if (!formResponses || formResponses.length === 0) {
    return <Typography>No form responses available.</Typography>;
  }

  const filteredResponses = formResponses[0].field_responses.filter(response => {
    const lowercaseLabel = response.field_label.toLowerCase();
    return !lowercaseLabel.includes('email') && 
           !lowercaseLabel.includes('gdpr') && 
           !lowercaseLabel.includes('marketing');
  });

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Field</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredResponses.map((response) => (
            <TableRow key={response.id}>
              <TableCell>{response.field_label}</TableCell>
              <TableCell>{response.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const MobileContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    margin: '0 auto',
    overflowX: 'hidden',
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: 'auto',
    paddingTop: theme.spacing(4)
  }
}));

const ClientHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: theme.spacing(2),
  }
}));

const ClientAvatar = styled(Box)(({ theme }) => ({
  width: 80,
  height: 80,
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '2rem',
}));

const LoadingSkeleton = styled(Box)(({ theme }) => ({
  '& .MuiSkeleton-root': {
    backgroundColor: theme.palette.background.paper,
  }
}));

const FloatingActionButton = styled(SpeedDial)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
}));

const ContactActionButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  textTransform: 'none',
  padding: theme.spacing(1, 2),
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[4],
  },
  transition: 'all 0.2s ease-in-out',
}));

const ContactIconButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '40px',
    height: '40px',
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem',
    }
  }
}));

const ClientDetailsSkeleton = () => (
  <LoadingSkeleton>
    <Skeleton variant="rectangular" height={200} sx={{ mb: 2, borderRadius: 1 }} />
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <Skeleton variant="rectangular" height={400} sx={{ borderRadius: 1 }} />
      </Grid>
      <Grid item xs={12} md={4}>
        <Skeleton variant="rectangular" height={200} sx={{ mb: 2, borderRadius: 1 }} />
        <Skeleton variant="rectangular" height={200} sx={{ borderRadius: 1 }} />
      </Grid>
    </Grid>
  </LoadingSkeleton>
);

const MessageList = styled(List)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  padding: theme.spacing(1),
  margin: 0,
  maxHeight: '500px',
  '&::-webkit-scrollbar': {
    width: '8px',
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.main,
    borderRadius: '4px',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  },
  '&:hover::-webkit-scrollbar-thumb': {
    opacity: 1,
  },
}));

const MessagePaper = styled(Paper)(({ theme, isBot }) => ({
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(1),
  backgroundColor: isBot ? theme.palette.primary.light : theme.palette.background.paper,
  color: isBot ? theme.palette.primary.contrastText : theme.palette.text.primary,
  borderRadius: '12px',
  maxWidth: '80%',
  marginLeft: isBot ? 0 : 'auto',
  marginRight: isBot ? 'auto' : 0,
  wordBreak: 'break-word',
}));

const ChatSection = ({ d, setSnackbar }) => {
  const [selectedChat, setSelectedChat] = useState(null);
  const [loading, setLoading] = useState(true);
  const clientId = d?.id;

  useEffect(() => {
    if (clientId) {
      fetchChats();
    }
  }, [clientId]);

  const fetchChats = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/Clients/client_chats/?client_id=${clientId}`);
      if (response.data && response.data.length > 0) {
        setSelectedChat(response.data[0]);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching chats:', error);
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!selectedChat) {
    return <Typography>No chat history available</Typography>;
  }

  return (
    <Box sx={{ 
      height: 'calc(100vh - 250px)',
      border: '1px solid',
      borderColor: 'divider',
      borderRadius: 1,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column'
    }}>
      {/* Chat content */}
      <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
        <iframe
          src={`/chat/${selectedChat.unique_id}#conversation`}
          style={{ 
            width: '100%',
            height: '100%',
            border: 'none'
          }}
          title="Chat Frame"
        />
      </Box>
    </Box>
  );
};

const LocationCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  '& .location-icon': {
    color: theme.palette.primary.main,
    marginTop: '2px'
  },
  '& .location-content': {
    flex: 1
  }
}));

const SessionPathCard = styled(StyledCard)(({ theme }) => ({
  '& .path-node': {
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(1),
    transition: 'all 0.2s ease-in-out',
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: theme.shadows[3],
      backgroundColor: theme.palette.action.hover,
      '& .time-badge': {
        transform: 'scale(1.1)',
      }
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '2px',
      background: 'linear-gradient(90deg, #8884d8, #82ca9d)',
      transform: 'scaleX(0)',
      transformOrigin: 'left',
      transition: 'transform 0.3s ease-in-out',
    },
    '&:hover::after': {
      transform: 'scaleX(1)',
    }
  },
  '& .path-arrow': {
    color: theme.palette.primary.main,
    margin: theme.spacing(0, 1),
    transition: 'transform 0.2s ease-in-out',
    animation: 'pulse 1.5s infinite',
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(1)',
        opacity: 1,
      },
      '50%': {
        transform: 'scale(1.2)',
        opacity: 0.7,
      },
      '100%': {
        transform: 'scale(1)',
        opacity: 1,
      }
    }
  },
  '& .time-badge': {
    position: 'absolute',
    top: -10,
    right: -10,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    fontSize: '0.75rem',
    transition: 'all 0.2s ease-in-out',
    zIndex: 1,
  },
  '& .session-header': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    '& .session-icon': {
      color: theme.palette.primary.main,
      animation: 'rotate 2s linear infinite',
      '@keyframes rotate': {
        '0%': {
          transform: 'rotate(0deg)',
        },
        '100%': {
          transform: 'rotate(360deg)',
        }
      }
    }
  }
}));

const AnalyticsVisualizations = ({ navigationData, selectedSessionId = null }) => {
  const theme = useTheme();
  
  const sessionData = navigationData.reduce((acc, entry) => {
    if (!acc[entry.session_id]) {
      acc[entry.session_id] = [];
    }
    acc[entry.session_id].push({
      ...entry,
      time_spent: convertTimeToSeconds(entry.time_spent)
    });
    return acc;
  }, {});

  // If a specific session is selected, filter data for that session only
  const filteredNavigationData = selectedSessionId 
    ? navigationData.filter(entry => entry.session_id === selectedSessionId)
    : navigationData;

  // Extract sessions by date for visualization
  const sessionsByDate = Object.values(sessionData).reduce((acc, entries) => {
    const date = new Date(entries[0].visit_timestamp).toLocaleDateString();
    acc[date] = (acc[date] || 0) + 1;
    return acc;
  }, {});

  const sessionsTimelineData = Object.entries(sessionsByDate).map(([date, count]) => ({
    date,
    sessions: count
  })).sort((a, b) => new Date(a.date) - new Date(b.date));

  // Calculate total time spent per day
  const timeSpentByDay = navigationData.reduce((acc, entry) => {
    const date = new Date(entry.visit_timestamp).toLocaleDateString();
    acc[date] = (acc[date] || 0) + convertTimeToSeconds(entry.time_spent);
    return acc;
  }, {});
  
  const timeSpentByDayData = Object.entries(timeSpentByDay).map(([date, time]) => ({
    date,
    time,
    timeFormatted: formatTimeDisplay(time)
  })).sort((a, b) => new Date(a.date) - new Date(b.date));

  // Calculate page engagement - filter out pages with no meaningful time spent
  const timeByPage = navigationData.reduce((acc, entry) => {
    try {
      const url = new URL(entry.current_url);
      const page = url.pathname; // Use pathname only for cleaner labels
      acc[page] = (acc[page] || 0) + convertTimeToSeconds(entry.time_spent);
      return acc;
    } catch (e) {
      // Handle invalid URLs
      return acc;
    }
  }, {});

  // Only include pages with meaningful time spent (> 5 seconds)
  const timeByPageData = Object.entries(timeByPage)
    .filter(([_, time]) => time > 5)
    .map(([page, time]) => ({
      page: page.length > 20 ? page.substring(0, 20) + '...' : page,
      fullPage: page,
      time: Math.round(time),
      timeDisplay: formatTime(time),
    }))
    .sort((a, b) => b.time - a.time) // Sort by time spent descending
    .slice(0, 8); // Show top 8 pages for readability

  // Device distribution analysis
  const deviceTypes = navigationData.reduce((acc, entry) => {
    if (entry.device_type) { // Only count if device_type exists
      acc[entry.device_type] = (acc[entry.device_type] || 0) + 1;
    }
    return acc;
  }, {});
  
  const deviceDistributionData = Object.entries(deviceTypes).map(([type, count]) => ({
    name: type,
    value: count
  }));

  // Most used device
  const mostUsedDevice = deviceDistributionData.length > 0 
    ? deviceDistributionData.reduce((prev, current) => 
        (prev.value > current.value) ? prev : current
      ).name
    : 'N/A';

  // Session analysis
  const sessionAnalysis = Object.entries(sessionData).map(([sessionId, entries]) => {
    const startTime = new Date(entries[0].visit_timestamp);
    const totalTime = entries.reduce((sum, entry) => sum + entry.time_spent, 0);
    const pagesVisited = new Set(entries.map(e => {
      try {
        return new URL(e.current_url).pathname;
      } catch (error) {
        return e.current_url;
      }
    })).size;
    
    return {
      sessionId,
      date: startTime.toLocaleDateString(),
      time: startTime.toLocaleTimeString(),
      totalTime,
      totalTimeFormatted: formatTime(totalTime),
      pagesVisited,
      deviceType: entries[0].device_type,
    };
  }).sort((a, b) => new Date(b.date + ' ' + b.time) - new Date(a.date + ' ' + a.time));

  // Calculate total pages visited
  const totalPagesVisited = sessionAnalysis.reduce((sum, session) => sum + session.pagesVisited, 0);

  // Session paths (journey)
  const sessionPaths = Object.entries(sessionData).map(([sessionId, entries]) => {
    const sortedEntries = [...entries].sort((a, b) => 
      new Date(a.visit_timestamp) - new Date(b.visit_timestamp)
    );
    
    return {
      sessionId,
      startTime: new Date(sortedEntries[0].visit_timestamp).toLocaleString(),
      path: sortedEntries.map(entry => {
        let pathname;
        try {
          pathname = new URL(entry.current_url).pathname;
        } catch (error) {
          pathname = entry.current_url;
        }
        
        return {
          pathname,
          fullUrl: entry.current_url,
          timeSpent: Math.round(entry.time_spent),
        };
      })
    };
  });

  const COLORS = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.success.main,
    theme.palette.warning.main,
    theme.palette.info.main,
    theme.palette.error.main,
    '#9c27b0',
    '#607d8b'
  ];

  // Create a legend for interest levels
  const interestLevels = [
    { label: 'Interesse Basso', color: theme.palette.error.main, timeRange: '< 30 secondi' },
    { label: 'Interesse Medio', color: theme.palette.warning.main, timeRange: '30 - 5 minuti' },
    { label: 'Interesse Alto', color: theme.palette.success.main, timeRange: '5 - 60 minuti' },
    { label: 'Inattivo', color: theme.palette.grey[500], timeRange: '> 60 minuti' }
  ];

  return (
    <Box sx={{ pb: 4 }}>
      {selectedSessionId ? (
        // When a specific session is selected, only show detailed navigation data for that session
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
            <Button 
              variant="outlined" 
              startIcon={<ArrowBack />}
              onClick={() => window.history.back()}
              size="small"
            >
              Torna alla panoramica
            </Button>
            <Typography variant="h6">
              Dettagli Sessione {selectedSessionId}
            </Typography>
          </Box>
          
          {/* Detailed Navigation Data for selected session */}
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Dati di Navigazione Dettagliati
              </Typography>
              
              {/* Mobile view */}
              <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <Box sx={{ 
                  maxHeight: '400px', 
                  overflowY: 'auto',
                  pr: 1,
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: theme.palette.background.default,
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: theme.palette.primary.main,
                    borderRadius: '4px',
                  }
                }}>
                  {filteredNavigationData.map((entry, index) => (
                    <Box 
                      key={index}
                      sx={{
                        mb: 2,
                        p: 2,
                        borderRadius: 2,
                        bgcolor: 'background.paper',
                        boxShadow: 1,
                        border: '1px solid',
                        borderColor: 'divider',
                        position: 'relative',
                        overflow: 'hidden',
                        '&::before': {
                          content: '""',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '4px',
                          height: '100%',
                          bgcolor: getTimeColor(convertTimeToSeconds(entry.time_spent), theme)
                        }
                      }}
                    >
                      <Typography variant="subtitle2" sx={{ mb: 1, color: 'primary.main' }}>
                        {new Date(entry.visit_timestamp).toLocaleString()}
                      </Typography>
                      
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Typography variant="caption" color="textSecondary">Pagina corrente:</Typography>
                          <Typography variant="body2" sx={{ wordBreak: 'break-word', mb: 1 }}>
                            {(() => {
                              try {
                                return new URL(entry.current_url).pathname;
                              } catch (e) {
                                return entry.current_url;
                              }
                            })()}
                          </Typography>
                        </Grid>
                        
                        <Grid item xs={6}>
                          <Typography variant="caption" color="textSecondary">Dispositivo:</Typography>
                          <Typography variant="body2" sx={{ mb: 1 }}>
                            {entry.device_type || 'Unknown'}
                          </Typography>
                        </Grid>
                        
                        <Grid item xs={12}>
                          <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: 1,
                            borderRadius: 1,
                            bgcolor: 'background.default'
                          }}>
                            <Typography variant="caption">Tempo trascorso:</Typography>
                            <Typography variant="body2" fontWeight="bold">
                              {formatTime(convertTimeToSeconds(entry.time_spent))}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                </Box>
              </Box>
              
              {/* Desktop view */}
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <TableContainer 
                  component={Paper} 
                  sx={{ 
                    maxHeight: '400px',
                    '&::-webkit-scrollbar': {
                      width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                      background: theme.palette.background.default,
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: theme.palette.primary.main,
                      borderRadius: '4px',
                    }
                  }}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Data</TableCell>
                        <TableCell>Pagina</TableCell>
                        <TableCell>Dispositivo</TableCell>
                        <TableCell>Tempo</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredNavigationData.map((entry, index) => {
                        const timeInSeconds = convertTimeToSeconds(entry.time_spent);
                        let pageUrl;
                        try {
                          pageUrl = new URL(entry.current_url).pathname;
                        } catch (e) {
                          pageUrl = entry.current_url;
                        }
                        
                        return (
                          <TableRow 
                            key={index}
                            sx={{
                              '&:nth-of-type(odd)': {
                                bgcolor: 'background.default',
                              },
                              '&:hover': {
                                bgcolor: 'action.hover'
                              }
                            }}
                          >
                            <TableCell>
                              {new Date(entry.visit_timestamp).toLocaleString()}
                            </TableCell>
                            <TableCell sx={{ maxWidth: '200px' }}>
                              <Typography noWrap title={pageUrl}>
                                {pageUrl}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Chip 
                                label={entry.device_type || 'Unknown'}
                                size="small"
                                color={entry.device_type && entry.device_type.toLowerCase().includes('mobile') ? 'secondary' : 'primary'}
                                variant="outlined"
                              />
                            </TableCell>
                            <TableCell>
                              <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1
                              }}>
                                <Box 
                                  sx={{ 
                                    width: 10, 
                                    height: 10, 
                                    borderRadius: '50%',
                                    bgcolor: getTimeColor(timeInSeconds, theme)
                                  }} 
                                />
                                <Typography>
                                  {formatTime(timeInSeconds)}
                                </Typography>
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </CardContent>
          </StyledCard>
        </>
      ) : (
        // Default view with all analytics
        <>
          {/* Sessions Overview */}
          <StyledCard sx={{ mb: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom align="center">
                Panoramica Sessioni
              </Typography>
              <Box sx={{ 
                display: 'grid', 
                gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' },
                gap: 2,
                mb: 3 
              }}>
                <Box sx={{ 
                  p: 3, 
                  borderRadius: 2, 
                  bgcolor: theme.palette.background.paper,
                  border: '1px solid',
                  borderColor: 'divider',
                  boxShadow: theme.shadows[2],
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <Typography variant="h4" color="primary.main" sx={{ mb: 1 }}>
                    {Object.keys(sessionData).length}
                  </Typography>
                  <Typography variant="body1">Sessioni Totali</Typography>
                </Box>
                
                <Box sx={{ 
                  p: 3, 
                  borderRadius: 2, 
                  bgcolor: theme.palette.background.paper,
                  border: '1px solid',
                  borderColor: 'divider',
                  boxShadow: theme.shadows[2],
                  textAlign: 'center'
                }}>
                  <Typography variant="h4" color="secondary.main" sx={{ mb: 1 }}>
                    {totalPagesVisited}
                  </Typography>
                  <Typography variant="body1">Pagine Visitate</Typography>
                </Box>
                
                <Box sx={{ 
                  p: 3, 
                  borderRadius: 2, 
                  bgcolor: theme.palette.background.paper,
                  border: '1px solid',
                  borderColor: 'divider',
                  boxShadow: theme.shadows[2],
                  textAlign: 'center'
                }}>
                  <Typography variant="h4" color="success.main" sx={{ mb: 1 }}>
                    {mostUsedDevice || 'N/A'}
                  </Typography>
                  <Typography variant="body1">Dispositivo Principale</Typography>
                </Box>
              </Box>
            </CardContent>
          </StyledCard>

          {/* Two column layout for metrics */}
          <Grid container spacing={3} sx={{ mb: 3 }}>
            {/* Page Engagement Chart - Only show if we have data */}
            {timeByPageData.length > 0 && (
              <>
                <Grid item xs={12} md={8}>
                  <StyledCard>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Pagine con Maggiore Coinvolgimento
                      </Typography>
                      <ResponsiveContainer width="100%" height={350}>
                        <BarChart 
                          data={timeByPageData}
                          layout="vertical"
                          margin={{ left: 40, right: 20, top: 10, bottom: 10 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
                          <XAxis 
                            type="number"
                            label={{ 
                              value: 'Tempo (secondi)', 
                              position: 'bottom',
                              offset: 0
                            }}
                          />
                          <YAxis 
                            dataKey="page"
                            type="category"
                            tick={{ fontSize: 12 }}
                            width={150}
                          />
                          <Tooltip 
                            formatter={(value) => [formatTime(value), "Tempo Trascorso"]}
                            labelFormatter={(label) => `Pagina: ${label}`}
                            cursor={{ fill: 'rgba(0, 0, 0, 0.1)' }}
                          />
                          <Bar 
                            dataKey="time" 
                            fill={theme.palette.primary.main}
                            name="Tempo Trascorso"
                            label={{
                              position: 'right',
                              formatter: (value) => formatTime(value),
                              fill: theme.palette.text.primary,
                              fontSize: 12
                            }}
                          >
                            {timeByPageData.map((entry, index) => {
                              // Color based on interest level
                              const color = getTimeColor(entry.time, theme);
                              return (
                                <Cell 
                                  key={`cell-${index}`} 
                                  fill={color}
                                />
                              );
                            })}
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                      
                      {/* Interest Level Legend */}
                      <Box sx={{ 
                        display: 'flex', 
                        flexWrap: 'wrap', 
                        justifyContent: 'center',
                        gap: 2,
                        mt: 2,
                        pb: 1
                      }}>
                        {interestLevels.map((level, index) => (
                          <Box 
                            key={index}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1
                            }}
                          >
                            <Box 
                              sx={{ 
                                width: 16, 
                                height: 16, 
                                borderRadius: '4px',
                                bgcolor: level.color
                              }} 
                            />
                            <Typography variant="caption">
                              {level.label} ({level.timeRange})
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </CardContent>
                  </StyledCard>
                </Grid>

                <Grid item xs={12} md={4}>
                  <StyledCard>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        Distribuzione Dispositivi
                      </Typography>
                      <ResponsiveContainer width="100%" height={350}>
                        <PieChart>
                          <Pie
                            data={deviceDistributionData}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={80}
                            labelLine={{ stroke: theme.palette.text.primary, strokeWidth: 1 }}
                            label={(entry) => entry.name}
                          >
                            {deviceDistributionData.map((entry, index) => (
                              <Cell 
                                key={`cell-${index}`} 
                                fill={COLORS[index % COLORS.length]} 
                              />
                            ))}
                          </Pie>
                          <Tooltip 
                            formatter={(value, name, props) => [`${value} visite`, props.payload.name]}
                            labelFormatter={() => 'Dispositivo'}
                          />
                          <Legend 
                            formatter={(value, entry) => {
                              return (
                                <span style={{ color: entry.color }}>
                                  {value}: {entry.payload.value} visite
                                </span>
                              );
                            }}
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    </CardContent>
                  </StyledCard>
                </Grid>
              </>
            )}
          </Grid>

          {/* Session Details Cards */}
          <StyledCard sx={{ mb: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Dettaglio Sessioni
              </Typography>
              <Box sx={{ 
                display: 'grid', 
                gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' },
                gap: 2,
              }}>
                {sessionAnalysis.map((session) => (
                  <Box 
                    key={session.sessionId}
                    sx={{
                      p: 2,
                      borderRadius: 2,
                      border: '1px solid',
                      borderColor: 'divider',
                      bgcolor: 'background.paper',
                      boxShadow: 1,
                      transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: 3,
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                      <Typography variant="subtitle2" color="primary.main">
                        {session.date} - {session.time}
                      </Typography>
                      <Chip
                        label={session.deviceType || 'Unknown'}
                        size="small"
                        color={session.deviceType && session.deviceType.toLowerCase().includes('mobile') ? 'secondary' : 'primary'}
                      />
                    </Box>
                    
                    <Divider sx={{ my: 1 }} />
                    
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                      <Typography variant="body2">Pagine visitate:</Typography>
                      <Typography variant="body2" fontWeight="bold">
                        {session.pagesVisited}
                      </Typography>
                    </Box>
                    
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography variant="body2">Tempo totale:</Typography>
                      <Typography variant="body2" fontWeight="bold" color="success.main">
                        {session.totalTimeFormatted}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </CardContent>
          </StyledCard>

          {/* User Journey Paths (Improved with clear start/end points and interest level colors) */}
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ 
                background: 'linear-gradient(45deg, #8884d8, #82ca9d)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontWeight: 'bold',
                mb: 3
              }}>
                Percorsi di Navigazione
              </Typography>
              
              {/* Interest Level Legend */}
              <Box sx={{ 
                display: 'flex', 
                flexWrap: 'wrap', 
                justifyContent: 'center',
                gap: 2,
                mb: 3,
                p: 2,
                bgcolor: 'background.default',
                borderRadius: 1
              }}>
                {interestLevels.map((level, index) => (
                  <Box 
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1
                    }}
                  >
                    <Box 
                      sx={{ 
                        width: 16, 
                        height: 16, 
                        borderRadius: '50%',
                        bgcolor: level.color
                      }} 
                    />
                    <Typography variant="caption">
                      {level.label} ({level.timeRange})
                    </Typography>
                  </Box>
                ))}
              </Box>
              
              {sessionPaths.map((session, sessionIndex) => (
                <Box 
                  key={session.sessionId} 
                  sx={{ 
                    mb: 4,
                    opacity: 0,
                    animation: 'fadeIn 0.5s forwards',
                    animationDelay: `${sessionIndex * 0.2}s`,
                    '@keyframes fadeIn': {
                      from: { opacity: 0, transform: 'translateY(20px)' },
                      to: { opacity: 1, transform: 'translateY(0)' }
                    },
                    p: 2,
                    borderRadius: 2,
                    bgcolor: 'background.paper',
                    boxShadow: 1,
                    border: '1px solid',
                    borderColor: 'divider'
                  }}
                >
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 1, 
                    mb: 2, 
                    pb: 2,
                    borderBottom: '1px solid',
                    borderColor: 'divider'
                  }}>
                    <ScheduleIcon sx={{ color: 'primary.main' }} />
                    <Typography variant="subtitle2">
                      Sessione iniziata il: {session.startTime}
                    </Typography>
                  </Box>
                  
                  <Box sx={{ pl: { xs: 0, sm: 2 } }}>
                    {session.path.map((page, index) => {
                      const isFirst = index === 0;
                      const isLast = index === session.path.length - 1;
                      const interestColor = getTimeColor(page.timeSpent, theme);
                      const interestLabel = getInterestLabel(page.timeSpent);
                      
                      return (
                        <Box 
                          key={index}
                          sx={{
                            position: 'relative',
                            mb: 2,
                            pb: 2,
                            borderLeft: !isLast ? '2px dashed' : 'none',
                            borderColor: 'primary.light',
                            pl: 2,
                            '&::before': !isLast ? {
                              content: '""',
                              position: 'absolute',
                              left: -5,
                              top: 0,
                              width: 10,
                              height: 10,
                              borderRadius: '50%',
                              bgcolor: interestColor
                            } : {}
                          }}
                        >
                          <Box sx={{ 
                            p: 2, 
                            bgcolor: 'background.default',
                            borderRadius: 1,
                            border: '1px solid',
                            borderColor: 'divider',
                            position: 'relative',
                            borderLeft: `4px solid ${interestColor}`
                          }}>
                            {/* Start/End markers */}
                            {isFirst && (
                              <Chip 
                                label="Inizio" 
                                size="small" 
                                color="primary" 
                                sx={{ 
                                  position: 'absolute',
                                  top: -10,
                                  left: -10,
                                  fontWeight: 'bold'
                                }}
                              />
                            )}
                            
                            {isLast && (
                              <Chip 
                                label="Fine" 
                                size="small" 
                                color="secondary" 
                                sx={{ 
                                  position: 'absolute',
                                  top: -10,
                                  left: -10,
                                  fontWeight: 'bold'
                                }}
                              />
                            )}
                            
                            <Box sx={{ 
                              position: 'absolute',
                              top: -10,
                              right: -10,
                              bgcolor: interestColor,
                              color: 'white',
                              py: 0.5,
                              px: 1,
                              borderRadius: 1,
                              fontSize: '0.75rem',
                              fontWeight: 'bold',
                              display: 'flex',
                              alignItems: 'center',
                              gap: 0.5
                            }}>
                              <Box 
                                sx={{ 
                                  width: 8, 
                                  height: 8, 
                                  borderRadius: '50%',
                                  bgcolor: 'white'
                                }} 
                              />
                              {formatTime(page.timeSpent)}
                            </Box>
                            
                            <Typography 
                              variant="body2" 
                              sx={{ 
                                wordBreak: 'break-word',
                                fontSize: '0.875rem'
                              }}
                            >
                              {page.pathname}
                            </Typography>
                            
                            <Typography 
                              variant="caption" 
                              sx={{ 
                                display: 'block',
                                mt: 1,
                                color: interestColor,
                                fontWeight: 'medium'
                              }}
                            >
                              {interestLabel}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              ))}
            </CardContent>
          </StyledCard>

          {/* Detailed Navigation Data (Improved with interest level colors) */}
          <StyledCard sx={{ mt: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Dati di Navigazione Dettagliati
              </Typography>
              
              {/* Interest Level Legend */}
              <Box sx={{ 
                display: 'flex', 
                flexWrap: 'wrap', 
                gap: 2,
                mb: 3,
                pb: 2,
                borderBottom: '1px solid',
                borderColor: 'divider'
              }}>
                {interestLevels.map((level, index) => (
                  <Box 
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1
                    }}
                  >
                    <Box 
                      sx={{ 
                        width: 12, 
                        height: 12, 
                        borderRadius: '50%',
                        bgcolor: level.color
                      }} 
                    />
                    <Typography variant="caption">
                      {level.label}
                    </Typography>
                  </Box>
                ))}
              </Box>
              
              {/* Mobile view - Improved cards */}
              <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                {navigationData.map((entry, index) => {
                  const timeInSeconds = convertTimeToSeconds(entry.time_spent);
                  const interestColor = getTimeColor(timeInSeconds, theme);
                  const interestLabel = getInterestLabel(timeInSeconds);
                  
                  return (
                    <MobileDataCard 
                      key={entry.id}
                      sx={{
                        borderLeft: `4px solid ${interestColor}`
                      }}
                    >
                      <Typography className="label">Data</Typography>
                      <Typography className="value">
                        {new Date(entry.visit_timestamp).toLocaleString()}
                      </Typography>
                      
                      <Typography className="label">Pagina</Typography>
                      <Typography className="value" sx={{ wordBreak: 'break-word' }}>
                        {(() => {
                          try {
                            return new URL(entry.current_url).pathname;
                          } catch (e) {
                            return entry.current_url;
                          }
                        })()}
                      </Typography>
                      
                      <Typography className="label">Dispositivo</Typography>
                      <Typography className="value">
                        {entry.device_type || 'Unknown'}
                      </Typography>
                      
                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between', 
                        alignItems: 'center',
                        bgcolor: 'background.default',
                        p: 1.5,
                        borderRadius: 1,
                        mt: 1
                      }}>
                        <Typography variant="caption" fontWeight="bold">
                          {interestLabel}
                        </Typography>
                        
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Box 
                            sx={{ 
                              width: 10, 
                              height: 10, 
                              borderRadius: '50%',
                              bgcolor: interestColor
                            }} 
                          />
                          <Typography variant="body2" fontWeight="bold">
                            {formatTime(timeInSeconds)}
                          </Typography>
                        </Box>
                      </Box>
                    </MobileDataCard>
                  );
                })}
              </Box>
              
              {/* Desktop view - Improved table */}
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Data</TableCell>
                        <TableCell>Pagina</TableCell>
                        <TableCell>Dispositivo</TableCell>
                        <TableCell>Livello Interesse</TableCell>
                        <TableCell>Tempo</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {navigationData.map((entry, index) => {
                        const timeInSeconds = convertTimeToSeconds(entry.time_spent);
                        const interestColor = getTimeColor(timeInSeconds, theme);
                        const interestLabel = getInterestLabel(timeInSeconds);
                        
                        let pageUrl;
                        try {
                          pageUrl = new URL(entry.current_url).pathname;
                        } catch (e) {
                          pageUrl = entry.current_url;
                        }
                        
                        return (
                          <TableRow 
                            key={index}
                            sx={{
                              '&:nth-of-type(odd)': {
                                bgcolor: 'background.default',
                              },
                              '&:hover': {
                                bgcolor: 'action.hover'
                              },
                              borderLeft: `4px solid ${interestColor}`
                            }}
                          >
                            <TableCell>
                              {new Date(entry.visit_timestamp).toLocaleString()}
                            </TableCell>
                            <TableCell sx={{ maxWidth: '200px' }}>
                              <Typography noWrap title={pageUrl}>
                                {pageUrl}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Chip 
                                label={entry.device_type || 'Unknown'}
                                size="small"
                                color={entry.device_type && entry.device_type.toLowerCase().includes('mobile') ? 'secondary' : 'primary'}
                                variant="outlined"
                              />
                            </TableCell>
                            <TableCell>
                              <Chip 
                                label={interestLabel}
                                size="small"
                                sx={{
                                  bgcolor: interestColor,
                                  color: 'white',
                                  fontWeight: 'bold'
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1
                              }}>
                                <Box 
                                  sx={{ 
                                    width: 10, 
                                    height: 10, 
                                    borderRadius: '50%',
                                    bgcolor: interestColor
                                  }} 
                                />
                                <Typography>
                                  {formatTime(timeInSeconds)}
                                </Typography>
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </CardContent>
          </StyledCard>
        </>
      )}
    </Box>
  );
};

// Add this new styled component for mobile data cards
const MobileDataCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  '& .label': {
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(0.5)
  },
  '& .value': {
    marginBottom: theme.spacing(1.5)
  }
}));

const ClientDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { userRole, appAccess } = useAuth();
  const theme = useTheme();
  const permissions = appAccess['crm_app'] || {};
  const canEdit = permissions.can_edit || userRole === 'superadmin';
  const canCreate = permissions.can_create || userRole === 'superadmin';

  const [client, setClient] = useState(null);
  const [feedback, setFeedback] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [editMode, setEditMode] = useState(false);
  const [editedClient, setEditedClient] = useState(null);
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const [newFeedback, setNewFeedback] = useState({ rating: 5, comment: '' });
  const [activeTab, setActiveTab] = useState(0);
  const [page, setPage] = useState(1);
  const [navigationData, setNavigationData] = useState([]);
  const [selectedSessionId, setSelectedSessionId] = useState(null);

  useEffect(() => {
    fetchClientDetails();
    if (id) {
      fetchNavigationData();
    }
    
    // Check URL for session parameter
    const urlParams = new URLSearchParams(window.location.search);
    const sessionParam = urlParams.get('session');
    if (sessionParam) {
      setSelectedSessionId(sessionParam);
    }
    
    // Listen for history changes (back button)
    const handlePopState = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const sessionParam = urlParams.get('session');
      setSelectedSessionId(sessionParam || null);
    };
    
    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, [id]);

  const fetchClientDetails = async () => {
    try {
      const clientResponse = await api.get(`/Clients/${id}/`);
      setClient(clientResponse.data);
      setEditedClient(clientResponse.data);

      const feedbackResponse = await api.get(`/Client-feedback/?client=${id}`);
      setFeedback(feedbackResponse.data);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching client details:', error);
      handleApiError(error);
      setLoading(false);
    }
  };

  const fetchNavigationData = async () => {
    try {
      const response = await api.get(`/Clients/client-navigation/?client_id=${id}`);
      setNavigationData(response.data);
      console.log('Navigation data fetched:', response.data);
    } catch (error) {
      console.error('Error fetching navigation data:', error);
      handleApiError(error);
    }
  };

  const handleApiError = (error) => {
    if (error.response && error.response.status === 401) {
      setSnackbar({ open: true, message: 'Authentication failed. Please log in again.', severity: 'error' });
      navigate('/login');
    } else {
      setSnackbar({ open: true, message: 'An error occurred. Please try again.', severity: 'error' });
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setEditedClient(client);
  };

  const handleSaveEdit = async () => {
    try {
        const submissionData = {
            first_name: editedClient.first_name || '',
            last_name: editedClient.last_name || '',
            number: editedClient.number || '',
            label_segmentation: Array.isArray(editedClient.label_segmentation) 
                ? editedClient.label_segmentation 
                : [],
            introduzione: editedClient.introduzione || '',
        };

        const response = await api.put(`/Clients/${id}/`, submissionData);
        
        if (response.status === 200) {
            setClient(response.data);
            setEditMode(false);
            setSnackbar({ 
                open: true, 
                message: 'Client updated successfully!', 
                severity: 'success' 
            });
        }
    } catch (error) {
        console.error('Error updating client:', error);
        handleApiError(error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedClient(prev => ({
        ...prev,
        [name]: name === 'label_segmentation' ? 
                (Array.isArray(value) ? value : [value]) :
                value
    }));
  };

  const handleAddFeedback = () => {
    setFeedbackDialogOpen(true);
  };

  const handleFeedbackChange = (event) => {
    const { name, value } = event.target;
    setNewFeedback((prev) => ({ ...prev, [name]: name === 'rating' ? parseInt(value, 10) : value }));
  };

  const handleFeedbackSubmit = async () => {
    try {
      const response = await api.post('/Client-feedback/', {
        ...newFeedback,
        clients: parseInt(id, 10),
      });
      setFeedback([response.data, ...feedback]);
      setFeedbackDialogOpen(false);
      setNewFeedback({ rating: 5, comment: '' });
      setSnackbar({ open: true, message: 'Feedback added successfully!', severity: 'success' });
    } catch (error) {
      console.error('Error adding feedback:', error);
      handleApiError(error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const fetchAndNavigateToLatestChat = async (clientId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/Clients/client_chats/?client_id=${clientId}`);
      if (response.data && response.data.length > 0) {
        const latestChat = response.data[0];
        navigate(`/client-chat/${latestChat.unique_id}`);
      }
    } catch (error) {
      console.error('Error fetching chats:', error);
      setSnackbar({
        open: true,
        message: 'Failed to load chat history',
        severity: 'error'
      });
    }
  };

  const handleCopyLink = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url).then(() => {
      setSnackbar({ 
        open: true, 
        message: 'Link copied to clipboard!', 
        severity: 'success' 
      });
    }).catch(err => {
      console.error('Failed to copy link:', err);
      setSnackbar({ 
        open: true, 
        message: 'Failed to copy link', 
        severity: 'error' 
      });
    });
  };

  if (loading) {
    return <ClientDetailsSkeleton />;
  }

  if (!client) {
    return <Typography>Client not found.</Typography>;
  }

  const actions = [
    { 
      icon: <PhoneIcon />, 
      name: 'Call', 
      onClick: () => {
        if (client.number) {
          window.location.href = `tel:${client.number}`;
        }
      },
      color: 'success.main'
    },
    { 
      icon: <WhatsAppIcon />, 
      name: 'WhatsApp', 
      onClick: () => {
        if (client.number) {
          const formattedNumber = client.number.replace(/[\s()-]/g, '');
          window.open(`https://wa.me/${formattedNumber}`, '_blank');
        }
      },
      color: '#25D366'
    },
    { icon: <MessageIcon />, name: 'Send Message', onClick: () => {} },
    { icon: <ScheduleIcon />, name: 'Schedule Meeting', onClick: () => {} },
    { icon: <NoteAddIcon />, name: 'Add Note', onClick: () => {} },
    { 
      icon: <LinkIcon />, 
      name: 'Copy Link', 
      onClick: handleCopyLink,
      color: 'info.main'
    },
  ];

  const tabs = [
    { label: "Overview", value: 0 },
    { label: "Chats", value: 1 },
    { label: "Analytics", value: 2 }
  ];

  return (
    <MobileContainer>
      <Box sx={{ maxWidth: '1200px', padding: { xs: 2, sm: 3 }, margin: 'auto' }}>
        <Breadcrumbs sx={{ mb: 2, mt: { xs: 3, sm: 0 } }}>
          <Link color="inherit" href="/dashboard">
            Dashboard
          </Link>
          <Link color="inherit" href="/clients">
            Clients
          </Link>
          <Typography color="textPrimary">
            {client.first_name} {client.last_name}
          </Typography>
        </Breadcrumbs>

        <MobileActionButton
          onClick={() => navigate('/clients')}
          variant="outlined"
          startIcon={<ArrowBack />}
          sx={{ mb: 2, mt: { xs: 2, sm: 0 } }}
        >
          Back to Clients List
        </MobileActionButton>

        <ClientHeader>
          <ClientAvatar>
            {client.first_name?.[0]}{client.last_name?.[0]}
          </ClientAvatar>
          <Box sx={{ flex: 1, width: { xs: '100%', sm: 'auto' } }}>
            <Typography variant="h4" gutterBottom>
              {client.first_name} {client.last_name}
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              gap: 1, 
              flexWrap: 'wrap',
              width: '100%'
            }}>
              {client.label_segmentation_names.map((segment, index) => (
                <Chip
                  key={index}
                  label={segment}
                  color="primary"
                  size="small"
                  variant="outlined"
                  sx={{ mb: 0.5 }}
                />
              ))}
            </Box>
            {client.number && (
              <Box sx={{ 
                display: { xs: 'none', sm: 'flex' }, 
                gap: 2, 
                mt: 2,
                flexWrap: 'wrap',
                alignItems: 'center'
              }}>
                <Typography variant="body1" sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  gap: 1,
                  color: 'text.secondary'
                }}>
                  <PhoneIcon fontSize="small" />
                  {client.number}
                </Typography>
                <ContactActionButton
                  variant="contained"
                  color="success"
                  startIcon={<PhoneIcon />}
                  onClick={() => window.location.href = `tel:${client.number}`}
                  size="small"
                >
                  Call
                </ContactActionButton>
                <ContactActionButton
                  variant="contained"
                  sx={{
                    bgcolor: '#25D366',
                    '&:hover': {
                      bgcolor: '#1fa855'
                    }
                  }}
                  startIcon={<WhatsAppIcon />}
                  onClick={() => {
                    const formattedNumber = client.number.replace(/[\s()-]/g, '');
                    window.open(`https://wa.me/${formattedNumber}`, '_blank');
                  }}
                  size="small"
                >
                  WhatsApp
                </ContactActionButton>
              </Box>
            )}
          </Box>
          {canEdit && (
            <Button
              variant="contained"
              startIcon={<EditIcon />}
              onClick={handleEditClick}
              sx={{ alignSelf: 'flex-start' }}
            >
              Edit Profile
            </Button>
          )}
        </ClientHeader>

        <Tabs 
          value={activeTab} 
          onChange={handleTabChange} 
          sx={{ mb: 3 }}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Overview" />
          <Tab label="Chats" />
          <Tab label="Analytics" />
        </Tabs>

        {activeTab === 0 && (
          <Grid container spacing={3} sx={{ pb: { xs: 10, sm: 2 } }}>
            <Grid item xs={12} md={8}>
              <StyledCard>
                <CardContent>
                  {editMode ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="First Name"
                          name="first_name"
                          value={editedClient.first_name}
                          onChange={handleInputChange}
                          disabled={!canEdit}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Last Name"
                          name="last_name"
                          value={editedClient.last_name}
                          onChange={handleInputChange}
                          disabled={!canEdit}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Phone"
                          name="number"
                          value={editedClient.number}
                          onChange={handleInputChange}
                          disabled={!canEdit}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          label="Sede"
                          name="introduzione"
                          value={editedClient.introduzione}
                          onChange={handleInputChange}
                          disabled={!canEdit}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button onClick={handleSaveEdit} variant="contained" color="primary" sx={{ mr: 1 }} disabled={!canEdit}>
                          Save
                        </Button>
                        <Button onClick={handleCancelEdit} variant="outlined">
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      <Typography variant="h5" gutterBottom>
                        {client.first_name} {client.last_name}
                      </Typography>
                      <IconText variant="body1">
                        <Phone />
                        {client.number}
                      </IconText>
                      <LocationCard>
                        <LocationIcon className="location-icon" />
                        <Box className="location-content">
                          <Typography variant="subtitle2" color="primary" gutterBottom>
                            Sede preferita
                          </Typography>
                          <Typography variant="body2">
                            {client.location || 'No location specified'}
                          </Typography>
                        </Box>
                      </LocationCard>
                    </>
                  )}
                </CardContent>
              </StyledCard>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <StyledCard>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6">Feedback</Typography>
                    {canCreate && (
                      <Button
                        variant="outlined"
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={handleAddFeedback}
                      >
                        Add Feedback
                      </Button>
                    )}
                  </Box>
                  {feedback.length === 0 ? (
                    <Typography variant="body2" color="textSecondary">
                      No feedback available
                    </Typography>
                  ) : (
                    <Box sx={{ maxHeight: 400, overflowY: 'auto' }}>
                      {feedback.map((item, index) => (
                        <Box key={index} sx={{ mb: 2 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                            <Rating value={item.rating} readOnly size="small" />
                            <Typography variant="caption" color="textSecondary" sx={{ ml: 1 }}>
                              {new Date(item.created_at).toLocaleDateString()}
                            </Typography>
                          </Box>
                          <Typography variant="body2">{item.comment}</Typography>
                          {index < feedback.length - 1 && <Divider sx={{ my: 2 }} />}
                        </Box>
                      ))}
                    </Box>
                  )}
                </CardContent>
              </StyledCard>
            </Grid>
          </Grid>
        )}

        {activeTab === 1 && (
          <ChatSection 
            d={client}
            setSnackbar={setSnackbar}
          />
        )}

        {activeTab === 2 && (
          <AnalyticsVisualizations 
            navigationData={navigationData} 
            selectedSessionId={selectedSessionId}
          />
        )}

        <FloatingActionButton
          ariaLabel="Quick Actions"
          icon={<SpeedDialIcon />}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.onClick}
              sx={action.color ? {
                '& .MuiSpeedDialAction-fab': {
                  bgcolor: action.color,
                  color: 'white',
                  '&:hover': {
                    bgcolor: action.color,
                    opacity: 0.9
                  }
                }
              } : {}}
            />
          ))}
        </FloatingActionButton>

        <Dialog open={feedbackDialogOpen} onClose={() => setFeedbackDialogOpen(false)}>
          <DialogTitle>Add New Feedback for {client.first_name} {client.last_name}</DialogTitle>
          <DialogContent>
            <TextField
              name="rating"
              label="Rating"
              type="number"
              value={newFeedback.rating}
              onChange={handleFeedbackChange}
              fullWidth
              margin="normal"
              inputProps={{ min: 1, max: 5 }}
            />
            <TextField
              name="comment"
              label="Comment"
              value={newFeedback.comment}
              onChange={handleFeedbackChange}
              fullWidth
              margin="normal"
              multiline
              rows={4}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setFeedbackDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleFeedbackSubmit} color="primary" variant="contained" disabled={!canCreate}>
              Submit</Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          TransitionComponent={(props) => <Slide {...props} direction="up" />}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </MobileContainer>
  );
};

export default ClientDetailsPage;
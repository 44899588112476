import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://admin.aitomotivelab.com/user_management/api';

export const fetchLocations = async (companyId) => {
  const response = await axios.get(`${API_URL}/locations/`, {
    params: { company: companyId },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });
  console.log("location",response.data);
  return response.data;
};

export const createLocation = async (companyId, locationData) => {
  const data = {
    ...locationData,
    company: companyId
  };

  const response = await axios.post(
    `${API_URL}/locations/`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }
  );
  return response.data;
};

export const updateLocation = async (companyId, locationId, locationData) => {
  const data = {
    ...locationData,
    company: companyId
  };

  const response = await axios.put(
    `${API_URL}/locations/${locationId}/`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }
  );
  return response.data;
};

export const deleteLocation = async (locationId) => {
  await axios.delete(`${API_URL}/locations/${locationId}/`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });
};